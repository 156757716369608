var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grow d-flex flex-column flex-nowrap",attrs:{"fluid":""}},[_c('v-row',{staticClass:"grow"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-img',{class:_vm.coverImageClass,attrs:{"max-height":"100%","max-width":"100%","aspect-ratio":16 / 9,"src":require("../../static/login-landscape.jpg")}})],1),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","height":"75%"}},[_c('v-img',{attrs:{"max-height":"75%","aspect-ratio":1769 / 802,"src":require("../../static/veglogic-logo.jpg")}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"white--text"},[_vm._v(" Please enter your email address. You will receive a reset password link to that email. ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"email","vid":"resetPasswordFormEmail","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors,"required":"","filled":""},model:{value:(_vm.resetPasswordFormEmail),callback:function ($$v) {_vm.resetPasswordFormEmail=$$v},expression:"resetPasswordFormEmail"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"confirm email","rules":"confirmed:resetPasswordFormEmail|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirm Email","error-messages":errors,"required":"","filled":""},model:{value:(_vm.resetPasswordFormEmailConfirm),callback:function ($$v) {_vm.resetPasswordFormEmailConfirm=$$v},expression:"resetPasswordFormEmailConfirm"}})]}}],null,true)})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"large":"","type":"submit","color":"primary"},on:{"click":function($event){return _vm.forgotPassword()}}},[_vm._v("Reset Password ")])],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('a',{staticClass:"text-body-2 text-decoration-underline white--text",on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v(" < Back to Login ")])])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }