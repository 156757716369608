import { MigrationInterface, QueryRunner, TableColumn } from 'typeorm'

export class AddInteractiveSelectedValue1683812592138
  implements MigrationInterface
{
  public async up(queryRunner: QueryRunner): Promise<void> {
    let vegeFilters = await queryRunner.hasTable('vege_issue_applied_filters')
    if (vegeFilters === false) {
      console.log('No filter tables created yet - bail out of migration')
      return
    }

    await queryRunner.addColumn(
      'vege_issue_applied_filters',
      new TableColumn({
        name: 'interactiveSelectedValue',
        type: 'integer',
        isNullable: true
      })
    )
  }

  public async down(queryRunner: QueryRunner): Promise<void> {
    await queryRunner.dropColumn(
      'vege_issue_applied_filters',
      'interactiveSelectedValue'
    )
  }
}
