
























































































































































































































































































































import Vue from 'vue'
import { getYear } from 'date-fns'
import {
  ManagementAreaResult,
  VegetationIssueResult
} from '../veg-common/apiTypes'
import { getListOfMostRecentFollowups } from '../util/IssueHelpers'
import * as drawing from '../util/drawing'

export const cmp: any = Vue.extend({
  components: {
    MapComponent: () => import('./MapComponent.vue')
  },
  data(): AreaInformationDialogI {
    return {
      drawingInProgress: false,
      mapIsLoaded: false,
      mostRecentIssueList: []
    }
  },
  props: {
    selectedAreaForDisplay: {
      type: Object as () => ManagementAreaResult
    },
    areaDialog: {
      type: Boolean as () => boolean
    }
  },
  computed: {
    dialog(): boolean {
      return this.areaDialog
    },
    highRiskColour(): string {
      return drawing.highRiskColour
    },
    medRiskColour(): string {
      return drawing.medRiskColour
    },
    lowRiskColour(): string {
      return drawing.lowRiskColour
    },
    noRiskColour(): string {
      return drawing.noRiskColour
    },
    defaultColour(): string {
      return drawing.defaultColour
    },
    totalIssues(): number {
      return this.mostRecentIssueList.length
    },
    assignedIssueLength(): number {
      let assignedIssues = 0
      for (const issue of this.mostRecentIssueList) {
        if (issue.isInteractive) {
          assignedIssues++
        }
      }
      return assignedIssues
    },
    highRiskIssueLength(): number {
      let highRiskIssues = 0
      for (const issue of this.mostRecentIssueList) {
        if (issue.has_issue && issue.risk_score === 10 && issue.isInteractive) {
          highRiskIssues++
        }
      }
      return highRiskIssues
    },
    medRiskIssueLength(): number {
      let medRiskIssues = 0
      for (const issue of this.mostRecentIssueList) {
        if (issue.has_issue && issue.risk_score === 5 && issue.isInteractive) {
          medRiskIssues++
        }
      }
      return medRiskIssues
    },
    lowRiskIssueLength(): number {
      let lowRiskIssues = 0
      for (const issue of this.mostRecentIssueList) {
        if (issue.has_issue && issue.risk_score === 1 && issue.isInteractive) {
          lowRiskIssues++
        }
      }
      return lowRiskIssues
    },
    noRiskIssueLength(): number {
      let noRiskIssues = 0
      for (const issue of this.mostRecentIssueList) {
        if (issue.has_issue && issue.risk_score === 0 && issue.isInteractive) {
          noRiskIssues++
        }
      }
      return noRiskIssues
    },
    resolvedIssuesLength(): number {
      let resolvedIssues = 0
      for (const issue of this.mostRecentIssueList) {
        if (!issue.has_issue) {
          resolvedIssues++
        }
      }
      return resolvedIssues
    },
    unAssignedIssueLength(): number {
      let unAssignedIssues = 0
      for (const issue of this.mostRecentIssueList) {
        if (!issue.isInteractive) {
          unAssignedIssues++
        }
      }
      return unAssignedIssues
    },
    selectedYear(): number | null {
      return this.$typedStore.state.selectedAssetOverviewYear
    },
    dialogBoxWidth(): string {
      if (this.isSmallScreen) {
        return 'width:90vw;'
      } else {
        return 'width:50vw;'
      }
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.xsOnly
    },
    mapHeightStyle(): string {
      if (this.isSmallScreen) {
        return 'height: 20vh'
      } else {
        return 'height: 35vh'
      }
    },
    isClientAdmin(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 1
      }
      return false
    },
    isThirdPartyAdmin(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 2
      }
      return false
    },
    isFieldUser(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 3
      }
      return false
    }
  },
  watch: {
    dialog(): void {
      if (this.dialog) {
        if (this.selectedAreaForDisplay) {
          let workingList = getListOfMostRecentFollowups(
            this.selectedAreaForDisplay.issues
          )
          if (this.selectedYear) {
            let mostRecentList: VegetationIssueResult[] = []

            for (let i = 0; workingList.length > i; i++) {
              if (
                getYear(new Date(workingList[i].inspection_date)) ===
                this.selectedYear
              ) {
                mostRecentList.push(workingList[i])
              }
            }
            this.mostRecentIssueList = mostRecentList
          } else {
            this.mostRecentIssueList = workingList
          }
        }

        if (this.mapIsLoaded) {
          this.drawAreaOnMap()
        }
      }
    }
  },
  methods: {
    mapLoaded(): void {
      // This gets run after initializeMap emits "mapLoaded"
      // So we don't try to add markers before the map is ready
      this.mapIsLoaded = true
      this.drawAreaOnMap()
    },
    drawAreaOnMap(): void {
      this.drawingInProgress = true

      let foundPolygon = this.$typedStore.getters.managementAreaPolygons.find(
        (polygon) => {
          return polygon.area.id === this.selectedAreaForDisplay.id
        }
      )
      if (foundPolygon) {
        //@ts-ignore
        this.$refs.mapComp.drawPolygon(foundPolygon, false)
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnArea(foundPolygon.area.shape, 18)
      } else {
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnAsset()
      }
      this.drawingInProgress = false
    },
    closeDialog(): void {
      this.$emit('closeDialog')
    }
  }
})
export default cmp
interface AreaInformationDialogI {
  drawingInProgress: boolean
  mapIsLoaded: boolean
  mostRecentIssueList: VegetationIssueResult[]
}
