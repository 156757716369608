













import Vue from 'vue'
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartData,
  ChartOptions
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default Vue.extend({
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    stackBars: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Object as () => ChartData,
      default: () => {}
    }
  },
  data(): BarChartI {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            stacked: this.stackBars,
            ticks: {
              color: '#FFFFFF',
              callback: function (value: string | number) {
                try {
                  if (Number(value) % 1 === 0) {
                    return value
                  }
                } catch (e) {
                  console.log(e)
                }
                return
              }
            }
          },
          x: {
            stacked: this.stackBars,
            ticks: {
              color: '#FFFFFF'
            }
          }
        }
      }
    }
  }
})

interface BarChartI {
  chartOptions: ChartOptions
}
