



















import Vue from 'vue'

export default Vue.extend({
  data() {
    return { adminLoginFormUsername: '', adminLoginFormPassword: '' }
  },
  methods: {
    doAdminLogin(e: Event) {
      e.preventDefault()
      let loginParams = {
        username: this.adminLoginFormUsername,
        password: this.adminLoginFormPassword
      }
      let adminLoginCall = this.$typedStore.state.token
        ? this.$rpc('callAdminLoginAuth', loginParams)
        : this.$rpc('callAdminLogin', loginParams)
      return adminLoginCall
        .then((result) => {
          if (result.success) {
            console.log('Admin login successful')
            this.adminLoginFormUsername = ''
            this.adminLoginFormPassword = ''
          } else {
            this.$typedStore.commit('setSnackbarParams', {
              type: 'error',
              msg: 'Incorrect username or password'
            })
          }
        })
        .catch((err) => {
          // TODO: Report error to user
          console.log(err)
        })
    }
  }
})
