import { BaseEntity } from './BaseEntity'
import { Entity, Column } from 'typeorm'
import { CulvertResult, NewCulvertParams } from '../../veg-common/apiTypes'
import { CulvertObject } from '../../veg-common/apiTypes/newResultTypes'
import { IsInt, IsNumber, IsString, IsBoolean } from 'class-validator'

@Entity()
export class CulvertResultEntity extends BaseEntity {
  constructor(item?: CulvertObject) {
    super()
    if (item) {
      this.id = item.id
      this.is_fish_habitat = item.is_fish_habitat
      this.site_culvert_id = item.site_culvert_id
      this.road_name = item.road_name
      this.has_markers = item.has_markers
      this.length = item.length
      this.diameter = item.diameter
      this.inlet_location = JSON.stringify(item.inlet_location)
      this.outlet_location = JSON.stringify(item.outlet_location)
      this.direction_of_flow = item.direction_of_flow
      this.assetId = item.assetId
    }
  }

  toApiType(): CulvertObject {
    return {
      id: this.id,
      is_fish_habitat: this.is_fish_habitat,
      site_culvert_id: this.site_culvert_id,
      road_name: this.road_name,
      has_markers: this.has_markers,
      length: this.length,
      diameter: this.diameter,
      inlet_location: JSON.parse(this.inlet_location),
      outlet_location: JSON.parse(this.outlet_location),
      direction_of_flow: this.direction_of_flow,
      assetId: this.assetId
    }
  }

  @Column({ type: 'int', unique: true })
  @IsInt()
  id!: number

  @Column('boolean')
  @IsBoolean()
  is_fish_habitat!: boolean

  @Column('text')
  @IsString()
  site_culvert_id!: string

  @Column('text')
  @IsString()
  road_name!: string

  @Column('boolean')
  @IsBoolean()
  has_markers!: boolean

  //length in m
  @Column('float')
  @IsNumber()
  length!: number

  //diameter in m
  @Column('float')
  @IsNumber()
  diameter!: number

  @Column('text')
  @IsString()
  inlet_location!: string

  @Column('text')
  @IsString()
  outlet_location!: string

  @Column('text')
  @IsString()
  direction_of_flow!: string

  @Column('int')
  @IsNumber()
  assetId!: number
}

@Entity()
export class NewCulvertParamsEntity extends BaseEntity {
  constructor(item?: NewCulvertParams) {
    super()
    if (item) {
      this.is_fish_habitat = item.is_fish_habitat
      this.site_culvert_id = item.site_culvert_id
      this.road_name = item.road_name
      this.has_markers = item.has_markers
      this.length = item.length
      this.diameter = item.diameter
      this.inlet_location = JSON.stringify(item.inlet_location)
      this.outlet_location = JSON.stringify(item.outlet_location)
      this.direction_of_flow = item.direction_of_flow
      this.assetId = item.assetId
      this.uuid = item.uuid
    }
  }

  toApiType(): NewCulvertParams {
    return new NewCulvertParams({
      is_fish_habitat: this.is_fish_habitat,
      site_culvert_id: this.site_culvert_id,
      road_name: this.road_name,
      has_markers: this.has_markers,
      length: this.length,
      diameter: this.diameter,
      inlet_location: JSON.parse(this.inlet_location),
      outlet_location: JSON.parse(this.outlet_location),
      direction_of_flow: this.direction_of_flow,
      assetId: this.assetId,
      uuid: this.uuid
    })
  }

  toResultType(): CulvertResult {
    return {
      id: this.sqliteId * -1,
      is_fish_habitat: this.is_fish_habitat,
      site_culvert_id: this.site_culvert_id,
      road_name: this.road_name,
      has_markers: this.has_markers,
      length: this.length,
      diameter: this.diameter,
      inlet_location: JSON.parse(this.inlet_location),
      outlet_location: JSON.parse(this.outlet_location),
      direction_of_flow: this.direction_of_flow,
      assetId: this.assetId,
      uuid: this.uuid,
      culvertInspections: []
    }
  }

  @Column('boolean')
  @IsBoolean()
  is_fish_habitat!: boolean

  @Column('text')
  @IsString()
  site_culvert_id!: string

  @Column('text')
  @IsString()
  road_name!: string

  @Column('boolean')
  @IsBoolean()
  has_markers!: boolean

  //length in m
  @Column('float')
  @IsNumber()
  length!: number

  //diameter in m
  @Column('float')
  @IsNumber()
  diameter!: number

  @Column('text')
  @IsString()
  inlet_location!: string

  @Column('text')
  @IsString()
  outlet_location!: string

  @Column('text')
  @IsString()
  direction_of_flow!: string

  @Column({ type: 'text', default: '' })
  @IsString()
  uuid!: string

  @Column('int')
  @IsNumber()
  assetId!: number
}
