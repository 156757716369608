var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',{staticClass:"pa-3"},[_c('ValidationObserver',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[(_vm.isLinkedIssue)?_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-col',_vm._g({attrs:{"cols":"7","sm":"8"}},on),[_c('ValidationProvider',{attrs:{"name":"management area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',_vm._g({staticClass:"mt-0 pt-0 pr-2 mx-0",attrs:{"items":_vm.managementAreas,"error-messages":errors,"label":"Select Management Area","hide-details":"auto","item-text":"name","item-value":"id","disabled":""},model:{value:(_vm.selectedManagementArea),callback:function ($$v) {_vm.selectedManagementArea=$$v},expression:"selectedManagementArea"}},on))]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v("The management area cannot be changed when an issue is linked with another issue.")])]):_c('v-col',{attrs:{"cols":"7","sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"management area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-0 pt-0 pr-2 mx-0",attrs:{"items":_vm.managementAreas,"error-messages":errors,"label":"Select Management Area","hide-details":"auto","item-text":"name","item-value":"id","disabled":_vm.singleArea},model:{value:(_vm.selectedManagementArea),callback:function ($$v) {_vm.selectedManagementArea=$$v},expression:"selectedManagementArea"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"5","sm":"4"}},[_c('SeeAreasDialog',{attrs:{"managementAreas":_vm.managementAreas,"subAssetData":_vm.subAssetData}})],1)],1),_c('v-row',[_c('v-divider',{staticClass:"accentAjmBlue mt-1"})],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('span',{staticClass:"align-start"},[_vm._v("Problem Vegetation Identified")])])],1),_c('v-row',{staticClass:"mt-0 mb-2 pt-0",attrs:{"no-gutters":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"problem identified","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mb-0 mt-2 py-0",attrs:{"mandatory":true,"row":"","hide-details":"auto","error-messages":errors},on:{"change":_vm.problemsFoundChanged},model:{value:(_vm.problemsFound),callback:function ($$v) {_vm.problemsFound=$$v},expression:"problemsFound"}},[_c('v-radio',{attrs:{"label":"Yes","value":true}}),_c('v-radio',{attrs:{"label":"No","value":false}})],1)]}}],null,true)})],1)],1),(_vm.problemsFound)?[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Vegetation Height","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-2 py-0",attrs:{"label":"Vegetation Height","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.vegHeight),callback:function ($$v) {_vm.vegHeight=$$v},expression:"vegHeight"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-0 py-0 flex-grow-1",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"type of problem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-4 pt-0 pr-2",attrs:{"items":_vm.relations.issueTypes,"error-messages":errors,"label":"Type of Problem Vegetation","hide-detail":"auto","item-text":"issue_type","item-value":"id","disabled":!_vm.isClientUser},on:{"change":_vm.selectedTypeOfProblemChanged},model:{value:(_vm.selectedTypeOfProblem),callback:function ($$v) {_vm.selectedTypeOfProblem=$$v},expression:"selectedTypeOfProblem"}})]}}],null,true)})],1),_c('DrawGpsPointComponent',{attrs:{"managementAreas":_vm.managementAreas,"selectedManagementArea":_vm.selectedManagementArea,"gpsPoint":_vm.gps_point},on:{"setGpsError":_vm.setGpsError,"savePoint":_vm.savePoint}}),_c('DrawGpsLineComponent',{ref:"lineComponent",attrs:{"managementAreas":_vm.managementAreas,"selectedManagementArea":_vm.selectedManagementArea,"gpsTrack":_vm.gps_track},on:{"setGpsError":_vm.setGpsError,"saveTrack":_vm.saveTrack}})],1),(_vm.gpsError)?_c('v-row',{staticClass:"px-0 mx-0 mb-3",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-col',[_c('span',{staticClass:"complementaryRed--text"},[_vm._v(" A gps field is required, either a point or a delineation ")])])],1):_vm._e(),_c('v-row',[_c('v-divider',{staticClass:"accentAjmBlue mb-2 mt-0"})],1),_c('v-row',[_c('v-col',{staticClass:"mb-1 mt-2 py-0"},[_c('span',{staticClass:"align-start"},[_vm._v("Estimated Vegetation Risk")]),_c('ValidationProvider',{attrs:{"name":"risk","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-slider',{attrs:{"tick-labels":_vm.riskLabels,"disabled":_vm.disableSlider,"min":0,"max":3,"step":"1","ticks":"always"},model:{value:(_vm.riskSlider),callback:function ($$v) {_vm.riskSlider=$$v},expression:"riskSlider"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-divider',{staticClass:"accentAjmBlue mt-1"})],1),_c('v-row',[_c('v-col',{staticClass:"mb-0 mt-5 py-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"action taken","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-3 pt-0",attrs:{"error-messages":errors,"items":_vm.relations.takenActions,"label":"Action Taken","hide-details":"auto","item-text":"issue_action","item-value":"id"},model:{value:(_vm.actionTaken),callback:function ($$v) {_vm.actionTaken=$$v},expression:"actionTaken"}})]}}],null,true)})],1),(!_vm.isSmallScreen)?_c('v-col',{staticClass:"my-0 py-0",attrs:{"md":"6"}},[_c('EditPhotoGalleryComponent',{ref:"photoComp",attrs:{"id":"camera","existingPhotos":_vm.photos},on:{"photoLoading":_vm.onPhotoLoading,"removeExistingPhoto":_vm.removeExistingPhoto},model:{value:(_vm.newPhotos),callback:function ($$v) {_vm.newPhotos=$$v},expression:"newPhotos"}})],1):_vm._e()],1),(_vm.selectedTypeOfProblem === _vm.weedId)?_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"physiological stage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-1",attrs:{"error-messages":errors,"items":_vm.relations.physiologicalStages,"label":"Physiological Stage of Vegetation","hide-details":"auto","item-text":"physiological_stage","item-value":"id"},model:{value:(_vm.selectedPhysStage),callback:function ($$v) {_vm.selectedPhysStage=$$v},expression:"selectedPhysStage"}})]}}],null,true)})],1)],1):_vm._e(),(
            _vm.selectedTypeOfProblem === _vm.weedId ||
            _vm.selectedTypeOfProblem === _vm.attractantIssueTypeId
          )?_c('div',[_c('v-row',[_c('v-col',{staticClass:"mt-2 pt-2 pb-0",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"weed species","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-1",attrs:{"error-messages":errors,"items":_vm.selectableSpecies,"label":"Select Species of Weeds","hide-details":"auto","item-text":"species","return-object":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"primary accentAjmBlue--text",attrs:{"input-value":data.selected,"close":""},on:{"click:close":function($event){return _vm.removeSpecies(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.species)+" ")])]}}],null,true),model:{value:(_vm.selectedWeedSpecies),callback:function ($$v) {_vm.selectedWeedSpecies=$$v},expression:"selectedWeedSpecies"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-0 mt-2 py-0",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"distribution","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-1",attrs:{"error-messages":errors,"items":_vm.relations.distributions,"label":"Distribution of Vegetation","hide-details":"auto","item-text":"distribution","item-value":"id"},model:{value:(_vm.selectedDistribution),callback:function ($$v) {_vm.selectedDistribution=$$v},expression:"selectedDistribution"}})]}}],null,true)})],1)],1)],1):_vm._e(),(
            _vm.selectedTypeOfProblem === _vm.vegAboveGroundPipeId ||
            _vm.selectedTypeOfProblem === _vm.vegBelowGroundPipeId ||
            _vm.selectedTypeOfProblem === _vm.vegNearInfrastructureId
          )?_c('v-row',{staticClass:"mb-0 mt-4 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"type of vegetation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-1",attrs:{"error-messages":errors,"items":_vm.relations.infraVegetationTypes,"label":"Type of Vegetation","hide-details":"auto","item-text":"vegetation_type","item-value":"id"},model:{value:(_vm.selectedInfraVegeType),callback:function ($$v) {_vm.selectedInfraVegeType=$$v},expression:"selectedInfraVegeType"}})]}}],null,true)})],1)],1):_vm._e(),(
            _vm.selectedTypeOfProblem === _vm.vegAboveGroundPipeId ||
            _vm.selectedTypeOfProblem === _vm.vegBelowGroundPipeId
          )?_c('v-row',[_c('v-col',{staticClass:"mb-0 mt-2 py-0",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"proximity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-0 pt-0",attrs:{"error-messages":errors,"items":_vm.relations.proximities,"label":"Proximity to Infrastructure","hide-details":"auto","item-text":"proximity","item-value":"id"},model:{value:(_vm.selectedProximity),callback:function ($$v) {_vm.selectedProximity=$$v},expression:"selectedProximity"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.selectedTypeOfProblem === _vm.vegAboveGroundPipeId)?_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"mt-3 mb-0 py-0",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"near low drain","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mb-0 mt-2 py-0",attrs:{"error-messages":errors,"row":"","hide-details":"auto"},model:{value:(_vm.nearLowDrain),callback:function ($$v) {_vm.nearLowDrain=$$v},expression:"nearLowDrain"}},[_c('span',[_vm._v("Is The Issue Near a Low Point Drain?")]),_c('v-radio',{staticClass:"ml-auto",attrs:{"label":"Yes","value":true}}),_c('v-radio',{attrs:{"label":"No","value":false}})],1)]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mb-0 mt-3 py-0",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"action required","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mb-0 mt-2 py-0",attrs:{"row":"","hide-details":"auto"},model:{value:(_vm.actionRequired),callback:function ($$v) {_vm.actionRequired=$$v},expression:"actionRequired"}},[_c('span',[_vm._v("Further Action Required?")]),_c('v-radio',{staticClass:"ml-auto",attrs:{"label":"Yes","value":true}}),_c('v-radio',{attrs:{"label":"No","value":false}})],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.noRiskRemindDialog),callback:function ($$v) {_vm.noRiskRemindDialog=$$v},expression:"noRiskRemindDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Risk Level ")]),_c('v-card-text',[_vm._v(" The risk level has to be No Risk when no further action required ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.noRiskRemindDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)],1),_c('v-row',[(_vm.actionRequired)?_c('v-col',{staticClass:"mt-0 mb-3 py-0",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"required actions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-3 pt-0",attrs:{"error-messages":errors,"items":_vm.relations.requiredActions,"label":"Actions Required","hide-details":"auto","item-text":"issue_action","return-object":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"primary accentAjmBlue--text",attrs:{"input-value":data.selected,"close":""},on:{"click:close":function($event){return _vm.removeRequiredAction(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.issue_action)+" ")])]}}],null,true),model:{value:(_vm.selectedActionsRequired),callback:function ($$v) {_vm.selectedActionsRequired=$$v},expression:"selectedActionsRequired"}})]}}],null,true)})],1):_vm._e()],1)]:_vm._e(),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-dialog',{ref:"dateDialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('ValidationProvider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',_vm._g({staticClass:"mb-2 py-0",attrs:{"error-message":errors,"value":_vm.humanReadableDate,"label":"Inspection Date","readonly":"","hide-details":"auto"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.dateDialog),callback:function ($$v) {_vm.dateDialog=$$v},expression:"dateDialog"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","full-width":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateDialog = false}}},[_vm._v("Ok")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-dialog',{ref:"timeDialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('ValidationProvider',{attrs:{"name":"time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',_vm._g({staticClass:"mb-2 py-0",attrs:{"error-messages":errors,"value":_vm.time,"label":"Inspection Time","readonly":"","hide-details":"auto"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.timeDialog),callback:function ($$v) {_vm.timeDialog=$$v},expression:"timeDialog"}},[(_vm.timeDialog)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timeDialog = false}}},[_vm._v("Ok")])],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"comments","rules":"max:1024"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mt-2 mb-2",attrs:{"error-messages":errors,"label":"Comments","auto-grow":"","rows":"4","outlined":"","hide-details":"auto"},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})]}}],null,true)})],1)],1),_c('v-row',[(_vm.isSmallScreen)?_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('EditPhotoGalleryComponent',{ref:"photoComp",attrs:{"existingPhotos":_vm.photos},on:{"photoLoading":_vm.onPhotoLoading,"removeExistingPhoto":_vm.removeExistingPhoto},model:{value:(_vm.newPhotos),callback:function ($$v) {_vm.newPhotos=$$v},expression:"newPhotos"}})],1):_vm._e()],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"primary accentAjmBlue--text",attrs:{"diabled":_vm.photoLoading,"loading":_vm.photoLoading},on:{"click":_vm.validateInputs}},[_vm._v(" Save Edits ")])],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"text":"","diabled":_vm.photoLoading,"loading":_vm.photoLoading},on:{"click":_vm.clearEdits}},[_vm._v(" Clear Edits ")])],1)],1)]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.exitEditingDialog),callback:function ($$v) {_vm.exitEditingDialog=$$v},expression:"exitEditingDialog"}},[_c('v-card',{staticClass:"ajmBlue"},[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Exit Editing Issue ")]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-2 pt-2"},[_vm._v(" Are you sure you want to exit editing issue? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":function($event){_vm.exitEditingDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"secondary accentAjmBlue--text mr-2",attrs:{"text":""},on:{"click":_vm.exit}},[_vm._v(" Exit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }