import { BaseEntity } from './BaseEntity'
import { Entity, Column } from 'typeorm'
import { IsString } from 'class-validator'
import { RelationsObject } from '../../veg-common/apiTypes/newResultTypes'

@Entity()
export class RelationsEntity extends BaseEntity {
  constructor(x?: RelationsObject) {
    super()
    if (x) {
      this.takenActions = JSON.stringify(x.takenActions)
      this.requiredActions = JSON.stringify(x.requiredActions)
      this.distributions = JSON.stringify(x.distributions)
      this.issueTypes = JSON.stringify(x.issueTypes)
      this.physiologicalStages = JSON.stringify(x.physiologicalStages)
      this.proximities = JSON.stringify(x.proximities)
      this.species = JSON.stringify(x.species)
      this.weedVegetationTypes = JSON.stringify(x.weedVegetationTypes)
      this.infraVegetationTypes = JSON.stringify(x.infraVegetationTypes)
      this.culvertStatuses = JSON.stringify(x.culvertStatuses)
      this.culvertTakenActions = JSON.stringify(x.culvertTakenActions)
      this.culvertRequiredActions = JSON.stringify(x.culvertRequiredActions)
    }
  }

  toApiType(): RelationsObject {
    return {
      takenActions: JSON.parse(this.takenActions),
      requiredActions: JSON.parse(this.requiredActions),
      distributions: JSON.parse(this.distributions),
      issueTypes: JSON.parse(this.issueTypes),
      physiologicalStages: JSON.parse(this.physiologicalStages),
      proximities: JSON.parse(this.proximities),
      species: JSON.parse(this.species),
      weedVegetationTypes: JSON.parse(this.weedVegetationTypes),
      infraVegetationTypes: JSON.parse(this.infraVegetationTypes),
      culvertStatuses: JSON.parse(this.culvertStatuses),
      culvertTakenActions: JSON.parse(this.culvertTakenActions),
      culvertRequiredActions: JSON.parse(this.culvertRequiredActions)
    }
  }

  @Column('text')
  @IsString()
  takenActions!: string

  @Column('text')
  @IsString()
  requiredActions!: string

  @Column('text')
  @IsString()
  distributions!: string

  @Column('text')
  @IsString()
  issueTypes!: string

  @Column('text')
  @IsString()
  physiologicalStages!: string

  @Column('text')
  @IsString()
  proximities!: string

  @Column('text')
  @IsString()
  species!: string

  @Column('text')
  @IsString()
  weedVegetationTypes!: string

  @Column('text')
  @IsString()
  infraVegetationTypes!: string

  @Column('text')
  @IsString()
  culvertStatuses!: string

  @Column('text')
  @IsString()
  culvertTakenActions!: string

  @Column('text')
  @IsString()
  culvertRequiredActions!: string
}
