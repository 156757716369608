import {
  GetThirdPartyCompanyResponse,
  SaveThirdPartyCompanyRequest,
  DeleteThirdPartyCompanyRequest,
  SaveAssignedIssuesRequest,
  TableSaveAssignedIssuesRequest,
  GetThirdPartyCompanyRelationsResponse,
  TransferIssuesRequest,
  DeleteAssignedIssueRequest
} from '../veg-common/apiTypes/thirdPartyCompanies'
import { GenericResult } from '../veg-common/apiTypes'
export const thirdPartyCompanyApiDef = {
  callGetCompanyRelations: {
    route: '/thirdPartyCompanies/getCompanyRelations',
    method: 'get',
    auth: true,
    inputType: 'void',
    outputType: GetThirdPartyCompanyRelationsResponse,
    returnsMultiple: true
  },
  callGetThirdPartyCompanies: {
    route: '/thirdPartyCompanies/getThirdPartyCompanies',
    method: 'get',
    auth: true,
    inputType: 'void',
    outputType: GetThirdPartyCompanyResponse,
    returnsMultiple: true
  },
  callSaveThirdPartyCompanies: {
    route: '/thirdPartyCompanies/saveCompanies',
    method: 'post',
    auth: true,
    inputType: SaveThirdPartyCompanyRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callSaveAssignedIssues: {
    route: '/thirdPartyCompanies/saveAssignedIssues',
    method: 'post',
    auth: true,
    inputType: SaveAssignedIssuesRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callTableSaveAssignedIssues: {
    route: '/thirdPartyCompanies/tableSaveAssignedIssues',
    method: 'post',
    auth: true,
    inputType: TableSaveAssignedIssuesRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callDeleteThirdPartyCompanies: {
    route: '/thirdPartyCompanies/delete',
    method: 'post',
    auth: true,
    inputType: DeleteThirdPartyCompanyRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callTransferIssues: {
    route: '/thirdPartyCompanies/transferIssues',
    method: 'post',
    auth: true,
    inputType: TransferIssuesRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callDeleteAssignedIssue: {
    route: '/thirdPartyCompanies/deleteAssignedIssue',
    method: 'post',
    auth: true,
    inputType: DeleteAssignedIssueRequest,
    outputType: GenericResult,
    returnsMultiple: false
  }
} as const
