
























































import Vue from 'vue'
import {
  AdminCreateUserParams,
  AdminGetCompanyResult
} from '../../veg-common/apiTypes'

export default Vue.extend({
  data(): AdminCreateUserDataI {
    return {
      companies: [],
      user: new AdminCreateUserParams(),
      roles: ['Admin', 'Field User']
    }
  },
  methods: {
    async onSubmit(): Promise<boolean> {
      let result = await this.$rpc('callAdminCreateUser', this.user)
      if (result.success) {
        console.log('AdminCreateUser success')
      } else {
        console.log('AdminCreateUser failed')
      }
      this.user = new AdminCreateUserParams()
      return result.success
    }
  },
  created() {
    this.$rpc('callAdminGetAllCompanies')
      .then(result => {
        this.companies = result
      })
      .catch(err => {
        console.log('Error getting companies')
        console.log(err)
      })
  }
})

interface AdminCreateUserDataI {
  companies: AdminGetCompanyResult[]
  user: AdminCreateUserParams
  roles: string[]
}
