

































































































import Vue from 'vue'
import VegeIssueInfoDialog from './VegeIssueView/VegeIssueInfoDialog.vue'
import VegeIssueFilterComponent from '../components/VegeIssueFilterComponent.vue'

import {
  VegetationIssueResult,
  RelationsRes,
  SubAssetResult,
  ManagementAreaResult,
  GetThirdPartyCompanyRelationsResponse
} from '../veg-common/apiTypes'
import { isoToHumanDateTime } from './../util/date-time'
import { riskToColour } from '../util/drawing'
import { DataTableHeader } from 'vuetify/types'
import { getListOfMostRecentFollowups } from '../util/IssueHelpers'

export default Vue.extend({
  components: {
    VegeIssueInfoDialog,
    VegeIssueFilterComponent
  },
  props: {
    vegeIssueData: {
      type: Array as () => VegetationIssueResult[],
      default: (): VegetationIssueResult[] => []
    },
    relations: {
      type: Object as () => RelationsRes,
      default: () => {}
    },
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    },
    managementAreas: {
      type: Array as () => ManagementAreaResult[],
      default: (): ManagementAreaResult[] => []
    }
  },
  data(): assignedIssuesData {
    return {
      headers: [
        {
          text: 'Risk Level',
          value: 'riskColor',
          sortable: false,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Area Name',
          value: 'area',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Date Inspected',
          value: 'inspection_date',
          class: 'font-weight-black white--text'
        },
        {
          text: 'VegLogic Id',
          value: 'originalIssueId',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Issue Type',
          value: 'issue_type',
          class: 'font-weight-black white--text'
        },

        {
          text: 'Details',
          value: 'detailsDialog',
          sortable: false,
          class: 'font-weight-black white--text'
        }
      ],
      thirdPartyCompaniesData: [],
      companyName: '',
      selectedRows: [],
      thirdPartyCompanyId: null,
      assignIssuesDialog: false,
      filteredDataForTable: [],
      assignableIssueData: []
    }
  },
  methods: {
    setIssueData(filteredDataForTable: VegetationIssueResult[]) {
      this.filteredDataForTable = filteredDataForTable
    },
    formatInspectionDate(date: string) {
      return isoToHumanDateTime(date)
    },
    setRiskColour(item: VegetationIssueResult): string {
      return riskToColour(item.risk_score, item.isInteractive)
    },
    openAssignIssuesDialog() {
      if (this.selectedRows.length <= 0) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No issue selected.'
        })
      } else {
        this.assignIssuesDialog = true
      }
    },
    async assignIssues(): Promise<void> {
      let originalIds: number[] = []
      this.selectedRows.map((issue) =>
        issue.original_issue_id
          ? originalIds.push(issue.original_issue_id)
          : originalIds.push(issue.id)
      )
      if (this.thirdPartyCompanyId && originalIds.length > 0) {
        try {
          this.$rpc('callSaveAssignedIssues', {
            original_issue_ids: originalIds,
            company_id: this.thirdPartyCompanyId
          }).then(() => this.getRelatedThirdPartyCompanies())
        } catch (e) {
          console.log(e)
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Error saving data.'
          })
        }
      }
      this.selectedRows = []
      this.assignIssuesDialog = false
    },
    async getRelatedThirdPartyCompanies(): Promise<void> {
      this.thirdPartyCompanyId = Number(this.$route.params.id)
      try {
        let result = await this.$rpc('callGetCompanyRelations')
        this.thirdPartyCompaniesData = result
      } catch (e) {
        console.log(e)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error retrieving data.'
        })
      }
    }
  },
  created() {
    this.getRelatedThirdPartyCompanies().then(() => {
      let company = this.thirdPartyCompaniesData.find(
        (c) => c.company.id === this.thirdPartyCompanyId
      )

      let mostRecentFollowups = getListOfMostRecentFollowups(this.vegeIssueData)

      if (company) {
        this.companyName = company.company.company_name
        let assignedIssues = company.company.vegetationIssues

        let tableData: VegetationIssueResult[] = []
        if (assignedIssues && assignedIssues.length > 0) {
          tableData = mostRecentFollowups.filter(
            (entry1: VegetationIssueResult) =>
              entry1.has_issue &&
              !assignedIssues.some(
                (entry2: VegetationIssueResult) =>
                  entry1.id === entry2.id ||
                  entry1.original_issue_id === entry2.id
              )
          )
        }
        this.assignableIssueData = this.filteredDataForTable = tableData
      } else {
        this.assignableIssueData = this.filteredDataForTable = []
      }
    })
  }
})
interface assignedIssuesData {
  headers: DataTableHeader[]
  selectedRows: VegetationIssueResult[]
  thirdPartyCompaniesData: GetThirdPartyCompanyRelationsResponse[]
  companyName: string
  thirdPartyCompanyId: null | number
  assignIssuesDialog: boolean
  filteredDataForTable: VegetationIssueResult[]
  assignableIssueData: VegetationIssueResult[]
}
