import {
  IsInt,
  IsNumber,
  IsString,
  IsBoolean,
  ValidateNested,
  IsDefined,
  Length,
  IsOptional,
} from 'class-validator'
import { Geometry } from 'geojson'
import { CulvertInspectionResult } from './culvertInspections'

export class CulvertResult {
  constructor(item: CulvertResult) {
    if (item) {
      this.id = item.id
      this.is_fish_habitat = item.is_fish_habitat
      this.site_culvert_id = item.site_culvert_id
      this.road_name = item.road_name
      this.has_markers = item.has_markers
      this.length = item.length
      this.diameter = item.diameter
      this.inlet_location = item.inlet_location
      this.outlet_location = item.outlet_location
      this.direction_of_flow = item.direction_of_flow
      this.assetId = item.assetId
      this.uuid = item.uuid
    }
  }

  @IsInt()
  id!: number

  @IsBoolean()
  is_fish_habitat!: boolean

  @IsString()
  site_culvert_id!: string

  @IsString()
  road_name!: string

  @IsBoolean()
  has_markers!: boolean

  //length in m
  //if is -1 then we didn't have the value, and needs to be updated later by someone in the field (after VT-251 is done)
  @IsNumber()
  length!: number

  //diameter in m
  //if is -1 then we didn't have the value, and needs to be updated later by someone in the field (after VT-251 is done)
  @IsNumber()
  diameter!: number

  //still need geometry validator
  inlet_location!: Geometry

  //still need geometry validator
  outlet_location!: Geometry

  @IsString()
  direction_of_flow!: string

  @IsNumber()
  assetId!: number

  @IsString()
  @IsOptional()
  uuid?: string

  @ValidateNested()
  culvertInspections!: CulvertInspectionResult[]
}

export class EditCulvertParams {
  constructor(item: EditCulvertParams) {
    if (item) {
      this.id = item.id
      this.is_fish_habitat = item.is_fish_habitat
      this.site_culvert_id = item.site_culvert_id
      this.road_name = item.road_name
      this.has_markers = item.has_markers
      this.length = item.length
      this.diameter = item.diameter
      this.inlet_location = item.inlet_location
      this.outlet_location = item.outlet_location
      this.direction_of_flow = item.direction_of_flow
    }
  }

  @IsInt()
  id!: number

  @IsBoolean()
  is_fish_habitat!: boolean

  @IsString()
  site_culvert_id!: string

  @IsString()
  road_name!: string

  @IsBoolean()
  has_markers!: boolean

  //length in m
  @IsNumber()
  length!: number

  //diameter in m
  @IsNumber()
  diameter!: number

  @IsDefined()
  //still need geometry validator
  inlet_location!: Geometry

  @IsDefined()
  //still need geometry validator
  outlet_location!: Geometry

  @IsString()
  direction_of_flow!: string
}

export class NewCulvertParams {
  constructor(item: NewCulvertParams) {
    if (item) {
      this.is_fish_habitat = item.is_fish_habitat
      this.site_culvert_id = item.site_culvert_id
      this.road_name = item.road_name
      this.has_markers = item.has_markers
      this.length = item.length
      this.diameter = item.diameter
      this.inlet_location = item.inlet_location
      this.outlet_location = item.outlet_location
      this.direction_of_flow = item.direction_of_flow
      this.assetId = item.assetId
      this.uuid = item.uuid
    }
  }

  @IsBoolean()
  is_fish_habitat!: boolean

  @IsString()
  site_culvert_id!: string

  @IsString()
  road_name!: string

  @IsBoolean()
  has_markers!: boolean

  //length in m
  @IsNumber()
  length!: number

  //diameter in m
  @IsNumber()
  diameter!: number

  @IsDefined()
  //still need geometry validator
  inlet_location!: Geometry

  @IsDefined()
  //still need geometry validator
  outlet_location!: Geometry

  @IsString()
  direction_of_flow!: string

  @IsNumber()
  assetId!: number

  @IsString()
  @Length(36, 36)
  uuid!: string
}
