import {
  IsOptional,
  ArrayUnique,
  IsDateString,
  IsBoolean,
  IsInt,
  IsNumber,
  IsString,
  Length
} from 'class-validator'
import { Geometry } from 'geojson'

export class IssuePdfParams {
  constructor(params: IssuePdfParams) {
    if (params) {
      this.assetId = params.assetId
      this.issueId = params.issueId
    }
  }

  @IsInt()
  assetId!: number

  @IsInt()
  issueId!: number
}

export class IssueImageParams {
  constructor(params: IssueImageParams) {
    if (params) {
      this.key = params.key
    }
  }

  @IsString()
  key!: string
}

export class VegetationIssueParams {
  constructor(x: VegetationIssueParams) {
    if (x) {
      this.uuid = x.uuid
      this.inspection_date = x.inspection_date
      this.has_issue = x.has_issue
      this.veg_height = x.veg_height
      this.risk_score = x.risk_score
      this.area_size = x.area_size
      this.gps_track = x.gps_track
      this.gps_point = x.gps_point
      this.action_required = x.action_required
      this.individual_count = x.individual_count
      this.density = x.density
      this.comments = x.comments
      this.areaId = x.areaId
      this.userId = x.userId
      this.actionTakenId = x.actionTakenId
      this.distributionId = x.distributionId
      this.physiologicalStageId = x.physiologicalStageId
      this.issueTypeId = x.issueTypeId
      this.vegetationTypeId = x.vegetationTypeId
      this.proximityId = x.proximityId
      this.speciesIds = x.speciesIds
      this.actionsRequiredIds = x.actionsRequiredIds
      this.originalIssueId = x.originalIssueId
      this.near_low_point_drain = x.near_low_point_drain
      this.thirdPartyCompanyIds = x.thirdPartyCompanyIds
    }
  }

  @IsString()
  @Length(36, 36)
  uuid!: string

  @IsDateString()
  inspection_date!: string

  @IsBoolean()
  has_issue!: boolean

  @IsNumber()
  risk_score!: number

  @IsString()
  veg_height!: string

  @IsOptional()
  @IsNumber()
  area_size!: number | null

  @IsOptional()
  //@NeedGeometryValidator
  gps_track!: Geometry | null

  @IsOptional()
  //@NeedGeometryValidator
  gps_point!: Geometry | null

  @IsOptional()
  @IsBoolean()
  action_required!: boolean | null

  @IsOptional()
  @IsNumber()
  individual_count!: number | null

  @IsOptional()
  @IsNumber()
  density!: number | null

  @IsString()
  @Length(0, 1024)
  comments!: string

  @IsInt()
  areaId!: number

  @IsInt()
  userId!: number

  @IsOptional()
  @IsInt()
  actionTakenId!: number | null

  @IsOptional()
  @ArrayUnique()
  actionsRequiredIds!: number[] | null

  @IsOptional()
  @IsInt()
  distributionId!: number | null

  @IsOptional()
  @IsInt()
  physiologicalStageId!: number | null

  @IsOptional()
  @IsInt()
  issueTypeId!: number | null

  @IsOptional()
  @IsInt()
  vegetationTypeId!: number | null

  @IsOptional()
  @IsInt()
  proximityId!: number | null

  @IsOptional()
  @ArrayUnique()
  speciesIds!: number[] | null

  @IsOptional()
  @IsInt()
  originalIssueId!: number | null

  @IsOptional()
  @IsBoolean()
  near_low_point_drain!: boolean | null

  @IsOptional()
  @ArrayUnique()
  thirdPartyCompanyIds!: number[] | null
}

export class VegetationIssueResult {
  constructor(x: VegetationIssueResult) {
    if (x) {
      this.id = x.id
      this.photos = x.photos
      this.inspection_date = x.inspection_date
      this.has_issue = x.has_issue
      this.risk_score = x.risk_score
      this.area_size = x.area_size
      this.gps_track = x.gps_track
      this.gps_point = x.gps_point
      this.action_taken = x.action_taken
      this.action_required = x.action_required
      this.actionsRequired = x.actionsRequired
      this.individual_count = x.individual_count
      this.comments = x.comments
      this.area = x.area
      this.user = x.user
      this.distribution = x.distribution
      this.physiological_stage = x.physiological_stage
      this.issue_type = x.issue_type
      this.species = x.species
      this.vegetation_type = x.vegetation_type
      this.proximity = x.proximity
      this.density = x.density
      this.original_issue_id = x.original_issue_id
      this.subsequentIssueIds = x.subsequentIssueIds
      this.near_low_point_drain = x.near_low_point_drain
      this.isInteractive = x.isInteractive
    }
  }

  @IsInt()
  id!: number

  @ArrayUnique()
  photos!: string[]

  @IsDateString()
  inspection_date!: string

  @IsBoolean()
  has_issue!: boolean

  @IsNumber()
  risk_score!: number

  @IsOptional()
  @IsNumber()
  area_size!: number | null

  @IsOptional()
  //@NeedGeometryValidator
  gps_track!: Geometry | null

  @IsOptional()
  //@NeedGeometryValidator
  gps_point!: Geometry | null

  @IsOptional()
  @IsString()
  action_taken!: string | null

  @IsOptional()
  @ArrayUnique()
  actionsRequired!: string[] | null

  @IsOptional()
  @IsBoolean()
  action_required!: boolean | null

  @IsOptional()
  @IsNumber()
  individual_count!: number | null

  @IsString()
  comments!: string

  @IsString()
  area!: string

  @IsString()
  user!: string

  @IsOptional()
  @IsString()
  distribution!: string | null

  @IsOptional()
  @IsString()
  physiological_stage!: string | null

  @IsOptional()
  @IsString()
  issue_type!: string | null

  @IsOptional()
  @ArrayUnique()
  species!: string[] | null

  @IsOptional()
  @IsString()
  vegetation_type!: string | null

  @IsOptional()
  @IsString()
  proximity!: string | null

  @IsOptional()
  @IsNumber()
  density!: number | null

  @IsOptional()
  @IsInt()
  original_issue_id!: number | null

  @IsOptional()
  subsequentIssueIds!: number[]

  @IsOptional()
  @IsBoolean()
  near_low_point_drain!: boolean | null

  @IsBoolean()
  isInteractive!: boolean
}

export class UpdateIssueRequest {
  constructor(x: UpdateIssueRequest) {
    if (x) {
      this.id = x.id
      this.inspection_date = x.inspection_date
      this.has_issue = x.has_issue
      this.veg_height = x.veg_height
      this.risk_score = x.risk_score
      this.area_size = x.area_size
      this.action_required = x.action_required
      this.individual_count = x.individual_count
      this.density = x.density
      this.comments = x.comments
      this.areaId = x.areaId
      this.actionTakenId = x.actionTakenId
      this.actionsRequiredIds = x.actionsRequiredIds
      this.distributionId = x.distributionId
      this.physiologicalStageId = x.physiologicalStageId
      this.issueTypeId = x.issueTypeId
      this.vegetationTypeId = x.vegetationTypeId
      this.proximityId = x.proximityId
      this.speciesIds = x.speciesIds
      this.near_low_point_drain = x.near_low_point_drain
      this.gps_point = x.gps_point
      this.gps_track = x.gps_track
    }
  }

  @IsInt()
  id!: number

  @IsDateString()
  inspection_date!: string

  @IsBoolean()
  has_issue!: boolean

  @IsString()
  veg_height!: string

  @IsNumber()
  risk_score!: number

  @IsOptional()
  @IsNumber()
  area_size!: number | null

  @IsOptional()
  @IsBoolean()
  action_required!: boolean | null

  @IsOptional()
  @IsNumber()
  individual_count!: number | null

  @IsOptional()
  @IsNumber()
  density!: number | null

  @IsString()
  @Length(0, 1024)
  comments!: string

  @IsInt()
  areaId!: number

  @IsOptional()
  @IsInt()
  actionTakenId!: number | null

  @IsOptional()
  @ArrayUnique()
  actionsRequiredIds!: number[] | null

  @IsOptional()
  @IsInt()
  distributionId!: number | null

  @IsOptional()
  @IsInt()
  physiologicalStageId!: number | null

  @IsOptional()
  @IsInt()
  issueTypeId!: number | null

  @IsOptional()
  @IsInt()
  vegetationTypeId!: number | null

  @IsOptional()
  @IsInt()
  proximityId!: number | null

  @IsOptional()
  @ArrayUnique()
  speciesIds!: number[] | null

  @IsOptional()
  @IsBoolean()
  near_low_point_drain!: boolean | null

  @IsOptional()
  //@NeedGeometryValidator
  gps_track!: Geometry | null

  @IsOptional()
  //@NeedGeometryValidator
  gps_point!: Geometry | null
}
