import { BaseEntity } from './BaseEntity'
import { Entity, Column } from 'typeorm'
import { CulvertInspectionParams } from '../../veg-common/apiTypes'
import {
  CulvertInspectionsObject,
  UserObject
} from '../../veg-common/apiTypes/newResultTypes'
import {
  IsBoolean,
  IsInt,
  IsNumber,
  IsString,
  IsDateString,
  IsOptional,
  Length
} from 'class-validator'

@Entity()
export class CulvertInspectionResultEntity extends BaseEntity {
  constructor(item?: CulvertInspectionsObject) {
    super()
    if (item) {
      this.id = item.id
      this.photos = JSON.stringify(item.photos)
      this.inspection_date = item.inspection_date
      this.next_inspection_date = item.next_inspection_date
      this.comments = item.comments
      this.risk_score = item.risk_score
      this.userId = item.userId
      this.user = JSON.stringify(item.user)
      this.culvertId = item.culvertId
      this.culvertStatusId = item.culvertStatusId
      this.hidden = item.hidden

      this.actionsTakenIds = item.actionsTakenIds
        ? JSON.stringify(item.actionsTakenIds)
        : null
      this.actionsRequiredIds = item.actionsRequiredIds
        ? JSON.stringify(item.actionsRequiredIds)
        : null
    }
  }
  toApiType(): CulvertInspectionsObject {
    return {
      id: this.id,
      photos: JSON.parse(this.photos),
      inspection_date: this.inspection_date,
      next_inspection_date: this.next_inspection_date,
      comments: this.comments,
      risk_score: this.risk_score,
      userId: this.userId,
      user: JSON.parse(this.user),
      culvertId: this.culvertId,
      culvertStatusId: this.culvertStatusId,
      hidden: this.hidden,

      actionsTakenIds: this.actionsTakenIds
        ? JSON.parse(this.actionsTakenIds)
        : [],
      actionsRequiredIds: this.actionsRequiredIds
        ? JSON.parse(this.actionsRequiredIds)
        : []
    }
  }

  @Column({ type: 'int', unique: true })
  @IsInt()
  id!: number

  @Column('text')
  @IsString()
  photos!: string

  @Column('text')
  @IsDateString()
  inspection_date!: string

  @Column('text')
  @IsDateString()
  next_inspection_date!: string

  @Column('text')
  @IsString()
  comments!: string

  @Column('int')
  @IsNumber()
  risk_score!: number

  @Column('int')
  @IsString()
  userId!: number

  @Column('text')
  @IsString()
  user!: string

  @Column('int')
  @IsInt()
  culvertId!: number

  @Column('int')
  @IsInt()
  culvertStatusId!: number

  @Column('boolean')
  @IsBoolean()
  hidden!: boolean

  @Column({ type: 'text', nullable: true })
  @IsString()
  actionsTakenIds!: string | null

  @Column({ type: 'text', nullable: true })
  @IsString()
  actionsRequiredIds!: string | null
}

@Entity()
export class CulvertInspectionParamsEntity extends BaseEntity {
  constructor(item?: CulvertInspectionParams) {
    super()
    if (item) {
      this.uuid = item.uuid
      this.inspection_date = item.inspection_date
      this.next_inspection_date = item.next_inspection_date
      this.comments = item.comments
      this.risk_score = item.risk_score
      this.userId = item.userId
      this.culvertId = item.culvertId
      this.culvertStatusId = item.culvertStatusId
      this.hidden = item.hidden

      this.actionsTakenIds = item.actionsTakenIds
        ? JSON.stringify(item.actionsTakenIds)
        : null
      this.actionsRequiredIds = item.actionsRequiredIds
        ? JSON.stringify(item.actionsRequiredIds)
        : null
      this.culvertUuid = item.culvertUuid ? item.culvertUuid : null
    }
  }

  toApiType(): CulvertInspectionParams {
    return new CulvertInspectionParams({
      uuid: this.uuid,
      inspection_date: this.inspection_date,
      next_inspection_date: this.next_inspection_date,
      comments: this.comments,
      risk_score: this.risk_score,
      userId: this.userId,
      culvertId: this.culvertId,
      culvertStatusId: this.culvertStatusId,
      hidden: this.hidden,

      actionsTakenIds: this.actionsTakenIds
        ? JSON.parse(this.actionsTakenIds)
        : null,
      actionsRequiredIds: this.actionsRequiredIds
        ? JSON.parse(this.actionsRequiredIds)
        : null,
      culvertUuid: this.culvertUuid ? this.culvertUuid : undefined
    })
  }

  toResultType(): CulvertInspectionsObject {
    return {
      id: this.sqliteId * -1,
      photos: [],
      inspection_date: this.inspection_date,
      next_inspection_date: this.next_inspection_date,
      comments: this.comments,
      risk_score: this.risk_score,
      userId: this.userId,
      user: {} as UserObject, //placeholder to be filled in store getter
      culvertId: this.culvertId,
      culvertStatusId: this.culvertStatusId,
      hidden: this.hidden,

      actionsTakenIds: this.actionsTakenIds
        ? JSON.parse(this.actionsTakenIds)
        : [],
      actionsRequiredIds: this.actionsRequiredIds
        ? JSON.parse(this.actionsRequiredIds)
        : [],
      culvertUuid: this.culvertUuid ? this.culvertUuid : null
    }
  }

  @Column({ type: 'text', unique: true })
  @IsString()
  @Length(36, 36)
  uuid!: string

  @Column('text')
  @IsDateString()
  inspection_date!: string

  @Column('text')
  @IsDateString()
  next_inspection_date!: string

  @Column('text')
  @IsString()
  comments!: string

  @Column('int')
  @IsNumber()
  risk_score!: number

  @Column('int')
  @IsNumber()
  userId!: number

  @Column('int')
  @IsNumber()
  culvertId!: number

  @Column('int')
  @IsNumber()
  culvertStatusId!: number

  @Column({ type: 'text', nullable: true, default: '' })
  @IsString()
  @IsOptional()
  @Length(36, 36)
  culvertUuid!: string | null

  @Column({ type: 'boolean', default: false })
  @IsBoolean()
  hidden!: boolean

  @Column({ type: 'text', nullable: true })
  @IsOptional()
  @IsString()
  actionsTakenIds!: string | null

  @Column({ type: 'text', nullable: true })
  @IsOptional()
  @IsString()
  actionsRequiredIds!: string | null
}
