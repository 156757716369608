













































































































































































import Vue from 'vue'
import { DataTableHeader } from 'vuetify/types'
import { CreateUserRequest } from '../../veg-common/apiTypes'
import { UserObject } from '../../veg-common/apiTypes/newResultTypes'

export default Vue.extend({
  data(): UsersI {
    return {
      roles: ['Admin', 'Field User'],
      search: '',
      selectedRow: [],
      newUser: {
        user_name: '',
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        user_role: ''
      },
      deactivateUser: false,
      reactiveUser: false,
      userRequestDialog: false,
      selectedUser: null,
      selectedUserName: ''
    }
  },
  computed: {
    usersData(): UserObject[] {
      let users: UserObject[] = this.$typedStore.getters.usersArray.sort(
        (x, y) => {
          //@ts-ignore
          return y.is_active - x.is_active
        }
      )
      if (users.length > 0) {
        let styleText = '--text font-italic'
        for (let user of users) {
          switch (user.userRoleId) {
            case 1:
              if (user.is_active) {
                user.user_role = 'Admin'
                user.style = 'secondary' + styleText
              } else {
                user.user_role = 'Deactivated'
                user.style = 'white' + styleText
              }
              break
            case 2:
              if (user.is_active) {
                user.user_role = 'Third Party Admin'
                user.style = 'success' + styleText
              } else {
                user.user_role = 'Deactivated'
                user.style = 'white' + styleText
              }
              break
            case 3:
              if (user.is_active) {
                user.user_role = 'Field User'
                user.style = 'primary' + styleText
              } else {
                user.user_role = 'Deactivated'
                user.style = 'white' + styleText
              }
              break
          }
        }
      }
      return users
    },
    isAdmin(): boolean {
      return this.$typedStore.getters.isAdminRole
    },
    headers(): DataTableHeader[] {
      let headers: DataTableHeader[] = [
        {
          text: 'User Role',
          value: 'user_role',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'User Name',
          value: 'user_name',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Email',
          value: 'email',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'First Name',
          value: 'first_name',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Last Name',
          value: 'last_name',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: '',
          value: 'reactive',
          sortable: true,
          class: 'font-weight-black white--text'
        }
      ]
      return headers
    },
    dialogText(): string {
      if (!this.selectedUser) {
        return ''
      } else {
        let part1 = 'Are you sure you want to'
        return this.reactiveUser
          ? part1 + ' reactive ' + this.selectedUser.user_name + '?'
          : part1 + ' deactive ' + this.selectedUser.user_name + '?'
      }
    }
  },
  methods: {
    userStyle(item: UserObject): string {
      return !item.is_active ? 'deactivatedStyle' : ''
    },
    closeDialog(): void {
      this.userRequestDialog = false
      this.deactivateUser = false
      this.reactiveUser = false
    },
    reactiveUserAction(item: UserObject): void {
      this.userRequestDialog = true
      this.reactiveUser = true
      this.selectedUser = item
    },
    async reactiveSubmit(): Promise<void> {
      try {
        if (this.selectedUser) {
          await this.$rpc('callToggleUserActivation', {
            id: this.selectedUser.id
          })
          this.$emit('requestUpdate')
          this.userRequestDialog = false
          this.selectedUser = null
          this.reactiveUser = false
        }
      } catch (e) {
        console.log(e)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error deactivating selected user.'
        })
        this.selectedUser = null
        this.reactiveUser = false
      }
    },
    async onCreateUserSubmit(): Promise<boolean> {
      try {
        await this.$rpc('callUsersCreate', this.newUser)
        this.newUser = {
          user_name: '',
          email: '',
          first_name: '',
          last_name: '',
          password: '',
          user_role: ''
        }
        this.$typedStore.commit('setSnackbarParams', {
          type: 'success',
          msg: 'User created.'
        })
        this.$emit('requestUpdate')
        return true // To close dialog when done
      } catch (e) {
        console.log(e)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error retrieving data.'
        })
      }
      return false
    },
    openDeactivateUserDialog(): void {
      if (this.selectedRow.length <= 0) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No users selected.'
        })
      } else {
        this.userRequestDialog = true
        this.deactivateUser = true
        this.selectedUser = this.selectedRow[0]
      }
    },

    async deactiveUsers(): Promise<void> {
      try {
        if (this.selectedUser) {
          await this.$rpc('callToggleUserActivation', {
            id: this.selectedUser.id
          })
          this.$emit('requestUpdate')
          this.userRequestDialog = false
          this.selectedUser = null
          this.deactivateUser = false
          this.selectedRow = []
        }
      } catch (e) {
        console.log(e)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error deactivating selected user.'
        })
      }
    }
  }
})

interface UsersI {
  roles: string[]
  search: string
  newUser: CreateUserRequest
  deactivateUser: boolean
  reactiveUser: boolean
  selectedUser: null | UserObject
  userRequestDialog: boolean
  selectedUserName: string
  selectedRow: UserObject[]
}
