import { IsString, IsInt } from 'class-validator'

export class CreateAlertRequest {
  constructor(params: CreateAlertRequest) {
    if (params) {
      this.userId = params.userId
      this.alert_type = params.alert_type
    }
  }

  @IsInt()
  userId!: number

  @IsString()
  alert_type!: string
}

export class AlertResponse {
  constructor(params: AlertResponse) {
    if (params) {
      this.id = params.id
      this.alert_type = params.alert_type
    }
  }

  @IsInt()
  id!: number

  @IsString()
  alert_type!: string
}
