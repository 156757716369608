










































import Vue from 'vue'
import { CulvertResult } from '../../veg-common/apiTypes'
// @ts-ignore
import * as json2csv from 'json2csv'
import { DataTableHeader } from 'vuetify/types'
import { getLatestCulvertInspection } from '../../util/CulvertHelpers'
import { riskToColour } from '../../util/drawing'
import { format as dfnFormat } from 'date-fns'
import { feature, featureCollection, Feature } from '@turf/helpers'
import _pick from 'lodash/pick'
import { Point } from 'geojson'
import { isoToHumanDateTime } from '../../util/date-time'
// @ts-ignore
import tokml from 'tokml'
var utm = require('utm-latlng')

export default Vue.extend({
  props: {
    culverts: {
      type: Array as () => CulvertResult[],
      default: (): CulvertResult[] => []
    }
  },
  data(): CulvertInspectionTableI {
    return {
      selectedRows: [],
      tableExpansionPanel: false
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      return [
        {
          text: 'Culvert ID',
          align: 'start',
          value: 'site_culvert_id',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Road Name',
          value: 'road_name',
          class: 'font-weight-black white--text'
        },
        // {
        //   text: 'Details',
        //   value: 'details',
        //   sortable: false,
        //   width: '5%',
        //   align: 'center',
        //   class: 'font-weight-black white--text'
        // },
        {
          text: '',
          value: 'edit',
          filterable: false,
          sortable: false,
          width: '5%'
        }
      ]
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    tableExpansionPanelHeader(): string {
      return this.tableExpansionPanel ? 'Hide Culverts' : 'Show Culverts'
    }
  },
  methods: {
    downloadCsv(): void {
      let dataToDownload = this.culverts
      if (this.selectedRows.length > 0) {
        dataToDownload = this.selectedRows
      }

      let fields = [
        { label: 'Site Culvert Id', value: 'site_culvert_id' },
        { label: 'Road Name', value: 'road_name' },
        { label: 'Fish Habitat', value: 'is_fish_habitat' },
        { label: 'Diameter (m)', value: 'diameter' },
        { label: 'Length (m)', value: 'length' },
        { label: 'Direction of Flow', value: 'direction_of_flow' },
        { label: 'Inlet Latitude', value: 'inlet_lat' },
        { label: 'Inlet Longitude', value: 'inlet_long' },
        { label: 'Inlet UTM', value: 'inlet_utm' },
        { label: 'Outlet Latitude', value: 'outlet_lat' },
        { label: 'Outlet Longitude', value: 'outlet_long' },
        { label: 'Outlet UTM', value: 'outlet_utm' },
        { label: 'Has Markers?', value: 'has_markers' },
        // culvert fields
        { label: 'Has Culvert Inspection?', value: 'culvert_inspection' },
        { label: 'Actions Required', value: 'actions_required' },
        { label: 'Actions Taken', value: 'actions_taken' },
        { label: 'Comments', value: 'comments' },
        { label: 'Culvert Status', value: 'culvert_status' },
        { label: 'Inspection Date', value: 'inspection_date' },
        { label: 'Next Inspection Date', value: 'next_inspection_date' },
        { label: 'Photos Taken', value: 'photos' },
        { label: 'Risk Score', value: 'risk_score' },
        { label: 'User', value: 'user' }
      ]
      let culvertCsvData = []

      for (let selectedCulvertData of dataToDownload) {
        let DataForCsv: { [key: string]: number | string } = {}
        // culvert section
        DataForCsv.site_culvert_id = selectedCulvertData.site_culvert_id
        DataForCsv.road_name = selectedCulvertData.road_name
        selectedCulvertData.is_fish_habitat
          ? (DataForCsv.is_fish_habitat = 'Yes')
          : (DataForCsv.is_fish_habitat = 'No')
        DataForCsv.diameter = selectedCulvertData.diameter
        DataForCsv.length = selectedCulvertData.length
        DataForCsv.direction_of_flow = selectedCulvertData.direction_of_flow
        const inlet = selectedCulvertData.inlet_location as Point
        const outlet = selectedCulvertData.outlet_location as Point
        DataForCsv.inlet_lat = inlet.coordinates[1]
        DataForCsv.inlet_long = inlet.coordinates[0]
        let newUtm = new utm('GRS 1980')
        let inletLat = inlet.coordinates[1]
        let inletLng = inlet.coordinates[0]
        let outletLat = outlet.coordinates[1]
        let outletLng = outlet.coordinates[0]
        let precision = 0
        let inletUtm = newUtm.convertLatLngToUtm(inletLat, inletLng, precision)
        let outletUtm = newUtm.convertLatLngToUtm(
          outletLat,
          outletLng,
          precision
        )
        DataForCsv.inlet_utm = `${inletUtm.ZoneNumber} ${inletUtm.ZoneLetter} ${inletUtm.Easting} ${inletUtm.Northing}`
        DataForCsv.outlet_utm = `${outletUtm.ZoneNumber} ${outletUtm.ZoneLetter} ${outletUtm.Easting} ${outletUtm.Northing}`
        DataForCsv.outlet_lat = outlet.coordinates[1]
        DataForCsv.outlet_long = outlet.coordinates[0]
        selectedCulvertData.has_markers
          ? (DataForCsv.has_markers = 'Yes')
          : (DataForCsv.has_markers = 'No')
        // inspection section
        if (selectedCulvertData.culvertInspections.length) {
          let latestInspection = getLatestCulvertInspection(selectedCulvertData)
          if (latestInspection) {
            latestInspection
              ? (DataForCsv.culvert_inspection = 'Yes')
              : (DataForCsv.culvert_inspection = 'N/A')
            if (latestInspection.actionsRequired) {
              latestInspection.actionsRequired.length
                ? (DataForCsv.actions_required =
                    latestInspection.actionsRequired.join(', '))
                : (DataForCsv.actions_required = 'N/A')
            }
            if (latestInspection.actionsTaken) {
              latestInspection.actionsTaken.length
                ? (DataForCsv.actions_taken =
                    latestInspection.actionsTaken.join(', '))
                : (DataForCsv.actions_taken = 'N/A')
            }
            latestInspection.comments
              ? (DataForCsv.comments = latestInspection.comments)
              : (DataForCsv.comments = 'N/A')
            latestInspection.culvertStatus
              ? (DataForCsv.culvert_status = latestInspection.culvertStatus)
              : (DataForCsv.culvert_status = 'N/A')
            latestInspection.inspection_date
              ? (DataForCsv.inspection_date = isoToHumanDateTime(
                  latestInspection.inspection_date
                ))
              : (DataForCsv.inspection_date = 'N/A')
            latestInspection.next_inspection_date
              ? (DataForCsv.next_inspection_date = isoToHumanDateTime(
                  latestInspection.next_inspection_date
                ))
              : (DataForCsv.next_inspection_date = 'N/A')
            DataForCsv.photos = `${latestInspection.photos.length} photo(s) taken`
            switch (latestInspection.risk_score) {
              case 0:
                DataForCsv.risk_score = 'No Risk'
                break
              case 1:
                DataForCsv.risk_score = 'Low Risk'
                break
              case 5:
                DataForCsv.risk_score = 'Medium Risk'
                break
              case 10:
                DataForCsv.risk_score = 'High Risk'
                break
              default:
                DataForCsv.risk_score = 'N/A'
                break
            }
            latestInspection.user
              ? (DataForCsv.user = latestInspection.user)
              : (DataForCsv.user = 'N/A')
          }
        } else {
          DataForCsv.culvert_inspection = 'No inspection completed.'
        }
        culvertCsvData.push(DataForCsv)
      }
      let parser = new json2csv.Parser({ fields, excelStrings: true })
      let csv = parser.parse(culvertCsvData)
      let blob = new Blob([csv], { type: 'application/json' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `Culvert Export.csv`
      link.click()
    },
    downloadKml(): void {
      let dataToDownload = this.culverts
      if (this.selectedRows.length > 0) {
        dataToDownload = this.selectedRows
      }

      const culvert = (item: CulvertResult) => {
        return _pick(item, [
          'id',
          'is_fish_habitat',
          'site_culvert_id',
          'road_name',
          'has_markers',
          'direction_of_flow'
        ])
      }
      const features: Feature[] = dataToDownload.flatMap((row) => {
        let latestInspection = getLatestCulvertInspection(row)
        const inlet = row.inlet_location as Point
        const outlet = row.outlet_location as Point
        let newUtm = new utm('GRS 1980')
        let inletLat = inlet.coordinates[1]
        let inletLng = inlet.coordinates[0]
        let outletLat = outlet.coordinates[1]
        let outletLng = outlet.coordinates[0]
        let precision = 0
        let inletUtm = newUtm.convertLatLngToUtm(inletLat, inletLng, precision)
        let outletUtm = newUtm.convertLatLngToUtm(
          outletLat,
          outletLng,
          precision
        )
        let displayInletUtm = `${inletUtm.ZoneNumber} ${inletUtm.ZoneLetter} ${inletUtm.Easting} ${inletUtm.Northing}`
        let displayOutletUtm = `${outletUtm.ZoneNumber} ${outletUtm.ZoneLetter} ${outletUtm.Easting} ${outletUtm.Northing}`
        if (row) {
          if (
            latestInspection &&
            latestInspection.actionsTaken &&
            latestInspection.actionsRequired
          ) {
            let colour = riskToColour(latestInspection.risk_score)

            return [
              feature(row.inlet_location as Point, {
                // culvert
                'marker-color': colour,
                culvert_opening: 'inlet',
                'length_(m)': row.length,
                'diameter_(m)': row.diameter,
                inlet_latitude: inlet.coordinates[1],
                inlet_longitude: inlet.coordinates[0],
                inlet_utm: displayInletUtm,
                ...culvert(row),
                // inspection
                photos: `${latestInspection.photos.length} photo(s) taken`,
                inspection_date: isoToHumanDateTime(
                  latestInspection.inspection_date
                ),
                next_inspection_date: isoToHumanDateTime(
                  latestInspection.next_inspection_date
                ),
                comments: latestInspection.comments,
                risk_score: latestInspection.risk_score,
                user: latestInspection.user,
                culvert: latestInspection.culvert,
                culvert_status: latestInspection.culvertStatus,
                actions_taken: latestInspection.actionsTaken.join(', '),
                actions_required: latestInspection.actionsRequired.join(', ')
              }),
              feature(row.outlet_location as Point, {
                // culvert
                'marker-color': colour,
                culvert_opening: 'outlet',
                'length_(m)': row.length,
                'diameter_(m)': row.diameter,
                outlet_latitude: outlet.coordinates[1],
                outlet_longitude: outlet.coordinates[0],
                outlet_utm: displayOutletUtm,
                ...culvert(row),
                // inspection
                photos: `${latestInspection.photos.length} photo(s) taken`,
                inspection_date: isoToHumanDateTime(
                  latestInspection.inspection_date
                ),
                next_inspection_date: isoToHumanDateTime(
                  latestInspection.next_inspection_date
                ),
                comments: latestInspection.comments,
                risk_score: latestInspection.risk_score,
                user: latestInspection.user,
                culvert: latestInspection.culvert,
                culvert_status: latestInspection.culvertStatus,
                actions_taken: latestInspection.actionsTaken.join(', '),
                actions_required: latestInspection.actionsRequired.join(', ')
              })
            ]
          } else {
            return [
              feature(row.inlet_location as Point, {
                'marker-color': 'grey',
                culvert_opening: 'inlet',
                'length_(m)': row.length,
                'diameter_(m)': row.diameter,
                inlet_latitude: inlet.coordinates[1],
                inlet_longitude: inlet.coordinates[0],
                inlet_utm: displayInletUtm,
                ...culvert(row)
              }),
              feature(row.outlet_location as Point, {
                'marker-color': 'grey',
                culvert_opening: 'outlet',
                'length_(m)': row.length,
                'diameter_(m)': row.diameter,
                outlet_latitude: outlet.coordinates[1],
                outlet_longitude: outlet.coordinates[0],
                outlet_utm: displayOutletUtm,
                ...culvert(row)
              })
            ]
          }
        } else {
          return []
        }
      })
      const kml = tokml(featureCollection(features), {
        documentName: 'Culvert Export',
        documentDescription:
          'Culvert exported at ' + dfnFormat(new Date(), 'yyyy-MM-dd HH:mm'),
        name: 'id',
        timestamp: 'inspection_date',
        simplestyle: true
      })

      let link = document.createElement('a')

      link.setAttribute(
        'href',
        'data:application/vnd.google-earth.kml+xml;charset=utf-8,' +
          encodeURIComponent(kml)
      )

      link.setAttribute('download', 'culvert.kml')
      link.click()
    },
    editCulvert(culvert: CulvertResult): void {
      if ('id' in culvert) {
        this.$router.push({
          path: `/culverts/edit/${culvert.id}`
        })
      }
    }
  },
  watch: {
    selectedRows() {
      this.$emit('culvertsSelected', this.selectedRows.length > 0)
    }
  }
})

interface CulvertInspectionTableI {
  selectedRows: CulvertResult[]
  tableExpansionPanel: boolean
}
