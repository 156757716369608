











































































































































import Vue from 'vue'
import { DataTableHeader } from 'vuetify/types'
import {
  VegetationIssueResult,
  RelationsRes,
  GetThirdPartyCompanyRelationsResponse,
  GetThirdPartyCompanyResponse
} from '../veg-common/apiTypes'
import VegeIssueInfoDialog from './VegeIssueView/VegeIssueInfoDialog.vue'
export default Vue.extend({
  components: {
    VegeIssueInfoDialog
  },
  props: {
    vegeIssueData: {
      type: Array as () => VegetationIssueResult[],
      default: (): VegetationIssueResult[] => []
    },
    relations: {
      type: Object as () => RelationsRes,
      default: () => {}
    }
  },

  data(): ThirdPartyCompanyI {
    return {
      headers: [
        {
          text: 'Company Name',
          value: 'company.company_name',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Email',
          value: 'admin_email',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Assigned By',
          value: 'name',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          class: 'font-weight-black white--text'
        }
      ],
      search: '',
      selectedCompanyRows: [],
      assignIssueDialog: false,
      thirdPartyCompanySelectDialog: false,
      selectedRows: [],
      selectedThirdPartyCompanies: [],
      thirdPartyCompanySelectOptions: [],
      allThirdPartyCompanies: [],
      thirdPartyCompaniesData: [],
      thirdPartyCompanyId: null,
      thirdPartyCompanyName: '',
      assignedIssues: [],
      assignedIssuesDialog: false,
      deleteProvidersDialog: false,
      companyIds: [],
      transferCompanySelectDialog: false,
      deleteAssignedIssueId: null
    }
  },
  computed: {
    assignIssueTableHeaders(): DataTableHeader[] {
      let headers = [
        {
          text: 'Area Name',
          value: 'area',
          width: '10%'
        },
        {
          text: 'Date Inspected',
          value: 'inspection_date'
        },
        {
          text: 'VegLogic Id',
          value: 'originalIssueId'
        },
        {
          text: 'Issue Type',
          value: 'issue_type'
        },
        {
          text: 'Risk Score',
          value: 'risk_score'
        },
        {
          text: 'Details',
          value: 'detailsDialog',
          sortable: false
        }
      ]
      if (this.assignedIssuesDialog) {
        headers.push({
          text: 'Remove',
          value: 'delete',
          sortable: false
        })
      }
      return headers
    }
  },
  methods: {
    openDeleteProvidersDialog(): void {
      if (this.selectedCompanyRows.length <= 0) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No service providers selected'
        })
      } else {
        for (const row of this.selectedCompanyRows) {
          this.companyIds.push(row.companyId)
        }
        this.deleteProvidersDialog = true
      }
    },
    redirectAssignedIssuesList(id: number): void {
      this.$router.push(`/service-provider-issue-list/${id}`)
    },
    redirectAssignIssues(id: number) {
      this.$router.push(`/service-provider-assign-issues/${id}`)
    },
    async deleteCompany(): Promise<void> {
      try {
        if (this.companyIds) {
          this.$rpc('callDeleteThirdPartyCompanies', {
            third_party_company_ids: this.companyIds
          }).then(() => {
            this.deleteProvidersDialog = false
            this.selectedCompanyRows = []
            this.getRelatedThirdPartyCompanies().then(() => {
              this.getThirdPartyCompanies()
            })
          })
        }
      } catch (e) {
        console.log(e)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error deleting data.'
        })
      }
    },
    closeThirdPartyCompanySelectDialog(): void {
      this.thirdPartyCompanySelectDialog = false
      this.selectedThirdPartyCompanies = []
    },
    async AddThirdPartyCompany(): Promise<void> {
      let thirdPartyCompanyIds = []

      if (this.selectedThirdPartyCompanies.length > 0) {
        try {
          for (let company of this.selectedThirdPartyCompanies) {
            thirdPartyCompanyIds.push(company.id)
          }
          await this.$rpc('callSaveThirdPartyCompanies', {
            third_party_company_ids: thirdPartyCompanyIds
          })
        } catch (e) {
          console.log(e)
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Error saving data.'
          })
        }
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No companies selected'
        })
      }
      this.thirdPartyCompanySelectDialog = false
      this.selectedThirdPartyCompanies = []
      this.getRelatedThirdPartyCompanies().then(() => {
        this.getThirdPartyCompanies()
      })
    },
    async getThirdPartyCompanies(): Promise<void> {
      try {
        let result = await this.$rpc('callGetThirdPartyCompanies')
        this.allThirdPartyCompanies = result
        this.thirdPartyCompanySelectOptions = result.filter(
          (entry1) =>
            !this.thirdPartyCompaniesData.some(
              (entry2) => entry1.id === entry2.company.id
            )
        )
      } catch (e) {
        console.log(e)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error retrieving data.'
        })
      }
    },
    async getRelatedThirdPartyCompanies(): Promise<void> {
      try {
        let result = await this.$rpc('callGetCompanyRelations')
        this.thirdPartyCompaniesData = result
        await this.$typedStore.dispatch('setThirdPartyCompanies', result)
      } catch (e) {
        console.log(e)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error retrieving data.'
        })
      }
    },
    selectAllToggle(props: {
      items: VegetationIssueResult[]
      value: boolean
    }): void {
      const numValidRows = props.items.reduce(
        (acc: number, val: VegetationIssueResult) => {
          return val.gps_point || val.gps_track ? acc + 1 : acc
        },
        0
      )
      if (this.selectedRows.length !== numValidRows) {
        this.selectedRows = props.items.filter((x) => {
          return x.gps_point !== null || x.gps_track !== null
        })
      } else {
        this.selectedRows = []
      }
    }
  },
  created() {
    this.getRelatedThirdPartyCompanies().then(() =>
      this.getThirdPartyCompanies()
    )
  }
})

interface ThirdPartyCompanyI {
  headers: DataTableHeader[]
  search: string
  assignIssueDialog: boolean
  thirdPartyCompanySelectDialog: boolean
  selectedRows: VegetationIssueResult[]
  thirdPartyCompaniesData: GetThirdPartyCompanyRelationsResponse[]
  thirdPartyCompanyId: number | null
  thirdPartyCompanyName: string
  selectedThirdPartyCompanies: GetThirdPartyCompanyResponse[]
  selectedCompanyRows: GetThirdPartyCompanyRelationsResponse[]
  thirdPartyCompanySelectOptions: GetThirdPartyCompanyResponse[]
  assignedIssues: VegetationIssueResult[]
  assignedIssuesDialog: boolean
  deleteProvidersDialog: boolean
  companyIds: number[]
  transferCompanySelectDialog: boolean
  allThirdPartyCompanies: GetThirdPartyCompanyResponse[]
  deleteAssignedIssueId: null | number
}
