




























































import Vue from 'vue'
import { DataTableHeader } from 'vuetify/types'
import { SpeciesRes } from '../../../veg-common/apiTypes'
export default Vue.extend({
  data(): SpeciesList {
    return {
      speciesListDialog: false,
      speciesData: [],
      deleteDialog: false,
      selectedRows: []
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      let headers: DataTableHeader[] = [
        {
          text: 'Species Name',
          value: 'species',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Vegetation Type',
          value: 'vegeType',
          sortable: true,
          class: 'font-weight-black white--text'
        }
      ]
      return headers
    }
  },
  methods: {
    openDeleteDialog(): void {
      if (this.selectedRows.length > 0) {
        this.deleteDialog = true
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No Species Selected'
        })
      }
    },
    closeDeleteSpeciesDialog(): void {
      this.deleteDialog = false
    },
    async deleteSpecies(): Promise<void> {
      if (this.selectedRows.length > 0 && this.$route.params.id) {
        try {
          let speciesIds = this.selectedRows.map((data) => data.id)
          let result = await this.$rpc('callDeleteSpecies', {
            asset_id: Number(this.$route.params.id),
            species_ids: speciesIds
          })
          if (result.success) {
            this.getAssetSpeciesLists()
            this.$emit('requestUpdate')
            this.$typedStore.commit('setSnackbarParams', {
              type: 'success',
              msg: 'Selected species have been removed.'
            })
          } else {
            this.$typedStore.commit('setSnackbarParams', {
              type: 'error',
              msg: 'Remove species failed, check your internet connection.'
            })
          }
        } catch (e) {
          console.log(e)
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Error deleting selected alerts.'
          })
        }
      }
      this.selectedRows = []
      this.deleteDialog = false
    },
    async getAssetSpeciesLists(): Promise<void> {
      let result = await this.$rpc('callGetAssetSpeciesLists')
      if (result.length > 0 && this.$route.params.id) {
        let speciesData = result.find(
          (data: SpeciesListsI) => data.id === Number(this.$route.params.id)
        )
        if (speciesData) {
          let speciesWithType = speciesData.vegetationSpecies.map(
            (data: SpeciesRes) => {
              let vegeType = this.$typedStore.getters.relations.issueTypes.find(
                (type) => type.id === data.vegeTypeId
              )

              return { ...data, vegeType: vegeType?.issue_type }
            }
          )

          this.speciesData = speciesWithType
        }
      }
    }
  },
  created() {
    this.getAssetSpeciesLists()
  }
})

interface SpeciesList {
  speciesListDialog: boolean
  speciesData: SpeciesRes[]
  selectedRows: SpeciesListsI[]
  deleteDialog: boolean
}
interface SpeciesListsI {
  id: number
  vegetationSpecies: SpeciesRes[]
}
