

















































































import Vue from 'vue'
import MultiFileUpload from './MultiFileUpload.vue'
import { IsMobileDevice } from '../util/ParseUserAgent'
import { base64ToBlob, blobToBase64 } from '../util/binaryData'
import { Camera, CameraResultType } from '@capacitor/camera'
import { Photo } from '../util/imageProcessing'
import { Filesystem } from '@capacitor/filesystem'

export default Vue.extend({
  components: {
    MultiFileUpload
  },
  data(): CameraViewI {
    return {
      cameraDialog: false,
      loading: true,
      photosForDisplay: [],
      isMobileDevice: IsMobileDevice(),
      photoUploadDialog: false
    }
  },
  props: {
    numPhotosAllowed: {
      default: 8,
      type: Number as () => number
    },
    value: {
      default: [],
      type: Array as () => Photo[]
    }
  },
  computed: {
    photosRemainingText(): string {
      return `${this.photosForDisplay.length} / ${this.numPhotosAllowed}`
    },
    photoButtonsDisabled(): boolean {
      return this.photosForDisplay.length >= this.numPhotosAllowed
    },
    takePhotoButtonText(): string {
      return this.photoButtonsDisabled
        ? `${this.numPhotosAllowed} Photo Maximum`
        : 'Take Photo'
    },
    uploadPhotoButtonText(): string {
      return this.photoButtonsDisabled
        ? `${this.numPhotosAllowed} Photo Maximum`
        : 'Upload Photo'
    }
  },
  watch: {
    photoUploadDialog(): void {
      if (!this.photoUploadDialog) {
        //@ts-ignore
        this.$refs.fileUploadComp.clearFileList()
      }
    }
  },
  methods: {
    removePhoto(index: number): void {
      this.value.splice(index, 1)
      this.photosForDisplay.splice(index, 1)
      this.$emit('input', this.value)
    },
    async capture(): Promise<void> {
      const image = await Camera.getPhoto({
        quality: 60,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        saveToGallery: false
      })

      if (image.path && image.webPath) {
        this.photosForDisplay.push(image.webPath)

        let photo = await Filesystem.readFile({ path: image.path })
        //camera photos are always in jpeg format, safe to set the data type, otherwise the blob returns 'text/html' as the file type
        let photoBlob = await base64ToBlob(
          `data:image/jpg;base64,${photo.data}`
        )

        this.value.push({ blob: photoBlob, stringified: image.path })
        this.$emit('input', this.value)
        this.$emit('photoLoading', false)
      }
    },
    imagesProcessing(processing: boolean): void {
      this.$emit('photoLoading', processing)
      if (processing === false) {
        this.photoUploadDialog = false
      }
    },
    async addToInput(processedImage: Blob): Promise<void> {
      let base64 = await blobToBase64(processedImage)
      this.photosForDisplay.push(base64)
      this.value.push({ blob: processedImage, stringified: base64 })
      this.$emit('input', this.value)
    }
  }
})

interface CameraViewI {
  cameraDialog: boolean
  photosForDisplay: string[]
  loading: boolean
  isMobileDevice: boolean
  photoUploadDialog: boolean
}
