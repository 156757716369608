import { VegetationIssueResult } from '../veg-common/apiTypes'
import { getListOfMostRecentFollowups } from './IssueHelpers'
import _flatMap from 'lodash/flatMap'

export function ParseRiskSlider(slider: number) {
  switch (slider) {
    case 0:
      return NoRisk
      break
    case 1:
      return LowRisk
      break
    case 2:
      return MediumRisk
      break
    case 3:
      return HighRisk
      break
  }
  return NoRisk
}

export interface RiskI {
  score: number
  displayText: string
}

let NoRisk: RiskI = {
  score: 0,
  displayText: 'No Risk'
}
let LowRisk: RiskI = {
  score: 1,
  displayText: 'Low Risk'
}
let MediumRisk: RiskI = {
  score: 5,
  displayText: 'Medium Risk'
}
let HighRisk: RiskI = {
  score: 10,
  displayText: 'High Risk'
}

//find the numerical value for the highest risk issue in a given list of issues
export function determineMaxRisk(
  issues: VegetationIssueResult[]
): number | null {
  let maxIssueRisk: number | null = null
  // Find highest risk in this area
  let mostRecentIssues: (VegetationIssueResult | null)[] = []

  if (issues && issues.length > 0) {
    mostRecentIssues = getListOfMostRecentFollowups(issues)
  }

  // Only do this if there are issues, otherwise maxIssueRisk will
  // become -Infinity when it should be null
  if (mostRecentIssues.length > 0) {
    maxIssueRisk = mostRecentIssues.reduce((maxRisk, issue) => {
      return Math.max(maxRisk, issue ? issue.risk_score : 0)
    }, -Infinity)
  }
  return maxIssueRisk
}
