











































































































































































































































































































import Vue from 'vue'
import {
  CulvertResult,
  CulvertInspectionResult,
  RelationsRes
} from '../veg-common/apiTypes/'

import { getLatestCulvertInspection } from '../util/CulvertHelpers'

export default Vue.extend({
  props: {
    culverts: {
      type: Array as () => CulvertResult[],
      default: (): CulvertResult[] => []
    }
  },
  data(): CulvertFilterComponentI {
    return {
      filterExpansionPanel: false,
      wrapped: true,

      culvertSelectedValues: [],
      roadNameSearch: '',
      riskScoreOptions: [
        { text: 'No Risk', value: 0 },
        { text: 'Low Risk', value: 1 },
        { text: 'Medium Risk', value: 5 },
        { text: 'High Risk', value: 10 }
      ],
      riskScoreSelectedValues: [],

      hasMarkersOptions: [
        { text: 'No Markers', value: 0 },
        { text: 'Has Markers', value: 1 }
      ],
      markersSelectedValues: [],

      fishHabitatOptions: [
        { text: 'Is Not Fish Habitat', value: 0 },
        { text: 'Is A Fish Habitat', value: 1 }
      ],
      fishHabitatSelectedValues: [],

      culvertStatusSelectedValues: [],

      badgeContent: 0,
      badgeValue: false,

      max: 150,
      min: -1,
      diameterRange: [-1, 150],
      lengthRange: [-1, 150]
    }
  },
  watch: {
    filterExpansionPanel() {
      this.$emit('toggleExpansionPanelWidth', this.filterExpansionPanel)
    }
  },
  computed: {
    dataForTable(): CulvertResult[] | [] {
      let fullCulvertList = this.culverts

      return fullCulvertList.filter((item: CulvertResult) => {
        let latestInspection: CulvertInspectionResult | null =
          getLatestCulvertInspection(item)

        const culvertFilterMatches = this.filterSelectionHelper(
          this.culvertSelectedValues,
          item,
          'site_culvert_id'
        )

        const roadFilterMatches = this.stringFilterHelper(
          this.roadNameSearch,
          item.road_name
        )
        const riskScoreFilterMatches = this.riskScoreFilter(latestInspection)
        const culvertStatusFilterMatches =
          this.culvertStatusFilter(latestInspection)

        const hasMarkersFilterMatches = this.booleanFieldFilterHelper(
          this.markersSelectedValues,
          item,
          'has_markers'
        )
        const fishHabitatFilterMatches = this.booleanFieldFilterHelper(
          this.fishHabitatSelectedValues,
          item,
          'is_fish_habitat'
        )

        const diameterFilterMatches = this.numberRangeFilterHelper(
          this.diameterRange,
          item.diameter > -1 ? item.diameter * 100 : item.diameter //convert from m to cm
        )
        const lengthFilterMatches = this.numberRangeFilterHelper(
          this.lengthRange,
          item.length
        )

        return (
          culvertFilterMatches &&
          roadFilterMatches &&
          riskScoreFilterMatches &&
          culvertStatusFilterMatches &&
          fishHabitatFilterMatches &&
          hasMarkersFilterMatches &&
          diameterFilterMatches &&
          lengthFilterMatches
        )
      })
    },
    relations(): RelationsRes {
      return this.$typedStore.getters.relations
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    clearAllFiltersClass(): string {
      if (this.isSmallScreen) {
        return 'pa-0 ma-0'
      } else {
        return 'd-flex justify-end pa-0 ma-0'
      }
    },
    applyFilterButtonClass(): string {
      if (this.isSmallScreen) {
        return 'flex-grow-0 primary accentAjmBlue--text'
      } else {
        return 'flex-grow-0 primary accentAjmBlue--text pr-6 pl-6 mr-10'
      }
    },
    culvertSiteIdOptions(): selectStringOptions[] {
      return this.generateSelectOptions(this.culverts, 'site_culvert_id')
    },
    culvertStatusOptions(): selectStringOptions[] {
      return this.generateSelectOptions(
        this.relations.culvertStatuses,
        'culvert_status'
      )
    }
  },
  methods: {
    applyFilters(): void {
      this.$emit('setTableData', this.dataForTable)

      this.$typedStore.dispatch('setAppliedCulvertFilters', {
        culvertSelectedValues: this.culvertSelectedValues,
        riskScoreSelectedValues: this.riskScoreSelectedValues,
        culvertStatusSelectedValues: this.culvertStatusSelectedValues,
        markersSelectedValues: this.markersSelectedValues,
        roadNameSearch: this.roadNameSearch,
        fishHabitatSelectedValues: this.fishHabitatSelectedValues,
        diameterRange: this.diameterRange,
        lengthRange: this.lengthRange
      })

      this.setBadgeData()
    },
    setBadgeData(): void {
      let filtersApplied = 0
      this.culvertSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.roadNameSearch ? filtersApplied++ : filtersApplied + 0
      this.riskScoreSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.culvertStatusSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.markersSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.fishHabitatSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.diameterRange[0] !== -1 || this.diameterRange[1] !== 150
        ? filtersApplied++
        : filtersApplied + 0
      this.lengthRange[0] !== -1 || this.lengthRange[1] !== 150
        ? filtersApplied++
        : filtersApplied + 0

      this.badgeContent = filtersApplied
      this.badgeValue = filtersApplied > 0 ? true : false
    },
    filterSelectionHelper(
      selectedValue: string[],
      item: CulvertResult,
      key: keyof CulvertResult
    ): boolean {
      if (selectedValue.length <= 0) return true

      let filtered = false
      for (let value of selectedValue) {
        if (item[key] === value) {
          return true
        }
      }

      return filtered
    },
    generateSelectOptions(
      optionType: any[],
      item: string
    ): selectStringOptions[] {
      let option = []
      optionType = optionType.sort((a, b) =>
        a[item].localeCompare(b[item], undefined, { numeric: true })
      )
      for (let data of optionType) {
        if (typeof data[item] === 'string') {
          option.push({ text: data[item], value: data[item] })
        }
      }
      return option
    },
    stringFilterHelper(filter: string, culvertValue: string): boolean {
      if (!filter || filter.replace(/\s/g, '').length === 0) {
        return true
      }
      return culvertValue
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(filter.toLowerCase().replace(/\s/g, ''))
    },
    booleanFieldFilterHelper(
      filter: number[],
      item: CulvertResult,
      key: keyof CulvertResult
    ): boolean {
      if (filter.length <= 0) return true

      for (let selected of filter) {
        if ((selected === 0 && item[key]) || (selected === 1 && !item[key])) {
          return true
        }
      }
      return false
    },
    numberRangeFilterHelper(filter: number[], value: number): boolean {
      return value >= filter[0] && value <= filter[1]
    },
    culvertStatusFilter(
      latestInspection: CulvertInspectionResult | null
    ): boolean {
      let containsStatus = false
      if (this.culvertStatusSelectedValues.length <= 0) {
        containsStatus = true
      } else {
        for (let status of this.culvertStatusSelectedValues) {
          if (latestInspection) {
            if (
              status.toLowerCase().replace(/\s/g, '') ===
              latestInspection.culvertStatus.toLowerCase().replace(/\s/g, '')
            ) {
              containsStatus = true
            }
          }
        }
      }
      return containsStatus
    },
    riskScoreFilter(latestInspection: CulvertInspectionResult | null): boolean {
      let containsRisk = false
      if (this.riskScoreSelectedValues.length <= 0) {
        containsRisk = true
      } else {
        if (latestInspection) {
          for (let risk of this.riskScoreSelectedValues) {
            if (risk === latestInspection.risk_score) {
              containsRisk = true
            }
          }
        }
      }

      return containsRisk
    },
    removeSelectedFromList(
      toRemove: string | number,
      list: string[] | number[]
    ): void {
      if (list.length <= 0) return
      let index = list.findIndex((item: string | number) => {
        return item === toRemove
      })

      if (index >= 0) list.splice(index, 1)
    },
    clearFilters(): void {
      this.culvertSelectedValues = []
      this.roadNameSearch = ''
      this.riskScoreSelectedValues = []
      this.culvertStatusSelectedValues = []
      this.fishHabitatSelectedValues = []
      this.markersSelectedValues = []
      this.diameterRange = [-1, 150]
      this.lengthRange = [-1, 150]
      this.applyFilters()
    }
  },
  created() {
    this.culvertSelectedValues =
      this.$typedStore.state.appliedCulvertFilters.siteIdSelectedValues
    this.diameterRange =
      this.$typedStore.state.appliedCulvertFilters.diameterRange
    this.lengthRange = this.$typedStore.state.appliedCulvertFilters.lengthRange
    this.roadNameSearch =
      this.$typedStore.state.appliedCulvertFilters.roadNameSearch
    this.culvertStatusSelectedValues =
      this.$typedStore.state.appliedCulvertFilters.culvertStatusSelectedValues
    this.fishHabitatSelectedValues =
      this.$typedStore.state.appliedCulvertFilters.fishHabitatSelectedValues
    this.markersSelectedValues =
      this.$typedStore.state.appliedCulvertFilters.markersSelectedValues
    this.riskScoreSelectedValues =
      this.$typedStore.state.appliedCulvertFilters.riskScoreSelectedValues

    this.applyFilters()
  }
})

interface CulvertFilterComponentI {
  filterExpansionPanel: boolean
  wrapped: boolean
  culvertSelectedValues: string[]
  roadNameSearch: string
  riskScoreOptions: selectNumberOptions[]
  riskScoreSelectedValues: number[]
  hasMarkersOptions: selectNumberOptions[]
  markersSelectedValues: number[]

  culvertStatusSelectedValues: string[]

  fishHabitatOptions: selectNumberOptions[]
  fishHabitatSelectedValues: number[]

  badgeContent: number
  badgeValue: boolean

  max: number
  min: number
  diameterRange: number[]
  lengthRange: number[]
}
interface selectNumberOptions {
  text: string
  value: number | null
}
interface selectStringOptions {
  text: string
  value: string | null
}
