



































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import {
  dateObjToDateString,
  dateObjToTimeString,
  dateTimeStringToISO,
  isoToHumanDate
} from '../../util/date-time'
import { parseISO as dfnParseISO } from 'date-fns'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Point, LineString } from 'geojson'

import SeeAreasDialog from '../../components/SeeAreasDialog.vue'
import EditPhotoGalleryComponent from '../../components/EditPhotoGalleryComponent.vue'
import DrawGpsPointComponent from '../../components/DrawGpsPointComponent.vue'
import DrawGpsLineComponent from '../../components/DrawGpsLineComponent.vue'
import {
  IssueActionsRes,
  RelationsRes,
  SpeciesRes,
  ManagementAreaResult,
  EntitiesWithPhotos,
  SubAssetResult
} from '../../veg-common/apiTypes'
import { IssueObject } from '../../veg-common/apiTypes/newResultTypes'
import { RiskI, ParseRiskSlider } from '../../util/CalculateIssueRisk'

export default Vue.extend({
  components: {
    SeeAreasDialog,
    ValidationObserver,
    ValidationProvider,
    EditPhotoGalleryComponent,
    DrawGpsPointComponent,
    DrawGpsLineComponent
  },
  props: {
    managementAreas: {
      type: Array as () => ManagementAreaResult[]
    },
    relations: {
      type: Object as () => RelationsRes
    },
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    }
  },
  data(): VegetationIssueEditI {
    return {
      // @ts-ignore guaranteed to be set in created
      unmodifiedIssue: null,

      //types of problems
      weedId: 1,
      vegAboveGroundPipeId: 2,
      vegBelowGroundPipeId: 3,
      vegNearInfrastructureId: 4,
      attractantIssueTypeId: 5,

      //vegetation types
      attractantTypeId: 6,

      selectedManagementArea: null,
      selectedDistribution: null,
      selectedTypeOfProblem: null,
      selectedPhysStage: null,
      selectedWeedSpecies: [],
      selectedActionsRequired: [],
      selectedInfraVegeType: null,
      selectedWeedVegetationType: null,
      actionTaken: null,
      selectedProximity: null,
      gps_point: null,
      gps_track: null,
      gpsError: false,

      problemsFound: true,
      comments: '',
      weedIndividuals: 0,
      areaSize: 0,
      actionRequired: true,
      date: dateObjToDateString(new Date()),
      time: dateObjToTimeString(new Date()),
      dateDialog: false,
      timeDialog: false,
      nearLowDrain: null,
      riskSlider: 0,
      riskLabels: ['No Risk', 'Low', 'Medium', 'High'],
      photoLoading: false,
      photos: [],
      newPhotos: [],
      photosToRemove: [],
      noRiskRemindDialog: false,
      disableSlider: false,
      vegHeight: '',

      singleArea: false,
      componentJustCreated: false,

      exitEditingDialog: false,
      confirmedLeaving: false,
      issueEdited: false,
      routerNextPath: ''
    }
  },
  computed: {
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    isClientUser(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 1
      }
      return false
    },
    originalDate(): Date {
      return dfnParseISO(this.unmodifiedIssue.inspection_date)
    },
    isLinkedIssue(): boolean {
      return this.unmodifiedIssue.originalIssueId !== null
    },
    calculatedRiskScore(): RiskI {
      return ParseRiskSlider(this.riskSlider)
    },
    density(): number {
      return this.weedIndividuals >= 0 && this.areaSize > 0
        ? this.weedIndividuals / this.areaSize
        : 0
    },
    humanReadableDate(): string {
      return this.date ? isoToHumanDate(this.date) : ''
    },
    selectableSpecies(): SpeciesRes[] {
      if (this.selectedTypeOfProblem === this.attractantIssueTypeId) {
        return this.relations.species.filter((species) => {
          return species.vegeTypeId === this.attractantTypeId
        })
      } else {
        return this.relations.species.filter((species) => {
          return species.vegeTypeId !== this.attractantTypeId
        })
      }
    }
  },
  //@ts-ignore
  beforeRouteLeave(to, from, next) {
    if (to.path) {
      this.routerNextPath = to.path
    }
    if (this.issueEdited) {
      this.exitEditingDialog = false
      next()
    } else if (!this.issueEdited && !this.confirmedLeaving) {
      this.exitEditingDialog = true
      /*
        warning  [Vue warn]: Error in v-on handler (Promise/async): "Error: Navigation aborted from "/vegetation-issues/edit" to "/vegetation-issues" via a navigation guard."
        is caused by the call to next(false), it is nothing to be concerned with.
      */
      next(false)
    } else if (!this.issueEdited && this.confirmedLeaving) {
      next()
    }
  },
  methods: {
    exit() {
      this.exitEditingDialog = false
      this.confirmedLeaving = true
      if (this.routerNextPath === '') this.routerNextPath = '/vegetation-issues'

      this.$router.push({ path: this.routerNextPath })
    },
    setSingleManageArea() {
      if (this.managementAreas.length === 1) {
        this.selectedManagementArea = this.managementAreas[0].id
        this.singleArea = true
      }
    },

    selectedTypeOfProblemChanged(val: number) {
      if (val === this.weedId) {
        this.selectedInfraVegeType = null
        this.selectedProximity = null
        this.nearLowDrain = null
      } else {
        this.selectedPhysStage = null
        this.selectedWeedVegetationType = null
        this.selectedWeedSpecies = []
        this.weedIndividuals = 0
        this.selectedDistribution = null
      }
    },
    problemsFoundChanged(val: boolean) {
      if (this.problemsFound === false) {
        this.selectedTypeOfProblem = null
        this.selectedWeedSpecies = []
        this.selectedProximity = null
        this.selectedInfraVegeType = null
        this.selectedWeedVegetationType = null
        this.weedIndividuals = 0
        this.areaSize = 0
        this.selectedDistribution = null
        this.actionTaken = null
        this.selectedPhysStage = null
        this.actionRequired = false
      }
    },
    clearEdits(): void {
      this.setData()
      //@ts-ignore
      this.$refs.photoComp.displayExistingPhotos(this.photos)
    },
    setData(): void {
      this.selectedManagementArea = this.unmodifiedIssue.areaId
      this.selectedDistribution = this.unmodifiedIssue.distributionId
      this.selectedTypeOfProblem = this.unmodifiedIssue.issueTypeId
      this.selectedPhysStage = this.unmodifiedIssue.physiologicalStageId
      this.vegHeight = this.unmodifiedIssue.veg_height

      this.selectedWeedSpecies = this.relations.species.filter((species) => {
        return this.unmodifiedIssue.speciesIds.includes(species.id)
      })

      if (this.selectedTypeOfProblem === this.weedId) {
        this.selectedWeedVegetationType = this.unmodifiedIssue.vegetationTypeId
        this.selectedInfraVegeType = null
      } else {
        this.selectedWeedVegetationType = null
        this.selectedInfraVegeType = this.unmodifiedIssue.vegetationTypeId
      }

      this.actionTaken = this.unmodifiedIssue.actionTakenId
      this.selectedActionsRequired = this.relations.requiredActions.filter(
        (action) => {
          return this.unmodifiedIssue.actionsRequiredIds.includes(action.id)
        }
      )

      this.selectedProximity = this.unmodifiedIssue.proximityId
      this.problemsFound = this.unmodifiedIssue.has_issue
      this.comments = this.unmodifiedIssue.comments
      this.weedIndividuals = this.unmodifiedIssue.individual_count || 0
      this.areaSize = this.unmodifiedIssue.area_size || 0
      this.actionRequired =
        this.unmodifiedIssue.action_required === null
          ? true
          : this.unmodifiedIssue.action_required
      this.date = dateObjToDateString(this.originalDate)
      this.time = dateObjToTimeString(this.originalDate)
      this.nearLowDrain = this.unmodifiedIssue.near_low_point_drain

      if (this.unmodifiedIssue.gps_point) {
        this.gps_point = {
          type: this.unmodifiedIssue.gps_point.type,
          coordinates: this.unmodifiedIssue.gps_point.coordinates
        } as Point
      }

      if (this.unmodifiedIssue.gps_track) {
        this.gps_track = {
          type: this.unmodifiedIssue.gps_track.type,
          coordinates: this.unmodifiedIssue.gps_track.coordinates
        } as LineString
      }

      this.riskSlider = 0
      switch (this.unmodifiedIssue.risk_score) {
        case 0:
          this.riskSlider = 0
          break
        case 1:
          this.riskSlider = 1
          break
        case 5:
          this.riskSlider = 2
          break
        case 10:
          this.riskSlider = 3
          break
      }

      this.photos = Array.from(this.unmodifiedIssue.photos)
      this.newPhotos = []
      this.photosToRemove = []
    },
    async validateInputs(): Promise<void> {
      //@ts-ignore
      let valid = await this.$refs.validator.validate()
      if (valid && this.calculatedRiskScore.score >= 0) {
        await this.updateIssue()
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Form invalid.'
        })
      }
    },
    async updateIssue(): Promise<void> {
      let app = this
      let chosenVegeType = null
      if (this.selectedInfraVegeType) {
        chosenVegeType = this.selectedInfraVegeType
      } else if (this.selectedWeedVegetationType) {
        chosenVegeType = this.selectedWeedVegetationType
      }
      //@ts-ignore
      let result = await this.$rpc('callUpdateVegetationIssue', {
        id: this.unmodifiedIssue.id,
        inspection_date: dateTimeStringToISO(app.date, app.time),
        has_issue: app.problemsFound,
        risk_score: app.calculatedRiskScore.score,
        veg_height: app.vegHeight,
        area_size: Number(app.areaSize),
        action_required: app.actionRequired,
        individual_count: app.weedIndividuals
          ? Number(app.weedIndividuals)
          : null,
        density: app.density,
        comments: app.comments,
        areaId: app.selectedManagementArea as number,
        actionTakenId: app.actionTaken,
        distributionId: app.selectedDistribution,
        physiologicalStageId: app.selectedPhysStage,
        issueTypeId: app.selectedTypeOfProblem,
        vegetationTypeId: chosenVegeType,
        proximityId: app.selectedProximity,
        speciesIds: app.selectedWeedSpecies
          ? app.selectedWeedSpecies.map((species) => species.id)
          : [],
        actionsRequiredIds: app.selectedActionsRequired
          ? app.selectedActionsRequired.map((action) => action.id)
          : [],
        near_low_point_drain: app.nearLowDrain ? app.nearLowDrain : null,
        gps_point: app.gps_point,
        gps_track: app.gps_track
      })
      let followUpIssueIds = app.unmodifiedIssue.subsequentIssueIds
      for (let followUpIssueId of followUpIssueIds) {
        let updateFollowUpIssueData =
          this.$typedStore.state.assetData.issues[followUpIssueId]
        updateFollowUpIssueData.areaId = app.selectedManagementArea as number
        //@ts-ignore
        await this.$rpc('callUpdateVegetationIssue', updateFollowUpIssueData)
      }
      if (!result.success) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Issue update failed.'
        })
        return
      }

      this.$typedStore.commit('setSnackbarParams', {
        type: 'success',
        msg: 'Issue updated successfully.'
      })

      this.$emit('requestUpdate')
      this.issueEdited = true

      this.$router.push('/vegetation-issues')

      for (let i = 0; i < this.newPhotos.length; i++) {
        await this.$rpc(
          'callPhotoUploadAddExisting',
          {
            parentId: this.unmodifiedIssue.id,
            targetType: EntitiesWithPhotos.VegetationIssuePhoto,
            fileType: this.newPhotos[i].type
          },
          this.newPhotos[i]
        )
      }

      for (let i = 0; i < this.photosToRemove.length; i++) {
        await this.$rpc('callPhotoRemove', {
          parentId: this.unmodifiedIssue.id,
          targetType: EntitiesWithPhotos.VegetationIssuePhoto,
          fileKey: this.photosToRemove[i]
        })
      }
    },
    onPhotoLoading(state: boolean) {
      this.photoLoading = state
    },
    savePoint(point: Point | null): void {
      this.gps_point = point
    },
    setGpsError(gpsError: boolean): void {
      this.gpsError = gpsError
    },
    saveTrack(track: LineString | null): void {
      this.gps_track = track
    },
    removeExistingPhoto(photoKey: string) {
      this.photosToRemove.push(photoKey)
      let existingIndex = this.photos.findIndex((photo: string) => {
        return photo === photoKey
      })
      this.photos.splice(existingIndex, 1)
    },
    removeSpecies(toRemove: SpeciesRes): void {
      if (!this.selectedWeedSpecies) return
      let index = this.selectedWeedSpecies.findIndex((species: SpeciesRes) => {
        return species.id === toRemove.id
      })
      if (index >= 0) this.selectedWeedSpecies.splice(index, 1)
    },
    removeRequiredAction(toRemove: IssueActionsRes): void {
      if (!this.selectedActionsRequired) return
      let index = this.selectedActionsRequired.findIndex(
        (action: IssueActionsRes) => {
          return action.id === toRemove.id
        }
      )
      if (index >= 0) this.selectedActionsRequired.splice(index, 1)
    }
  },
  watch: {
    actionRequired() {
      this.componentJustCreated = false
      if (this.componentJustCreated) {
        if (!this.actionRequired) {
          this.noRiskRemindDialog = false
          this.disableSlider = true
        }
      } else {
        if (!this.actionRequired) {
          if (this.riskSlider > 0) {
            this.noRiskRemindDialog = true
          }
          this.disableSlider = true
          this.riskSlider = 0
        } else {
          this.noRiskRemindDialog = false
          this.disableSlider = false
          this.riskSlider = 0
          switch (this.unmodifiedIssue.risk_score) {
            case 0:
              this.riskSlider = 0
              break
            case 1:
              this.riskSlider = 1
              break
            case 5:
              this.riskSlider = 2
              break
            case 10:
              this.riskSlider = 3
              break
          }
        }
      }
    }
  },
  created() {
    const issueId = this.$route.params['id']
    const issue = this.$typedStore.state.assetData.issues[issueId]
    if (issue === undefined) {
      this.$typedStore.commit('setSnackbarParams', {
        type: 'error',
        msg: 'Unable to edit this issue.'
      })
      this.$router.replace('/vegetation-issues')
      return
    }
    this.unmodifiedIssue = issue
    this.setData()
    this.componentJustCreated = true
    this.setSingleManageArea()
  }
})

interface VegetationIssueEditI {
  unmodifiedIssue: IssueObject

  //issue types
  weedId: number
  vegAboveGroundPipeId: number
  vegBelowGroundPipeId: number
  vegNearInfrastructureId: number
  attractantIssueTypeId: number

  //vegetation types
  attractantTypeId: number

  selectedManagementArea: number | null
  selectedDistribution: number | null
  selectedTypeOfProblem: number | null
  selectedPhysStage: number | null
  selectedWeedSpecies: SpeciesRes[]
  selectedActionsRequired: IssueActionsRes[]
  selectedInfraVegeType: number | null
  selectedWeedVegetationType: number | null
  singleArea: boolean
  actionTaken: number | null
  selectedProximity: number | null
  gps_point: Point | null
  gps_track: LineString | null
  gpsError: boolean
  problemsFound: boolean
  comments: string
  weedIndividuals: number
  areaSize: number
  actionRequired: boolean
  date: string
  time: string
  dateDialog: boolean
  timeDialog: boolean
  nearLowDrain: boolean | null
  riskSlider: number
  riskLabels: string[]
  vegHeight: string

  photoLoading: boolean
  photos: string[]
  newPhotos: Blob[]
  photosToRemove: string[]

  disableSlider: boolean
  noRiskRemindDialog: boolean
  componentJustCreated: boolean

  exitEditingDialog: boolean
  confirmedLeaving: boolean
  issueEdited: boolean
  routerNextPath: string
}
