import axios from 'axios'
import { classToPlain } from 'class-transformer'
import stableStringify from 'json-stable-stringify'

import { transformAndValidate } from '../veg-common'
import { NoResponseError } from '../util/errors'

import { RpcTypeGeneric } from './magic'
import { adminApiDef } from './admin'
import { alertApiDef } from './alert'
import { loginApiDef } from './login'
import { usersApiDef } from './users'
import { vegetationIssuesApiDef } from './vegetationIssues'
import { photosApiDef } from './photos'
import { thirdPartyCompanyApiDef } from './thirdPartyCompanies'
import { speciesCustomizationApiDef } from './speciesCustomization'
import { culvertInspectionsApiDef } from './culvertInspections'
import { culvertsApiDef } from './culverts'
import { subAssetsApiDef } from './subAssets'

import {
  GetAppRequest,
  GetAppResponse,
  GetAssetRequest,
  GetAssetResponse,
  RelationsRes
} from '../veg-common/apiTypes'

axios.defaults.timeout = 20000

export const apiDef = {
  callGetAppUpdate: {
    route: '/update',
    method: 'get',
    auth: true,
    inputType: GetAppRequest,
    outputType: GetAppResponse,
    returnsMultiple: false
  },
  callGetAssetUpdate: {
    route: '/update/asset',
    method: 'get',
    auth: true,
    inputType: GetAssetRequest,
    outputType: GetAssetResponse,
    returnsMultiple: false
  },
  callGetRelations: {
    route: '/update/relations',
    method: 'get',
    auth: true,
    inputType: 'void',
    outputType: RelationsRes,
    returnsMultiple: false
  },
  ...adminApiDef,
  ...alertApiDef,
  ...loginApiDef,
  ...usersApiDef,
  ...vegetationIssuesApiDef,
  ...photosApiDef,
  ...thirdPartyCompanyApiDef,
  ...speciesCustomizationApiDef,
  ...culvertInspectionsApiDef,
  ...culvertsApiDef,
  ...subAssetsApiDef
} as const

export type RpcType = RpcTypeGeneric<typeof apiDef>

export async function callApi(
  def: any,
  apiUrl: string,
  token: string | null,
  endpoint: any,
  params?: object,
  params2?: object
): Promise<{ data: any; token: string | null; version: string }> {
  let { route, method, auth, inputType, outputType, returnsMultiple } =
    def[endpoint]
  let authHeaders = {}

  if (auth) {
    if (token === null) {
      throw new Error('Route requires authorization but token is null')
    } else {
      authHeaders = {
        Authorization: token
      }

      if (process.env.NODE_ENV === 'localApp') {
        authHeaders = {
          'ngrok-skip-browser-warning': '69420', //skip common ngrok error for mobile debugging - only needed for local dev on mobile
          ...authHeaders
        }
      }
    }
  }

  let paramsChecked = undefined
  if (inputType !== 'void') {
    if (params) {
      await transformAndValidate(inputType, params)
      paramsChecked = classToPlain(params)
    } else {
      throw new Error(
        'Expected params to be defined when inputType is not void'
      )
    }
  }

  let responsePromise
  switch (method) {
    case 'get':
      responsePromise = axios.get(apiUrl + route, {
        ...(paramsChecked
          ? { params: { obj: stableStringify(paramsChecked) } }
          : {}),
        headers: { ...authHeaders }
      })
      break
    case 'post':
      responsePromise = axios.post(apiUrl + route, paramsChecked, {
        headers: {
          'Content-Type': 'application/json',
          ...authHeaders
        }
      })
      break
    case 'post-octet':
      if (paramsChecked === undefined) {
        // inputType is void params is body
        responsePromise = axios.post(apiUrl + route, params, {
          headers: {
            'Content-Type': 'application/octet-stream',
            ...authHeaders
          },
          timeout: 15000
        })
      } else {
        // inputType is not void, paramsChecked go in url, params2 is body
        responsePromise = axios.post(apiUrl + route, params2, {
          headers: {
            'Content-Type': 'application/octet-stream',
            ...authHeaders
          },
          timeout: 15000,
          params: { obj: stableStringify(paramsChecked) }
        })
      }
      break
    default:
      throw new Error('Unsupported method')
  }

  let response = await responsePromise.catch((error) => {
    if (error.response) {
      // Non 200
      console.log('callApi: non-200 response', error.toJSON())
      throw error
    } else if (error.request) {
      // No response: error.request is an XMLHttpRequest
      throw new NoResponseError(error.request)
    } else {
      // Something wrong with the request
      console.log('callApi: Error with request', error.toJSON())
      throw error
    }
  })

  if (typeof response.data !== 'object') {
    throw new Error('Expected an object response from API')
  }

  let validated: any
  if (returnsMultiple) {
    if (response.data instanceof Array) {
      validated = await transformAndValidate(outputType, response.data)
    } else {
      throw new Error('Expected an array of objects from the API')
    }
  } else {
    if (response.data instanceof Array) {
      throw new Error('Expected a single object from the API')
    } else {
      validated = await transformAndValidate(outputType, response.data)
    }
  }

  let newToken: string | null = null
  let backendVersion: string = ''
  if (response.headers) {
    if (response.headers['x-veg-token']) {
      newToken = response.headers['x-veg-token']
    }
    if (response.headers['version']) {
      backendVersion = response.headers['version']
    }
  }

  return { data: validated, token: newToken, version: backendVersion }
}
