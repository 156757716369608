



































import Vue from 'vue'
import { DataTableHeader } from 'vuetify/types'
import { UserObject } from '../veg-common/apiTypes/newResultTypes'

export default Vue.extend({
  data(): AssignIssuesI {
    return {
      headers: [
        {
          text: 'Username',
          value: 'user_name',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'font-weight-black white--text'
        }
      ],
      search: ''
    }
  },
  computed: {
    fieldUsers(): UserObject[] {
      return this.$typedStore.getters.usersArray.filter(
        (user) => user.userRoleId === 3
      )
    }
  },
  methods: {
    redirectAssignedIssuesList(id: number): void {
      this.$router.push(`/user-issue-list/${id}`)
    },
    redirectAssignIssues(id: number) {
      this.$router.push(`/user-assign-issues/${id}`)
    }
  }
})
interface AssignIssuesI {
  headers: DataTableHeader[]
  search: string
}
