import { BaseEntity } from './BaseEntity'
import { Entity, Column } from 'typeorm'
import { VegSpeciesObject } from '../../veg-common/apiTypes/newResultTypes'
import { IsString, IsNumber, IsInt } from 'class-validator'

@Entity()
export class AssetSpeciesEntity extends BaseEntity {
  constructor(x?: VegSpeciesObject) {
    super()
    if (x) {
      this.id = x.id
      this.species = x.species
      this.score = x.score
      this.vegeTypeId = x.vegeTypeId
      this.reference_photo_url = x.reference_photo_url
    }
  }

  toApiType(): VegSpeciesObject {
    return {
      id: this.id,
      species: this.species,
      score: this.score,
      vegeTypeId: this.vegeTypeId,
      reference_photo_url: this.reference_photo_url
    }
  }

  @Column({ type: 'int', unique: true })
  @IsInt()
  id!: number

  @Column('text')
  @IsString()
  species!: string

  @Column('double')
  @IsNumber()
  score!: number

  @Column('int')
  @IsInt()
  vegeTypeId!: number

  @Column('text')
  @IsString()
  reference_photo_url!: string
}
