import { LoginParams, LoginResponse } from '../veg-common/apiTypes'

export const loginApiDef = {
  callLogin: {
    route: '/login',
    method: 'post',
    auth: false,
    inputType: LoginParams,
    outputType: LoginResponse,
    returnsMultiple: false
  }
} as const
