import { VegetationIssueResult } from '../veg-common/apiTypes'
import { parseISO } from 'date-fns'
import { cloneDeep } from 'lodash'

let dfn = require('date-fns')

/**
 * function to return the single most recent follow up to the given issue from the passed in list
 * @param allIssues the list of issues to compare to the single issue either the entire list of issues or the list of issues in an area
 * @param issue the single issue (should be original issue) to find it's more recent follow up
 * @returns the most recent follow up, the original issue if no follow ups, or null if the most recent follow up is resolved
 */
export function findMostRecentFollowup(
  allIssues: VegetationIssueResult[],
  issue: VegetationIssueResult
): VegetationIssueResult {
  if (issue.subsequentIssueIds.length > 0) {
    issue.subsequentIssueIds.sort(function (issue1, issue2) {
      let followUp1 = allIssues.find((iss) => iss.id === issue1)
      let followUp2 = allIssues.find((iss) => iss.id === issue2)

      if (followUp1 && followUp2) {
        return dfn.compareDesc(
          parseISO(followUp1.inspection_date),
          parseISO(followUp2.inspection_date)
        )
      } else if (followUp1 && !followUp2) {
        return 1 //positive value to sort item 1 ahead of item 2
      } else if (!followUp1 && followUp2) {
        return -1 //negative value to sort item 2 ahead of item 1
      }
    })
    //using compareDesc, the issue at index 0 will be the most recent
    let mostRecentFollowUp = allIssues.find(
      (x) => x.id === issue.subsequentIssueIds[0]
    )

    if (mostRecentFollowUp) {
      return mostRecentFollowUp
    } else {
      return issue
    }
  } else {
    return issue
  }
}

/**
 *
 * @param issues the issues to go over and find the most recent follow ups to the contents of the list
 * @returns a list of just the most recent follow up issues without issues that have been resolved
 */
export function getListOfMostRecentFollowups(
  issues: VegetationIssueResult[]
): VegetationIssueResult[] {
  let mostRecentIssues = []
  let issueArray = cloneDeep(issues)

  // Grab all issues without a followup, they are the scores that can
  // be used so initialize final list with this
  let originalIssues: VegetationIssueResult[] = issueArray.filter((issue) => {
    return issue.original_issue_id === null
  })

  for (let issue of originalIssues) {
    let mostRecent = findMostRecentFollowup(issueArray, issue)

    if (mostRecent.isInteractive === false) mostRecent.risk_score = -20

    mostRecentIssues.push(mostRecent)
  }
  return mostRecentIssues
}
