import { GenericResult } from '../veg-common/apiTypes'
import {
  DeleteSpeciesRequest,
  AddNewSpeciesRequest,
  GetAssetSpeciesListsResponse
} from '../veg-common/apiTypes/speciesCustomization'

export const speciesCustomizationApiDef = {
  callDeleteSpecies: {
    route: '/speciesCustomization/delete',
    method: 'post',
    auth: true,
    inputType: DeleteSpeciesRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callAddNewSpecies: {
    route: '/speciesCustomization/addNewSpecies',
    method: 'post',
    auth: true,
    inputType: AddNewSpeciesRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callGetAssetSpeciesLists: {
    route: '/speciesCustomization/getAssetSpeciesLists',
    method: 'get',
    auth: true,
    inputType: 'void',
    outputType: GetAssetSpeciesListsResponse,
    returnsMultiple: true
  }
} as const
