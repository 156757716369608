









































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import {
  dateObjToDateString,
  dateObjToTimeString,
  dateTimeStringToISO,
  isoToHumanDate
} from '../../util/date-time'
import { v4 as uuidv4 } from 'uuid'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Point, LineString } from 'geojson'
import { parseISO } from 'date-fns'
import distance from '@turf/distance'
import lineToPolygon from '@turf/line-to-polygon'
import centerOfMass from '@turf/center-of-mass'
import { Position } from '@capacitor/geolocation'

import SeeAreasDialog from '../../components/SeeAreasDialog.vue'
import CameraViewComponent from '../../components/CameraViewComponent.vue'
import DrawGpsPointComponent from '../../components/DrawGpsPointComponent.vue'
import DrawGpsLineComponent from '../../components/DrawGpsLineComponent.vue'
import { JwtContent } from '../../veg-common/jwt'
import {
  IssueActionsRes,
  VegetationIssueResult,
  DistributionsRes,
  IssueTypeRes,
  RelationsRes,
  PhysiologicalStagesRes,
  ProximitiesRes,
  VegetationTypesRes,
  SpeciesRes,
  ManagementAreaResult,
  SubAssetResult
} from '../../veg-common/apiTypes'
import { RiskI, ParseRiskSlider } from '../../util/CalculateIssueRisk'
import { sortManagementAreas } from '../../util/SortManagementAreas'
import { IsMobileDevice } from '../../util/ParseUserAgent'
import { isoToHumanDateTime } from '../../util/date-time'
import { findMostRecentFollowup } from '../../util/IssueHelpers'
import { Photo } from '../../util/imageProcessing'

let dfn = require('date-fns')

export default Vue.extend({
  components: {
    SeeAreasDialog,
    ValidationObserver,
    ValidationProvider,
    CameraViewComponent,
    DrawGpsPointComponent,
    DrawGpsLineComponent
  },
  props: {
    managementAreas: {
      type: Array as () => ManagementAreaResult[]
    },
    relations: {
      type: Object as () => RelationsRes
    },
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    }
  },
  data(): AddNewVegeIssueI {
    return {
      //TODO: remove attractant issue type
      //it makes more sense for animal attractant to be a type of vegetation (IE noxious, unreggulated)
      //since it's just a weed with all the same entries other then manual risk assessment
      //Once a better way to select species is created (and in extension types of vegetation),
      //we would be able to remove that and come up with someway to calculate risk on animal attractants
      //while keeping them together with other weeds
      managementAreaOptions: [],
      //types of problems
      weedId: 1,
      vegAboveGroundPipeId: 2,
      vegBelowGroundPipeId: 3,
      vegNearInfrastructureId: 4,
      attractantIssueTypeId: 5,
      otherIssueTypeId: 6,

      copyIssue: false,
      copyAreaDialog: false,
      copyArea: false,

      selectedManagementArea: null,
      selectedDistribution: null,
      selectedTypeOfProblem: null,
      originalTypeOfProblem: null,
      selectedPhysStage: null,
      selectedWeedSpecies: [],
      selectedInfraVegeType: null,
      actionTaken: null,
      selectedProximity: null,
      gps_point: null,
      gps_track: null,

      problemsFound: true,
      comments: '',
      weedIndividuals: 0,
      areaSize: 0,
      actionRequired: true,
      selectedActionsRequired: [],
      date: dateObjToDateString(new Date()),
      time: dateObjToTimeString(new Date()),
      dateDialog: false,
      timeDialog: false,
      trackInProgress: false,
      confirmationDialog: false,
      closestIssuesDialog: false,
      originalIssueSelect: [],
      fullIssueList: [],
      selectedIssue: null,
      pageLoading: true,
      gpsError: false,
      photos: [],
      photoLoading: false,
      nearLowDrain: null,
      riskSlider: 0,
      originalRiskSlider: 0,
      riskLabels: ['No Risk', 'Low', 'Medium', 'High'],
      isMobileDevice: IsMobileDevice(),
      weedSelectMenu: false,
      actionsRequiredSelectMenu: false,
      disableSlider: false,
      noRiskRemindDialog: false,
      vegHeight: '',

      thirdPartyCompanySelectOptions: [],
      selectedThirdPartyCompanies: [],
      singleArea: false,

      issueSaved: false,
      exitDialog: false,
      confirmedLeaving: false,

      routerNextPath: ''
    }
  },
  computed: {
    issueMessage() {
      if (this.isClientUser) {
        return 'Copy, Follow Up or Add New Issue'
      } else {
        return 'Add Follow Up Issue'
      }
    },
    watchPosition(): Position | null {
      return this.$typedStore.state.latestPosition
    },
    calculatedRiskScore(): RiskI {
      return ParseRiskSlider(this.riskSlider)
    },
    density(): number {
      return this.weedIndividuals >= 0 && this.areaSize > 0
        ? this.weedIndividuals / this.areaSize
        : 0
    },
    humanReadableDate(): string {
      return this.date ? isoToHumanDate(this.date) : ''
    },
    proximityOptions(): ProximitiesRes[] {
      if (this.selectedTypeOfProblem !== null) {
        let returnOptions: ProximitiesRes[] = []
        this.relations.proximities.forEach((proxOption) => {
          if (proxOption.issueTypeId === this.selectedTypeOfProblem) {
            returnOptions.push(proxOption)
          }
        })
        return returnOptions
      } else {
        return []
      }
    },
    selectableSpecies(): SpeciesRes[] {
      let assetAssociatedSpeciesArr: SpeciesRes[] = []
      for (let key in this.$typedStore.state.assetData.assetAssociatedSpecies) {
        assetAssociatedSpeciesArr.push(
          this.$typedStore.state.assetData.assetAssociatedSpecies[key]
        )
      }

      if (this.selectedTypeOfProblem === this.attractantIssueTypeId) {
        assetAssociatedSpeciesArr = assetAssociatedSpeciesArr.filter(
          (species) => {
            return species.vegeTypeId === this.attractantIssueTypeId
          }
        )
      } else {
        assetAssociatedSpeciesArr = assetAssociatedSpeciesArr.filter(
          (species) => {
            return species.vegeTypeId !== this.attractantIssueTypeId
          }
        )
      }

      return assetAssociatedSpeciesArr.sort((a, b) => {
        return a.species.localeCompare(b.species, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      })
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    addIssueDialogWidth(): string {
      if (this.isSmallScreen) {
        return '100%'
      } else {
        return '65%'
      }
    },
    copyFollowUpButtonClass(): string {
      if (!this.isSmallScreen) {
        return 'd-flex justify-center'
      } else {
        return ''
      }
    },
    isClientUser(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 1
      }
      return false
    }
  },
  methods: {
    closeActionsRequiredMenu() {
      this.actionsRequiredSelectMenu = false
      // @ts-ignore
      this.$refs.actionsRequired.blur()
    },
    closeSelectSpeciesMenu() {
      this.weedSelectMenu = false
      //@ts-ignore
      this.$refs.selectSpecies.blur()
    },
    setSingleManageArea() {
      if (this.managementAreaOptions.length === 1) {
        this.selectedManagementArea = this.managementAreaOptions[0].id
        this.singleArea = true
      }
    },
    selectedTypeOfProblemChanged(val: number): void {
      if (val === this.weedId) {
        this.selectedInfraVegeType = null
        this.selectedProximity = null
        this.nearLowDrain = null
      } else {
        this.selectedPhysStage = null
        this.selectedWeedSpecies = []
        this.weedIndividuals = 0
        this.selectedDistribution = null
      }
    },
    problemsFoundChanged(): void {
      if (!this.problemsFound) {
        this.selectedTypeOfProblem = null
        this.selectedWeedSpecies = []
        this.selectedProximity = null
        this.selectedInfraVegeType = null
        this.weedIndividuals = 0
        this.areaSize = 0
        this.selectedDistribution = null
        this.actionTaken = null
        this.selectedPhysStage = null
        this.actionRequired = true
      } else {
        this.selectedTypeOfProblem = this.originalTypeOfProblem
      }
    },
    async validateInputs(): Promise<void> {
      //@ts-ignore
      let valid = await this.$refs.validator.validate()
      if (
        this.problemsFound &&
        this.gps_point === null &&
        this.gps_track === null
      ) {
        valid = false
        this.gpsError = true
      }

      if (valid && this.calculatedRiskScore.score >= 0) {
        if (this.trackInProgress) {
          this.confirmationDialog = true //check the track after validation, user can fix validation errors before being forced to stop the gps
        } else {
          await this.saveIssue()
        }
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Form invalid.'
        })
      }
    },
    printDateReadableForOriginalIssueSelect(date: string) {
      return isoToHumanDateTime(date)
    },
    getIssuesFromAreas(userPosition?: Position): void {
      let app = this
      let listOfIssues: IssueWithDistanceToUser[] = []
      let userPoint: Point | null = null

      if (userPosition) {
        userPoint = {
          type: 'Point',
          coordinates: [
            userPosition.coords.longitude,
            userPosition.coords.latitude
          ]
        }
      }

      app.managementAreas.forEach((area) => {
        for (let idx = 0; idx < area.issues.length; idx++) {
          let issue: VegetationIssueResult | null = area.issues[idx]

          if (issue.gps_point && issue.original_issue_id === null) {
            issue = findMostRecentFollowup(
              app.$typedStore.getters.vegetationIssues,
              issue
            )
            //null means the most recent followup was marked as complete, thus ignore this issue chain and continue through the loop
            if (!issue.has_issue || !issue.isInteractive) {
              continue
            }

            let issueWithDistance = this.createIssueWithDistanceToUser(
              issue,
              userPoint
            )

            listOfIssues.push(issueWithDistance)
          }
        }
      })

      if (listOfIssues.length > 0) {
        let sortedList: IssueWithDistanceToUser[]

        if (userPoint) {
          sortedList = listOfIssues.sort(function (issue1, issue2) {
            return issue1.distance - issue2.distance
          })
        } else {
          sortedList = listOfIssues.sort(function (issue1, issue2) {
            return dfn.compareDesc(
              parseISO(issue1.issue.inspection_date),
              parseISO(issue2.issue.inspection_date)
            )
          })
        }
        app.fullIssueList = sortedList

        if (userPoint) {
          //only give the user the 10 closest issues to prevent scrolling forever on mobile
          app.originalIssueSelect = sortedList.slice(0, 10)
        } else {
          app.originalIssueSelect = sortedList
        }

        app.closestIssuesDialog = true
      } else {
        app.getClosestArea()
      }
    },
    loadFollowUpIssue(userPosition?: Position): void {
      let app = this
      if (app.$route.query.followUpIssueId) {
        let id = Number(app.$route.query.followUpIssueId)
        let originalIssue = app.$typedStore.getters.vegetationIssues.find(
          (iss) => iss.id === id
        )

        //if originalIssue is undefined then the query has not been set correctly,
        //go through the normal process of showing the user all of the active issues
        if (!originalIssue) {
          app.getIssuesFromAreas(userPosition)
          return
        }

        let issue = findMostRecentFollowup(
          app.$typedStore.getters.vegetationIssues,
          originalIssue
        )
        if (issue.has_issue) {
          let userPoint: Point | null = null
          if (userPosition) {
            userPoint = {
              type: 'Point',
              coordinates: [
                userPosition.coords.longitude,
                userPosition.coords.latitude
              ]
            }
          }

          let issueWithDistance = this.createIssueWithDistanceToUser(
            issue,
            userPoint
          )

          app.fullIssueList = [issueWithDistance]
        } else {
          app.getIssuesFromAreas(userPosition)
          return
        }

        //only setting the array to have one element, so just auto set the selectedissue to be that issue
        app.selectedIssue = 0
        app.setIssueData()
      }
      //entered function unexpectedly, go through the normal process of showing the user all of the active issues
      else {
        app.getIssuesFromAreas(userPosition)
      }
    },
    createIssueWithDistanceToUser(
      issue: VegetationIssueResult,
      userPoint: Point | null
    ): IssueWithDistanceToUser {
      let distanceToIssue = -100
      if (userPoint) {
        distanceToIssue = distance(
          userPoint,
          (issue.gps_point as Point).coordinates
        )
      }

      let issueIdentifier = ''
      let issueIdentifierSubtitle = ''

      if (
        issue.issue_type === 'Weeds' ||
        issue.issue_type === 'Animal Attractant'
      ) {
        issueIdentifierSubtitle = 'Issue of Species'
        issueIdentifier = issue.species
          ? issue.species.join(' | ')
          : 'Unknown Value'
      } else if (issue.issue_type === 'Other') {
        issueIdentifierSubtitle = 'Issue of Type'
        issueIdentifier = 'Other'
      } else {
        issueIdentifierSubtitle = 'Issue of Type'
        //hit this mark then vegetation_type will not be null, wrap it to make it a string to go past typescript compile errors
        issueIdentifier = `${issue.vegetation_type}`
      }

      return {
        issue,
        distance: distanceToIssue,
        issueIdentifierSubtitle,
        issueIdentifier
      }
    },
    createFollowUpFromIssueSelect(itemIndex: number): void {
      this.selectedIssue = itemIndex
      this.setIssueData()
    },
    setIssueData(issueToCopy?: number): void {
      let issue: VegetationIssueResult
      if (issueToCopy === null || issueToCopy === undefined) {
        if (this.selectedIssue !== null) {
          issue = this.fullIssueList[this.selectedIssue].issue
        } else {
          return
        }
      } else if (issueToCopy !== NaN && issueToCopy >= 0) {
        let foundIssue = this.$typedStore.getters.vegetationIssues.find((i) => {
          return i.id === issueToCopy
        })
        if (foundIssue === undefined) {
          return
        } else {
          issue = foundIssue
        }
      } else {
        return
      }
      //areas gets set in the sort areas function that doesn't get hit if the issue is a follow up, set the areas to the default list so it can be displayed
      this.managementAreaOptions = this.managementAreas

      let foundArea: ManagementAreaResult | undefined = undefined
      let foundIssueType: IssueTypeRes | undefined = undefined
      let foundDistribution: DistributionsRes | undefined = undefined
      let foundPhysStage: PhysiologicalStagesRes | undefined = undefined
      let foundProximity: ProximitiesRes | undefined = undefined
      let foundInfraVegeType: VegetationTypesRes | undefined = undefined
      let foundSpeciesList: SpeciesRes[] = []
      let foundNewestFollowupRiskScore: number = 0
      foundArea = this.managementAreas.find((area) => {
        return area.name === issue.area
      })
      if (this.copyIssue && !this.copyArea) {
        foundArea = undefined
      }
      foundIssueType = this.relations.issueTypes.find((type) => {
        return type.issue_type === issue.issue_type
      })
      foundDistribution = this.relations.distributions.find((dist) => {
        return dist.distribution === issue.distribution
      })
      foundPhysStage = this.relations.physiologicalStages.find((physStage) => {
        return physStage.physiological_stage === issue.physiological_stage
      })
      foundProximity = this.relations.proximities.find((proximity) => {
        return proximity.proximity === issue.proximity
      })
      foundInfraVegeType = this.relations.infraVegetationTypes.find(
        (vegeType) => {
          return vegeType.vegetation_type === issue.vegetation_type
        }
      )

      let newestFollowupIssue = findMostRecentFollowup(
        this.$typedStore.getters.vegetationIssues,
        issue
      )

      if (newestFollowupIssue.has_issue) {
        foundNewestFollowupRiskScore = newestFollowupIssue.risk_score
      } else {
        foundNewestFollowupRiskScore = issue.risk_score
      }

      if (issue.species) {
        for (let i = 0; i < issue.species.length; i++) {
          let foundSpecies: SpeciesRes | undefined =
            this.relations.species.find((species) => {
              return issue.species
                ? species.species === issue.species[i]
                : undefined
            })
          if (foundSpecies) foundSpeciesList.push(foundSpecies)
        }
      }

      if (foundArea) this.selectedManagementArea = foundArea.id
      if (foundIssueType) this.selectedTypeOfProblem = foundIssueType.id
      if (foundDistribution) this.selectedDistribution = foundDistribution.id
      if (foundPhysStage) this.selectedPhysStage = foundPhysStage.id
      if (foundProximity) this.selectedProximity = foundProximity.id
      if (foundInfraVegeType) this.selectedInfraVegeType = foundInfraVegeType.id
      this.selectedWeedSpecies = foundSpeciesList
      if (!this.isClientUser) {
        this.riskSlider = foundNewestFollowupRiskScore
        this.originalRiskSlider = foundNewestFollowupRiskScore
        this.originalTypeOfProblem = foundIssueType ? foundIssueType.id : null
      }

      this.closestIssuesDialog = false
      this.pageLoading = false
    },
    getClosestArea(): void {
      let app = this
      app.selectedIssue = null
      if (this.watchPosition && this.isMobileDevice) {
        app.findClosestArea(this.watchPosition)
      } else {
        //desktop, finish loading and show no default selected area
        app.pageLoading = false
      }
      app.closestIssuesDialog = false
    },
    findClosestArea(position: Position | null): void {
      let app = this
      if (position) {
        app.managementAreaOptions = sortManagementAreas(
          position,
          app.managementAreas
        )
      }

      if (app.selectedManagementArea === null) {
        app.selectedManagementArea = app.managementAreaOptions[0].id
      }
      app.pageLoading = false
    },
    async saveIssue(): Promise<void> {
      let app = this

      if (!app.$typedStore.getters.jwtContent) return
      if (!app.$typedStore.getters.jwtContent.data.userId) return
      if (!app.selectedManagementArea) return

      let chosenVegeType = null
      if (this.selectedInfraVegeType) {
        chosenVegeType = this.selectedInfraVegeType
      }

      let originalIssueId: number | null = null
      //duplicate and follow up both use selectedIssue to fill their data, make sure it isn't a copy issue when setting original issue id as well
      if (
        app.fullIssueList.length > 0 &&
        app.selectedIssue !== null &&
        app.copyIssue === false
      ) {
        if (app.fullIssueList[app.selectedIssue].issue.original_issue_id) {
          originalIssueId =
            app.fullIssueList[app.selectedIssue].issue.original_issue_id
        } else {
          originalIssueId = app.fullIssueList[app.selectedIssue].issue.id
        }
      }

      if (this.gps_track && !this.gps_point) {
        let linePolygon = lineToPolygon(this.gps_track)
        this.gps_point = centerOfMass(linePolygon).geometry
      }

      await this.$typedStore.dispatch('saveIssue', {
        params: {
          uuid: uuidv4(),
          //converts to utc automatically
          inspection_date: dateTimeStringToISO(app.date, app.time),
          has_issue: app.problemsFound,
          risk_score: app.calculatedRiskScore.score,
          veg_height: app.vegHeight,
          // vue makes text input strings, but we've set it to be just numbers in
          // the component so this will always be fine
          area_size: Number(app.areaSize),
          individual_count: app.weedIndividuals
            ? Number(app.weedIndividuals)
            : null,
          gps_track: app.gps_track ? app.gps_track : null,
          gps_point: app.gps_point ? app.gps_point : null,
          action_required: app.problemsFound ? app.actionRequired : false,
          density: app.density,
          comments: app.comments,
          //the casts here are because they were complaining it could be null,
          // but I've got the truthy checks above so it's fine
          areaId: app.selectedManagementArea as number,
          userId: (app.$typedStore.getters.jwtContent as JwtContent).data
            .userId as number,
          actionTakenId: app.actionTaken,
          distributionId: app.selectedDistribution,
          physiologicalStageId: app.selectedPhysStage,
          issueTypeId: app.selectedTypeOfProblem,
          vegetationTypeId: chosenVegeType,
          proximityId: app.selectedProximity,
          speciesIds: app.selectedWeedSpecies
            ? app.selectedWeedSpecies.map((species) => species.id)
            : [],
          actionsRequiredIds: app.selectedActionsRequired
            ? app.selectedActionsRequired.map((action) => action.id)
            : [],
          originalIssueId,
          near_low_point_drain: app.nearLowDrain ? app.nearLowDrain : null,
          thirdPartyCompanyIds: app.selectedThirdPartyCompanies.map(
            (company) => company.id
          )
        },
        photos: this.photos
      })
      this.issueSaved = true
      this.$router.push('/vegetation-issues')
    },
    onPhotoLoading(state: boolean) {
      this.photoLoading = state
    },
    savePoint(point: Point | null): void {
      this.gps_point = point
    },
    setGpsError(gpsError: boolean): void {
      this.gpsError = gpsError
    },
    saveTrack(track: LineString | null): void {
      this.gps_track = track
    },
    setTrackInProgress(inProgress: boolean): void {
      this.trackInProgress = inProgress
    },
    removeSpecies(toRemove: SpeciesRes): void {
      if (!this.selectedWeedSpecies) return
      let index = this.selectedWeedSpecies.findIndex((species: SpeciesRes) => {
        return species.id === toRemove.id
      })
      if (index >= 0) this.selectedWeedSpecies.splice(index, 1)
    },
    removeRequiredAction(toRemove: IssueActionsRes): void {
      if (!this.selectedActionsRequired) return
      let index = this.selectedActionsRequired.findIndex(
        (action: IssueActionsRes) => {
          return action.id === toRemove.id
        }
      )
      if (index >= 0) this.selectedActionsRequired.splice(index, 1)
    },
    removeCompanies(toRemove: ThirdPartyCompaniesData): void {
      if (!this.selectedThirdPartyCompanies) return
      let index = this.selectedThirdPartyCompanies.findIndex(
        (company: ThirdPartyCompaniesData) => {
          return company.id === toRemove.id
        }
      )
      if (index >= 0) this.selectedThirdPartyCompanies.splice(index, 1)
    },
    openCopyIssueDialog(itemIndex?: number): void {
      if (itemIndex !== undefined) {
        this.selectedIssue = itemIndex
        if (this.managementAreas.length !== 1) {
          this.copyAreaDialog = true
        }
      } else if (
        this.$route.query.duplicate &&
        typeof this.$route.query.duplicate === 'string'
      ) {
        this.copyAreaDialog = true
      }

      if (this.managementAreas.length === 1) {
        this.duplicateIssue(true)
      }
    },
    duplicateIssue(sameArea: boolean): void {
      this.copyAreaDialog = false
      this.copyIssue = true
      this.copyArea = sameArea
      if (
        this.$route.query.duplicate &&
        typeof this.$route.query.duplicate === 'string'
      ) {
        let dupId = parseInt(this.$route.query.duplicate)
        this.setIssueData(dupId)
      } else if (this.selectedIssue !== null) {
        this.setIssueData()
      } else {
        this.getClosestArea()
      }

      this.pageLoading = false
      this.closestIssuesDialog = false
    },
    getThirdPartyCompanies(): void {
      let companies = this.$typedStore.getters.relatedThirdPartyCompanies

      let companyArray: ThirdPartyCompaniesData[] = []
      for (let c of companies) {
        companyArray.push(c.company)
      }

      this.thirdPartyCompanySelectOptions = companyArray
    },
    exit() {
      this.exitDialog = false
      this.confirmedLeaving = true
      if (this.routerNextPath === '') this.routerNextPath = '/vegetation-issues'

      this.$router.push({ path: this.routerNextPath })
    }
  },
  //@ts-ignore
  beforeRouteLeave(to, from, next) {
    if (to.path) {
      this.routerNextPath = to.path
    }
    if (this.issueSaved) {
      this.exitDialog = false
      next()
    } else if (!this.issueSaved && !this.confirmedLeaving) {
      this.exitDialog = true
      /*
        warning  [Vue warn]: Error in v-on handler (Promise/async): "Error: Navigation aborted from "/vegetation-issues/add" to "/vegetation-issues" via a navigation guard."
        is caused by the call to next(false), it is nothing to be concerned with.
      */
      next(false)
    } else if (!this.issueSaved && this.confirmedLeaving) {
      next()
    }
  },
  watch: {
    actionRequired() {
      if (!this.actionRequired) {
        if (this.riskSlider > 0) {
          this.noRiskRemindDialog = true
        }
        this.disableSlider = true
        this.riskSlider = 0
      } else {
        this.noRiskRemindDialog = false
        this.disableSlider = false
      }
    }
  },
  async created() {
    if (!this.isClientUser) {
      this.disableSlider = true
    }
    if (
      this.$route.query.areaId &&
      typeof this.$route.query.areaId === 'string'
    ) {
      try {
        let parsedId = parseInt(this.$route.query.areaId)
        this.selectedManagementArea = parsedId
      } catch (e) {
        console.log('areaId not a number')
      }
    }

    let foundAction = this.relations.takenActions.find((action) => {
      return action.issue_action.toLowerCase() === 'no action'
    })
    if (foundAction) this.actionTaken = foundAction.id

    //if duplicate then ask the user if they want to use the same area when duplicating
    if (
      this.$route.query.duplicate &&
      typeof this.$route.query.duplicate === 'string'
    ) {
      this.openCopyIssueDialog()
    }
    // else then go into normal mobile path, checking if the user is on mobile and has a position to order the issues and areas by distance
    else if (
      this.managementAreas.length > 0 &&
      this.watchPosition &&
      IsMobileDevice()
    ) {
      //check if the user wants to make a follow up to a specific issue, otherwise load all of the issues
      if (this.$route.query.followUpIssueId) {
        this.loadFollowUpIssue(this.watchPosition)
      } else {
        this.getIssuesFromAreas(this.watchPosition)
      }
    }
    //else then the user is on desktop initialize the area list by standard sorting
    else {
      this.managementAreaOptions = this.managementAreas
      //check if the user wants to make a follow up to a specific issue, otherwise load all of the issues
      if (this.$route.query.followUpIssueId) {
        this.loadFollowUpIssue()
      } else {
        this.getIssuesFromAreas()
      }
    }
    this.getThirdPartyCompanies()
    this.setSingleManageArea()
  }
})

interface IssueWithDistanceToUser {
  issue: VegetationIssueResult
  distance: number
  issueIdentifierSubtitle: string
  issueIdentifier: string
}

interface AddNewVegeIssueI {
  //issue types
  weedId: number
  vegAboveGroundPipeId: number
  vegBelowGroundPipeId: number
  vegNearInfrastructureId: number
  attractantIssueTypeId: number
  otherIssueTypeId: number

  copyIssue: boolean
  copyAreaDialog: boolean
  copyArea: boolean

  managementAreaOptions: ManagementAreaSelectOption[]
  selectedManagementArea: number | null
  selectedDistribution: number | null
  selectedTypeOfProblem: number | null
  originalTypeOfProblem: number | null
  selectedPhysStage: number | null
  selectedWeedSpecies: SpeciesRes[]
  selectedActionsRequired: IssueActionsRes[]
  selectedInfraVegeType: number | null
  actionTaken: number | null
  selectedProximity: number | null
  gps_point: Point | null
  gps_track: LineString | null
  problemsFound: boolean
  comments: string
  weedIndividuals: number
  areaSize: number
  actionRequired: boolean
  date: string
  time: string
  dateDialog: boolean
  timeDialog: boolean
  trackInProgress: boolean
  confirmationDialog: boolean
  closestIssuesDialog: boolean
  originalIssueSelect: IssueWithDistanceToUser[]
  fullIssueList: IssueWithDistanceToUser[]
  selectedIssue: number | null
  pageLoading: boolean
  gpsError: boolean
  photos: Photo[]
  photoLoading: boolean
  nearLowDrain: boolean | null
  riskSlider: number
  originalRiskSlider: number
  riskLabels: string[]
  isMobileDevice: boolean
  weedSelectMenu: boolean
  actionsRequiredSelectMenu: boolean
  disableSlider: boolean
  noRiskRemindDialog: boolean
  vegHeight: string

  thirdPartyCompanySelectOptions: ThirdPartyCompaniesData[]
  selectedThirdPartyCompanies: ThirdPartyCompaniesData[]

  singleArea: boolean
  issueSaved: boolean
  exitDialog: boolean
  confirmedLeaving: boolean
  routerNextPath: string
}
interface ManagementAreaSelectOption extends ManagementAreaResult {
  distance?: number
}
interface ThirdPartyCompaniesData {
  id: number
  company_name: string
  company_tag: string
  company_type: string
}
