import {
  AssignAreaToSubAsset,
  CreateSubAsset,
  EditSubAsset,
  IdRequest,
  RemoveAreaFromSubAsset,
  SubAssetResult,
  SuccessResult
} from '../veg-common/apiTypes'

export const subAssetsApiDef = {
  callCreateSubAsset: {
    route: '/subAssets/create',
    method: 'post',
    auth: true,
    inputType: CreateSubAsset,
    outputType: SuccessResult,
    returnsMultiple: false
  },
  callDeleteManagementArea: {
    route: '/subAssets/removeAreaFromSubAsset',
    method: 'post',
    auth: true,
    inputType: RemoveAreaFromSubAsset,
    outputType: SuccessResult,
    returnsMultiple: false
  },
  callAssignAreaToSubAsset: {
    route: '/subAssets/assignAreaToSubAsset',
    method: 'post',
    auth: true,
    inputType: AssignAreaToSubAsset,
    outputType: SuccessResult,
    returnsMultiple: false
  },
  callGetSubAsset: {
    route: '/subAssets/getSubAsset',
    method: 'get',
    auth: true,
    inputType: IdRequest,
    outputType: SubAssetResult,
    returnsMultiple: false
  },
  callEditSubAsset: {
    route: '/subAssets/editSubAsset',
    method: 'post',
    auth: true,
    inputType: EditSubAsset,
    outputType: SuccessResult,
    returnsMultiple: false
  }
} as const
