export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.readAsDataURL(blob)
  })
}

export const base64ToBlob = (base64: string): Promise<Blob> => {
  return fetch(base64).then(fetchResult => {
    return fetchResult.blob()
  })
}
