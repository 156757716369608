import { createSchema as S, TsjsonParser } from 'ts-json-validator'

const booleanSchema = S({ type: 'boolean' })
const nullSchema = S({ type: 'null' })
const numberSchema = S({ type: 'number' })
const objectSchema = S({ type: 'object' })
const stringSchema = S({ type: 'string' })

export const subAssetSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    sub_asset_name: stringSchema,
    latitude: numberSchema,
    longitude: numberSchema
  },
  required: ['id', 'sub_asset_name', 'latitude', 'longitude']
})

export const assetSchema = S({
  type: 'object',
  properties: {
    id: S({ type: 'number' }),
    asset_name: S({ type: 'string' }),
    latitude: S({ type: 'number' }),
    longitude: S({ type: 'number' })
  },
  required: ['id', 'asset_name', 'latitude', 'longitude']
})
export const assetParser = new TsjsonParser(assetSchema)

export const companySchema = S({
  type: 'object',
  properties: {
    company_name: S({ type: 'string' }),
    company_tag: S({ type: 'string' }),
    company_type: S({ type: 'string' })
  },
  required: ['company_name', 'company_tag', 'company_type']
})
export const companyParser = new TsjsonParser(companySchema)

export const alertSchema = S({
  type: 'object',
  properties: {
    id: S({ type: 'number' }),
    alert_type: S({ type: 'string' })
  },
  required: ['id', 'alert_type']
})
export const alertParser = new TsjsonParser(alertSchema)

export const userSchema = S({
  type: 'object',
  properties: {
    id: S({ type: 'number' }),
    user_name: S({ type: 'string' }),
    email: S({ type: 'string' }),
    first_name: S({ type: 'string' }),
    last_name: S({ type: 'string' }),
    is_active: S({ type: 'boolean' }),
    userRoleId: S({ type: 'number' })
  },
  required: [
    'id',
    'user_name',
    'email',
    'first_name',
    'last_name',
    'is_active',
    'userRoleId'
  ]
})
export const userParser = new TsjsonParser(userSchema)

export const vegActionsSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    issue_action: stringSchema,
    score: numberSchema,
    action_type: stringSchema
  },
  required: ['id', 'issue_action', 'score', 'action_type']
})

export const vegDistributionsSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    distribution: stringSchema,
    score: numberSchema
  },
  required: ['id', 'distribution', 'score']
})

export const vegIssueTypesSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    issue_type: stringSchema
  },
  required: ['id', 'issue_type']
})

export const vegPhysiologicalStagesSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    physiological_stage: stringSchema,
    score: numberSchema
  },
  required: ['id', 'physiological_stage', 'score']
})

export const vegProximitiesSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    proximity: stringSchema,
    score: numberSchema,
    issueTypeId: numberSchema
  },
  required: ['id', 'proximity', 'score', 'issueTypeId']
})

export const vegSpeciesSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    species: stringSchema,
    score: numberSchema,
    vegeTypeId: numberSchema,
    reference_photo_url: stringSchema
  },
  required: ['id', 'species', 'score', 'vegeTypeId', 'reference_photo_url']
})

export const vegTypesSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    vegetation_type: stringSchema,
    score: numberSchema,
    issueTypeId: numberSchema
  },
  required: ['id', 'vegetation_type', 'score', 'issueTypeId']
})

export const culvertStatusSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    culvert_status: stringSchema
  },
  required: ['id', 'culvert_status']
})

export const culvertInspectionActionSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    action_type: stringSchema,
    inspection_action: stringSchema
  },
  required: ['id', 'action_type', 'inspection_action']
})

export const relationsSchema = S({
  type: 'object',
  properties: {
    takenActions: S({ type: 'array', items: vegActionsSchema }),
    requiredActions: S({ type: 'array', items: vegActionsSchema }),
    distributions: S({ type: 'array', items: vegDistributionsSchema }),
    issueTypes: S({ type: 'array', items: vegIssueTypesSchema }),
    physiologicalStages: S({
      type: 'array',
      items: vegPhysiologicalStagesSchema
    }),
    proximities: S({ type: 'array', items: vegProximitiesSchema }),
    species: S({ type: 'array', items: vegSpeciesSchema }),
    weedVegetationTypes: S({ type: 'array', items: vegTypesSchema }),
    infraVegetationTypes: S({ type: 'array', items: vegTypesSchema }),
    culvertStatuses: S({ type: 'array', items: culvertStatusSchema }),
    culvertTakenActions: S({
      type: 'array',
      items: culvertInspectionActionSchema
    }),
    culvertRequiredActions: S({
      type: 'array',
      items: culvertInspectionActionSchema
    })
  },
  required: [
    'takenActions',
    'requiredActions',
    'distributions',
    'issueTypes',
    'physiologicalStages',
    'proximities',
    'species',
    'weedVegetationTypes',
    'infraVegetationTypes',
    'culvertStatuses',
    'culvertTakenActions',
    'culvertRequiredActions'
  ]
})
export const relationsParser = new TsjsonParser(relationsSchema)

export const areaSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    assetId: numberSchema,
    name: stringSchema,
    shape: objectSchema,
    image_label: stringSchema,
    subAssetId: S({ oneOf: [nullSchema, numberSchema] })
  },
  required: ['id', 'assetId', 'name', 'shape', 'image_label', 'subAssetId']
})
export const areaParser = new TsjsonParser(areaSchema)

export const issueSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    hidden: booleanSchema,
    inspection_date: stringSchema,
    has_issue: booleanSchema,
    veg_height: stringSchema,
    risk_score: numberSchema,
    area_size: S({ oneOf: [nullSchema, numberSchema] }),
    gps_track: S({ oneOf: [nullSchema, objectSchema] }),
    gps_point: S({ oneOf: [nullSchema, objectSchema] }),
    action_required: S({ oneOf: [nullSchema, booleanSchema] }),
    individual_count: S({ oneOf: [nullSchema, numberSchema] }),
    density: S({ oneOf: [nullSchema, numberSchema] }),
    comments: stringSchema,
    areaId: numberSchema,
    userId: numberSchema,
    actionTakenId: S({ oneOf: [nullSchema, numberSchema] }),
    distributionId: S({ oneOf: [nullSchema, numberSchema] }),
    physiologicalStageId: S({ oneOf: [nullSchema, numberSchema] }),
    issueTypeId: S({ oneOf: [nullSchema, numberSchema] }),
    vegetationTypeId: S({ oneOf: [nullSchema, numberSchema] }),
    proximityId: S({ oneOf: [nullSchema, numberSchema] }),
    speciesIds: S({ type: 'array', items: numberSchema }),
    actionsRequiredIds: S({ type: 'array', items: numberSchema }),
    originalIssueId: S({ oneOf: [nullSchema, numberSchema] }),
    photos: S({ type: 'array', items: stringSchema }),
    subsequentIssueIds: S({ type: 'array', items: numberSchema }),
    near_low_point_drain: S({ oneOf: [nullSchema, booleanSchema] }),
    user: userSchema,
    isInteractive: booleanSchema
  },
  required: [
    'id',
    'hidden',
    'inspection_date',
    'has_issue',
    'veg_height',
    'risk_score',
    'area_size',
    'gps_track',
    'gps_point',
    'action_required',
    'individual_count',
    'density',
    'comments',
    'areaId',
    'userId',
    'actionTakenId',
    'distributionId',
    'physiologicalStageId',
    'issueTypeId',
    'vegetationTypeId',
    'proximityId',
    'speciesIds',
    'actionsRequiredIds',
    'originalIssueId',
    'photos',
    'subsequentIssueIds',
    'near_low_point_drain',
    'user',
    'isInteractive'
  ]
})
export const issueParser = new TsjsonParser(issueSchema)

export const culvertSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    assetId: numberSchema,
    is_fish_habitat: booleanSchema,
    site_culvert_id: stringSchema,
    road_name: stringSchema,
    has_markers: booleanSchema,
    length: numberSchema,
    diameter: numberSchema,
    inlet_location: S({ oneOf: [nullSchema, objectSchema] }),
    outlet_location: S({ oneOf: [nullSchema, objectSchema] }),
    direction_of_flow: stringSchema
  },
  required: [
    'id',
    'assetId',
    'is_fish_habitat',
    'site_culvert_id',
    'road_name',
    'has_markers',
    'length',
    'diameter',
    'inlet_location',
    'outlet_location',
    'direction_of_flow'
  ]
})
export const culvertParser = new TsjsonParser(culvertSchema)

export const culvertInspectionsSchema = S({
  type: 'object',
  properties: {
    id: numberSchema,
    inspection_date: stringSchema,
    next_inspection_date: stringSchema,
    comments: stringSchema,
    risk_score: numberSchema,
    userId: numberSchema,
    user: userSchema,
    culvertId: numberSchema,
    culvertUuid: S({ oneOf: [stringSchema, nullSchema] }),
    culvertStatusId: numberSchema,
    actionsTakenIds: S({ type: 'array', items: numberSchema }),
    actionsRequiredIds: S({ type: 'array', items: numberSchema }),
    photos: S({ type: 'array', items: stringSchema }),
    hidden: booleanSchema
  },
  required: [
    'id',
    'inspection_date',
    'next_inspection_date',
    'comments',
    'risk_score',
    'userId',
    'user',
    'culvertId',
    'culvertStatusId',
    'actionsTakenIds',
    'actionsRequiredIds',
    'photos',
    'hidden'
  ]
})
export const culvertInspectionsParser = new TsjsonParser(
  culvertInspectionsSchema
)
