import { Geometry } from 'geojson'

import _flatMap from 'lodash/flatMap'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'
import { getYear } from 'date-fns'

import { IStore } from './'
import { StateFor, GettersFor } from 'vuex-typescript-interface'
import { determineMaxRisk } from '../util/CalculateIssueRisk'

import { DrawablePolygon, riskToColour } from '../util/drawing'
import { decodeJwtNoVerifySync } from '../util/jwt'
import {
  VegetationIssueResult,
  CulvertInspectionResult,
  CulvertResult,
  GetThirdPartyCompanyRelationsResponse,
  RelationsRes
} from '../veg-common/apiTypes'
import {
  AssetObject,
  UserObject,
  VegActionsObject,
  VegDistributionsObject,
  VegIssueTypesObject,
  VegPhysiologicalStagesObject,
  VegProximitiesObject,
  VegSpeciesObject,
  VegTypesObject,
  CulvertStatusObject,
  CulvertActionsObject
} from '../veg-common/apiTypes/newResultTypes'
import { sortAllLists } from '../util/SortRelationsList'

export default {
  /* Misc Getters - login related, or other smaller getters based on store data */
  apiUrl() {
    return process.env.VUE_APP_API_URL || ''
  },
  basePhotoUrl() {
    return 'https://s3.ca-central-1.amazonaws.com/photos.veglogic.io/'
  },
  culvertlogicStatus(state: StateFor<IStore>): boolean {
    return state.loggedIntoCulvertLogic
  },
  getSnackbarParams(state: StateFor<IStore>) {
    return state.snackbarParams
  },
  hasUnsyncedData(state: StateFor<IStore>) {
    return (
      state.unsyncedIssues.length > 0 ||
      state.unsyncedPhotos.length > 0 ||
      state.unsyncedCulvertInspections.length > 0 ||
      state.unsyncedCulverts.length > 0
    )
  },
  isAdminRole(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    if (
      getters.tokenValid &&
      getters.jwtContent !== null &&
      getters.jwtContent.data.userId !== null
    ) {
      const user = state.appData.users[getters.jwtContent.data.userId]
      if (user) {
        //client admin or third party client admin
        return user.userRoleId === 1 || user.userRoleId === 2
      } else {
        return false
      }
    } else {
      return false
    }
  },
  // Is a user logged in. Doesn't say anything about whether logged in as
  // a superuser.
  isLoggedIn(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    return (
      getters.tokenValid &&
      getters.jwtContent !== null &&
      getters.jwtContent.data.userId !== null
    )
  },
  isSuper(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    return (
      getters.tokenValid &&
      getters.jwtContent !== null &&
      getters.jwtContent.data.isSuper
    )
  },
  jwtContent(state: StateFor<IStore>) {
    if (state.token) {
      let decodeResult = decodeJwtNoVerifySync(state.token)
      if (decodeResult instanceof Error) {
        return null
      } else {
        return decodeResult
      }
    } else {
      return null
    }
  },
  managementAreaPolygons(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    let polygonArray: DrawablePolygon[] = []
    let year = state.selectedAssetOverviewYear

    getters.managementAreas.forEach((ma) => {
      let polygon: DrawablePolygon | null

      if (ma.shape.type !== 'Polygon') {
        polygon = null
      } else {
        let coordinates: google.maps.LatLngLiteral[] =
          ma.shape.coordinates[0].map((coords) => {
            return {
              //geojson coordinates are [lng, lat]
              lng: coords[0],
              lat: coords[1]
            }
          })

        let maxIssueRisk: number | null
        if (year) {
          if (ma.issues.length) {
            let vegIssue = ma.issues
            let issuesInYear = []
            for (let issue of vegIssue) {
              let inspDate = getYear(new Date(issue.inspection_date))
              if (inspDate === year) {
                issuesInYear.push(issue)
              }
            }
            maxIssueRisk = determineMaxRisk(issuesInYear)
          } else {
            //if no issues then no risk, we can set to the lowest risk manually here
            maxIssueRisk = 0
          }
        } else {
          // Determine polygon color
          maxIssueRisk = determineMaxRisk(ma.issues)
        }

        let colour = riskToColour(maxIssueRisk)
        polygon = { coordinates, opacity: 0.65, colour, area: ma }
      }

      if (polygon !== null) {
        polygonArray.push(polygon)
      }
    })
    return polygonArray
  },
  relations(state: StateFor<IStore>) {
    return sortAllLists(state.appData.relations as RelationsRes)
  },
  selectedAsset(state: StateFor<IStore>) {
    let retVal: AssetObject | null = null

    if (state.appData !== null) {
      const selectedAssetId = state.appData.selectedAssetId
      const allAssetIds = Object.keys(state.appData.assets)

      if (
        selectedAssetId === null ||
        !allAssetIds.includes(selectedAssetId.toString())
      ) {
        if (allAssetIds.length > 0) {
          let intId = Number.parseInt(allAssetIds[0])
          if (Number.isInteger(intId)) {
            console.log(`Selecting assetId ${intId} as default.`)
            retVal = state.appData.assets[intId]
          }
        }
      } else {
        retVal = state.appData.assets[selectedAssetId]
      }
    }

    return retVal
  },
  tokenValid(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    if (getters.jwtContent === null) {
      return false
    } else {
      let timestamp = Math.floor(Date.now() / 1000)
      if (timestamp > getters.jwtContent.exp) {
        return false
      } else {
        return true
      }
    }
  },
  user(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    if (
      getters.tokenValid &&
      getters.jwtContent !== null &&
      getters.jwtContent.data.userId !== null
    ) {
      const user = state.appData.users[getters.jwtContent.data.userId]
      if (user) {
        return user
      } else {
        return null
      }
    } else {
      return null
    }
  },
  vegelogicStatus(state: StateFor<IStore>) {
    return state.loggedIntoVegeLogic
  },
  /*END of Misc Getters */

  /* Raw data getters - getters for getting the raw lists of all our data */
  alerts(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    return Object.keys(state.appData.alerts).map((k) => {
      const alert = state.appData.alerts[k]
      return {
        id: alert.id as number,
        alert_type: alert.alert_type as string
      }
    })
  },
  assetsArray(state: StateFor<IStore>) {
    if (state.appData === null) {
      return []
    } else {
      return Object.values(state.appData.assets as Record<string, AssetObject>)
    }
  },
  assetAssociatedSpecies(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    return Object.keys(state.assetData.assetAssociatedSpecies).map((k) => {
      const assetAssociatedSpecie = state.assetData.assetAssociatedSpecies[k]
      return {
        id: assetAssociatedSpecie.id as number,
        reference_photo_url:
          assetAssociatedSpecie.reference_photo_url as string,
        score: assetAssociatedSpecie.score as number,
        species: assetAssociatedSpecie.species as string,
        vegeTypeId: assetAssociatedSpecie.vegeTypeId as number
      }
    })
  },
  company(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    if (
      getters.tokenValid &&
      getters.jwtContent !== null &&
      getters.jwtContent.data.userId !== null
    ) {
      const company = state.appData.company
      if (company) {
        return company
      } else {
        return null
      }
    } else {
      return null
    }
  },
  culverts(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    const inspectionsByCulvert = _groupBy(
      getters.culvertInspections,
      'culvertId'
    )
    let culverts: CulvertResult[] = []
    Object.keys(state.assetData.culverts).flatMap((k) => {
      const culvert = state.assetData.culverts[k]
      culverts.push({
        ...culvert,
        inlet_location: culvert.inlet_location as unknown as Geometry,
        outlet_location: culvert.outlet_location as unknown as Geometry,
        culvertInspections:
          culvert.id in inspectionsByCulvert
            ? inspectionsByCulvert[culvert.id]
            : []
      })
    })

    const inspectionsByOfflineCulvert = _groupBy(
      getters.culvertInspections,
      'culvertUuid'
    )

    state.unsyncedCulverts.forEach((paramsCulvert) => {
      let culvert = paramsCulvert.toResultType()
      culvert.culvertInspections =
        culvert.uuid && culvert.uuid in inspectionsByOfflineCulvert
          ? inspectionsByOfflineCulvert[culvert.uuid]
          : []
      culverts.push(culvert)
    })

    return culverts
  },
  culvertInspections(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    let culvertInspections: CulvertInspectionResult[] = Object.keys(
      state.assetData.culvertInspections
    ).flatMap((k) => {
      const inspection = state.assetData.culvertInspections[k]
      if (inspection.hidden) {
        return []
      } else {
        let user = { first_name: '', last_name: '' }
        if (inspection.user) {
          user.first_name = inspection.user.first_name
          user.last_name = inspection.user.last_name
        } else {
          user = state.appData.users[inspection.userId] || {
            first_name: '',
            last_name: ''
          }
        }
        const culvert = state.assetData.culverts[inspection.culvertId] || {
          site_culvert_id: ''
        }

        return [
          {
            ...inspection,
            ...{
              user: `${user.first_name} ${user.last_name}`,
              culvert: culvert.site_culvert_id
            },
            actionsTaken: inspection.actionsTakenIds.map((x: number) =>
              _get(getters.culvertActionsMap, x, '')
            ),
            culvertStatus: inspection.culvertStatusId
              ? _get(getters.culvertStatusMap, inspection.culvertStatusId, '')
              : '',
            actionsRequired: inspection.actionsRequiredIds.map((x: number) =>
              _get(getters.culvertActionsMap, x, '')
            )
          }
        ]
      }
    })

    let unsyncedInspections: CulvertInspectionResult[] = []
    state.unsyncedCulvertInspections.forEach((paramsInspection) => {
      let inspection = paramsInspection.toResultType()
      let photosEntities = state.unsyncedPhotos.filter(
        (photo) => photo.parentUuid === paramsInspection.uuid
      )
      let photos: string[] = []
      photosEntities.forEach((photo) => {
        photos.push(photo.photoBase64)
      })

      let user = state.appData.users[inspection.userId] || {
        first_name: '',
        last_name: ''
      }

      let culvert: any
      if (inspection.culvertId < 0) {
        let offlineCulvert = state.unsyncedCulverts.find(
          (culvert) => inspection.culvertUuid === culvert.uuid
        )
        if (offlineCulvert) {
          culvert = offlineCulvert
        } else {
          //culvert has been synced, but something prevent the inspection from being synced
          Object.keys(state.assetData.culverts).flatMap((k) => {
            if (state.assetData.culverts[k].uuid === inspection.culvertUuid) {
              console.log(state.assetData.culverts[k].uuid)
              console.log(inspection.culvertUuid)

              culvert = state.assetData.culverts[k]
              return
            }
          })
          if (!culvert) {
            culvert = { site_culvert_id: '' }
          }
        }
      } else {
        culvert = state.assetData.culverts[inspection.culvertId] || {
          site_culvert_id: ''
        }
      }

      unsyncedInspections.push({
        id: inspection.id,
        photos: photos,
        inspection_date: inspection.inspection_date,
        next_inspection_date: inspection.next_inspection_date,
        comments: inspection.comments,
        risk_score: inspection.risk_score,
        hidden: false,
        user: `${user.first_name} ${user.last_name}`,
        culvert: culvert.site_culvert_id,
        actionsTaken: inspection.actionsTakenIds.map((x: number) =>
          _get(getters.culvertActionsMap, x, '')
        ),
        culvertStatus: inspection.culvertStatusId
          ? _get(getters.culvertStatusMap, inspection.culvertStatusId, '')
          : '',
        actionsRequired: inspection.actionsRequiredIds.map((x: number) =>
          _get(getters.culvertActionsMap, x, '')
        )
      })
    })

    return culvertInspections.concat(unsyncedInspections)
  },
  managementAreas(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    const issuesByArea = _groupBy(getters.vegetationIssues, 'areaId')
    return Object.keys(state.assetData.areas).map((k) => {
      const area = state.assetData.areas[k]
      return {
        ...area,
        shape: area.shape as unknown as Geometry,
        imageLabel: area.image_label,
        issues: area.id in issuesByArea ? issuesByArea[area.id] : [],
        subAssetId: area.subAssetId,
        averageRisk: 0 // unused
      }
    })
  },
  relatedThirdPartyCompanies(state: StateFor<IStore>) {
    return Object.keys(state.appData.relatedThirdPartyCompanies).map((k) => {
      //appData.relatedThirdPartyCompanies is an any object.
      //To make a validator would include circular references to the issues and companies, so the validator is set to just accept an object for relatedThirdPartyCompanies
      //this means the type itself is 'unknown'
      const thirdParty = state.appData.relatedThirdPartyCompanies[
        k
      ] as GetThirdPartyCompanyRelationsResponse
      return {
        companyId: thirdParty.companyId,
        company: thirdParty.company,
        userId: thirdParty.userId,
        admin_email: thirdParty.admin_email
      }
    })
  },
  subAssets(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    return Object.keys(state.assetData.subAssets).map((k) => {
      const subAsset = state.assetData.subAssets[k]
      return {
        sub_asset_name: subAsset.sub_asset_name,
        id: subAsset.id,
        latitude: subAsset.latitude,
        longitude: subAsset.longitude
      }
    })
  },
  vegetationIssues(state: StateFor<IStore>, getters: GettersFor<IStore>) {
    let resultIssues: VegetationIssueResult[] = Object.keys(
      state.assetData.issues
    ).flatMap((k) => {
      const issue = state.assetData.issues[k]

      if (issue.hidden) {
        return []
      } else {
        let user = { first_name: '', last_name: '' }
        if (issue.user) {
          user.first_name = issue.user.first_name
          user.last_name = issue.user.last_name
        } else {
          user = state.appData.users[issue.userId] || {
            first_name: '',
            last_name: ''
          }
        }
        const area = state.assetData.areas[issue.areaId] || { name: '' }
        return [
          {
            ...issue,
            user: `${user.first_name} ${user.last_name}`,
            area: area.name,
            action_taken: issue.actionTakenId
              ? _get(getters.actionsMap, issue.actionTakenId, null)
              : null,
            actionsRequired: issue.actionsRequiredIds.map((x: number) =>
              _get(getters.actionsMap, x, '')
            ),
            distribution: issue.distributionId
              ? _get(getters.distributionsMap, issue.distributionId, null)
              : null,
            physiological_stage: issue.physiologicalStageId
              ? _get(
                  getters.physiologicalStagesMap,
                  issue.physiologicalStageId,
                  null
                )
              : null,
            issue_type: issue.issueTypeId
              ? _get(getters.issueTypesMap, issue.issueTypeId, null)
              : null,
            species: issue.speciesIds.map((x: number) =>
              _get(getters.speciesMap, x, '')
            ),
            vegetation_type: issue.vegetationTypeId
              ? _get(getters.vegetationTypesMap, issue.vegetationTypeId, null)
              : null,
            proximity: issue.proximityId
              ? _get(getters.proximitiesMap, issue.proximityId, null)
              : null,
            original_issue_id: issue.originalIssueId,
            gps_track: issue.gps_track as unknown as Geometry,
            gps_point: issue.gps_point as unknown as Geometry,
            subsequentIssueIds: issue.subsequentIssueIds
          }
        ]
      }
    })
    let unsyncedIssues: VegetationIssueResult[] = []
    state.unsyncedIssues.forEach((paramsIssue) => {
      let issue = paramsIssue.toResultType()
      let photosEntities = state.unsyncedPhotos.filter(
        (photo) => photo.parentUuid === paramsIssue.uuid
      )
      let photos: string[] = []
      photosEntities.forEach((photo) => {
        photos.push(photo.photoBase64)
      })

      let user = state.appData.users[issue.userId] || {
        first_name: '',
        last_name: ''
      }

      let area = state.assetData.areas[issue.areaId] || { name: '' }

      unsyncedIssues.push({
        ...paramsIssue,
        id: issue.id,
        photos: issue.photos,
        inspection_date: issue.inspection_date,
        has_issue: issue.has_issue,
        risk_score: issue.risk_score,
        area_size: issue.area_size,
        user: `${user.first_name} ${user.last_name}`,
        area: area.name,
        action_taken: issue.actionTakenId
          ? _get(getters.actionsMap, issue.actionTakenId, null)
          : null,
        actionsRequired: issue.actionsRequiredIds.map((x: number) =>
          _get(getters.actionsMap, x, '')
        ),
        distribution: issue.distributionId
          ? _get(getters.distributionsMap, issue.distributionId, null)
          : null,
        physiological_stage: issue.physiologicalStageId
          ? _get(
              getters.physiologicalStagesMap,
              issue.physiologicalStageId,
              null
            )
          : null,
        issue_type: issue.issueTypeId
          ? _get(getters.issueTypesMap, issue.issueTypeId, null)
          : null,
        species: issue.speciesIds.map((x: number) =>
          _get(getters.speciesMap, x, '')
        ),
        vegetation_type: issue.vegetationTypeId
          ? _get(getters.vegetationTypesMap, issue.vegetationTypeId, null)
          : null,
        proximity: issue.proximityId
          ? _get(getters.proximitiesMap, issue.proximityId, null)
          : null,
        original_issue_id: issue.originalIssueId,
        gps_track: issue.gps_track as unknown as Geometry,
        gps_point: issue.gps_point as unknown as Geometry,
        action_required: issue.action_required,
        individual_count: issue.individual_count,
        comments: issue.comments,
        density: issue.density,
        subsequentIssueIds: [],
        near_low_point_drain: issue.near_low_point_drain,
        isInteractive: issue.isInteractive
      })
    })
    return resultIssues.concat(unsyncedIssues)
  },
  usersArray(state: StateFor<IStore>) {
    if (state.appData === null) {
      return []
    } else {
      return Object.values(state.appData.users as Record<string, UserObject>)
    }
  },
  /* END Raw data getters */

  /* Relations mapping Getters */
  actionsMap(state: StateFor<IStore>) {
    let retVal: Record<number, string> = {}
    state.appData.relations.takenActions.forEach((x: VegActionsObject) => {
      retVal[x.id] = x.issue_action
    })
    state.appData.relations.requiredActions.forEach((x: VegActionsObject) => {
      retVal[x.id] = x.issue_action
    })
    return retVal
  },
  culvertActionsMap(state: StateFor<IStore>) {
    let retVal: Record<number, string> = {}
    state.appData.relations.culvertTakenActions.forEach(
      (x: CulvertActionsObject) => {
        retVal[x.id] = x.inspection_action
      }
    )
    state.appData.relations.culvertRequiredActions.forEach(
      (x: CulvertActionsObject) => {
        retVal[x.id] = x.inspection_action
      }
    )
    return retVal
  },
  culvertStatusMap(state: StateFor<IStore>) {
    let retVal: Record<number, string> = {}
    state.appData.relations.culvertStatuses.forEach(
      (x: CulvertStatusObject) => {
        retVal[x.id] = x.culvert_status
      }
    )
    return retVal
  },
  distributionsMap(state: StateFor<IStore>) {
    let retVal: Record<number, string> = {}
    state.appData.relations.distributions.forEach(
      (x: VegDistributionsObject) => {
        retVal[x.id] = x.distribution
      }
    )
    return retVal
  },
  issueTypesMap(state: StateFor<IStore>) {
    let retVal: Record<number, string> = {}
    state.appData.relations.issueTypes.forEach((x: VegIssueTypesObject) => {
      retVal[x.id] = x.issue_type
    })
    return retVal
  },
  physiologicalStagesMap(state: StateFor<IStore>) {
    let retVal: Record<number, string> = {}
    state.appData.relations.physiologicalStages.forEach(
      (x: VegPhysiologicalStagesObject) => {
        retVal[x.id] = x.physiological_stage
      }
    )
    return retVal
  },
  proximitiesMap(state: StateFor<IStore>) {
    let retVal: Record<number, string> = {}
    state.appData.relations.proximities.forEach((x: VegProximitiesObject) => {
      retVal[x.id] = x.proximity
    })
    return retVal
  },
  speciesMap(state: StateFor<IStore>) {
    let retVal: Record<number, string> = {}
    state.appData.relations.species.forEach((x: VegSpeciesObject) => {
      retVal[x.id] = x.species
    })
    return retVal
  },
  vegetationTypesMap(state: StateFor<IStore>) {
    let retVal: Record<number, string> = {}
    state.appData.relations.weedVegetationTypes.forEach((x: VegTypesObject) => {
      retVal[x.id] = x.vegetation_type
    })
    state.appData.relations.infraVegetationTypes.forEach(
      (x: VegTypesObject) => {
        retVal[x.id] = x.vegetation_type
      }
    )
    return retVal
  }
}
