























import Vue from 'vue'
import BrowserTypesNotification from './components/BrowserTypesNotification.vue'
import SnackbarWidget from './components/SnackbarWidget.vue'
import { IsMobileDevice } from './util/ParseUserAgent'

export default Vue.extend({
  components: {
    BrowserTypesNotification,
    SnackbarWidget
  },
  data(): AppI {
    return {
      refreshing: false
    }
  },
  computed: {
    updateVersion(): string {
      return this.$typedStore.state.version
    }
  },
  watch: {
    updateVersion(newVal, oldVal): void {
      if (!IsMobileDevice() && newVal !== oldVal) {
        this.$router.go(0) //force refresh the page to get the new site contents
      }
    }
  }
})

interface AppI {
  refreshing: boolean
}
