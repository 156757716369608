import { IsInt, IsString, IsUrl, ValidateNested } from 'class-validator'

export class RelationsRes {
  constructor(
    takenActions: IssueActionsRes[],
    requiredActions: IssueActionsRes[],
    distributions: DistributionsRes[],
    issueTypes: IssueTypeRes[],
    physiologicalStages: PhysiologicalStagesRes[],
    proximities: ProximitiesRes[],
    species: SpeciesRes[],
    weedVegetationTypes: VegetationTypesRes[],
    infraVegetationTypes: VegetationTypesRes[],
    culvertStatuses: CulvertStatusRes[],
    culvertTakenActions: CulvertInspectionActionRes[],
    culvertRequiredActions: CulvertInspectionActionRes[]
  ) {
    if (takenActions) this.takenActions = takenActions
    if (requiredActions) this.requiredActions = requiredActions
    if (distributions) this.distributions = distributions
    if (issueTypes) this.issueTypes = issueTypes
    if (physiologicalStages) this.physiologicalStages = physiologicalStages
    if (proximities) this.proximities = proximities
    if (species) this.species = species
    if (weedVegetationTypes) this.weedVegetationTypes = weedVegetationTypes
    if (infraVegetationTypes) this.infraVegetationTypes = infraVegetationTypes
    if (culvertStatuses) this.culvertStatuses = culvertStatuses
    if (culvertTakenActions) this.culvertTakenActions = culvertTakenActions
    if (culvertRequiredActions)
      this.culvertRequiredActions = culvertRequiredActions
  }

  @ValidateNested()
  takenActions!: IssueActionsRes[]

  @ValidateNested()
  requiredActions!: IssueActionsRes[]

  @ValidateNested()
  distributions!: DistributionsRes[]

  @ValidateNested()
  issueTypes!: IssueTypeRes[]

  @ValidateNested()
  physiologicalStages!: PhysiologicalStagesRes[]

  @ValidateNested()
  proximities!: ProximitiesRes[]

  @ValidateNested()
  species!: SpeciesRes[]

  @ValidateNested()
  weedVegetationTypes!: VegetationTypesRes[]

  @ValidateNested()
  infraVegetationTypes!: VegetationTypesRes[]

  @ValidateNested()
  culvertStatuses!: CulvertStatusRes[]

  @ValidateNested()
  culvertTakenActions!: CulvertInspectionActionRes[]

  @ValidateNested()
  culvertRequiredActions!: CulvertInspectionActionRes[]
}

export class IssueActionsRes {
  constructor(item: IssueActionsRes) {
    if (item) {
      this.id = item.id
      this.issue_action = item.issue_action
      this.score = item.score
      this.action_type = item.action_type
    }
  }

  @IsString()
  issue_action!: string

  @IsInt()
  id!: number

  @IsInt()
  score!: number

  @IsString()
  action_type!: string
}

export class DistributionsRes {
  constructor(item: DistributionsRes) {
    if (item) {
      this.id = item.id
      this.distribution = item.distribution
      this.score = item.score
    }
  }

  @IsInt()
  id!: number

  @IsString()
  distribution!: string

  @IsInt()
  score!: number
}

export class IssueTypeRes {
  constructor(item: IssueTypeRes) {
    if (item) {
      this.id = item.id
      this.issue_type = item.issue_type
    }
  }

  @IsInt()
  id!: number

  @IsString()
  issue_type!: string
}

export class PhysiologicalStagesRes {
  constructor(item: PhysiologicalStagesRes) {
    if (item) {
      this.id = item.id
      this.physiological_stage = item.physiological_stage
      this.score = item.score
    }
  }

  @IsInt()
  id!: number

  @IsString()
  physiological_stage!: string

  @IsInt()
  score!: number
}

export class ProximitiesRes {
  constructor(item: ProximitiesRes) {
    if (item) {
      this.id = item.id
      this.proximity = item.proximity
      this.score = item.score
      this.issueTypeId = item.issueTypeId
    }
  }

  @IsInt()
  id!: number

  @IsString()
  proximity!: string

  @IsInt()
  score!: number

  @IsInt()
  issueTypeId!: number
}

export class SpeciesRes {
  constructor(item: SpeciesRes) {
    if (item) {
      this.id = item.id
      this.species = item.species
      this.score = item.score
      this.reference_photo_url = item.reference_photo_url
      this.vegeTypeId = item.vegeTypeId
    }
  }

  @IsInt()
  id!: number

  @IsString()
  species!: string

  @IsInt()
  score!: number

  @IsInt()
  vegeTypeId!: number

  @IsString()
  @IsUrl()
  reference_photo_url!: string
}

export class VegetationTypesRes {
  constructor(item: VegetationTypesRes) {
    if (item) {
      this.id = item.id
      this.vegetation_type = item.vegetation_type
      this.score = item.score
      this.issueTypeId = item.issueTypeId
    }
  }

  @IsInt()
  id!: number

  @IsString()
  vegetation_type!: string

  @IsInt()
  score!: number

  @IsInt()
  issueTypeId!: number
}

export class CulvertStatusRes {
  constructor(item: CulvertStatusRes) {
    if (item) {
      this.id = item.id
      this.culvert_status = item.culvert_status
    }
  }

  @IsInt()
  id!: number

  @IsString()
  culvert_status!: string
}

export class CulvertInspectionActionRes {
  constructor(item: CulvertInspectionActionRes) {
    if (item) {
      this.id = item.id
      this.inspection_action = item.inspection_action
      this.action_type = item.action_type
    }
  }

  @IsInt()
  id!: number

  @IsString()
  inspection_action!: string

  @IsString()
  action_type!: string
}
