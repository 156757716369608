import { IsDefined, IsBoolean, IsInt } from 'class-validator'
import { classToPlain } from 'class-transformer'

export abstract class VegResponse {
  toPlain() {
    return classToPlain(this)
  }
}

export class GenericResult extends VegResponse {
  @IsDefined()
  @IsBoolean()
  success: boolean

  constructor(success: boolean) {
    super()
    this.success = success
  }
}

export class SuccessResult extends GenericResult {
  constructor(id?: number) {
    super(true)
  }
}

export class FailureResult extends GenericResult {
  constructor() {
    super(false)
  }
}

export class IdRequest {
  @IsDefined()
  @IsInt()
  id: number

  constructor(id: number) {
    this.id = id
  }
}

export class IdResponse {
  @IsDefined()
  @IsInt()
  id: number

  constructor(id: number) {
    this.id = id
  }
}

export class FileResponse {
  @IsDefined()
  file!: string

  @IsDefined()
  contentType!: string

  constructor(file: string, contentType: string) {
    this.file = file
    this.contentType = contentType
  }
}
