












































import Vue from 'vue'

export default Vue.extend({
  data(): ResetPasswordI {
    return {
      formPassword1: '',
      formPassword2: '',
      inProgress: false
    }
  },
  methods: {
    async onSubmit() {
      this.inProgress = true
      try {
        let token = this.$route.query.x
        if (typeof token !== 'string') {
          throw new Error('Invalid token')
        }
        if (this.formPassword1 !== this.formPassword2) {
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Passwords do not match.'
          })
          this.inProgress = false
          return
        }

        let result = await this.$rpc('callResetPasswordSubmission', {
          token,
          newPassword: this.formPassword1
        })

        if (result.success) {
          this.formPassword1 = ''
          this.formPassword2 = ''
          this.$typedStore.commit('setSnackbarParams', {
            type: 'success',
            msg: 'Reset password succeeded.'
          })
          this.$router.replace(`/login`)
        } else {
          // API returned error. Invalid or expired token
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Reset password expired. Please try again.'
          })
        }
      } catch (e) {
        // Validation, network, or other error
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Reset password failed. Please try again.'
        })
      }
      this.inProgress = false
    }
  }
})

interface ResetPasswordI {
  formPassword1: string
  formPassword2: string
  inProgress: boolean
}
