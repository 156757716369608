import { v4 as uuidv4 } from 'uuid'

import _flatMap from 'lodash/flatMap'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'

import Vue from 'vue'
import { IStore } from './'
import { ActionContext } from 'vuex-typescript-interface'
import { localDb } from '../sqlite/SQLiteConnection'
import * as Entities from '../sqlite/entities'
import { RpcType, apiDef, callApi } from '../rpc'
import { NoResponseError } from '../util/errors'
import { Filesystem } from '@capacitor/filesystem'

import { GetThirdPartyCompanyRelationsResponse } from '../veg-common/apiTypes'

import {
  EntitiesWithPhotos,
  GetAppResponse,
  GetAssetResponse,
  RelationsRes,
  LoginResponse,
  VegetationIssueParams,
  AlertResponse,
  CulvertInspectionParams,
  NewCulvertParams
} from '../veg-common/apiTypes'
import {
  alertParser,
  relationsParser
} from '../veg-common/apiTypes/newResultSchemas'
import { base64ToBlob } from '../util/binaryData'
import { Photo } from '../util/imageProcessing'
import { IsMobileDevice } from '../util/ParseUserAgent'

export default {
  async checkVersion(
    { commit, state }: ActionContext<IStore>,
    version: string
  ) {
    if (state.version !== version) {
      commit('setVersion', version)
    }
  },
  async doClearFilters({ commit }: ActionContext<IStore>) {
    await localDb.clear(Entities.VegeIssueAppliedFilters)
    await localDb.clear(Entities.AppliedCulvertFiltersDataEntity)
    await localDb.clear(Entities.AppliedCulvertInspectionFiltersDataEntity)
    await localDb.SaveToStore()
    commit('clearFilters')
  },
  async doLogin(
    { dispatch }: ActionContext<IStore>,
    login: LoginResponse
  ): Promise<void> {
    if (login.token) {
      await dispatch('setToken', login.token)
    } else {
      console.log('doLogin called without token')
    }
  },
  async doLogout({ commit }: ActionContext<IStore>): Promise<void> {
    await localDb.clearAll()
    await localDb.SaveToStore()
    commit('clearFilters')
    commit('logout')
  },
  async getUpdateTime({}, key: string | number): Promise<string | null> {
    let updateTimes = await localDb.find(Entities.UpdatedAtEntity)
    let foundUpdateTime: Entities.UpdatedAtEntity | null = null
    for (let i = 0; i < updateTimes.length; i++) {
      if (updateTimes[i].key === key.toString()) {
        foundUpdateTime = updateTimes[i]
        break
      }
    }
    if (foundUpdateTime) {
      return foundUpdateTime.since
    } else {
      return null
    }
  },
  async loadCache({
    state,
    getters,
    dispatch,
    commit
  }: ActionContext<IStore>): Promise<void> {
    let vegIssueFilters = await localDb.findOne(
      Entities.VegeIssueAppliedFilters
    )
    let culvertInspectionFilters = await localDb.findOne(
      Entities.AppliedCulvertInspectionFiltersDataEntity
    )
    let culvertFilters = await localDb.findOne(
      Entities.AppliedCulvertFiltersDataEntity
    )
    let alerts = await localDb.find(Entities.AlertResponseEntity)
    let assets = await localDb.find(Entities.AssetResultEntity)
    let users = await localDb.find(Entities.UsersEntity)
    let relations = await localDb.findOne(Entities.RelationsEntity)
    let extraAppData = await localDb.findOne(Entities.ExtraAppDataEntity)
    let loggedInStoreState = await localDb.findOne(
      Entities.AppLoggedInStoreStateEntity
    )
    let storeState = await localDb.findOne(Entities.StoreStateEntity)
    let relatedThirdPartyCompanies = await localDb.find(
      Entities.ThirdPartyEntity
    )

    commit('setAppData', {
      alerts,
      assets,
      relatedThirdPartyCompanies,
      users,
      extraAppData,
      relations,
      vegIssueFilters,
      culvertInspectionFilters,
      culvertFilters,
      loggedInStoreState,
      storeState
    })

    //a user might not have alerts, so we don't need to check that list here
    let appFromCache =
      users.length > 0 &&
      assets.length > 0 &&
      relations !== null &&
      extraAppData !== null

    if (!appFromCache) {
      await dispatch('setUpdateTime', { item: 'app', time: null })
    }

    if (getters.selectedAsset !== null) {
      const assetId = getters.selectedAsset.id
      //read asset data
      let areas = await localDb.find(Entities.ManagementAreasEntity)
      let issues = await localDb.find(Entities.VegetationIssuesResultEntity)
      let subAssets = await localDb.find(Entities.SubAssetResultEntity)
      let culverts = await localDb.find(Entities.CulvertResultEntity)
      let culvertInspections = await localDb.find(
        Entities.CulvertInspectionResultEntity
      )
      let assetSpeciesList = await localDb.find(Entities.AssetSpeciesEntity)

      commit('setAssetData', {
        areas,
        issues,
        subAssets,
        culverts,
        culvertInspections,
        assetSpeciesList
      })

      //an asset might not have subassets, so we don't need to check that loaded list here
      let assetDataLoadedFromCache =
        areas.length > 0 &&
        issues.length > 0 &&
        culverts.length > 0 &&
        culvertInspections.length > 0 &&
        assetSpeciesList.length > 0

      if (!assetDataLoadedFromCache) {
        await dispatch('setUpdateTime', {
          item: assetId,
          time: null
        })
      }
    }

    state.unsyncedIssues = await localDb.find(
      Entities.VegetationIssueParamsEntity
    )
    state.unsyncedPhotos = await localDb.find(Entities.CreatePhotoRequestEntity)
    state.unsyncedCulvertInspections = await localDb.find(
      Entities.CulvertInspectionParamsEntity
    )
    state.unsyncedCulverts = await localDb.find(Entities.NewCulvertParamsEntity)
  },
  async loadToken({ state }: ActionContext<IStore>): Promise<void> {
    let tokenEntity = await localDb.findOne(Entities.UserToken)

    if (tokenEntity) {
      state.token = tokenEntity.token
    } else {
      state.token = null
    }
  },
  async setAlerts(
    { state }: ActionContext<IStore>,
    alerts: AlertResponse[]
  ): Promise<void> {
    state.appData.alerts = {}
    await localDb.clear(Entities.AlertResponseEntity)
    alerts.forEach(async (x) => {
      if (alertParser.validates(x)) {
        Vue.set(state.appData.alerts, x.id, x)
        await localDb.save(new Entities.AlertResponseEntity(x))
      }
    })
    await localDb.SaveToStore()
  },
  async setAppliedFilters(
    { state }: ActionContext<IStore>,
    filtersData: any
  ): Promise<void> {
    Vue.set(
      state.appliedFilters,
      Object.keys(filtersData)[0],
      filtersData[Object.keys(filtersData)[0]]
    )
    await localDb.clear(Entities.VegeIssueAppliedFilters)
    await localDb.save(new Entities.VegeIssueAppliedFilters(filtersData))
    await localDb.SaveToStore()
  },
  async setAppliedCulvertInspectionFilters(
    { state }: ActionContext<IStore>,
    filtersData: any
  ): Promise<void> {
    Vue.set(
      state.appliedCulvertInspectionFilters,
      Object.keys(filtersData)[0],
      filtersData[Object.keys(filtersData)[0]]
    )
    await localDb.clear(Entities.AppliedCulvertInspectionFiltersDataEntity)
    await localDb.save(
      new Entities.AppliedCulvertInspectionFiltersDataEntity(filtersData)
    )
    await localDb.SaveToStore()
  },
  async setAppliedCulvertFilters(
    { state }: ActionContext<IStore>,
    filtersData: any
  ): Promise<void> {
    Vue.set(
      state.appliedCulvertFilters,
      Object.keys(filtersData)[0],
      filtersData[Object.keys(filtersData)[0]]
    )
    await localDb.clear(Entities.AppliedCulvertFiltersDataEntity)
    await localDb.save(
      new Entities.AppliedCulvertFiltersDataEntity(filtersData)
    )
    await localDb.SaveToStore()
  },
  async setLoggedIntoTool(
    { commit, state }: ActionContext<IStore>,
    loggedInStatuses: {
      loggedIntoVegeLogic: boolean
      loggedIntoCulvertLogic: boolean
    }
  ) {
    let storeState = await localDb.findOne(Entities.AppLoggedInStoreStateEntity)

    if (storeState) {
      storeState.loggedIntoVegeLogic = loggedInStatuses.loggedIntoVegeLogic
      storeState.loggedIntoCulvertLogic =
        loggedInStatuses.loggedIntoCulvertLogic
      await localDb.save(storeState)
    } else {
      await localDb.save(
        new Entities.AppLoggedInStoreStateEntity(loggedInStatuses)
      )
    }

    commit('setLoggedIntoStatus', loggedInStatuses)
    await localDb.SaveToStore()

    console.log(`logged into Vegelogic: ${state.loggedIntoVegeLogic}`)
    console.log(`logged into Culvertlogic: ${state.loggedIntoCulvertLogic}`)
  },
  async setShowLsdGrid(
    { commit }: ActionContext<IStore>,
    showLsdGrid: boolean
  ) {
    let storeState = await localDb.findOne(Entities.StoreStateEntity)

    if (storeState) {
      storeState.showLsdGrid = showLsdGrid
      await localDb.save(storeState)
    } else {
      storeState = await localDb.save(
        new Entities.StoreStateEntity({ showLsdGrid })
      )
    }
    commit('setStoreState', storeState)
    await localDb.SaveToStore()
  },
  async setSelectedAsset(
    { state, dispatch, commit }: ActionContext<IStore>,
    id: number
  ): Promise<void> {
    state.appData.selectedAssetId = id
    await localDb.upsert(
      new Entities.ExtraAppDataEntity({
        company: state.appData.company,
        selectedAssetId: id
      }),
      'company'
    )
    //reset updatetime so new data can come in
    await dispatch('setUpdateTime', { item: id, time: null })

    commit('clearAssetData')

    await localDb.clear(Entities.ManagementAreasEntity)
    await localDb.clear(Entities.VegetationIssuesResultEntity)
    await localDb.clear(Entities.SubAssetResultEntity)
    await localDb.clear(Entities.CulvertResultEntity)
    await localDb.clear(Entities.CulvertInspectionResultEntity)
    await localDb.clear(Entities.AssetSpeciesEntity)
    await localDb.SaveToStore()
  },
  async setRelations(
    { state }: ActionContext<IStore>,
    relations: RelationsRes
  ): Promise<void> {
    if (relationsParser.validates(relations)) {
      state.appData.relations = relations
      await localDb.clear(Entities.RelationsEntity)
      await localDb.save(new Entities.RelationsEntity(relations))
      await localDb.SaveToStore()
    }
  },
  async setThirdPartyCompanies(
    { state }: ActionContext<IStore>,
    thirdPartyCompanies: GetThirdPartyCompanyRelationsResponse[]
  ) {
    thirdPartyCompanies.forEach(async (x) => {
      Vue.set(state.appData.relatedThirdPartyCompanies, x.company.id, x)
      await localDb.upsert(new Entities.ThirdPartyEntity(x), 'companyId')
    })
    await localDb.SaveToStore()
  },
  async setToken(
    { state }: ActionContext<IStore>,
    token: string
  ): Promise<void> {
    state.token = token
    //clear token table to ensure only one token is ever saved
    await localDb.clear(Entities.UserToken)
    await localDb.save(new Entities.UserToken(token))
    await localDb.SaveToStore()
  },
  /**
   *@param {string | number} item 'app' or the assetId as a number to represent an update happened to either app data or the selected asset data
   *@param {string | null} time string of the datetime that the data was updated from the api, or null if the data needs to be fully updated
   */

  async setUpdateTime(
    {},
    { item, time }: { item: string | number; time: string | null }
  ): Promise<void> {
    //to string item in case it's the assetid
    let params = {
      key: item.toString(),
      since: time
    }
    await localDb.upsert(new Entities.UpdatedAtEntity(params), 'key')
    await localDb.SaveToStore()
  },
  async updateAppData(
    { state, dispatch, getters }: ActionContext<IStore>,
    data: GetAppResponse
  ): Promise<void> {
    state.appData.company = data.company

    data.users.forEach(async (x) => {
      Vue.set(state.appData.users, x.id, x)
      await localDb.upsert(new Entities.UsersEntity(x), 'id')
    })

    data.assets.forEach(async (x) => {
      Vue.set(state.appData.assets, x.id, x)
      await localDb.upsert(new Entities.AssetResultEntity(x), 'id')
    })

    data.thirdPartyCompanies.forEach(async (x) => {
      Vue.set(state.appData.relatedThirdPartyCompanies, x.company.id, x)
      await localDb.upsert(new Entities.ThirdPartyEntity(x), 'companyId')
    })

    //selectedAsset can return null if assets has not been set yet,
    //however we set the assets in the chunk above, so we can guarantee that assets will not be null
    //so we can safely set the selectedasset here, where the getter either auto sets the selectedAsset to be the first asset, or the proper selectedAsset
    await localDb.upsert(
      new Entities.ExtraAppDataEntity({
        company: state.appData.company,
        selectedAssetId: getters.selectedAsset ? getters.selectedAsset.id : 0
      }),
      'company'
    )
    await localDb.SaveToStore()

    await dispatch('setUpdateTime', { item: 'app', time: data.since })
  },
  async updateAssetData(
    { state, dispatch }: ActionContext<IStore>,
    {
      assetId,
      data,
      firstTimeSetup
    }: {
      assetId: number
      data: GetAssetResponse
      firstTimeSetup: boolean
    }
  ): Promise<void> {
    data.areas.forEach(async (x) => {
      Vue.set(state.assetData.areas, x.id, x)
      await localDb.upsert(new Entities.ManagementAreasEntity(x), 'id')
    })

    data.issues.forEach(async (x) => {
      Vue.set(state.assetData.issues, x.id, x)
      await localDb.upsert(new Entities.VegetationIssuesResultEntity(x), 'id')
    })

    data.subAssets.forEach(async (x) => {
      Vue.set(state.assetData.subAssets, x.id, x)
      await localDb.upsert(new Entities.SubAssetResultEntity(x), 'id')
    })

    data.culverts.forEach(async (x) => {
      Vue.set(state.assetData.culverts, x.id, x)
      await localDb.upsert(new Entities.CulvertResultEntity(x), 'id')
    })

    data.culvertInspections.forEach(async (x) => {
      Vue.set(state.assetData.culvertInspections, x.id, x)
      await localDb.upsert(new Entities.CulvertInspectionResultEntity(x), 'id')
    })
    //delete all assetAssociatedSpecies first,then set with new data
    for (let key in state.assetData.assetAssociatedSpecies) {
      Vue.delete(state.assetData.assetAssociatedSpecies, key)
    }
    await localDb.clear(Entities.AssetSpeciesEntity)

    await data.assetAssociatedSpecies.forEach(async (x) => {
      Vue.set(state.assetData.assetAssociatedSpecies, x.id, x)
      await localDb.upsert(new Entities.AssetSpeciesEntity(x), 'id')
    })

    // After adding all issues to the issues data, make sure each issue that's
    // a part of the update is in its parent issue's subsequent issue ids
    // We do this here because it simplifies things on the backend when
    // querying for updates. If a new (followup) issue is created, both the
    // parent and the new issue would need to be returned in the update. This
    // way, we just get the new issue from the api and manually update the
    // parent in the state.
    // Only do this if the action has been called not in initial startup
    // All the subsequent relations are set in the backend on creation, this is to catch those extra
    if (!firstTimeSetup) {
      if (data.issues.length > 0) {
        if (!data.issues[0].originalIssueId) {
          for (let key in state.assetData.issues) {
            if (
              state.assetData.issues[key].originalIssueId === data.issues[0].id
            ) {
              data.issues[0].subsequentIssueIds.push(
                state.assetData.issues[key].id
              )
              await localDb.upsert(
                new Entities.VegetationIssuesResultEntity(
                  state.assetData.issues[key]
                ),
                'id'
              )
            }
          }
        }
        for (let issue of data.issues) {
          // If this is a followup
          if (issue.originalIssueId !== null) {
            // If the original issue is in the map (it should always be)
            if (issue.originalIssueId in state.assetData.issues) {
              // If the new/updated followup isn't already in the array
              if (
                !state.assetData.issues[
                  issue.originalIssueId
                ].subsequentIssueIds.includes(issue.id)
              ) {
                state.assetData.issues[
                  issue.originalIssueId
                ].subsequentIssueIds.push(issue.id)

                await localDb.upsert(
                  new Entities.VegetationIssuesResultEntity(
                    state.assetData.issues[issue.originalIssueId]
                  ),
                  'id'
                )
              }
            }
          }
        }
      }
    }

    await localDb.SaveToStore()

    await dispatch('setUpdateTime', {
      item: assetId,
      time: data.since
    })
  },
  async runSync({
    state,
    getters,
    dispatch
  }: ActionContext<IStore>): Promise<void> {
    if (state.dataSyncing) {
      return
    }
    state.dataSyncing = true

    // @ts-ignore
    const rpc: RpcType = async (
      endpoint: any,
      ...args: any[]
    ): Promise<void> => {
      const { token } = await callApi(
        apiDef,
        getters.apiUrl,
        state.token,
        endpoint,
        ...args
      )
      if (token) {
        await dispatch('setToken', token)
      }
    }

    while (true) {
      let issues = state.unsyncedIssues
      let photos = state.unsyncedPhotos
      let culvertInspections = state.unsyncedCulvertInspections
      let culverts = state.unsyncedCulverts

      try {
        if (culverts.length > 0) {
          console.log(`${culverts.length} culverts to sync`)
          let syncMe = culverts[0].toApiType()
          await rpc('callPostCulvert', syncMe)

          state.unsyncedCulverts = state.unsyncedCulverts.slice(1)
          await localDb.deleteOne(
            Entities.NewCulvertParamsEntity,
            culverts[0].sqliteId
          )
          console.log('Uploaded culvert: ', JSON.stringify(syncMe))
        } else if (issues.length > 0) {
          console.log(`${issues.length} issues to sync`)

          let syncMe = issues[0].toApiType()
          await rpc('callPostIssue', syncMe)
          if (syncMe.originalIssueId) {
            let syncedOriginalIssue = await localDb.findOne(
              Entities.VegetationIssuesResultEntity,
              {
                id: syncMe.originalIssueId
              }
            )
            //make sure that the original issue has the updated list of subsequent ids
            if (syncedOriginalIssue) {
              let subsequentIdsArray = JSON.parse(
                syncedOriginalIssue.subsequentIssueIds
              ) as number[]

              let idx = subsequentIdsArray.findIndex(
                (iss) => iss === issues[0].sqliteId * -1
              )
              subsequentIdsArray.splice(idx, 1)

              syncedOriginalIssue.subsequentIssueIds =
                JSON.stringify(subsequentIdsArray)

              await localDb.upsert(syncedOriginalIssue, 'sqliteId')

              state.assetData.issues[
                syncedOriginalIssue.id
              ].subsequentIssueIds = subsequentIdsArray
            }
          }

          state.unsyncedIssues = state.unsyncedIssues.slice(1)
          await localDb.deleteOne(
            Entities.VegetationIssueParamsEntity,
            issues[0].sqliteId
          )
          console.log('Uploaded issue: ', JSON.stringify(syncMe))
        } else if (culvertInspections.length > 0) {
          console.log(
            `${culvertInspections.length} culvert inspections to sync`
          )

          let syncMe = culvertInspections[0].toApiType()
          await rpc('callPostCulvertInspection', syncMe)

          state.unsyncedCulvertInspections =
            state.unsyncedCulvertInspections.slice(1)
          await localDb.deleteOne(
            Entities.CulvertInspectionParamsEntity,
            culvertInspections[0].sqliteId
          )
          console.log('Uploaded culvert inspection: ', JSON.stringify(syncMe))
        } else if (photos.length > 0) {
          console.log(`${photos.length} photos to sync`)

          //photos saved in store as Entity type to keep the photo data and photo itself together
          //but the data and photo need to be split for the actual api call to satisify being a MultiPartFormData which is required to http send a blob
          let syncMe = photos[0].toApiType()

          if (typeof syncMe.photo === 'string') {
            let photoBlob

            if (
              syncMe.photo.startsWith('file://') ||
              (syncMe.photo.startsWith('content://') && IsMobileDevice())
            ) {
              let photo = await Filesystem.readFile({ path: syncMe.photo })

              photoBlob = await base64ToBlob(
                `data:image/jpg;base64,${photo.data}`
              )
            } else {
              photoBlob = await base64ToBlob(syncMe.photo)
            }
            await rpc('callPhotoUpload', syncMe.photoRequest, photoBlob)
          } else {
            console.log(
              `runSync error: photo not in store: ${syncMe.photoRequest.uuid}.`
            )
          }

          state.unsyncedPhotos = state.unsyncedPhotos.slice(1)
          if (IsMobileDevice()) {
            await Filesystem.deleteFile({ path: syncMe.photo })
          }
          await localDb.deleteOne(
            Entities.CreatePhotoRequestEntity,
            photos[0].sqliteId
          )
          console.log('Uploaded photo: ', JSON.stringify(syncMe))
        } else {
          console.log('Sync complete.')
          break
        }
      } catch (error) {
        if (error instanceof NoResponseError) {
          // end sync if we don't get a response
          console.log('No response from API, cancelling sync.')
          break
        } else {
          // If some other error occurs, it'll be that the api returns an
          // error code, or the request was malformed. In either case, there
          // isn't anything we can do to fix it, so we consider it synced.
          console.log('Request error in runSync: ', error)
          break
        }
      }
      // Request completed (or failed for some reason).
    }

    await localDb.SaveToStore()
    state.dataSyncing = false
  },
  async saveCulvert(
    { state, dispatch }: ActionContext<IStore>,
    { params }: { params: NewCulvertParams }
  ): Promise<void> {
    let culvertEntity = new Entities.NewCulvertParamsEntity(params)
    await localDb.save(culvertEntity)
    state.unsyncedCulverts.push(culvertEntity)
    // No await here so runSync can finish in the background
    //runSync handles the SaveToStore
    dispatch('runSync')
  },
  async saveCulvertInspection(
    { state, dispatch }: ActionContext<IStore>,
    {
      params,
      photos
    }: {
      params: CulvertInspectionParams
      photos: Photo[]
    }
  ): Promise<void> {
    let culvertInspectionEntity = new Entities.CulvertInspectionParamsEntity(
      params
    )
    await localDb.save(culvertInspectionEntity)
    state.unsyncedCulvertInspections.push(culvertInspectionEntity)

    for (let i = 0; i < photos.length; i++) {
      const photoPostUuid = uuidv4()

      let photoRequest = new Entities.CreatePhotoRequestEntity(
        {
          uuid: photoPostUuid,
          targetType: EntitiesWithPhotos.CulvertInspectionPhoto,
          parentUuid: params.uuid,
          fileType: photos[i].blob.type
        },
        photos[i].stringified
      )
      await localDb.save(photoRequest)
      state.unsyncedPhotos.push(photoRequest)
    }

    // No await here so runSync can finish in the background
    //runSync handles the SaveToStore
    dispatch('runSync')
  },
  async saveIssue(
    { state, dispatch }: ActionContext<IStore>,
    {
      params,
      photos
    }: {
      params: VegetationIssueParams
      photos: Photo[]
    }
  ): Promise<void> {
    let newIssue = new Entities.VegetationIssueParamsEntity(params)
    await localDb.save(newIssue)
    state.unsyncedIssues.push(newIssue)

    for (let i = 0; i < photos.length; i++) {
      const photoPostUuid = uuidv4()

      let photoRequest = new Entities.CreatePhotoRequestEntity(
        {
          uuid: photoPostUuid,
          targetType: EntitiesWithPhotos.VegetationIssuePhoto,
          parentUuid: params.uuid,
          fileType: photos[i].blob.type
        },
        photos[i].stringified
      )
      await localDb.save(photoRequest)
      state.unsyncedPhotos.push(photoRequest)
    }

    if (newIssue.originalIssueId) {
      let syncedOriginalIssue = await localDb.findOne(
        Entities.VegetationIssuesResultEntity,
        {
          id: newIssue.originalIssueId
        }
      )
      //make sure that the original issue has the updated list of subsequent ids
      if (syncedOriginalIssue) {
        let subsequentIdsArray = JSON.parse(
          syncedOriginalIssue.subsequentIssueIds
        ) as number[]

        subsequentIdsArray.push(newIssue.sqliteId * -1) //matches what will be created in ParamsEntity.toResultType()
        syncedOriginalIssue.subsequentIssueIds =
          JSON.stringify(subsequentIdsArray)

        await localDb.upsert(syncedOriginalIssue, 'sqliteId')
        await localDb.SaveToStore()

        state.assetData.issues[syncedOriginalIssue.id].subsequentIssueIds =
          subsequentIdsArray
      }
    }

    // No await here so runSync can finish in the background
    //runSync handles the SaveToStore
    dispatch('runSync')
  }
}
