import {
  EditCulvertParams,
  NewCulvertParams,
  GenericResult,
  IdResponse
} from '../veg-common/apiTypes'

export const culvertsApiDef = {
  callPostCulvert: {
    route: '/culverts/create',
    method: 'post',
    auth: true,
    inputType: NewCulvertParams,
    outputType: IdResponse,
    returnsMultiple: false
  },
  callEditCulvert: {
    route: '/culverts/update',
    method: 'post',
    auth: true,
    inputType: EditCulvertParams,
    outputType: GenericResult,
    returnsMultiple: false
  }
} as const
