import { Geometry } from 'geojson'
import {
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsString,
  ValidateNested,
  IsOptional
} from 'class-validator'
import { VegetationIssueResult } from './vegetationIssues'

export class ManagementAreaResult {
  constructor(x: ManagementAreaResult) {
    if (x) {
      this.averageRisk = x.averageRisk
      this.id = x.id
      this.assetId = x.assetId
      this.subAssetId = x.subAssetId
      this.name = x.name
      this.shape = x.shape
      this.imageLabel = x.imageLabel
      this.issues = x.issues
    }
  }

  @IsNumber()
  averageRisk!: number

  @IsInt()
  id!: number

  @IsInt()
  assetId!: number

  @IsOptional()
  @IsInt()
  subAssetId!: number | null

  @IsString()
  @IsNotEmpty()
  name!: string

  //@NeedGeometryValidator
  shape!: Geometry

  @IsString()
  imageLabel!: string

  @ValidateNested()
  issues!: VegetationIssueResult[]
}
