import { MigrationInterface, QueryRunner, TableColumn } from 'typeorm'

export class UpdateImageLabelColumn1672677442440 implements MigrationInterface {
  public async up(queryRunner: QueryRunner): Promise<void> {
    let entity = await queryRunner.hasTable('management_areas_entity')
    if (entity === false) {
      console.log(
        'Management areas table not created yet - bail out of migration'
      )
      return
    }

    let areasTable = await queryRunner.getTable('management_areas_entity')
    let imageLabelColumn =
      areasTable?.findColumnByName('imageLabel')?.type !== 'text'

    if (imageLabelColumn) {
      await queryRunner.changeColumn(
        'management_areas_entity',
        'imageLabel',
        new TableColumn({
          name: 'imageLabel',
          type: 'text'
        })
      )
    }
  }

  public async down(queryRunner: QueryRunner): Promise<void> {
    await queryRunner.query(
      'alter table management_areas_entity drop column image_label_2'
    )
  }
}
