



















































































































































import Vue from 'vue'
import DrawGpsPointComponent from '../../../components/DrawGpsPointComponent.vue'
import SeeAreasDialog from '../../../components/SeeAreasDialog.vue'
import { Point } from 'geojson'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  ManagementAreaResult,
  SubAssetResult
} from '../../../veg-common/apiTypes'

export default Vue.extend({
  components: {
    DrawGpsPointComponent,
    SeeAreasDialog,
    ValidationObserver,
    ValidationProvider
  },
  data(): EditSubAssetI {
    return {
      assetId: 0,
      confirmedLeaving: false,
      currentlyAssignedAreas: [],
      exitDialog: false,
      gpsError: false,
      latitude: null,
      longitude: null,
      managementAreaOptions: [],
      managementAreasToRemove: [],
      pageLoading: false,
      routerNextPath: '',
      selectedManagementArea: [],
      subAssetEdited: false,
      subAssetGpsPoint: null,
      subAssetName: '',
      subAssetToEdit: null
    }
  },
  props: {
    managementAreas: {
      type: Array as () => ManagementAreaResult[],
      default: (): ManagementAreaResult[] => []
    },
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    }
  },
  computed: {
    isSet(): boolean {
      return this.subAssetGpsPoint ? true : false
    }
  },
  methods: {
    areasFiltered() {
      let currentAssetId: number
      if (this.$typedStore.getters.selectedAsset) {
        currentAssetId = this.$typedStore.getters.selectedAsset.id
      }
      this.managementAreas.forEach((item) => {
        if (!item.subAssetId) {
          this.managementAreaOptions.push(item)
        } else if (
          item.assetId === currentAssetId &&
          item.subAssetId === Number(this.$route.params.id)
        ) {
          this.currentlyAssignedAreas.push(item)
        }
      })
    },
    clearEdits(): void {
      this.setFormData()
      if (this.subAssetGpsPoint) {
        //@ts-ignore
        this.$refs.subAsset.cancelManualGpsPoint()
      }
    },
    exit() {
      this.exitDialog = false
      this.confirmedLeaving = true
      if (this.routerNextPath === '') this.routerNextPath = '/sub-assets'
      this.$router.push({ path: this.routerNextPath })
    },
    async getSubAsset(): Promise<void> {
      try {
        let result = await this.$rpc('callGetSubAsset', {
          id: this.subAssetData[0].id
        })
        this.subAssetToEdit = result
        this.setFormData()
      } catch (e) {
        console.log(e)
        this.$emit('snackbar', {
          type: 'error',
          msg: 'Error retrieving data.'
        })
      }
    },
    async editSubAsset(): Promise<void> {
      this.pageLoading = true
      let app = this

      if (!app.$typedStore.getters.jwtContent) return
      if (!app.$typedStore.getters.jwtContent.data.userId) return

      let result
      if (
        app.latitude &&
        app.longitude &&
        app.subAssetToEdit &&
        app.selectedManagementArea &&
        app.managementAreasToRemove
      ) {
        result = await app.$rpc('callEditSubAsset', {
          id: app.subAssetToEdit.id,
          sub_asset_name: app.subAssetName,
          latitude: Number(app.latitude),
          longitude: Number(app.longitude),
          selectedManagementArea: app.selectedManagementArea,
          managementAreasToRemove: app.managementAreasToRemove
        })
      }

      if (result && !result.success) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Sub Asset edit failed.'
        })
        return
      }

      this.$typedStore.commit('setSnackbarParams', {
        type: 'success',
        msg: 'Sub Asset edited successfully.'
      })

      app.subAssetEdited = true
      app.$emit('requestUpdate', { edit: true })
      this.pageLoading = false
      app.$router.push('/sub-assets')
    },
    setFormData() {
      if (this.subAssetToEdit) {
        this.subAssetName = this.subAssetToEdit.sub_asset_name
        this.latitude = this.subAssetToEdit.latitude
        this.longitude = this.subAssetToEdit.longitude
      }
    },
    subAssetPoint(point: Point | null): void {
      this.subAssetGpsPoint = point
    },
    setGpsError(gpsError: boolean): void {
      this.gpsError = gpsError
    },
    async validateInputs(): Promise<void> {
      //@ts-ignore
      let valid = await this.$refs.validator.validate()

      if (valid) {
        await this.editSubAsset()
      } else {
        this.$emit('snackbar', {
          type: 'error',
          msg: 'Sub Asset invalid.'
        })
      }
    }
  },
  watch: {
    subAssetGpsPoint() {
      if (this.subAssetGpsPoint) {
        this.latitude = this.subAssetGpsPoint.coordinates[1]
        this.longitude = this.subAssetGpsPoint.coordinates[0]
      }
    }
  },
  created() {
    this.getSubAsset()
    this.areasFiltered()
  },
  //@ts-ignore
  beforeRouteLeave(to, from, next) {
    if (to.path) {
      this.routerNextPath = to.path
    }
    if (this.subAssetEdited) {
      this.exitDialog = false
      next()
    } else if (!this.subAssetEdited && !this.confirmedLeaving) {
      this.exitDialog = true
      next(false)
    } else if (!this.subAssetEdited && this.confirmedLeaving) {
      next()
    }
  }
})

interface EditSubAssetI {
  assetId: number
  confirmedLeaving: boolean
  currentlyAssignedAreas: ManagementAreaResult[]
  exitDialog: boolean
  gpsError: boolean
  latitude: number | null
  longitude: number | null
  managementAreaOptions: ManagementAreaResult[]
  managementAreasToRemove: number[]
  pageLoading: boolean
  routerNextPath: string
  selectedManagementArea: number[]
  subAssetEdited: boolean
  subAssetGpsPoint: Point | null
  subAssetName: string
  subAssetToEdit: null | SubAssetResult
}
