import {
  IsNotEmpty,
  IsOptional,
  IsString
} from 'class-validator'

export class LoginParams {
  @IsString()
  @IsNotEmpty()
  username: string

  @IsString()
  @IsNotEmpty()
  password: string

  constructor(username: string, password: string) {
    this.username = username
    this.password = password
  }
}

export class LoginResponse {
  constructor(x: LoginResponse) {
    if (x) {
      this.token = x.token
    }
  }

  @IsString()
  @IsOptional()
  token!: string | null
}
