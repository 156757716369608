import {
  Length,
  IsEmail,
  IsInt,
  IsNotEmpty,
  IsString,
  ArrayNotEmpty,
  IsArray,
  IsOptional
} from 'class-validator'

export class ResetPasswordRequestParams {
  constructor(x: ResetPasswordRequestParams) {
    if (x) {
      this.email = x.email
    }
  }

  @IsEmail()
  email!: string
}

export class ResetPasswordSubmissionParams {
  constructor(x: ResetPasswordSubmissionParams) {
    if (x) {
      this.token = x.token
      this.newPassword = x.newPassword
    }
  }

  @Length(36, 36)
  token!: string

  @Length(4, 100)
  newPassword!: string
}

export class CreateUserRequest {
  constructor(x: CreateUserRequest) {
    if (x) {
      this.user_name = x.user_name
      this.email = x.email
      this.first_name = x.first_name
      this.last_name = x.last_name
      this.password = x.password
      this.user_role = x.user_role
    }
  }

  @IsString()
  @IsNotEmpty()
  user_name!: string

  @IsEmail()
  email!: string

  @IsString()
  @Length(4, 100)
  password!: string

  @IsString()
  @IsNotEmpty()
  first_name!: string

  @IsString()
  @IsNotEmpty()
  last_name!: string

  @IsString()
  @IsNotEmpty()
  user_role!: string
}

export class CreateUserResponse {
  constructor(x: CreateUserResponse) {
    if (x) {
      this.newUserId = x.newUserId
    }
  }

  @IsInt()
  newUserId!: number
}

export class SendFeedbackRequest {
  constructor(x: SendFeedbackRequest) {
    if (x) {
      this.userId = x.userId
      this.feedbackContent = x.feedbackContent
    }
  }

  @IsInt()
  userId!: number

  @IsString()
  @IsNotEmpty()
  feedbackContent!: string
}

export class AssignAssetsRequest {
  constructor(x: AssignAssetsRequest) {
    if (x) {
      this.userId = x.userId
      this.assetIds = x.assetIds
    }
  }

  @IsInt()
  userId!: number

  @IsArray()
  @IsOptional()
  assetIds!: number[]
}

export class AssignIssuesRequest {
  constructor(x: AssignIssuesRequest) {
    if (x) {
      this.userId = x.userId
      this.issueIds = x.issueIds
    }
  }

  @IsInt()
  userId!: number

  @ArrayNotEmpty()
  issueIds!: number[]
}
