var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',{staticClass:"pa-3"},[(_vm.pageLoading)?_c('v-col',{staticClass:"mt-8",attrs:{"justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"size":70,"indeterminate":"","color":"primary"}}),_c('p',{staticClass:"mt-8"},[_vm._v("Loading Culvert Inspection")])],1):_c('ValidationObserver',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"7","sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"culvert","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-0 pt-0 pr-2",attrs:{"items":_vm.fullCulvertList,"error-messages":errors,"label":"Select Culvert","hide-details":"auto","item-text":"culvert.site_culvert_id","item-value":"culvert.id"},model:{value:(_vm.selectedCulvert),callback:function ($$v) {_vm.selectedCulvert=$$v},expression:"selectedCulvert"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"5","sm":"4"}},[_c('SeeCulvertsDialog',{attrs:{"culverts":_vm.fullCulvertList}})],1)],1),_c('v-row',[_c('v-divider',{staticClass:"accentAjmBlue mt-1"})],1),_c('v-row',[_c('v-col',{staticClass:"mb-1 mt-2 py-0"},[_c('span',{staticClass:"align-start"},[_vm._v("Estimated Risk")]),_c('ValidationProvider',{attrs:{"name":"risk","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-slider',{attrs:{"tick-labels":_vm.riskLabels,"min":0,"max":3,"step":"1","ticks":"always"},model:{value:(_vm.riskSlider),callback:function ($$v) {_vm.riskSlider=$$v},expression:"riskSlider"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-divider',{staticClass:"accentAjmBlue mt-1"})],1),_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"culvert status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-3 pt-0",attrs:{"error-messages":errors,"items":_vm.relations.culvertStatuses,"label":"Culvert Status","hide-details":"auto","item-text":"culvert_status","item-value":"id"},model:{value:(_vm.selectedCulvertStatus),callback:function ($$v) {_vm.selectedCulvertStatus=$$v},expression:"selectedCulvertStatus"}})]}}],null,true)})],1),(!_vm.isSmallScreen)?_c('v-col',{staticClass:"my-0 py-0",attrs:{"md":"6"}},[_c('EditPhotoGalleryComponent',{ref:"photoComp",attrs:{"id":"camera","existingPhotos":_vm.photos},on:{"photoLoading":_vm.onPhotoLoading,"removeExistingPhoto":_vm.removeExistingPhoto},model:{value:(_vm.newPhotos),callback:function ($$v) {_vm.newPhotos=$$v},expression:"newPhotos"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"mt-3 py-0",attrs:{"cols":"12"}},[_c('v-switch',{staticStyle:{"width":"50%"},attrs:{"hide-details":"","label":"Actions Have Been Taken","inset":""},model:{value:(_vm.haveActionsTaken),callback:function ($$v) {_vm.haveActionsTaken=$$v},expression:"haveActionsTaken"}})],1),(_vm.haveActionsTaken)?_c('v-col',{staticClass:"mt-3 py-0",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"actions taken","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"actionsTaken",staticClass:"mt-3 pt-0",attrs:{"error-messages":errors,"items":_vm.relations.culvertTakenActions,"label":"Actions Taken","hide-details":"auto","item-text":"inspection_action","return-object":"","multiple":"","menu-props":{ value: _vm.actionsTakenSelectMenu }},on:{"click":function($event){_vm.actionsTakenSelectMenu = true},"blur":function($event){_vm.actionsTakenSelectMenu = false}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"primary accentAjmBlue--text",attrs:{"input-value":data.selected,"close":""},on:{"click:close":function($event){return _vm.removeTakenAction(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.inspection_action)+" ")])]}},{key:"append-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.closeActionsTakenMenu()}}},[_c('v-btn',{staticClass:"primary ajmBlue--text",staticStyle:{"width":"100%"}},[_vm._v("Close")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selectedActionsTaken),callback:function ($$v) {_vm.selectedActionsTaken=$$v},expression:"selectedActionsTaken"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"mt-3 py-0",attrs:{"cols":"12"}},[_c('v-switch',{staticStyle:{"width":"50%"},attrs:{"hide-details":"","label":"Actions Still Required","inset":""},model:{value:(_vm.haveActionsRequired),callback:function ($$v) {_vm.haveActionsRequired=$$v},expression:"haveActionsRequired"}})],1),(_vm.haveActionsRequired)?_c('v-col',{staticClass:"mt-3 mb-3 py-0",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"required actions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"actionsRequired",staticClass:"mt-3 pt-0",attrs:{"error-messages":errors,"items":_vm.relations.culvertRequiredActions,"label":"Actions Required","hide-details":"auto","item-text":"inspection_action","return-object":"","multiple":"","menu-props":{ value: _vm.actionsRequiredSelectMenu }},on:{"click":function($event){_vm.actionsRequiredSelectMenu = true},"blur":function($event){_vm.actionsRequiredSelectMenu = false}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"primary accentAjmBlue--text",attrs:{"input-value":data.selected,"close":""},on:{"click:close":function($event){return _vm.removeRequiredAction(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.inspection_action)+" ")])]}},{key:"append-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.closeActionsRequiredMenu()}}},[_c('v-btn',{staticClass:"primary ajmBlue--text",staticStyle:{"width":"100%"}},[_vm._v("Close")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selectedActionsRequired),callback:function ($$v) {_vm.selectedActionsRequired=$$v},expression:"selectedActionsRequired"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-dialog',{ref:"dateDialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({staticClass:"mb-2 py-0",attrs:{"error-message":errors,"value":_vm.humanReadableDate,"label":"Inspection Date","readonly":"","hide-details":"auto"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.dateDialog),callback:function ($$v) {_vm.dateDialog=$$v},expression:"dateDialog"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","full-width":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateDialog = false}}},[_vm._v("Ok")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-dialog',{ref:"timeDialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"name":"time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({staticClass:"mb-2 py-0",attrs:{"error-messages":errors,"value":_vm.time,"label":"Inspection Time","readonly":"","hide-details":"auto"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.timeDialog),callback:function ($$v) {_vm.timeDialog=$$v},expression:"timeDialog"}},[(_vm.timeDialog)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timeDialog = false}}},[_vm._v("Ok")])],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-dialog',{ref:"nextDateDialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"name":"next date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({staticClass:"mb-2 py-0",attrs:{"error-message":errors,"value":_vm.humanReadableNextDate,"label":"Next Inspection Date","readonly":"","hide-details":"auto"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.nextDateDialog),callback:function ($$v) {_vm.nextDateDialog=$$v},expression:"nextDateDialog"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","full-width":""},model:{value:(_vm.nextDate),callback:function ($$v) {_vm.nextDate=$$v},expression:"nextDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.nextDateDialog = false}}},[_vm._v("Ok")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"comments","rules":"max:1024","rules":"max:1024"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mt-2 mb-2",attrs:{"error-messages":errors,"label":"Comments","auto-grow":"","rows":"4","outlined":"","hide-details":"auto"},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})]}}],null,true)})],1)],1),(_vm.isSmallScreen)?_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"sm":"12"}},[_c('EditPhotoGalleryComponent',{ref:"photoComp",attrs:{"existingPhotos":_vm.photos},on:{"photoLoading":_vm.onPhotoLoading,"removeExistingPhoto":_vm.removeExistingPhoto},model:{value:(_vm.newPhotos),callback:function ($$v) {_vm.newPhotos=$$v},expression:"newPhotos"}})],1)],1):_vm._e(),_c('v-row',{staticClass:"pb-6 mt-2"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"primary accentAjmBlue--text",attrs:{"disabled":_vm.photoLoading,"loading":_vm.photoLoading},on:{"click":function($event){return _vm.validateInputs()}}},[_vm._v("Save ")])],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"text":"","diabled":_vm.photoLoading,"loading":_vm.photoLoading},on:{"click":_vm.clearEdits}},[_vm._v(" Clear Edits ")])],1)],1)]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.exitDialog),callback:function ($$v) {_vm.exitDialog=$$v},expression:"exitDialog"}},[_c('v-card',{staticClass:"ajmBlue"},[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Exit Adding Inspection ")]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-2 pt-2"},[_vm._v(" Are you sure you want to exit adding inspection? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":function($event){_vm.exitDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"secondary accentAjmBlue--text mr-2",on:{"click":_vm.exit}},[_vm._v(" Exit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }