














































































































































































































































































































































































































































































































import Vue from 'vue'
import {
  dateObjToDateString,
  dateObjToTimeString,
  dateTimeStringToISO,
  isoToHumanDate,
  isoToHumanDateTime,
  dateStringToISO
} from '../../../util/date-time'
import { v4 as uuidv4 } from 'uuid'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { parseISO, add } from 'date-fns'
import distance from '@turf/distance'
import { Position } from '@capacitor/geolocation'

import CameraViewComponent from '../../../components/CameraViewComponent.vue'
import SeeCulvertsDialog from '../../../components/SeeCulvertsDialog.vue'
import { JwtContent } from '../../../veg-common/jwt'
import { getLatestCulvertInspection } from '../../../util/CulvertHelpers'

import {
  CulvertInspectionResult,
  CulvertInspectionActionRes,
  CulvertResult,
  RelationsRes,
  CulvertInspectionParams
} from '../../../veg-common/apiTypes'
import { RiskI, ParseRiskSlider } from '../../../util/CalculateIssueRisk'
import { IsMobileDevice } from '../../../util/ParseUserAgent'
import { Photo } from '../../../util/imageProcessing'
import { Point } from 'geojson'

let dfn = require('date-fns')

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider,
    CameraViewComponent,
    SeeCulvertsDialog
  },
  props: {
    culverts: {
      type: Array as () => CulvertResult[]
    },
    relations: {
      type: Object as () => RelationsRes
    }
  },
  data(): AddCulvertInspectionI {
    return {
      culvertSelectList: [],

      selectedCulvert: null,
      selectedActionsTaken: [],
      selectedCulvertStatus: null,

      comments: '',
      selectedActionsRequired: [],
      date: dateObjToDateString(new Date()),
      time: dateObjToTimeString(new Date()),
      nextDate: dateObjToDateString(add(new Date(), { years: 1 })),
      dateDialog: false,
      timeDialog: false,
      nextDateDialog: false,
      confirmationDialog: false,
      culvertDialogSelect: false,
      fullCulvertList: [],
      pageLoading: true,
      photos: [],
      photoLoading: false,
      riskSlider: 0,
      riskLabels: ['No Risk', 'Low', 'Medium', 'High'],
      isMobileDevice: IsMobileDevice(),
      actionsRequiredSelectMenu: false,
      actionsTakenSelectMenu: false,

      inspectionSaved: false,
      exitDialog: false,
      confirmedLeaving: false,
      routerNextPath: '',

      haveActionsTaken: false,
      haveActionsRequired: false,
      disableActions: false
    }
  },
  watch: {
    selectedCulvertStatus() {
      //the id of 'Culvert Not Found'
      if (this.selectedCulvertStatus === 4) {
        this.selectedActionsTaken = []
        this.selectedActionsRequired = []
        this.haveActionsRequired = false
        this.haveActionsTaken = false
        this.disableActions = true
      } else {
        this.disableActions = false
      }
    }
  },
  computed: {
    watchPosition(): Position | null {
      return this.$typedStore.state.latestPosition
    },
    calculatedRiskScore(): RiskI {
      return ParseRiskSlider(this.riskSlider)
    },
    humanReadableDate(): string {
      return this.date ? isoToHumanDate(this.date) : ''
    },
    humanReadableNextDate(): string {
      return this.nextDate ? isoToHumanDate(this.nextDate) : ''
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    addInspectionDialogWidth(): string {
      if (this.isSmallScreen) {
        return '100%'
      } else {
        return '65%'
      }
    },
    copyFollowUpButtonClass(): string {
      if (!this.isSmallScreen) {
        return 'd-flex justify-center'
      } else {
        return 'mt-0 pt-0'
      }
    },
    isClientUser(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 1
      }
      return false
    }
  },
  methods: {
    closeActionsTakenMenu() {
      this.actionsTakenSelectMenu = false
      //@ts-ignore
      this.$refs.actionsTaken.blur()
    },
    closeActionsRequiredMenu() {
      this.actionsRequiredSelectMenu = false
      // @ts-ignore
      this.$refs.actionsRequired.blur()
    },
    closeMainDialog() {
      this.culvertDialogSelect = false
    },
    async validateInputs(): Promise<void> {
      //@ts-ignore
      let valid = await this.$refs.validator.validate()

      if (valid && this.calculatedRiskScore.score >= 0) {
        await this.saveInspection()
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Form invalid.'
        })
      }
    },
    printDateReadableForCulvertSelect(date: string) {
      return isoToHumanDateTime(date)
    },
    getInspectionsFromCulverts(userPosition?: Position): void {
      let app = this
      let listOfCulverts: CulvertWithDistanceToUser[] = []
      let userPoint: Point | null = null

      if (userPosition) {
        userPoint = {
          type: 'Point',
          coordinates: [
            userPosition.coords.longitude,
            userPosition.coords.latitude
          ]
        }
      }

      app.culverts.forEach((culvert) => {
        let distanceToInlet = -100
        let distanceToOutlet = -100
        if (userPoint) {
          distanceToInlet = distance(
            userPoint,
            (culvert.inlet_location as Point).coordinates
          )
          distanceToOutlet = distance(
            userPoint,
            (culvert.outlet_location as Point).coordinates
          )
        }
        getLatestCulvertInspection(culvert)

        listOfCulverts.push({
          culvert,
          latestInspection: culvert.culvertInspections[0]
            ? culvert.culvertInspections[0]
            : null,
          distance:
            distanceToInlet <= distanceToOutlet
              ? distanceToInlet
              : distanceToOutlet
        })
      })

      if (listOfCulverts.length > 0) {
        let sortedList: CulvertWithDistanceToUser[]

        if (userPoint) {
          sortedList = listOfCulverts.sort(function (culvert1, culvert2) {
            return culvert1.distance - culvert2.distance
          })
        } else {
          //sort each culvert alphabetically to start, so that any culverts without an inspection are listed alphabetically in the dialog
          sortedList = listOfCulverts.sort(function (culvert1, culvert2) {
            var lowerCase1 =
              culvert1.culvert.site_culvert_id.toLocaleLowerCase()
            var lowerCase2 =
              culvert2.culvert.site_culvert_id.toLocaleLowerCase()
            return lowerCase1.localeCompare(lowerCase2)
          })
          //sorted each culverts inspections previously, set else argument to 1950 to ensure it ends up lower in the list
          sortedList = listOfCulverts.sort(function (culvert1, culvert2) {
            return dfn.compareDesc(
              culvert1.latestInspection
                ? parseISO(culvert1.latestInspection.inspection_date)
                : parseISO('1950'),
              culvert2.latestInspection
                ? parseISO(culvert2.latestInspection.inspection_date)
                : parseISO('1950')
            )
          })
        }
        app.fullCulvertList = sortedList

        if (this.selectedCulvert === null) {
          if (userPoint) {
            //only give the user the 10 closest issues to prevent scrolling forever on mobile
            app.culvertSelectList = sortedList.slice(0, 10)
          } else {
            app.culvertSelectList = sortedList
          }

          app.culvertDialogSelect = true
          app.pageLoading = false
        } else {
          app.pageLoading = false
        }
      }
    },
    createFollowUp(itemIndex: number): void {
      this.selectedCulvert = this.culvertSelectList[itemIndex].culvert.id
      this.culvertDialogSelect = false
    },
    async saveInspection(): Promise<void> {
      let app = this

      if (!app.$typedStore.getters.jwtContent) return
      if (!app.$typedStore.getters.jwtContent.data.userId) return
      if (!app.selectedCulvert || !app.selectedCulvertStatus) return

      let culvertInspection: CulvertInspectionParams = {
        uuid: uuidv4(),
        //converts to utc automatically
        inspection_date: dateTimeStringToISO(app.date, app.time),
        next_inspection_date: dateStringToISO(app.nextDate), //make sure the timezone difference keeps same day
        culvertStatusId: app.selectedCulvertStatus,
        actionsTakenIds: app.selectedActionsTaken
          ? app.selectedActionsTaken.map((action) => action.id)
          : [],
        actionsRequiredIds: app.selectedActionsRequired
          ? app.selectedActionsRequired.map((action) => action.id)
          : [],
        userId: (app.$typedStore.getters.jwtContent as JwtContent).data
          .userId as number,
        risk_score: app.calculatedRiskScore.score,
        comments: app.comments,
        culvertId: app.selectedCulvert,
        hidden: false
      }
      if (app.selectedCulvert < 0) {
        let selectedCulvert = app.culverts.find(
          (culvert) => culvert.id === app.selectedCulvert
        )
        if (selectedCulvert)
          culvertInspection.culvertUuid = selectedCulvert.uuid
      }

      await app.$typedStore.dispatch('saveCulvertInspection', {
        params: culvertInspection,
        photos: this.photos
      })
      this.inspectionSaved = true
      this.$router.push('/culvert-inspections')
    },
    onPhotoLoading(state: boolean) {
      this.photoLoading = state
    },
    removeRequiredAction(toRemove: CulvertInspectionActionRes): void {
      if (!this.selectedActionsRequired) return
      let index = this.selectedActionsRequired.findIndex(
        (action: CulvertInspectionActionRes) => {
          return action.id === toRemove.id
        }
      )
      if (index >= 0) this.selectedActionsRequired.splice(index, 1)
    },
    removeTakenAction(toRemove: CulvertInspectionActionRes): void {
      if (!this.selectedActionsTaken) return
      let index = this.selectedActionsTaken.findIndex(
        (action: CulvertInspectionActionRes) => {
          return action.id === toRemove.id
        }
      )
      if (index >= 0) this.selectedActionsTaken.splice(index, 1)
    },
    exit() {
      this.exitDialog = false
      this.confirmedLeaving = true
      if (this.routerNextPath === '')
        this.routerNextPath = '/culvert-inspections'

      this.$router.push({ path: this.routerNextPath })
    }
  },
  //@ts-ignore
  beforeRouteLeave(to, from, next) {
    if (to.path) {
      this.routerNextPath = to.path
    }
    if (this.inspectionSaved) {
      this.exitDialog = false
      next()
    } else if (!this.inspectionSaved && !this.confirmedLeaving) {
      this.exitDialog = true
      /*
        warning  [Vue warn]: Error in v-on handler (Promise/async): "Error: Navigation aborted from "/culvert-inspections/add" to "/culvert-inspections" via a navigation guard."
        is caused by the call to next(false), it is nothing to be concerned with.
      */
      next(false)
    } else if (!this.inspectionSaved && this.confirmedLeaving) {
      next()
    }
  },
  created() {
    if (this.$route.query.siteCulvertId) {
      try {
        let siteCulvertId = this.$route.query.siteCulvertId.toString()
        let foundCulvert = this.culverts.find((culvert) => {
          return (
            culvert.site_culvert_id.toLowerCase() ===
            siteCulvertId.toLowerCase()
          )
        })
        if (foundCulvert) {
          this.selectedCulvert = foundCulvert.id
        }
      } catch (e) {
        console.log('culvertId not a number')
      }
    }

    if (this.culverts.length > 0 && this.watchPosition && IsMobileDevice()) {
      this.getInspectionsFromCulverts(this.watchPosition)
    } else {
      this.getInspectionsFromCulverts()
    }
  }
})

interface AddCulvertInspectionI {
  selectedCulvert: number | null

  selectedActionsRequired: CulvertInspectionActionRes[]
  selectedActionsTaken: CulvertInspectionActionRes[]
  selectedCulvertStatus: number | null

  comments: string
  date: string
  time: string
  nextDate: string
  dateDialog: boolean
  timeDialog: boolean
  nextDateDialog: boolean
  confirmationDialog: boolean
  culvertDialogSelect: boolean
  fullCulvertList: CulvertWithDistanceToUser[]
  culvertSelectList: CulvertWithDistanceToUser[]
  pageLoading: boolean
  photos: Photo[]
  photoLoading: boolean
  riskSlider: number
  riskLabels: string[]
  isMobileDevice: boolean
  actionsRequiredSelectMenu: boolean
  actionsTakenSelectMenu: boolean

  inspectionSaved: boolean
  exitDialog: boolean
  confirmedLeaving: boolean
  routerNextPath: string

  haveActionsTaken: boolean
  haveActionsRequired: boolean
  disableActions: boolean
}

interface CulvertWithDistanceToUser {
  culvert: CulvertResult
  latestInspection: CulvertInspectionResult | null
  distance: number
}
