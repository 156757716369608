






















































































































































































































import Vue from 'vue'
import { Point } from 'geojson'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { CulvertResult } from '../../veg-common/apiTypes'

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data(): EditCulvertI {
    return {
      confirmedLeaving: false,
      culvertSaved: false,
      culvertToEdit: null,
      diameter: -1,
      directionOfFlow: '',
      exitDialog: false,
      fishHabitat: false,
      fishHabitatOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      hasMarkers: false,
      hasMarkersOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      inletLat: null,
      inletLong: null,
      length: -1,
      outletLat: null,
      outletLong: null,
      pageLoading: true,
      roadName: '',
      routerNextPath: '',
      siteCulvertId: ''
    }
  },
  computed: {
    culverts(): CulvertResult[] {
      return this.$typedStore.getters.culverts
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    clearEdits(): void {
      this.setFormData()
    },
    exit() {
      this.exitDialog = false
      this.confirmedLeaving = true
      if (this.routerNextPath === '')
        this.routerNextPath = '/culvert-asset-view'
      this.$router.push({ path: this.routerNextPath })
    },
    setFormData(): void {
      let toEdit: CulvertResult | null = this.culvertToEdit
      if (toEdit) {
        this.siteCulvertId = toEdit.site_culvert_id
        this.roadName = toEdit.road_name
        this.hasMarkers = toEdit.has_markers
        this.fishHabitat = toEdit.is_fish_habitat
        toEdit.diameter > 0 ? (this.diameter = toEdit.diameter * 100) : null //db saved in m, convert to cm
        toEdit.length > 0 ? (this.length = toEdit.length) : null
        this.directionOfFlow = toEdit.direction_of_flow
        let inlet = toEdit.inlet_location as Point
        let outlet = toEdit.outlet_location as Point
        this.inletLong = inlet.coordinates[0]
        this.inletLat = inlet.coordinates[1]
        this.outletLong = outlet.coordinates[0]
        this.outletLat = outlet.coordinates[1]
      }
      this.pageLoading = false
    },
    async validateInputs(): Promise<void> {
      //@ts-ignore
      let valid = await this.$refs.validator.validate()

      if (valid) {
        await this.saveCulvert()
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Form invalid.'
        })
      }
    },
    async saveCulvert(): Promise<void> {
      let app = this

      if (!app.$typedStore.getters.jwtContent) return
      if (!app.$typedStore.getters.jwtContent.data.userId) return

      if (app.culvertToEdit) {
        let inlet: Point = {
          type: 'Point',
          coordinates: [
            app.inletLong ? app.inletLong : 0,
            app.inletLat ? app.inletLat : 0
          ]
        }

        let outlet: Point = {
          type: 'Point',
          coordinates: [
            app.outletLong ? app.outletLong : 0,
            app.outletLat ? app.outletLat : 0
          ]
        }

        let result = await app.$rpc('callEditCulvert', {
          id: app.culvertToEdit.id,
          is_fish_habitat: app.fishHabitat,
          site_culvert_id: app.siteCulvertId,
          road_name: app.roadName,
          has_markers: app.hasMarkers,
          length: app.length,
          diameter: app.diameter / 100, //save in db as m not cm
          direction_of_flow: app.directionOfFlow,
          inlet_location: inlet,
          outlet_location: outlet
        })

        if (result && !result.success) {
          app.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Culvert update failed.'
          })
          return
        }

        app.$typedStore.commit('setSnackbarParams', {
          type: 'success',
          msg: 'Culvert updated successfully.'
        })

        app.culvertSaved = true
        app.$emit('requestUpdate', { edit: true })
        app.$router.push('/culvert-asset-view')
      }
    }
  },
  //@ts-ignore
  beforeRouteLeave(to, from, next) {
    if (to.path) {
      this.routerNextPath = to.path
    }
    if (this.culvertSaved) {
      this.exitDialog = false
      next()
    } else if (!this.culvertSaved && !this.confirmedLeaving) {
      this.exitDialog = true
      /*
        warning  [Vue warn]: Error in v-on handler (Promise/async): "Error: Navigation aborted from "/culvert-inspections/add" to "/culvert-inspections" via a navigation guard."
        is caused by the call to next(false), it is nothing to be concerned with.
      */
      next(false)
    } else if (!this.culvertSaved && this.confirmedLeaving) {
      next()
    }
  },
  created() {
    if (this.$route.params.id) {
      try {
        let idToFind = parseInt(this.$route.params.id.toString())

        let foundCulvert = this.culverts.find((culvert) => {
          return culvert.id === idToFind
        })

        if (foundCulvert) {
          this.culvertToEdit = foundCulvert
        }
      } catch (e) {
        console.log('siteCulvertId not a number')
        this.$router.back()
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error finding culvert to edit.'
        })
        return
      }
    } else {
      this.$router.back()
      this.$typedStore.commit('setSnackbarParams', {
        type: 'error',
        msg: 'Error finding culvert to edit.'
      })
      return
    }
    this.setFormData()
  }
})

interface EditCulvertI {
  confirmedLeaving: boolean
  culvertSaved: boolean
  culvertToEdit: CulvertResult | null
  diameter: number
  directionOfFlow: string
  exitDialog: boolean
  fishHabitat: boolean
  fishHabitatOptions: [
    { text: string; value: boolean },
    { text: string; value: boolean }
  ]
  hasMarkers: boolean
  hasMarkersOptions: [
    { text: string; value: boolean },
    { text: string; value: boolean }
  ]
  inletLat: number | null
  inletLong: number | null
  length: number
  outletLat: number | null
  outletLong: number | null
  pageLoading: boolean
  roadName: string
  routerNextPath: string
  siteCulvertId: string
}
