




























































































import Vue from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data(): ResetPasswordFormI {
    return {
      resetPasswordFormEmail: '',
      resetPasswordFormEmailConfirm: ''
    }
  },
  computed: {
    coverImageClass(): string {
      if (
        this.$vuetify.breakpoint.name === 'xs' ||
        this.$vuetify.breakpoint.name === 'sm'
      ) {
        return 'fill-height cover-image-mobile'
      } else {
        return 'fill-height cover-image'
      }
    }
  },
  methods: {
    async forgotPassword(): Promise<boolean> {
      //@ts-ignore
      let valid = await this.$refs.validator.validate()

      if (!valid) {
        return false
      }

      let result = await this.$rpc('callResetPasswordRequest', {
        email: this.resetPasswordFormEmail
      })
      if (result.success) {
        this.resetPasswordFormEmail = ''
        this.resetPasswordFormEmailConfirm = ''
        this.$typedStore.commit('setSnackbarParams', {
          type: 'info',
          msg: 'Password reset email sent.'
        })
        this.$router.push('/login')
        return true
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Password reset failed. Please verify the email address.'
        })
        return false
      }
    }
  }
})

interface ResetPasswordFormI {
  resetPasswordFormEmail: string
  resetPasswordFormEmailConfirm: string
}
