import { BaseEntity } from './BaseEntity'
import { Entity, Column } from 'typeorm'
import {
  Length,
  IsBoolean,
  IsInt,
  IsNumber,
  IsOptional,
  IsString,
  IsDateString
} from 'class-validator'
import { Geometry } from 'geojson'

import { VegetationIssueParams } from '../../veg-common/apiTypes/'
import {
  IssueObject,
  UserObject
} from '../../veg-common/apiTypes/newResultTypes'

@Entity()
export class VegetationIssueParamsEntity extends BaseEntity {
  constructor(x?: VegetationIssueParams) {
    super()
    if (x) {
      this.uuid = x.uuid
      this.inspection_date = x.inspection_date
      this.has_issue = x.has_issue
      this.risk_score = x.risk_score
      this.veg_height = x.veg_height
      this.area_size = x.area_size
      this.action_required = x.action_required
      this.individual_count = x.individual_count
      this.density = x.density
      this.comments = x.comments
      this.areaId = x.areaId
      this.userId = x.userId
      this.actionTakenId = x.actionTakenId
      this.distributionId = x.distributionId
      this.physiologicalStageId = x.physiologicalStageId
      this.issueTypeId = x.issueTypeId
      this.vegetationTypeId = x.vegetationTypeId
      this.proximityId = x.proximityId
      this.originalIssueId = x.originalIssueId
      this.near_low_point_drain = x.near_low_point_drain

      this.speciesIds = x.speciesIds ? JSON.stringify(x.speciesIds) : null
      this.actionsRequiredIds = x.actionsRequiredIds
        ? JSON.stringify(x.actionsRequiredIds)
        : null
      this.thirdPartyCompanyIds = x.thirdPartyCompanyIds
        ? JSON.stringify(x.thirdPartyCompanyIds)
        : null

      this.gps_point = x.gps_point ? JSON.stringify(x.gps_point) : null
      this.gps_track = x.gps_track ? JSON.stringify(x.gps_track) : null
    }
  }

  toApiType(): VegetationIssueParams {
    return new VegetationIssueParams({
      uuid: this.uuid,
      inspection_date: this.inspection_date,
      has_issue: this.has_issue,
      risk_score: this.risk_score,
      area_size: this.area_size,
      action_required: this.action_required,
      individual_count: this.individual_count,
      density: this.density,
      comments: this.comments,
      areaId: this.areaId,
      userId: this.userId,
      actionTakenId: this.actionTakenId,
      distributionId: this.distributionId,
      physiologicalStageId: this.physiologicalStageId,
      issueTypeId: this.issueTypeId,
      vegetationTypeId: this.vegetationTypeId,
      proximityId: this.proximityId,
      originalIssueId: this.originalIssueId,
      near_low_point_drain: this.near_low_point_drain,
      veg_height: this.veg_height,

      thirdPartyCompanyIds: this.thirdPartyCompanyIds
        ? (JSON.parse(this.thirdPartyCompanyIds) as number[])
        : null,
      speciesIds: this.speciesIds
        ? (JSON.parse(this.speciesIds) as number[])
        : null,
      actionsRequiredIds: this.actionsRequiredIds
        ? (JSON.parse(this.actionsRequiredIds) as number[])
        : null,

      gps_track: this.gps_track
        ? (JSON.parse(this.gps_track) as Geometry)
        : null,
      gps_point: this.gps_point
        ? (JSON.parse(this.gps_point) as Geometry)
        : null
    })
  }

  toResultType(): IssueObject {
    return {
      id: this.sqliteId * -1,
      hidden: false,
      inspection_date: this.inspection_date,
      has_issue: this.has_issue,
      risk_score: this.risk_score,
      veg_height: this.veg_height,
      area_size: this.area_size,
      actionTakenId: this.actionTakenId,
      action_required: this.action_required,
      individual_count: this.individual_count,
      comments: this.comments,
      areaId: this.areaId,
      userId: this.userId,
      distributionId: this.distributionId,
      physiologicalStageId: this.physiologicalStageId,
      issueTypeId: this.issueTypeId,
      vegetationTypeId: this.vegetationTypeId,
      proximityId: this.proximityId,
      density: this.density,
      originalIssueId: this.originalIssueId,
      near_low_point_drain: this.near_low_point_drain,

      subsequentIssueIds: [],
      speciesIds: this.speciesIds ? JSON.parse(this.speciesIds) : [],
      actionsRequiredIds: this.actionsRequiredIds
        ? JSON.parse(this.actionsRequiredIds)
        : [],
      photos: [], //placeholder until received in vegetation issue getters to be filled
      user: {} as UserObject, //placeholder until received in vegetation issue getters to be filled
      isInteractive: true,

      gps_track: this.gps_track ? JSON.parse(this.gps_track) : null,
      gps_point: this.gps_point ? JSON.parse(this.gps_point) : null
    }
  }

  @Column({ type: 'text', unique: true })
  @IsString()
  @Length(36, 36)
  uuid!: string

  @Column('text')
  @IsDateString()
  inspection_date!: string

  @Column('boolean')
  @IsBoolean()
  has_issue!: boolean

  @Column('int')
  @IsNumber()
  risk_score!: number

  @Column({ type: 'double', nullable: true })
  @IsOptional()
  @IsNumber()
  area_size!: number | null

  @Column({ type: 'text', nullable: true })
  @IsOptional()
  gps_track!: string | null

  @Column({ type: 'text', default: '' })
  @IsString()
  veg_height!: string

  @Column({ type: 'text', nullable: true })
  @IsOptional()
  gps_point!: string | null

  @Column({ type: 'boolean', nullable: true })
  @IsOptional()
  @IsBoolean()
  action_required!: boolean | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsNumber()
  individual_count!: number | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsNumber()
  density!: number | null

  @Column('text')
  @IsString()
  @Length(0, 1024)
  comments!: string

  @Column('int')
  @IsInt()
  areaId!: number

  @Column('int')
  @IsInt()
  userId!: number

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  actionTakenId!: number | null

  @Column({ type: 'text', nullable: true })
  @IsOptional()
  @IsString()
  actionsRequiredIds!: string | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  distributionId!: number | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  physiologicalStageId!: number | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  issueTypeId!: number | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  vegetationTypeId!: number | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  proximityId!: number | null

  @Column({ type: 'text', nullable: true })
  @IsOptional()
  @IsString()
  speciesIds!: string | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  originalIssueId!: number | null

  @Column({ type: 'boolean', nullable: true })
  @IsOptional()
  @IsBoolean()
  near_low_point_drain!: boolean | null

  @Column({ type: 'text', nullable: true })
  @IsOptional()
  @IsString()
  thirdPartyCompanyIds!: string | null
}

@Entity()
export class VegetationIssuesResultEntity extends BaseEntity {
  constructor(x?: IssueObject) {
    super()
    if (x) {
      this.id = x.id
      this.hidden = x.hidden
      this.inspection_date = x.inspection_date
      this.has_issue = x.has_issue
      this.risk_score = x.risk_score
      this.veg_height = x.veg_height
      this.area_size = x.area_size
      this.actionTakenId = x.actionTakenId
      this.action_required = x.action_required
      this.individual_count = x.individual_count
      this.comments = x.comments
      this.areaId = x.areaId
      this.userId = x.userId
      this.distributionId = x.distributionId
      this.physiologicalStageId = x.physiologicalStageId
      this.issueTypeId = x.issueTypeId
      this.vegetationTypeId = x.vegetationTypeId
      this.proximityId = x.proximityId
      this.density = x.density
      this.original_issue_id = x.originalIssueId
      this.near_low_point_drain = x.near_low_point_drain

      this.subsequentIssueIds = JSON.stringify(x.subsequentIssueIds)
      this.speciesIds = JSON.stringify(x.speciesIds)
      this.actionsRequiredIds = JSON.stringify(x.actionsRequiredIds)
      this.photos = JSON.stringify(x.photos)
      this.user = JSON.stringify(x.user)
      this.isInteractive = x.isInteractive
      this.gps_point = x.gps_point ? JSON.stringify(x.gps_point) : null
      this.gps_track = x.gps_track ? JSON.stringify(x.gps_track) : null
    }
  }

  toApiType(): IssueObject {
    return {
      id: this.id,
      hidden: this.hidden,
      inspection_date: this.inspection_date,
      has_issue: this.has_issue,
      veg_height: this.veg_height,
      risk_score: this.risk_score,
      area_size: this.area_size,
      actionTakenId: this.actionTakenId,
      action_required: this.action_required,
      individual_count: this.individual_count,
      comments: this.comments,
      areaId: this.areaId,
      userId: this.userId,
      distributionId: this.distributionId,
      physiologicalStageId: this.physiologicalStageId,
      issueTypeId: this.issueTypeId,
      vegetationTypeId: this.vegetationTypeId,
      proximityId: this.proximityId,
      density: this.density,
      originalIssueId: this.original_issue_id,
      near_low_point_drain: this.near_low_point_drain,

      subsequentIssueIds: JSON.parse(this.subsequentIssueIds),
      speciesIds: JSON.parse(this.speciesIds),
      actionsRequiredIds: JSON.parse(this.actionsRequiredIds),
      photos: JSON.parse(this.photos),
      user: JSON.parse(this.user),
      isInteractive: this.isInteractive,

      gps_track: this.gps_track ? JSON.parse(this.gps_track) : null,
      gps_point: this.gps_point ? JSON.parse(this.gps_point) : null
    }
  }

  @Column({ type: 'int', unique: true })
  @IsInt()
  id!: number

  @Column({ type: 'boolean' })
  @IsBoolean()
  hidden!: boolean

  @Column({ type: 'text', nullable: true })
  @IsString()
  photos!: string

  @Column('text')
  @IsDateString()
  inspection_date!: string

  @Column('boolean')
  @IsBoolean()
  has_issue!: boolean

  @Column({ type: 'text', default: '' })
  @IsString()
  veg_height!: string

  @Column('int')
  @IsNumber()
  risk_score!: number

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsNumber()
  area_size!: number | null

  @Column({ type: 'text', nullable: true })
  @IsOptional()
  @IsString()
  gps_track!: string | null

  @Column({ type: 'text', nullable: true })
  @IsOptional()
  @IsString()
  gps_point!: string | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  actionTakenId!: number | null

  @Column('text')
  @IsString()
  actionsRequiredIds!: string

  @Column({ type: 'boolean', nullable: true })
  @IsOptional()
  @IsBoolean()
  action_required!: boolean | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsNumber()
  individual_count!: number | null

  @Column('text')
  @IsString()
  comments!: string

  @Column('int')
  @IsInt()
  areaId!: number

  @Column('int')
  @IsInt()
  userId!: number

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  distributionId!: number | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  physiologicalStageId!: number | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  issueTypeId!: number | null

  @Column('text')
  @IsString()
  speciesIds!: string

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  vegetationTypeId!: number | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  proximityId!: number | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsNumber()
  density!: number | null

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  original_issue_id!: number | null

  @Column('text')
  @IsString()
  subsequentIssueIds!: string

  @Column({ type: 'boolean', nullable: true })
  @IsOptional()
  @IsBoolean()
  near_low_point_drain!: boolean | null

  @Column('text')
  @IsString()
  user!: string

  @Column({ type: 'boolean', nullable: false })
  @IsBoolean()
  isInteractive!: boolean
}
