import DeviceDetector from 'device-detector-js'

let supportedBrowsers: string[] = [
  'Chrome',
  'Firefox',
  'Safari',
  'Mobile Safari',
  'Chrome Mobile iOS',
  'Edge'
]
let deviceTypes: string[] = ['smartphone', 'phablet', 'tablet']

//if the device detector can't parse correctly, then we'll act as if the device is not a mobile device
//we need to keep an eye on this, as the user agent is ever changing (more brands of devices, means more )
export function IsMobileDevice(): boolean {
  if (window.navigator.userAgent) {
    let deviceDetector = new DeviceDetector()

    let parsedAgent = deviceDetector.parse(window.navigator.userAgent)
    if (parsedAgent && parsedAgent.device && parsedAgent.os) {
      //iPads come back as a Mac desktop type device, so checking if the device can handle multiple touchpoints is just about the only way to check if it's an iPad
      //https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
      //As of now there are no touchscreen based Mac
      if (
        parsedAgent.os.name === 'Mac' &&
        parsedAgent.device.type === 'desktop' &&
        navigator.maxTouchPoints
      ) {
        return navigator.maxTouchPoints > 2
      } else {
        return (
          deviceTypes.includes(parsedAgent.device.type) ||
          parsedAgent.os.name === 'iOS'
        )
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

export function IsAppleMobileDevice(): boolean {
  if (window.navigator.userAgent) {
    let deviceDetector = new DeviceDetector()
    let parsedAgent = deviceDetector.parse(window.navigator.userAgent)
    if (
      parsedAgent &&
      parsedAgent.os &&
      parsedAgent.client &&
      parsedAgent.device
    ) {
      //As above, check for touch points to decide if the device is a tablet
      if (
        parsedAgent.os.name === 'Mac' &&
        parsedAgent.device.type === 'desktop' &&
        navigator.maxTouchPoints
      ) {
        return navigator.maxTouchPoints > 2
      } else {
        return (
          parsedAgent.os.name === 'iOS' &&
          (parsedAgent.client.name === 'Mobile Safari' ||
            parsedAgent.client.name === 'Chrome Mobile iOS')
        )
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

export function isUnsupportedBrowser(): boolean {
  if (window.navigator.userAgent) {
    let deviceDetector = new DeviceDetector()
    let parsedAgent = deviceDetector.parse(window.navigator.userAgent)
    if (parsedAgent && parsedAgent.client) {
      if (supportedBrowsers.includes(parsedAgent.client.name)) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  } else {
    return true
  }
}
