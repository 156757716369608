



































































import Vue from 'vue'
import { riskToColour } from '../../util/drawing'
import { Point } from 'geojson'
import { IsAppleMobileDevice } from '../../util/ParseUserAgent'

export default Vue.extend({
  data(): VegDialogI {
    return {
      dialogModel: false
    }
  },
  props: {
    titleLabel: {
      type: String
    },
    riskScore: {
      type: Number as () => number,
      default: -1
    },
    gpsPoint: {
      type: Object as () => Point | null,
      default: null
    }
  },
  computed: {
    riskText() {
      let textRisk: string = ''
      switch (this.riskScore) {
        case 0:
          textRisk = 'No Risk'
          break
        case 1:
          textRisk = 'Low Risk'
          break
        case 5:
          textRisk = 'Medium Risk'
          break
        case 10:
          textRisk = 'High Risk'
          break
        default:
          textRisk = 'Risk Unknown'
          break
      }
      return textRisk
    }
  },
  methods: {
    // riskScoreColor(riskScore: number, isInteractive: boolean): string {
    riskScoreColor(riskScore: number): string {
      return riskToColour(riskScore)
    },
    linkToMaps(): void {
      if (this.gpsPoint) {
        let point = this.gpsPoint
        let link = ''
        if (IsAppleMobileDevice()) {
          link = `http://maps.apple.com/?daddr=${point.coordinates[1]},${point.coordinates[0]}`
        } else {
          link = `https://www.google.com/maps/dir/?api=1&destination=${point.coordinates[1]},${point.coordinates[0]}`
        }
        window.open(link)
      }
    }
  }
})

interface VegDialogI {
  dialogModel: boolean
}
