











































import Vue from 'vue'

import AdminLogin from './Admin/AdminLogin.vue'
import AdminCreateCompany from './Admin/AdminCreateCompany.vue'
import AdminCreateUser from './Admin/AdminCreateUser.vue'

export default Vue.extend({
  components: {
    'app-admin-login-form': AdminLogin,
    'app-admin-create-company': AdminCreateCompany,
    'app-admin-create-user': AdminCreateUser
  },
  data() {
    return {}
  },
  computed: {
    adminLoggedIn(): boolean {
      return this.$typedStore.getters.isSuper
    }
  },
  methods: {
    async adminLogout(): Promise<void> {
      if (!this.$typedStore.getters.isSuper) {
        return
      }
      try {
        let result = await this.$rpc('callAdminLogout')
        if (result.success) {
          this.$typedStore.commit('setSnackbarParams', {
            type: 'success',
            msg: 'Admin logout success.'
          })
        } else {
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Admin logout failed.'
          })
        }
      } catch (e) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Admin logout error.'
        })
      }
    }
  }
})
