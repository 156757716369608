import { BaseEntity } from './BaseEntity'

import { Entity, Column } from 'typeorm'
import { CompanyObject } from '../../veg-common/apiTypes/newResultTypes'
import { IsBoolean, IsString, IsOptional, IsInt } from 'class-validator'

export interface StoreStateEntityI {
  showLsdGrid: boolean
}

@Entity()
export class StoreStateEntity extends BaseEntity {
  constructor(x?: StoreStateEntityI) {
    super()
    if (x) {
      this.showLsdGrid = x.showLsdGrid
    }
  }

  @Column({ type: 'boolean', default: false })
  @IsBoolean()
  showLsdGrid!: boolean
}

export interface AppLoggedInStoreStateI {
  loggedIntoVegeLogic: boolean
  loggedIntoCulvertLogic: boolean
}

@Entity()
export class AppLoggedInStoreStateEntity extends BaseEntity {
  constructor(x?: AppLoggedInStoreStateI) {
    super()
    if (x) {
      this.loggedIntoVegeLogic = x.loggedIntoVegeLogic
      this.loggedIntoCulvertLogic = x.loggedIntoCulvertLogic
    }
  }

  @Column({ type: 'boolean', default: true })
  @IsBoolean()
  loggedIntoVegeLogic!: boolean

  @Column({ type: 'boolean', default: false })
  @IsBoolean()
  loggedIntoCulvertLogic!: boolean
}

export interface UpdatedAtEntityI {
  key: string
  since: string | null
}

@Entity()
export class UpdatedAtEntity extends BaseEntity {
  constructor(x?: UpdatedAtEntityI) {
    super()
    if (x) {
      this.key = x.key
      this.since = x.since
    }
  }

  @Column({ type: 'text', unique: true })
  @IsString()
  key!: string

  @Column({ type: 'text', nullable: true })
  @IsString()
  since!: string | null
}

//can't think of a better name right now, this can definitely be named better
export interface ExtraAppDataEntityI {
  company: CompanyObject | null
  selectedAssetId: number | null
}

@Entity()
export class ExtraAppDataEntity extends BaseEntity {
  constructor(x?: ExtraAppDataEntityI) {
    super()
    if (x) {
      this.company = x.company ? JSON.stringify(x.company) : null
      this.selectedAssetId = x.selectedAssetId
    }
  }

  toApiType(): ExtraAppDataEntityI {
    return {
      company: this.company ? JSON.parse(this.company) : null,
      selectedAssetId: this.selectedAssetId
    }
  }

  @Column({ type: 'text', nullable: true, unique: true })
  @IsString()
  @IsOptional()
  company!: string | null

  @Column({ type: 'int', nullable: true })
  @IsInt()
  @IsOptional()
  selectedAssetId!: number | null
}
