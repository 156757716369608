import jwtDecode from 'jwt-decode'
import { JwtContent } from '../veg-common/jwt'
import {
  transformAndValidate,
  transformAndValidateSync
} from '../veg-common/ctv'

// Given a string which is a jwt, verify, decode, and validate the content, returning a JwtContent object
export function decodeJwtNoVerify(token: string): Promise<JwtContent> {
  let decodeResult = jwtDecode(token)
  return transformAndValidate(JwtContent, decodeResult as object)
}

export function decodeJwtNoVerifySync(token: string): Error | JwtContent {
  let decodeResult = jwtDecode(token)
  return transformAndValidateSync(JwtContent, decodeResult as object)
}
