import {
  IsAlphanumeric,
  IsDefined,
  IsInt,
  IsEmail,
  IsNotEmpty,
  IsString,
} from 'class-validator'

interface CompanyResultConstructor {
  id: number
  company_name: string
}

export class AdminCreateCompanyParams {
  @IsString()
  @IsNotEmpty()
  company_name!: string

  @IsAlphanumeric()
  company_tag!: string

  @IsString()
  company_type!: string
}

export class AdminCreateUserParams {
  @IsString()
  @IsNotEmpty()
  user_name!: string

  @IsEmail()
  email!: string

  @IsString()
  @IsNotEmpty()
  first_name!: string

  @IsString()
  @IsNotEmpty()
  last_name!: string

  @IsString()
  @IsNotEmpty()
  password!: string

  @IsInt()
  company_id!: number

  @IsString()
  user_role!: string
}

export class AdminGetCompanyResult {
  @IsInt()
  @IsDefined()
  id!: number

  @IsString()
  @IsNotEmpty()
  company_name!: string

  constructor(x?: CompanyResultConstructor) {
    if (x) {
      this.id = x.id
      this.company_name = x.company_name
    }
  }
}

export class AdminLoginParams {
  @IsString()
  @IsNotEmpty()
  username: string

  @IsString()
  @IsNotEmpty()
  password: string

  constructor(username: string, password: string) {
    this.username = username
    this.password = password
  }
}
