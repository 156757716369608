import {
  RelationsRes,
  IssueTypeRes,
  PhysiologicalStagesRes,
  IssueActionsRes,
  DistributionsRes,
  ProximitiesRes,
  VegetationTypesRes,
  CulvertStatusRes,
  CulvertInspectionActionRes
} from 'veg-common/apiTypes'

import { cloneDeep } from 'lodash'

/**
 * Sorts the lists in the relations object. Currently ignores weedVegetationTypes as it isn't used and
 * species as species need to be sorted based on if the user can use the species in the selected asset
 * @param relations the list of relations that needs to be fully sort
 * @returns the relations object with all necessary sorted arrays, with other like options as the last input
 */
export function sortAllLists(relations: RelationsRes) {
  let rel = cloneDeep(relations)
  rel.issueTypes = sortIssueTypes(rel.issueTypes)
  rel.physiologicalStages = sortPhysiologicalStages(rel.physiologicalStages)
  rel.takenActions = sortActions(rel.takenActions)
  rel.requiredActions = sortActions(rel.requiredActions)
  rel.distributions = sortDistributions(rel.distributions)
  rel.proximities = sortProximities(rel.proximities)
  rel.infraVegetationTypes = sortVegetationTypes(rel.infraVegetationTypes)
  rel.culvertStatuses = sortCulvertStatuses(rel.culvertStatuses)
  rel.culvertTakenActions = sortCulvertActions(rel.culvertTakenActions)
  rel.culvertRequiredActions = sortCulvertActions(rel.culvertRequiredActions)
  return rel
}

function sortIssueTypes(types: IssueTypeRes[]): IssueTypeRes[] {
  let lastElement: any | undefined
  let index: number = -1

  for (let i = 0; i < types.length; i++) {
    if (types[i].issue_type.toLowerCase() === 'other') {
      index = i
      lastElement = types[i]
      break
    }
  }

  if (lastElement && index >= 0) {
    types.splice(index, 1)
  }
  types.sort((a: IssueTypeRes, b: IssueTypeRes) =>
    a.issue_type.localeCompare(b.issue_type, undefined, {
      numeric: true,
      sensitivity: 'base'
    })
  )
  if (lastElement) {
    types.push(lastElement)
  }
  return types
}

function sortPhysiologicalStages(
  stages: PhysiologicalStagesRes[]
): PhysiologicalStagesRes[] {
  let lastElement: any | undefined
  let index: number = -1

  for (let i = 0; i < stages.length; i++) {
    if (stages[i].physiological_stage.toLowerCase() === 'unknown') {
      index = i
      lastElement = stages[i]
      break
    }
  }

  if (lastElement && index >= 0) {
    stages.splice(index, 1)
  }
  stages.sort((a: PhysiologicalStagesRes, b: PhysiologicalStagesRes) =>
    a.physiological_stage.localeCompare(b.physiological_stage, undefined, {
      numeric: true,
      sensitivity: 'base'
    })
  )
  if (lastElement) {
    stages.push(lastElement)
  }
  return stages
}

function sortActions(actions: IssueActionsRes[]): IssueActionsRes[] {
  let lastElement: any | undefined
  let index: number = -1

  for (let i = 0; i < actions.length; i++) {
    if (actions[i].issue_action.toLowerCase() === 'no action') {
      index = i
      lastElement = actions[i]
      break
    }
  }

  if (lastElement && index >= 0) {
    actions.splice(index, 1)
  }
  actions.sort((a: IssueActionsRes, b: IssueActionsRes) =>
    a.issue_action.localeCompare(b.issue_action, undefined, {
      numeric: true,
      sensitivity: 'base'
    })
  )
  if (lastElement) {
    actions.push(lastElement)
  }

  return actions
}

function sortDistributions(
  distributions: DistributionsRes[]
): DistributionsRes[] {
  distributions.sort((a: DistributionsRes, b: DistributionsRes) =>
    a.distribution.localeCompare(b.distribution, undefined, {
      numeric: true,
      sensitivity: 'base'
    })
  )
  return distributions
}

function sortProximities(proximities: ProximitiesRes[]): ProximitiesRes[] {
  proximities.sort((a: ProximitiesRes, b: ProximitiesRes) =>
    a.proximity.localeCompare(b.proximity, undefined, {
      numeric: true,
      sensitivity: 'base'
    })
  )
  return proximities
}

function sortVegetationTypes(
  vegetationTypes: VegetationTypesRes[]
): VegetationTypesRes[] {
  vegetationTypes.sort((a: VegetationTypesRes, b: VegetationTypesRes) =>
    a.vegetation_type.localeCompare(b.vegetation_type, undefined, {
      numeric: true,
      sensitivity: 'base'
    })
  )
  return vegetationTypes
}

function sortCulvertStatuses(
  culvertStatuses: CulvertStatusRes[]
): CulvertStatusRes[] {
  culvertStatuses.sort((a: CulvertStatusRes, b: CulvertStatusRes) =>
    a.culvert_status.localeCompare(b.culvert_status, undefined, {
      numeric: true,
      sensitivity: 'base'
    })
  )
  return culvertStatuses
}

function sortCulvertActions(
  culvertActions: CulvertInspectionActionRes[]
): CulvertInspectionActionRes[] {
  culvertActions.sort(
    (a: CulvertInspectionActionRes, b: CulvertInspectionActionRes) =>
      a.inspection_action.localeCompare(b.inspection_action)
  )
  return culvertActions
}
