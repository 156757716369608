var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',[(_vm.pageLoading)?_c('v-col',{staticClass:"mt-8",attrs:{"justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"size":70,"indeterminate":"","color":"primary"}}),_c('p',{staticClass:"mt-8"},[_vm._v("Loading Sub Asset Form")])],1):_c('ValidationObserver',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"management area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-0 pt-0 pr-2",attrs:{"items":_vm.managementAreaOptions,"error-messages":errors,"label":"Select Management Area","multiple":"","chips":"","hide-details":"auto","item-text":"name","item-value":"id"},model:{value:(_vm.selectedManagementArea),callback:function ($$v) {_vm.selectedManagementArea=$$v},expression:"selectedManagementArea"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pl-3",attrs:{"cols":"5","sm":"4"}},[_c('SeeAreasDialog')],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Sub Asset Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Sub Asset Name","error-messages":errors},model:{value:(_vm.subAssetName),callback:function ($$v) {_vm.subAssetName=$$v},expression:"subAssetName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Central Latitude","rules":"required|between:-90,90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Central Latitude","error-messages":errors,"disabled":_vm.latIsSet},model:{value:(_vm.latitude),callback:function ($$v) {_vm.latitude=$$v},expression:"latitude"}})]}}],null,true)})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Central Longitude","rules":"required|between:-180,180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Central Longitude","error-messages":errors,"disabled":_vm.latIsSet},model:{value:(_vm.longitude),callback:function ($$v) {_vm.longitude=$$v},expression:"longitude"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('DrawGpsPointComponent',{ref:"subAsset",attrs:{"multipleSelectedManagementArea":_vm.selectedManagementArea,"addSubAssetCheck":true},on:{"setGpsError":_vm.setGpsError,"savePoint":_vm.subAssetPoint}})],1)],1),_c('v-row',{staticClass:"pb-6 mt-2"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"primary accentAjmBlue--text",on:{"click":function($event){return _vm.validateInputs()}}},[_vm._v("Save ")])],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.clearEdits}},[_vm._v("Clear Edits")])],1)],1)]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.exitDialog),callback:function ($$v) {_vm.exitDialog=$$v},expression:"exitDialog"}},[_c('v-card',{staticClass:"ajmBlue"},[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Exit Add Sub Asset ")]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-2 pt-2"},[_vm._v(" Are you sure you want to exit adding a Sub Asset? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":function($event){_vm.exitDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"secondary accentAjmBlue--text mr-2",on:{"click":_vm.exit}},[_vm._v(" Exit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }