import { IsEnum, IsString, Length, IsInt } from 'class-validator'

export enum EntitiesWithPhotos {
  VegetationIssuePhoto = 'vegetation-issue',
  CulvertInspectionPhoto = 'culvert-inspection',
}

export class CreatePhotoRequest {
  constructor(
    target: EntitiesWithPhotos,
    parentUuid: string,
    fileType: string,
    uuid: string
  ) {
    this.uuid = uuid
    this.targetType = target
    this.parentUuid = parentUuid
    this.fileType = fileType
  }

  @IsString()
  @Length(36, 36)
  uuid!: string

  @IsEnum(EntitiesWithPhotos)
  targetType: EntitiesWithPhotos

  @IsString()
  @Length(36, 36)
  parentUuid: string

  @IsString()
  fileType: string
}

export class CreateAddPhotoRequest {
  constructor(target: EntitiesWithPhotos, parentId: number, fileType: string) {
    this.targetType = target
    this.parentId = parentId
    this.fileType = fileType
  }

  @IsEnum(EntitiesWithPhotos)
  targetType: EntitiesWithPhotos

  @IsInt()
  parentId: number

  @IsString()
  fileType: string
}

export class RemovePhotoRequest {
  constructor(target: EntitiesWithPhotos, parentId: number, fileKey: string) {
    this.targetType = target
    this.parentId = parentId
    this.fileKey = fileKey
  }

  @IsEnum(EntitiesWithPhotos)
  targetType: EntitiesWithPhotos

  @IsInt()
  parentId: number

  @IsString()
  fileKey: string
}
