import {
  between,
  required,
  email,
  max,
  min_value,
  integer,
  confirmed
} from 'vee-validate/dist/rules'
//@ts-ignore
import { extend } from 'vee-validate'

extend('between', {
  ...between,
  message: 'The {_field_} field must be between {min} and {max}'
})

extend('required', {
  ...required,
  message: 'The {_field_} field is required'
})

extend('max', {
  ...max,
  message: 'The {_field_} field must be {length} characters or less'
})

extend('min_value', {
  ...min_value,
  message: 'The {_field_} field must be {min} or more'
})

extend('email', {
  ...email,
  message: 'The {_field_} field must be a valid email'
})

extend('integer', {
  ...integer,
  message: 'The {_field_} field must be a whole number (no decimal points)'
})

extend('confirmed', {
  ...confirmed,
  message: 'The fields must match'
})
