


















import Vue from 'vue'
import { CulvertInspectionResult } from '../../veg-common/apiTypes'
import CulvertInfoCard from './CulvertInfoCard.vue'
export default Vue.extend({
  data(): CulvertInfoDialogI {
    return {}
  },
  components: { CulvertInfoCard },
  props: {
    culvertInspection: {
      type: Object as () => CulvertInspectionResult
    }
  }
})

interface CulvertInfoDialogI {}
