













































































































































































































































































































































































































import Vue from 'vue'
import {
  CulvertInspectionResult,
  CulvertResult
} from '../../veg-common/apiTypes'
import { getLatestCulvertInspection } from '../../util/CulvertHelpers'
import { isoToHumanDateTime, isoToHumanDate } from '../../util/date-time'
import { Point } from 'geojson'
import { IsMobileDevice } from '../../util/ParseUserAgent'
import { format as dfnFormat, parseISO as dfnParseISO } from 'date-fns'

var utm = require('utm-latlng')

//setting the variable and exporting that is important here to prevent type checking errors,
//since this is a part of an intentional circular component usage tyepscript was complaining about export default Vue.extend being an implicit any
//setting it as any matches what we do in our test files, and lets the template also compile
const cmp: any = Vue.extend({
  components: {
    MapComponent: () => import('../../components/MapComponent.vue')
  },
  props: {
    culvertInspection: {
      type: Object as () => CulvertInspectionResult | null,
      default: null
    },
    culvert: {
      type: Object as () => CulvertResult | null,
      default: null
    }
  },
  data(): CulvertInfoCardI {
    return {
      culvertInfo: undefined,
      downloadingImage: false,
      drawingInProgress: false,
      latestInspection: null,
      mapIsLoaded: false,
      UtmErrorMessage: 'UTM data not available'
    }
  },
  computed: {
    convertToCm(): number | undefined {
      if (this.culvertInfo) {
        return this.culvertInfo.diameter * 100
      } else return
    },
    convertedUtmInletValue(): convertedUtmValue | undefined {
      if (this.culvertInfo) {
        let newUtm = new utm('GRS 1980')
        let point = this.culvertInfo.inlet_location as Point
        let lat = point.coordinates[1]
        let lng = point.coordinates[0]
        let precision = 0
        let convertedLatLng = newUtm.convertLatLngToUtm(lat, lng, precision)
        if (
          (convertedLatLng.ZoneNumber,
          convertedLatLng.ZoneLetter,
          convertedLatLng.Easting,
          convertedLatLng.Northing)
        ) {
          return newUtm.convertLatLngToUtm(lat, lng, precision)
        } else {
          return undefined
        }
      } else return
    },
    convertedUtmOutletValue(): convertedUtmValue | undefined {
      if (this.culvertInfo) {
        let newUtm = new utm('GRS 1980')
        let point = this.culvertInfo.outlet_location as Point
        let lat = point.coordinates[1]
        let lng = point.coordinates[0]
        let precision = 0
        let convertedLatLng = newUtm.convertLatLngToUtm(lat, lng, precision)
        if (
          (convertedLatLng.ZoneNumber,
          convertedLatLng.ZoneLetter,
          convertedLatLng.Easting,
          convertedLatLng.Northing)
        ) {
          return newUtm.convertLatLngToUtm(lat, lng, precision)
        } else {
          return undefined
        }
      } else return
    },
    nullCheckedActionsTaken(): string {
      if (
        this.culvertInspection &&
        this.culvertInspection.actionsTaken &&
        this.culvertInspection.actionsTaken.length > 0
      ) {
        return this.culvertInspection.actionsTaken.join(' | ')
      } else {
        return 'No Taken Actions'
      }
    },
    nullCheckedActionsRequired(): string {
      if (
        this.culvertInspection &&
        this.culvertInspection.actionsRequired &&
        this.culvertInspection.actionsRequired.length > 0
      ) {
        return this.culvertInspection.actionsRequired.join(' | ')
      } else {
        return 'No Required Actions'
      }
    },
    culvertData(): CulvertResult[] {
      return this.$typedStore.getters.culverts
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.sm
    },
    isSmAndDown(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    isSmAndUp(): boolean {
      return this.$vuetify.breakpoint.smAndUp
    },
    latestCulvertDateOfInspection(): string {
      if (this.culvertInspection) {
        return isoToHumanDateTime(this.culvertInspection.inspection_date)
      } else {
        return 'Data not currently available.'
      }
    },
    nextDateOfInspection(): string {
      if (this.culvertInspection) {
        return isoToHumanDate(this.culvertInspection.next_inspection_date)
      } else {
        return 'Data not currently available.'
      }
    },
    lengthCheck(): boolean | undefined {
      if (this.culvertInfo) {
        return this.culvertInfo.length != -1 ? true : false
      } else return
    },
    diameterCheck(): boolean | undefined {
      if (this.culvertInfo) {
        return this.culvertInfo.diameter != -1 ? true : false
      } else return
    }
  },

  methods: {
    setCulvertInfo() {
      if (this.culvert) {
        this.culvertInfo = this.culvert
      } else {
        this.culvertInfo = this.culvertData.find((e) => {
          return this.culvertInspection
            ? e.site_culvert_id === this.culvertInspection.culvert
            : false
        })
      }
    },
    culvertInletOutlet() {
      if (!this.mapIsLoaded) {
        return
      }
      //@ts-ignore
      this.$refs.mapComp.removeAllMarkers()
      this.drawingInProgress = true
      if (this.culvertInfo) {
        let inlet = this.culvertInfo.inlet_location as Point
        let myLatestInspection: CulvertInspectionResult | null =
          this.latestInspection
        // @ts-ignore
        this.$refs.mapComp.drawCulvertMarker(
          inlet as Point,
          {
            clickable: false,
            showLabel: false
          },
          this.culvertInfo,
          myLatestInspection
        )
        let outlet = this.culvertInfo.outlet_location as Point
        // @ts-ignore
        this.$refs.mapComp.drawCulvertMarker(
          outlet as Point,
          {
            clickable: false,
            showLabel: false
          },
          this.culvertInfo,
          myLatestInspection
        )
        const inletOutletPolyCoords = {
          coordinates: [inlet.coordinates, outlet.coordinates]
        }
        //@ts-ignore
        this.$refs.mapComp.drawPathWithArrow(inletOutletPolyCoords)
        this.drawingInProgress = false
        if (inlet && outlet)
          //@ts-ignore
          this.$refs.mapComp.zoomMapOnMidPoint(inlet, outlet, 15)
        //@ts-ignore
        else this.$refs.mapComp.zoomMapOnAsset()
      } else {
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnAsset()
      }
    },
    getLatestInspection() {
      if (this.culvertInfo) {
        this.latestInspection = getLatestCulvertInspection(this.culvertInfo)
      }
    },
    async downloadImage(key: string, index: number): Promise<void> {
      if (IsMobileDevice()) {
        window.location.href = `${this.$typedStore.getters.basePhotoUrl}${key}`
      } else {
        this.downloadingImage = true
        try {
          let fileRes = await this.$rpc('callGetImageFile', {
            key
          })

          let binaryString = window.atob(fileRes.file)
          let binaryLen = binaryString.length
          let arrayBuffer = new Uint8Array(binaryLen)
          for (let i = 0; i < binaryLen; i++) {
            let char = binaryString.charCodeAt(i)
            arrayBuffer[i] = char
          }

          let blob = new Blob([arrayBuffer], {
            type: fileRes.contentType
          })

          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          if (this.culvertInspection) {
            link.download =
              dfnFormat(
                dfnParseISO(this.culvertInspection.inspection_date),
                'MMMM-do-yyyy_HH-mm'
              ) + `-${index}`
          }
          link.click()
          link.remove()
          this.downloadingImage = false
        } catch (error) {
          console.log(error)
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Error downloading image.'
          })
          this.downloadingImage = false
        }
      }
    },
    mapLoaded(loadedSuccessfully: boolean): void {
      // This gets run after initializeMap emits "mapLoaded"
      // So we don't try to add markers before the map is ready
      this.mapIsLoaded = loadedSuccessfully
      if (loadedSuccessfully) this.culvertInletOutlet()
    },
    routeToAddNewInspection() {
      if (this.culvertInfo) {
        this.$router.push({
          path: '/culvert-inspections/add',
          query: {
            siteCulvertId: this.culvertInfo.site_culvert_id.toString()
          }
        })
      }
    }
  },
  created() {
    this.setCulvertInfo()
    this.getLatestInspection()
  },
  watch: {
    culvert() {
      this.setCulvertInfo()
      this.getLatestInspection()
      this.culvertInletOutlet()
    }
  }
})
export default cmp

interface convertedUtmValue {
  ZoneNumber: number
  ZoneLetter: string
  Easting: number
  Northing: number
}

interface CulvertInfoCardI {
  culvertInfo?: CulvertResult
  downloadingImage: boolean
  drawingInProgress: boolean
  latestInspection: CulvertInspectionResult | null
  mapIsLoaded: boolean
  UtmErrorMessage: string
}
