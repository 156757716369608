











































import Vue from 'vue'

export default Vue.extend({
  data(): VegDialogSmallDataI {
    return {
      inProgress: false,
      dialogModel: false,
    }
  },
  methods: {
    async onSubmit(e: Event): Promise<void> {
      if (typeof this.submitAction === 'function') {
        this.inProgress = true

        if (e && e instanceof Event) {
          e.preventDefault()
        }

        try {
          let submitResult = await this.submitAction()
          // If the submitAction returns "true", we use that as a signal
          // to close the dialog. Any other result leaves it open
          if (submitResult === true) {
            this.dialogModel = false
          }
        } catch (err) {
          console.log(err)
        }

        this.inProgress = false
      }
    },
  },
  props: {
    submitLabel: {
      type: String,
    },
    titleLabel: {
      type: String,
    },
    submitAction: {
      type: Function,
    },
  },
})

interface VegDialogSmallDataI {
  inProgress: boolean
  dialogModel: boolean
}
