





















import Vue from 'vue'

import { SnackbarParams } from '../store/types'

export default Vue.extend({
  data(): SnackbarI {
    return {
      snackbar: false,
      timeout: 5000,
      snackbarText: '',
      snackbarColor: ''
    }
  },
  computed: {
    snackbarParams(): SnackbarParams | null {
      return this.$typedStore.getters.getSnackbarParams
    }
  },
  watch: {
    snackbarParams(): void {
      if (this.snackbarParams) {
        this.snackbarDisplay(this.snackbarParams)
      } else {
        this.closeSnackbar()
      }
    }
  },
  methods: {
    closeSnackbar() {
      this.$typedStore.commit('resetSnackbar')
      this.snackbar = false
    },
    snackbarEvent() {
      if (this.snackbar === false) {
        this.closeSnackbar()
      }
    },
    snackbarDisplay(params: SnackbarParams): void {
      this.timeout = 5000
      if (params.timeout) {
        this.timeout = params.timeout
      }
      this.snackbar = true
      this.snackbarColor =
        'type' in params && typeof params.type === 'string'
          ? params.type
          : 'info'
      this.snackbarText =
        'msg' in params && typeof params.msg === 'string' ? params.msg : ''
    }
  }
})

interface SnackbarI {
  snackbar: boolean
  timeout: number
  snackbarText: string
  snackbarColor: string
}
