import { MigrationInterface, QueryRunner, TableColumn } from 'typeorm'

export class UpdateFilterFieldsToArrays1679079140024
  implements MigrationInterface
{
  public async up(queryRunner: QueryRunner): Promise<void> {
    let vegeFilters = await queryRunner.hasTable('vege_issue_applied_filters')
    let culvertFilters = await queryRunner.hasTable(
      'applied_culvert_filters_data_entity'
    )
    let culvertInspectionFilters = await queryRunner.hasTable(
      'applied_culvert_inspection_filters_data_entity'
    )
    if (
      vegeFilters === false &&
      culvertFilters === false &&
      culvertInspectionFilters === false
    ) {
      console.log('No filter tables created yet - bail out of migration')
      return
    }

    //update culvert filters
    if (
      await queryRunner.hasColumn(
        'applied_culvert_filters_data_entity',
        'riskScoreSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'applied_culvert_filters_data_entity',
        'riskScoreSelectedValue'
      )
      await queryRunner.addColumn(
        'applied_culvert_filters_data_entity',
        new TableColumn({
          name: 'riskScoreSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'applied_culvert_filters_data_entity',
        'siteIdSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'applied_culvert_filters_data_entity',
        'siteIdSelectedValue'
      )
      await queryRunner.addColumn(
        'applied_culvert_filters_data_entity',
        new TableColumn({
          name: 'siteIdSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'applied_culvert_filters_data_entity',
        'culvertStatusSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'applied_culvert_filters_data_entity',
        'culvertStatusSelectedValue'
      )
      await queryRunner.addColumn(
        'applied_culvert_filters_data_entity',
        new TableColumn({
          name: 'culvertStatusSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'applied_culvert_filters_data_entity',
        'fishHabitatSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'applied_culvert_filters_data_entity',
        'fishHabitatSelectedValue'
      )
      await queryRunner.addColumn(
        'applied_culvert_filters_data_entity',
        new TableColumn({
          name: 'fishHabitatSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'applied_culvert_filters_data_entity',
        'markersSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'applied_culvert_filters_data_entity',
        'markersSelectedValue'
      )
      await queryRunner.addColumn(
        'applied_culvert_filters_data_entity',
        new TableColumn({
          name: 'markersSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    //done update culvert filters
    //update culvert inspection filters
    if (
      await queryRunner.hasColumn(
        'applied_culvert_inspection_filters_data_entity',
        'culvertSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'applied_culvert_inspection_filters_data_entity',
        'culvertSelectedValue'
      )
      await queryRunner.addColumn(
        'applied_culvert_inspection_filters_data_entity',
        new TableColumn({
          name: 'culvertSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'applied_culvert_inspection_filters_data_entity',
        'riskScoreSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'applied_culvert_inspection_filters_data_entity',
        'riskScoreSelectedValue'
      )
      await queryRunner.addColumn(
        'applied_culvert_inspection_filters_data_entity',
        new TableColumn({
          name: 'riskScoreSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'applied_culvert_inspection_filters_data_entity',
        'culvertStatusSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'applied_culvert_inspection_filters_data_entity',
        'culvertStatusSelectedValue'
      )
      await queryRunner.addColumn(
        'applied_culvert_inspection_filters_data_entity',
        new TableColumn({
          name: 'culvertStatusSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'applied_culvert_inspection_filters_data_entity',
        'photoStatusSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'applied_culvert_inspection_filters_data_entity',
        'photoStatusSelectedValue'
      )
      await queryRunner.addColumn(
        'applied_culvert_inspection_filters_data_entity',
        new TableColumn({
          name: 'photoStatusSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    //done update culvert inspection filters
    //update vege issue filters
    if (
      await queryRunner.hasColumn(
        'vege_issue_applied_filters',
        'managementAreaSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'vege_issue_applied_filters',
        'managementAreaSelectedValue'
      )
      await queryRunner.addColumn(
        'vege_issue_applied_filters',
        new TableColumn({
          name: 'managementAreaSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'vege_issue_applied_filters',
        'riskScoreSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'vege_issue_applied_filters',
        'riskScoreSelectedValue'
      )
      await queryRunner.addColumn(
        'vege_issue_applied_filters',
        new TableColumn({
          name: 'riskScoreSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'vege_issue_applied_filters',
        'photoStatusSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'vege_issue_applied_filters',
        'photoStatusSelectedValue'
      )
      await queryRunner.addColumn(
        'vege_issue_applied_filters',
        new TableColumn({
          name: 'photoStatusSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'vege_issue_applied_filters',
        'issueTypeSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'vege_issue_applied_filters',
        'issueTypeSelectedValue'
      )
      await queryRunner.addColumn(
        'vege_issue_applied_filters',
        new TableColumn({
          name: 'issueTypeSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'vege_issue_applied_filters',
        'actionTakenSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'vege_issue_applied_filters',
        'actionTakenSelectedValue'
      )
      await queryRunner.addColumn(
        'vege_issue_applied_filters',
        new TableColumn({
          name: 'actionTakenSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'vege_issue_applied_filters',
        'physiologicalStageSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'vege_issue_applied_filters',
        'physiologicalStageSelectedValue'
      )
      await queryRunner.addColumn(
        'vege_issue_applied_filters',
        new TableColumn({
          name: 'physiologicalStageSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'vege_issue_applied_filters',
        'infraVegetationTypesSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'vege_issue_applied_filters',
        'infraVegetationTypesSelectedValue'
      )
      await queryRunner.addColumn(
        'vege_issue_applied_filters',
        new TableColumn({
          name: 'infraVegetationTypesSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'vege_issue_applied_filters',
        'distributionSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'vege_issue_applied_filters',
        'distributionSelectedValue'
      )
      await queryRunner.addColumn(
        'vege_issue_applied_filters',
        new TableColumn({
          name: 'distributionSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'vege_issue_applied_filters',
        'proximitySelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'vege_issue_applied_filters',
        'proximitySelectedValue'
      )
      await queryRunner.addColumn(
        'vege_issue_applied_filters',
        new TableColumn({
          name: 'proximitySelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'vege_issue_applied_filters',
        'originalIssueSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'vege_issue_applied_filters',
        'originalIssueSelectedValue'
      )
      await queryRunner.addColumn(
        'vege_issue_applied_filters',
        new TableColumn({
          name: 'originalIssueSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
    if (
      await queryRunner.hasColumn(
        'vege_issue_applied_filters',
        'subAssetSelectedValue'
      )
    ) {
      await queryRunner.dropColumn(
        'vege_issue_applied_filters',
        'subAssetSelectedValue'
      )
      await queryRunner.addColumn(
        'vege_issue_applied_filters',
        new TableColumn({
          name: 'subAssetSelectedValues',
          type: 'text',
          default: `'[]'`,
          isNullable: false
        })
      )
    }
  }

  public async down(queryRunner: QueryRunner): Promise<void> {}
}
