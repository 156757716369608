































































































































import Vue from 'vue'
import DrawGpsPointComponent from '../../../components/DrawGpsPointComponent.vue'
import SeeAreasDialog from '../../../components/SeeAreasDialog.vue'
import { Point } from 'geojson'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ManagementAreaResult } from '../../../veg-common/apiTypes'

export default Vue.extend({
  components: {
    DrawGpsPointComponent,
    SeeAreasDialog,
    ValidationObserver,
    ValidationProvider
  },
  data(): AddSubAssetI {
    return {
      assetId: 0,
      confirmedLeaving: false,
      exitDialog: false,
      gpsError: false,
      latitude: null,
      longitude: null,
      managementAreaOptions: [],
      pageLoading: false,
      routerNextPath: '',
      selectedManagementArea: [],
      subAssetGpsPoint: null,
      subAssetName: '',
      subAssetSaved: false
    }
  },
  props: {
    managementAreas: {
      type: Array as () => ManagementAreaResult[],
      default: (): ManagementAreaResult[] => []
    }
  },
  computed: {
    latIsSet(): boolean {
      return this.subAssetGpsPoint ? true : false
    }
  },
  methods: {
    areasFiltered() {
      this.managementAreas.forEach((item) => {
        if (!item.subAssetId) {
          this.managementAreaOptions.push(item)
        }
      })
    },
    clearEdits(): void {
      this.selectedManagementArea = []
      this.subAssetName = ''
      this.latitude = null
      this.longitude = null
      if (this.subAssetGpsPoint) {
        //@ts-ignore
        this.$refs.subAsset.cancelManualGpsPoint()
        this.subAssetGpsPoint = null
      }
    },
    exit() {
      this.exitDialog = false
      this.confirmedLeaving = true
      if (this.routerNextPath === '') this.routerNextPath = '/sub-assets'
      this.$router.push({ path: this.routerNextPath })
    },
    async saveSubAsset(): Promise<void> {
      this.pageLoading = true
      let app = this

      if (!app.$typedStore.getters.jwtContent) return
      if (!app.$typedStore.getters.jwtContent.data.userId) return

      let location: Point = {
        type: 'Point',
        coordinates: [
          app.longitude ? app.longitude : 0,
          app.latitude ? app.latitude : 0
        ]
      }

      if (app.$typedStore.getters.selectedAsset) {
        this.assetId = app.$typedStore.getters.selectedAsset.id
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Sub Asset failed to save.'
        })
        this.pageLoading = false
        return
      }

      let result = await app.$rpc('callCreateSubAsset', {
        sub_asset_name: app.subAssetName,
        latitude: Number(location.coordinates[1]),
        longitude: Number(location.coordinates[0]),
        assetId: app.assetId,
        selectedManagementArea: app.selectedManagementArea
      })

      if (result && !result.success) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Sub Asset creation failed.'
        })
        return
      }

      this.$typedStore.commit('setSnackbarParams', {
        type: 'success',
        msg: 'Sub Asset created successfully.'
      })

      app.subAssetSaved = true
      app.$emit('requestUpdate', { edit: true })
      this.pageLoading = false
      app.$router.push('/sub-assets')
    },
    subAssetPoint(point: Point | null): void {
      this.subAssetGpsPoint = point
    },
    setGpsError(gpsError: boolean): void {
      this.gpsError = gpsError
    },
    async validateInputs(): Promise<void> {
      //@ts-ignore
      let valid = await this.$refs.validator.validate()

      if (valid) {
        await this.saveSubAsset()
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Sub Asset invalid.'
        })
      }
    }
  },
  watch: {
    subAssetGpsPoint() {
      if (this.subAssetGpsPoint) {
        this.latitude = this.subAssetGpsPoint.coordinates[1]
        this.longitude = this.subAssetGpsPoint.coordinates[0]
      } else {
        this.latitude = null
        this.longitude = null
      }
    }
  },
  created() {
    this.areasFiltered()
  },
  //@ts-ignore
  beforeRouteLeave(to, from, next) {
    if (to.path) {
      this.routerNextPath = to.path
    }
    if (this.subAssetSaved) {
      this.exitDialog = false
      next()
    } else if (!this.subAssetSaved && !this.confirmedLeaving) {
      this.exitDialog = true
      /*
        warning  [Vue warn]: Error in v-on handler (Promise/async): "Error: Navigation aborted from "/culvert-inspections/add" to "/culvert-inspections" via a navigation guard."
        is caused by the call to next(false), it is nothing to be concerned with.
      */
      next(false)
    } else if (!this.subAssetSaved && this.confirmedLeaving) {
      next()
    }
  }
})

interface AddSubAssetI {
  assetId: number
  confirmedLeaving: boolean
  exitDialog: boolean
  gpsError: boolean
  latitude: number | null
  longitude: number | null
  managementAreaOptions: ManagementAreaResult[]
  pageLoading: boolean
  routerNextPath: string
  selectedManagementArea: number[]
  subAssetGpsPoint: Point | null
  subAssetName: string
  subAssetSaved: boolean
}
