import {
  CreatePhotoRequest,
  CreateAddPhotoRequest,
  RemovePhotoRequest,
  IdResponse,
  GenericResult
} from '../veg-common/apiTypes'

export const photosApiDef = {
  callPhotoUpload: {
    route: '/photos/upload',
    method: 'post-octet',
    auth: true,
    inputType: CreatePhotoRequest,
    outputType: IdResponse,
    returnsMultiple: false
  },
  callPhotoUploadAddExisting: {
    route: '/photos/upload-add-existing',
    method: 'post-octet',
    auth: true,
    inputType: CreateAddPhotoRequest,
    outputType: IdResponse,
    returnsMultiple: false
  },
  callPhotoRemove: {
    route: '/photos/remove',
    method: 'post',
    auth: true,
    inputType: RemovePhotoRequest,
    outputType: GenericResult,
    returnsMultiple: false
  }
} as const
