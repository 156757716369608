import { IsInt, IsNotEmpty, IsNumber, IsString } from 'class-validator'

export class AssetResult {
  constructor(item: AssetResult) {
    if (item) {
      this.id = item.id
      this.asset_name = item.asset_name
      this.latitude = item.latitude
      this.longitude = item.longitude
      this.companyId = item.companyId
    }
  }

  @IsInt()
  id!: number

  @IsInt()
  companyId!: number

  @IsString()
  @IsNotEmpty()
  asset_name!: string

  @IsNumber()
  latitude!: number

  @IsNumber()
  longitude!: number
}
