





























































































import Vue from 'vue'
import { VegetationIssueResult } from '../veg-common/apiTypes'
import { isoToHumanDateTime } from './../util/date-time'
import VegeIssueInfoDialog from './VegeIssueView/VegeIssueInfoDialog.vue'
import { riskToColour } from '../util/drawing'
import { DataTableHeader } from 'vuetify/types'
import { getListOfMostRecentFollowups } from '../util/IssueHelpers'

export default Vue.extend({
  components: {
    VegeIssueInfoDialog
  },
  props: {
    vegeIssueData: {
      type: Array as () => VegetationIssueResult[],
      default: (): VegetationIssueResult[] => []
    }
  },
  data(): assignedIssuesData {
    return {
      headers: [
        {
          text: 'Risk Level',
          value: 'riskColor',
          sortable: false,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Area Name',
          value: 'area',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Date Inspected',
          value: 'inspection_date',
          class: 'font-weight-black white--text'
        },
        {
          text: 'VegLogic Id',
          value: 'originalIssueId',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Issue Type',
          value: 'issue_type',
          class: 'font-weight-black white--text'
        },

        {
          text: 'Details',
          value: 'detailsDialog',
          sortable: false,
          class: 'font-weight-black white--text'
        }
      ],
      userName: '',
      selectedRows: [],
      deleteAssignedIssueDialog: false,
      userId: null,
      assignedIssuesTableData: []
    }
  },
  methods: {
    formatInspectionDate(date: string) {
      return isoToHumanDateTime(date)
    },
    setRiskColour(item: VegetationIssueResult): string {
      return riskToColour(item.risk_score, item.isInteractive)
    },
    openDeleteAssignedIssueDialog(): void {
      if (this.selectedRows.length <= 0) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No issue selected.'
        })
      } else {
        this.deleteAssignedIssueDialog = true
      }
    },

    deleteAssignedIssue(): void {
      if (this.selectedRows.length > 0 && this.userId) {
        let issueIds = this.selectedRows.map(
          (data: VegetationIssueResult) => data.id
        )
        if (issueIds.length > 0) {
          //@ts-ignore
          this.$rpc('callDeleteAssignedIssue', {
            delete_issue_ids: issueIds,
            client_field_user_id: this.userId
          }).then(() => {
            this.deleteAssignedIssueDialog = false
            this.$emit('requestUpdate')
          })
        }
      }
    }
  },
  created() {
    this.userId = Number(this.$route.params.id)
    let user = this.$typedStore.getters.usersArray.find(
      (user) => user.id === this.userId
    )
    if (user) {
      let mostRecentFollowups = getListOfMostRecentFollowups(this.vegeIssueData)

      this.userName = user.user_name
      //@ts-ignore
      let assignedIssues: VegetationIssueResult[] = user.assignedIssues
      let tableData: VegetationIssueResult[] = []
      if (assignedIssues && assignedIssues.length > 0) {
        tableData = mostRecentFollowups.filter(
          (entry1: VegetationIssueResult) =>
            entry1.has_issue &&
            assignedIssues.some(
              (entry2: VegetationIssueResult) =>
                entry1.id === entry2.id ||
                entry1.original_issue_id === entry2.id
            )
        )
      }

      this.assignedIssuesTableData = tableData
    } else {
      this.assignedIssuesTableData = []
    }
  }
})
interface assignedIssuesData {
  headers: DataTableHeader[]
  deleteAssignedIssueDialog: boolean
  selectedRows: VegetationIssueResult[]
  userName: string
  userId: null | number
  assignedIssuesTableData: VegetationIssueResult[]
}
