
































































































import Vue from 'vue'
import DoughnutChart from '../components/DoughnutChart.vue'
import BarChart from '../components/BarChart.vue'

import {
  sub,
  differenceInMilliseconds,
  eachWeekOfInterval,
  isAfter,
  isBefore,
  format,
  parseISO
} from 'date-fns'
import { now } from 'lodash'
import { ChartData, ChartDataset } from 'chart.js'

import { getListOfMostRecentFollowups } from '../util/IssueHelpers'
import { VegetationIssueResult } from '../veg-common/apiTypes'
import {
  noRiskColour,
  lowRiskColour,
  medRiskColour,
  highRiskColour,
  hexToRgba
} from '../util/drawing'
import { IsMobileDevice } from '../util/ParseUserAgent'

export default Vue.extend({
  components: {
    DoughnutChart,
    BarChart
  },
  props: {
    vegeIssueData: {
      type: Array as () => VegetationIssueResult[],
      default: (): VegetationIssueResult[] => []
    }
  },
  data(): DashboardI {
    return {}
  },
  computed: {
    allLatestIssues(): VegetationIssueResult[] {
      return getListOfMostRecentFollowups(this.vegeIssueData)
    },
    isMobileDevice(): boolean {
      return IsMobileDevice()
    },
    noRiskIssueCount(): number {
      let noRiskIssues = this.allLatestIssues.filter(
        (issue: VegetationIssueResult) => {
          return issue.has_issue && issue.risk_score === 0
        }
      )
      return noRiskIssues.length
    },
    lowRiskIssueCount(): number {
      let lowRiskIssues = this.allLatestIssues.filter(
        (issue: VegetationIssueResult) => {
          return issue.has_issue && issue.risk_score === 1
        }
      )
      return lowRiskIssues.length
    },
    medRiskIssueCount(): number {
      let medRiskIssues = this.allLatestIssues.filter(
        (issue: VegetationIssueResult) => {
          return issue.has_issue && issue.risk_score === 5
        }
      )
      return medRiskIssues.length
    },
    highRiskIssueCount(): number {
      let highRiskIssues = this.allLatestIssues.filter(
        (issue: VegetationIssueResult) => {
          return issue.has_issue && issue.risk_score === 10
        }
      )
      return highRiskIssues.length
    },
    followUpsRequired(): number {
      let followUpsRequired = this.allLatestIssues.filter(
        (issue: VegetationIssueResult) => {
          return issue.has_issue && issue.action_required === true
        }
      )
      return followUpsRequired.length
    },
    resolvedThreeMonths(): number {
      let threeMonthsDate = sub(now(), { months: 3 })
      let resolvedIssues = this.allLatestIssues.filter(
        (issue: VegetationIssueResult) => {
          let inRange = differenceInMilliseconds(
            new Date(issue.inspection_date),
            threeMonthsDate
          )
          return issue.has_issue === false && inRange >= 0
        }
      )
      return resolvedIssues.length
    },
    assignedCompanyIssues(): VegetationIssueResult[] {
      let assignedCompanyIssues: VegetationIssueResult[] = []
      for (let company of this.$typedStore.getters.relatedThirdPartyCompanies) {
        assignedCompanyIssues = assignedCompanyIssues.concat(
          company.company.vegetationIssues
        )
      }
      return assignedCompanyIssues
    },
    assignedUserIssues(): VegetationIssueResult[] {
      let assignedUserIssues: VegetationIssueResult[] = []
      for (let user of this.$typedStore.getters.usersArray) {
        if (user.userRoleId === 3) {
          assignedUserIssues = assignedUserIssues.concat(
            user.assignedIssues as VegetationIssueResult[]
          )
        }
      }
      return assignedUserIssues
    },
    unassignedIssues(): number {
      let unassignedIssues: VegetationIssueResult[] = []

      unassignedIssues = this.allLatestIssues.filter(
        (entry1: VegetationIssueResult) =>
          entry1.has_issue &&
          !this.assignedCompanyIssues.some(
            (entry2: VegetationIssueResult) =>
              entry1.id === entry2.id || entry1.original_issue_id === entry2.id
          ) &&
          !this.assignedUserIssues.some(
            (entry2: VegetationIssueResult) =>
              entry1.id === entry2.id || entry1.original_issue_id === entry2.id
          )
      )

      return unassignedIssues.length
    },
    assignedIssueChartData(): ChartData {
      return {
        labels: [
          'Assigned To a Third Party',
          'Assigned To Field Users',
          'Unassigned'
        ],
        datasets: [
          {
            backgroundColor: [
              this.$vuetify.theme.currentTheme.ajmBluePaletteGreen?.toString(),
              this.$vuetify.theme.currentTheme.ajmBluePalettePurple?.toString(),
              this.$vuetify.theme.currentTheme.ajmBluePaletteRed?.toString()
            ],
            data: [
              this.assignedCompanyIssues.length,
              this.assignedUserIssues.length,
              this.unassignedIssues
            ]
          }
        ]
      }
    },
    unresolvedIssueBreakdown(): ChartData {
      return {
        labels: ['No Risk', 'Low Risk', 'Medium Risk', 'High Risk'],
        datasets: [
          {
            backgroundColor: [
              hexToRgba(noRiskColour.toString(), 0.8),
              hexToRgba(lowRiskColour.toString(), 0.8),
              hexToRgba(medRiskColour.toString(), 0.8),
              hexToRgba(highRiskColour.toString(), 0.8)
            ],
            hoverBackgroundColor: [
              hexToRgba(noRiskColour.toString(), 100),
              hexToRgba(lowRiskColour.toString(), 100),
              hexToRgba(medRiskColour.toString(), 100),
              hexToRgba(highRiskColour.toString(), 100)
            ],
            data: [
              this.noRiskIssueCount,
              this.lowRiskIssueCount,
              this.medRiskIssueCount,
              this.highRiskIssueCount
            ]
          }
        ]
      }
    },
    newIssuesOverTime(): ChartData {
      let now = new Date()
      let eightWeeksPrior = sub(now, { weeks: 8 })

      let startOfWeekList = eachWeekOfInterval(
        {
          start: eightWeeksPrior,
          end: now
        },
        { weekStartsOn: 1 } //work week starts on Monday, 0 index is Sunday
      )

      let labels: string[] = []

      let datasets: ChartDataset[] = [
        {
          label: 'No Risk',
          backgroundColor: hexToRgba(noRiskColour.toString(), 0.8),
          hoverBackgroundColor: hexToRgba(noRiskColour.toString(), 100),
          borderWidth: 2,
          borderColor: '#FFFFFF',
          data: [],
          stack: `stack`
        },
        {
          label: 'Low Risk',
          backgroundColor: hexToRgba(lowRiskColour.toString(), 0.8),
          hoverBackgroundColor: hexToRgba(lowRiskColour.toString(), 100),
          borderWidth: 2,
          borderColor: '#FFFFFF',
          data: [],
          stack: `stack`
        },
        {
          label: 'Medium Risk',
          backgroundColor: hexToRgba(medRiskColour.toString(), 0.8),
          hoverBackgroundColor: hexToRgba(medRiskColour.toString(), 100),
          borderWidth: 2,
          borderColor: '#FFFFFF',
          data: [],
          stack: `stack`
        },
        {
          label: 'High Risk',
          backgroundColor: hexToRgba(highRiskColour.toString(), 0.8),
          hoverBackgroundColor: hexToRgba(highRiskColour.toString(), 100),
          borderWidth: 2,
          borderColor: '#FFFFFF',
          data: [],
          stack: `stack`
        }
      ]

      for (let i = 0; i < startOfWeekList.length; i++) {
        let noRiskIssueData = this.allLatestIssues.filter((iss) => {
          if (i === startOfWeekList.length - 1) {
            return (
              isAfter(parseISO(iss.inspection_date), startOfWeekList[i]) &&
              iss.risk_score === 0
            )
          } else {
            return (
              isAfter(parseISO(iss.inspection_date), startOfWeekList[i]) &&
              isBefore(parseISO(iss.inspection_date), startOfWeekList[i + 1]) &&
              iss.risk_score === 0
            )
          }
        })

        let lowRiskIssueData = this.allLatestIssues.filter((iss) => {
          if (i === startOfWeekList.length - 1) {
            return (
              isAfter(parseISO(iss.inspection_date), startOfWeekList[i]) &&
              iss.risk_score === 1
            )
          } else {
            return (
              isAfter(parseISO(iss.inspection_date), startOfWeekList[i]) &&
              isBefore(parseISO(iss.inspection_date), startOfWeekList[i + 1]) &&
              iss.risk_score === 1
            )
          }
        })

        let mediumRiskIssueData = this.allLatestIssues.filter((iss) => {
          if (i === startOfWeekList.length - 1) {
            return (
              isAfter(parseISO(iss.inspection_date), startOfWeekList[i]) &&
              iss.risk_score === 5
            )
          } else {
            return (
              isAfter(parseISO(iss.inspection_date), startOfWeekList[i]) &&
              isBefore(parseISO(iss.inspection_date), startOfWeekList[i + 1]) &&
              iss.risk_score === 5
            )
          }
        })

        let highRiskIssueData = this.allLatestIssues.filter((iss) => {
          if (i === startOfWeekList.length - 1) {
            return (
              isAfter(parseISO(iss.inspection_date), startOfWeekList[i]) &&
              iss.risk_score === 10
            )
          } else {
            return (
              isAfter(parseISO(iss.inspection_date), startOfWeekList[i]) &&
              isBefore(parseISO(iss.inspection_date), startOfWeekList[i + 1]) &&
              iss.risk_score === 10
            )
          }
        })

        datasets[0].data.push(noRiskIssueData.length)
        datasets[1].data.push(lowRiskIssueData.length)
        datasets[2].data.push(mediumRiskIssueData.length)
        datasets[3].data.push(highRiskIssueData.length)

        labels.push(`Week of ${format(startOfWeekList[i], 'MMM do')}`)
      }

      return {
        labels,
        datasets
      }
    }
  },
  methods: {
    routeToIssues(urlParams: string) {
      this.$router.push('/vegetation-issues' + urlParams)
    }
  }
})
interface DashboardI {}
