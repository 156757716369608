import {
  IsString,
  IsInt,
  IsDefined,
  IsNotEmpty,
  ArrayNotEmpty,
  IsArray,
  IsOptional
} from 'class-validator'
import { VegetationIssueResult } from './vegetationIssues'
import { UserObject } from './newResultTypes'
export class GetThirdPartyCompanyResponse {
  constructor(x: GetThirdPartyCompanyResponse) {
    if (x) {
      this.id = x.id
      this.company_name = x.company_name
      this.company_tag = x.company_tag
      this.company_type = x.company_type
      this.vegetationIssues = x.vegetationIssues
    }
  }
  @IsInt()
  @IsDefined()
  id!: number

  @IsString()
  @IsNotEmpty()
  company_name!: string

  @IsString()
  company_tag!: string

  @IsString()
  company_type!: string

  @IsArray()
  vegetationIssues!: VegetationIssueResult[]
}

export class GetThirdPartyCompanyRelationsResponse {
  constructor(x: GetThirdPartyCompanyRelationsResponse) {
    if (x) {
      this.companyId = x.company.id
      this.company = x.company
      this.userId = x.userId
      this.admin_email = x.admin_email
    }
  }

  @IsInt()
  @IsDefined()
  companyId!: number

  company!: GetThirdPartyCompanyResponse

  userId!: UserObject

  @IsString()
  admin_email!: string
}

export class SaveThirdPartyCompanyRequest {
  constructor(x: SaveThirdPartyCompanyRequest) {
    if (x) {
      this.third_party_company_ids = x.third_party_company_ids
    }
  }
  @ArrayNotEmpty()
  third_party_company_ids!: number[]
}

export class SaveAssignedIssuesRequest {
  constructor(x: SaveAssignedIssuesRequest) {
    if (x) {
      this.original_issue_ids = x.original_issue_ids
      this.company_id = x.company_id
    }
  }
  @ArrayNotEmpty()
  original_issue_ids!: number[]

  @IsInt()
  company_id!: number
}

export class TableSaveAssignedIssuesRequest {
  constructor(x: TableSaveAssignedIssuesRequest) {
    if (x) {
      this.original_issue_id = x.original_issue_id
      this.company_ids = x.company_ids
    }
  }
  @IsInt()
  original_issue_id!: number

  @IsArray()
  company_ids!: number[]
}

export class DeleteThirdPartyCompanyRequest {
  constructor(x: DeleteThirdPartyCompanyRequest) {
    if (x) {
      this.third_party_company_ids = x.third_party_company_ids
    }
  }
  @IsArray()
  third_party_company_ids!: number[]
}

export class TransferIssuesRequest {
  constructor(x: TransferIssuesRequest) {
    if (x) {
      this.transfer_issue_ids = x.transfer_issue_ids
      this.transfer_to_company_ids = x.transfer_to_company_ids
      this.transfer_from_company_id = x.transfer_from_company_id
    }
  }
  @IsArray()
  transfer_issue_ids!: number[]
  @IsArray()
  transfer_to_company_ids!: number[]
  @IsInt()
  transfer_from_company_id!: number
}

export class DeleteAssignedIssueRequest {
  constructor(x: DeleteAssignedIssueRequest) {
    if (x) {
      this.delete_issue_ids = x.delete_issue_ids
      this.third_party_company_id = x.third_party_company_id
      this.client_field_user_id = x.client_field_user_id
    }
  }
  @IsArray()
  delete_issue_ids!: number[]
  @IsOptional()
  @IsInt()
  third_party_company_id!: number
  @IsOptional()
  @IsInt()
  client_field_user_id!: number
}
