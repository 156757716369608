import { Feature, feature } from '@turf/helpers'
import { Point, LineString, MultiLineString, Polygon } from 'geojson'
import pointToLineDistance from '@turf/point-to-line-distance'
import polygonToLine from '@turf/polygon-to-line'
import { Position } from '@capacitor/geolocation'

import { ManagementAreaResult } from '../veg-common/apiTypes/managementAreas'

export function sortManagementAreas(
  position: Position,
  managementAreas: ManagementAreaResult[]
): ManagementAreaSelectOption[] {
  let userPoint: Point = {
    type: 'Point',
    coordinates: [position.coords.longitude, position.coords.latitude]
  }
  let copyOfManagementAreas = managementAreas.map(
    (managementArea: ManagementAreaResult) => {
      return { ...managementArea, distance: 0 }
    }
  )
  for (let i = 0; i < copyOfManagementAreas.length; i++) {
    let convertedLine = polygonToLine(
      copyOfManagementAreas[i].shape as Polygon
    ) as Feature<LineString | MultiLineString>
    let parsedLine: Feature<LineString>
    let distanceToArea: number = 0

    if (convertedLine && convertedLine.geometry) {
      if (convertedLine.geometry.type === 'MultiLineString') {
        let outsideLine: LineString = {
          type: 'LineString',
          coordinates: convertedLine.geometry.coordinates[0]
        }
        parsedLine = feature(outsideLine)
      } else {
        //pointToLinestring would be the LineString, parse it so pointToLineDistance can succeed
        parsedLine = convertedLine as Feature<LineString>
      }

      if (userPoint && parsedLine.geometry) {
        distanceToArea = pointToLineDistance(userPoint, parsedLine.geometry)
      }
      copyOfManagementAreas[i].distance = distanceToArea
    }
  }

  return copyOfManagementAreas.sort(
    (a: ManagementAreaSelectOption, b: ManagementAreaSelectOption) =>
      a.distance - b.distance
  )
}

interface ManagementAreaSelectOption extends ManagementAreaResult {
  distance: number
}
