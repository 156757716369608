import Vue from 'vue'
import VueRouter from 'vue-router'

import AddCulvert from '../views/CulvertLogic/AddCulvert.vue'
import AddNewVegeIssue from '../views/VegeIssueView/AddNewVegeIssue.vue'
import AddCulvertInspection from '../views/CulvertLogic/CulvertInspections/AddCulvertInspection.vue'
import Dashboard from '../views/Dashboard.vue'
import EditCulvert from '../views/CulvertLogic/EditCulvert.vue'
import Admin from '../views/Admin.vue'
import Alerts from '../views/SystemSetup/Alerts.vue'
import AssetView from '../views/AssetView.vue'
import Home from '../views/Home.vue'
import LoggedInWrapper from '../LoggedInWrapper.vue'
import Users from '../views/SystemSetup/Users.vue'
import ThirdPartyCompanies from '../views/ThirdPartyCompanies.vue'
import VegetationIssues from '../views/VegeIssueView.vue'
import SubAssetsTable from '../views/AssetSetup/SubAssets/SubAssetsTable.vue'
import AddSubAsset from '../views/AssetSetup/SubAssets/AddSubAsset.vue'
import EditSubAsset from '../views/AssetSetup/SubAssets/EditSubAsset.vue'
import ManagementAreasList from '../views/AssetSetup/SubAssets/ManagementAreasList.vue'
import VegetationIssuesEdit from '../views/VegeIssueView/Edit.vue'
import CulvertInspectionsEdit from '../views/CulvertLogic/CulvertInspections/EditCulvertInspection.vue'
import CulvertInspectionsTable from '../views/CulvertLogic/CulvertInspectionsTable.vue'
import CulvertView from '../views/CulvertLogic/CulvertView.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ResetPasswordForm from '../views/ResetPasswordForm.vue'
import SendFeedback from '../views/SendFeedback.vue'
import SpeciesCustomization from '../views/AssetSetup/Species/SpeciesCustomization.vue'
import AssignIssues from '../views/AssignIssues.vue'
import SpeciesList from '../views/AssetSetup/Species/SpeciesList.vue'
import ThirdPartyCompanyAssignedIssuesList from '../views/ThirdPartyCompanyAssignedIssuesList.vue'
import ThirdPartyCompanyAssignIssues from '../views/ThirdPartyCompanyAssignIssues.vue'
import UserAssignedIssueList from '../views/UserAssignedIssueList.vue'
import UserAssignIssues from '../views/UserAssignIssues.vue'
import SetLSDGrid from '../views/SystemSetup/SetLSDGrid.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reset-password-form',
    name: 'Reset Password Form',
    component: ResetPasswordForm
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/',
    component: LoggedInWrapper,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/overview',
        name: 'Asset Overview',
        component: AssetView,
        //@ts-ignore
        beforeEnter: (to, from, next) => {
          if (store.getters.managementAreas.length === 1) {
            next({ name: 'Vegetation Issues' })
          } else {
            next()
          }
        }
      },
      {
        path: '/alerts',
        name: 'Alerts',
        component: Alerts
      },
      {
        path: '/users',
        name: 'Users',
        component: Users
      },
      {
        path: '/set-lsd-grid',
        name: 'Set LSD Grid',
        component: SetLSDGrid
      },
      // {
      //   path: '/assign-assets',
      //   name: 'Assign Assets',
      //   component: AssignAssets
      // },
      {
        path: '/assign-issues',
        name: 'Assign Issues',
        component: AssignIssues
      },
      {
        path: '/service-providers',
        name: 'Service Providers',
        component: ThirdPartyCompanies
      },
      {
        path: '/vegetation-issues',
        name: 'Vegetation Issues',
        component: VegetationIssues
      },
      {
        path: '/sub-assets',
        name: 'Sub Assets',
        component: SubAssetsTable
      },
      {
        path: '/add-sub-asset',
        name: 'Add Sub Asset',
        component: AddSubAsset
      },
      {
        path: '/edit-sub-asset/:id',
        name: 'Edit Sub Asset',
        component: EditSubAsset
      },
      {
        path: '/management-areas-list/:id',
        name: 'Management Areas List',
        component: ManagementAreasList
      },
      {
        path: '/vegetation-issues/add',
        name: 'Add Issue',
        component: AddNewVegeIssue
      },
      {
        path: '/culvert-inspections/add',
        name: 'Add Culvert Inspection',
        component: AddCulvertInspection,
        //@ts-ignore
        beforeEnter: (to, from, next) => {
          if (
            store.state.appData.company &&
            store.state.appData.company.company_type === 'client'
          ) {
            next()
          } else if (to.query && to.query.siteCulvertId) {
            next()
          } else {
            next('/culvert-inspections')
          }
        }
      },
      {
        path: '/culvert/add',
        name: 'Add Culvert',
        component: AddCulvert,
        //@ts-ignore
        beforeEnter: (to, from, next) => {
          if (
            store.state.appData.company &&
            store.state.appData.company.company_type === 'client'
          ) {
            next()
          } else {
            next('/culvert-asset-view')
          }
        }
      },
      {
        path: '/culverts/edit/:id',
        name: 'Edit Culvert',
        component: EditCulvert,
        //@ts-ignore
        beforeEnter: (to, from, next) => {
          if (
            store.state.appData.company &&
            store.state.appData.company.company_type === 'client'
          ) {
            next()
          } else {
            next('/culvert-asset-view')
          }
        }
      },
      {
        path: '/vegetation-issues/edit/:id',
        name: 'Edit Vegetation Issue',
        component: VegetationIssuesEdit,
        //@ts-ignore
        beforeEnter: (to, from, next) => {
          if (
            store.state.appData.company &&
            store.state.appData.company.company_type === 'client'
          ) {
            next()
          } else {
            next('/vegetation-issues')
          }
        }
      },
      {
        path: '/culvert-inspections/edit/:id',
        name: 'Edit Culvert Inspection',
        component: CulvertInspectionsEdit,
        //@ts-ignore
        beforeEnter: (to, from, next) => {
          if (
            store.state.appData.company &&
            store.state.appData.company.company_type === 'client'
          ) {
            next()
          } else {
            next('/culvert-inspections')
          }
        }
      },

      {
        path: '/feedback',
        name: 'Send Feedback',
        component: SendFeedback
      },
      {
        path: '/species-customization',
        name: 'Species Customization',
        component: SpeciesCustomization
      },
      {
        path: '/species-list/:id',
        name: 'Species List',
        component: SpeciesList
      },
      {
        path: '/service-provider-issue-list/:id',
        name: 'Service Provider Assigned Issue List',
        component: ThirdPartyCompanyAssignedIssuesList
      },
      {
        path: '/service-provider-assign-issues/:id',
        name: 'Service Provider Assign Issue',
        component: ThirdPartyCompanyAssignIssues
      },
      {
        path: '/user-issue-list/:id',
        name: 'User Assigned Issue List',
        component: UserAssignedIssueList
      },
      {
        path: '/user-assign-issues/:id',
        name: 'User Assign Issues',
        component: UserAssignIssues
      },
      {
        path: '/culvert-inspections',
        name: 'Culvert Inspections',
        component: CulvertInspectionsTable
      },
      {
        path: '/culvert-asset-view',
        name: 'Culvert Asset View',
        component: CulvertView
      }
    ]
  },
  {
    path: '*',
    name: 'Everything Else',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.SITE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  let noTokenRoutes = ['Home', 'Reset Password Form', 'Admin', 'Reset Password']
  if (
    !store.getters.isLoggedIn &&
    to.name &&
    !noTokenRoutes.includes(to.name)
  ) {
    //route to the login page if the user is not logged in and trying to access a different page
    next({ name: 'Home' })
    return
  } else if (store.getters.isLoggedIn && !store.state.dataLoadedFromCache) {
    //if the user is logged in but the data has not been loaded, do it now to pass any specific router guards
    await store.dispatch('loadCache')
    store.state.dataLoadedFromCache = true
  }
  //user is either not logged in and routing to 'Home' already
  //or the user is logged in and the data has either just been loaded, or was loaded before
  next()
})

export default router
