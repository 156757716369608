

















import Vue from 'vue'
import MapComponent from '../../components/MapComponent.vue'
import {
  VegetationIssueResult,
  SubAssetResult
} from '../../veg-common/apiTypes'
import { Point } from 'geojson'
import { getListOfMostRecentFollowups } from '../../util/IssueHelpers'

export default Vue.extend({
  components: {
    MapComponent
  },
  props: {
    filteredVegeIssueData: {
      type: Array as () => VegetationIssueResult[],
      default: (): VegetationIssueResult[] => []
    },
    vegeIssueData: {
      type: Array as () => VegetationIssueResult[],
      default: (): VegetationIssueResult[] => []
    },
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    }
  },
  data(): VegeIssueViewMap {
    return {
      mapIsLoaded: false,
      mostRecentFollowUpList: []
    }
  },
  methods: {
    mapLoaded(loadedSuccessfully: boolean): void {
      // This gets run after initializeMap emits "mapLoaded"
      // So we don't try to add markers before the map is ready
      this.mapIsLoaded = loadedSuccessfully
      if (loadedSuccessfully) this.drawPointsOnMap()
    },
    drawPointsOnMap(): void {
      this.$typedStore.commit('setSnackbarParams', {
        type: 'info',
        msg: 'Drawing data onto map.',
        timeout: -1 //flag for v-snackbar to make the snackbar indefinite
      })

      try {
        //@ts-ignore
        this.$refs.mapComp.removeAllMarkers()
        if (this.filteredVegeIssueData.length > 0) {
          //store the followup issue ids that have already been drawn on the map to avoid duplicate drawing
          let drawnIssueIds: number[] = []

          for (let issue of this.filteredVegeIssueData) {
            let mostRecent = this.mostRecentFollowUpList.find(
              (iss) =>
                iss &&
                (iss.id === issue.id || iss.id === issue.original_issue_id)
            )

            if (mostRecent && mostRecent.has_issue) {
              let issueDrawn = drawnIssueIds.find(
                (iss) =>
                  iss === mostRecent?.id ||
                  iss === mostRecent?.original_issue_id
              )

              if (!issueDrawn) {
                //@ts-ignore
                this.$refs.mapComp.drawIssueMarker(
                  issue.gps_point as Point,
                  {
                    clickable: true,
                    showLabel: true
                  },
                  mostRecent
                )
                drawnIssueIds.push(
                  issue.original_issue_id ? issue.original_issue_id : issue.id
                )
              }
            }
          }

          //@ts-ignore
          this.$refs.mapComp.markerCluster()
        }
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnAsset()
      } catch (e) {
        console.log(e)
      }
      this.$typedStore.commit('resetSnackbar')
    }
  },
  watch: {
    filteredVegeIssueData() {
      if (this.mapIsLoaded) {
        this.drawPointsOnMap()
      }
    }
  },
  created() {
    this.mostRecentFollowUpList = getListOfMostRecentFollowups(
      this.vegeIssueData
    )
  }
})

interface VegeIssueViewMap {
  mapIsLoaded: boolean
  mostRecentFollowUpList: (VegetationIssueResult | null)[]
}
