import { BaseEntity } from './BaseEntity'
import { Entity, Column } from 'typeorm'
import {
  EntitiesWithPhotos,
  CreatePhotoRequest
} from '../../veg-common/apiTypes'
import { IsEnum, IsString, Length } from 'class-validator'

@Entity()
export class CreatePhotoRequestEntity extends BaseEntity {
  constructor(x?: CreatePhotoRequest, base64?: string) {
    super()
    if (x && base64) {
      this.uuid = x.uuid
      this.targetType = x.targetType
      this.parentUuid = x.parentUuid
      this.fileType = x.fileType
      this.photoBase64 = base64
    }
  }

  toApiType(): { photoRequest: CreatePhotoRequest; photo: string } {
    return {
      photoRequest: {
        uuid: this.uuid,
        targetType: this.targetType,
        parentUuid: this.parentUuid,
        fileType: this.fileType
      },
      photo: this.photoBase64
    }
  }

  @Column({ type: 'text', unique: true })
  @IsString()
  @Length(36, 36)
  uuid!: string

  @Column('text')
  @IsEnum(EntitiesWithPhotos)
  targetType!: EntitiesWithPhotos

  @Column('text')
  @IsString()
  @Length(36, 36)
  parentUuid!: string

  @Column('text')
  @IsString()
  fileType!: string

  @Column('text')
  @IsString()
  photoBase64!: string
}
