


















































































































































































import Vue from 'vue'
import { DataTableHeader } from 'vuetify/types'
import { isoToHumanDateTime } from '../../util/date-time'
import {
  VegetationIssueResult,
  GetThirdPartyCompanyResponse
} from '../../veg-common/apiTypes'
import VegeIssueInfoDialog from '../VegeIssueView/VegeIssueInfoDialog.vue'
import { IsMobileDevice } from '../../util/ParseUserAgent'
// @ts-ignore
import * as json2csv from 'json2csv'
import { riskToColour } from '../../util/drawing'
import { format as dfnFormat } from 'date-fns'
import { feature, featureCollection, Feature } from '@turf/helpers'
import _pick from 'lodash/pick'
import { LineString, Point } from 'geojson'
// @ts-ignore
import tokml from 'tokml'

export default Vue.extend({
  components: {
    VegeIssueInfoDialog
  },
  props: {
    filteredVegeIssueData: {
      type: Array as () => VegetationIssueResult[],
      default: (): VegetationIssueResult[] => []
    }
  },
  data(): VegeIssueTable {
    return {
      selectedRows: [],
      tableExpansionPanel: false,
      dialog: false,
      issueToDuplicate: null,
      selectedDeleteIssue: null,
      deleteIssueDialog: false,
      assignIssueDialog: false,
      ThirdPartyCompanySelectOptions: [],
      selectedThirdPartyCompanies: [],
      selectedAssignIssue: null
    }
  },
  watch: {
    selectedRows() {
      this.$emit('issuesSelected', this.selectedRows.length > 0)
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      let mobileHeadersList: DataTableHeader[] = []
      if (IsMobileDevice()) {
        mobileHeadersList = [
          {
            text: 'Synced',
            value: 'synced_status',
            width: '10%',
            class: 'font-weight-black white--text'
          }
        ]
      }

      let fullHeadersList: DataTableHeader[] = [
        {
          text: 'Risk Score',
          value: 'risk_score',
          width: '10%',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Area Name',
          value: 'area',
          width: '10%',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Date Inspected',
          value: 'inspection_date',
          class: 'font-weight-black white--text'
        },
        {
          text: 'VegLogic Id',
          value: 'originalIssueId',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Issue Type',
          value: 'issue_type',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Details',
          value: 'detailsDialog',
          sortable: false,
          width: '5%',
          class: 'font-weight-black white--text'
        },
        //TODO: when multi select delete comes in, width of 9% is good width for the 3 icons
        {
          text: 'Actions',
          value: 'actions',
          filterable: false,
          sortable: false,
          width: '11%',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Edit',
          value: 'edit',
          filterable: false,
          sortable: false,
          width: '5%'
        }
      ]
      return mobileHeadersList.concat(fullHeadersList)
    },
    tableExpansionPanelHeader(): string {
      return this.tableExpansionPanel ? 'Hide Issues' : 'Show Issues'
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    isClientUser(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 1
      }
      return false
    }
  },
  methods: {
    openAssignIssueDialog(item: VegetationIssueResult): void {
      let originalIssue = item.original_issue_id
        ? this.filteredVegeIssueData.find(
            (issue) =>
              !issue.original_issue_id && issue.id === item.original_issue_id
          )
        : item
      for (let company of this.ThirdPartyCompanySelectOptions) {
        for (let issue of company.vegetationIssues) {
          if (originalIssue && issue.id === originalIssue.id) {
            this.selectedThirdPartyCompanies.push(company)
          }
        }
      }
      this.selectedAssignIssue = item
      this.assignIssueDialog = true
    },
    closeAssignIssueDialog(): void {
      this.selectedThirdPartyCompanies = []
      this.selectedAssignIssue = null
      this.assignIssueDialog = false
    },
    async getThirdPartyCompanies(): Promise<void> {
      try {
        this.ThirdPartyCompanySelectOptions = await this.$rpc(
          'callGetThirdPartyCompanies'
        )
      } catch (e) {
        console.log(e)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error retrieving data.'
        })
      }
    },
    async assignIssue(): Promise<void> {
      let companyIds: number[] = []
      this.selectedThirdPartyCompanies.map((c: GetThirdPartyCompanyResponse) =>
        companyIds.push(c.id)
      )
      let issueId: number
      if (this.selectedAssignIssue) {
        issueId =
          this.selectedAssignIssue && this.selectedAssignIssue.original_issue_id
            ? this.selectedAssignIssue.original_issue_id
            : this.selectedAssignIssue.id
        try {
          this.$rpc('callTableSaveAssignedIssues', {
            original_issue_id: issueId,
            company_ids: companyIds
          }).then(() => this.getThirdPartyCompanies())
        } catch (e) {
          console.log(e)
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Error saving data.'
          })
        }
      }
      this.assignIssueDialog = false
      this.selectedThirdPartyCompanies = []
    },
    deleteSelectedIssues() {
      if (this.selectedRows.length === 0) {
        this.$emit('errorMessage')
        return
      }
      this.deleteIssueDialog = true
    },
    downloadKml(): void {
      let dataForDownload = this.filteredVegeIssueData.filter(
        (issue) => issue.isInteractive
      )
      if (this.selectedRows.length > 0) {
        dataForDownload = this.selectedRows
      }

      const pickMetadata = (x: VegetationIssueResult) => {
        return _pick(x, [
          'id',
          'inspection_date',
          'has_issue',
          'risk_score',
          'area_size',
          'action_required',
          'comments',
          'distribution',
          'physiological_stage',
          'issue_type',
          'vegetation_type',
          'proximity'
        ])
      }

      const features: Feature[] = dataForDownload.flatMap((x) => {
        let colour = riskToColour(x.risk_score)

        if (x.gps_point && x.gps_track) {
          return [
            feature(
              x.gps_point as Point,
              { 'marker-color': colour, ...pickMetadata(x) },
              { id: x.id }
            ),
            feature(
              x.gps_track as LineString,
              { stroke: colour, ...pickMetadata(x) },
              { id: x.id }
            )
          ]
        } else if (x.gps_point) {
          return [
            feature(
              x.gps_point as Point,
              { 'marker-color': colour, ...pickMetadata(x) },
              { id: x.id }
            )
          ]
        } else if (x.gps_track) {
          return [
            feature(
              x.gps_track as LineString,
              { stroke: colour, ...pickMetadata(x) },
              { id: x.id }
            )
          ]
        } else {
          return []
        }
      })

      const kml = tokml(featureCollection(features), {
        documentName: 'Vegetation Issue Export',
        documentDescription:
          'Issues exported at ' + dfnFormat(new Date(), 'yyyy-MM-dd HH:mm'),
        name: 'id',
        timestamp: 'inspection_date',
        simplestyle: true
      })

      let link = document.createElement('a')
      link.setAttribute(
        'href',
        'data:application/vnd.google-earth.kml+xml;charset=utf-8,' +
          encodeURIComponent(kml)
      )
      link.setAttribute('download', 'issues.kml')
      link.click()
    },
    downloadCsv(): void {
      let dataForDownload = this.filteredVegeIssueData.filter(
        (issue) => issue.isInteractive
      )
      if (this.selectedRows.length > 0) {
        dataForDownload = this.selectedRows
      }

      let fields = [
        { label: 'Area Name', value: 'area_name' },
        { label: 'Inspected Date', value: 'inspection_date' },
        { label: 'VegLogic Id', value: 'issue_id' },
        { label: 'Issue Type', value: 'issue_type' },
        { label: 'Risk Score', value: 'risk_score' },
        { label: 'Original Issue', value: 'original_issue' },
        { label: 'Proximities', value: 'proximity' },
        { label: 'Action Taken', value: 'action_taken' },
        { label: 'Physiological Stage', value: 'physiological_stage' },
        { label: 'Infra vegetation', value: 'vegetation_type' },
        { label: 'Distribution', value: 'distribution' },
        { label: 'Action Required', value: 'actions_required' },
        { label: 'Initiator', value: 'initiator' },
        { label: 'Species of Weeds', value: 'species_of_weeds' },
        { label: 'Comments', value: 'comments' }
      ]
      let vegeIssueCsvData = []
      for (let selectedIssueData of dataForDownload) {
        let DataForCsv: { [key: string]: number | string } = {}
        DataForCsv.area_name = selectedIssueData.area
        DataForCsv.inspection_date = isoToHumanDateTime(
          selectedIssueData.inspection_date
        )
        selectedIssueData.issue_type
          ? (DataForCsv.issue_type = selectedIssueData.issue_type)
          : (DataForCsv.issue_type = 'N/A')
        switch (selectedIssueData.risk_score) {
          case 0:
            DataForCsv.risk_score = 'No Risk'
            break
          case 1:
            DataForCsv.risk_score = 'Low Risk'
            break
          case 5:
            DataForCsv.risk_score = 'Medium Risk'
            break
          case 10:
            DataForCsv.risk_score = 'High Risk'
            break
        }
        selectedIssueData.proximity
          ? (DataForCsv.proximity = selectedIssueData.proximity)
          : (DataForCsv.proximity = 'N/A')
        selectedIssueData.action_taken
          ? (DataForCsv.action_taken = selectedIssueData.action_taken)
          : (DataForCsv.action_taken = 'N/A')
        selectedIssueData.physiological_stage
          ? (DataForCsv.physiological_stage =
              selectedIssueData.physiological_stage)
          : (DataForCsv.physiological_stage = 'N/A')
        selectedIssueData.vegetation_type
          ? (DataForCsv.vegetation_type = selectedIssueData.vegetation_type)
          : (DataForCsv.vegetation_type = 'N/A')
        selectedIssueData.distribution
          ? (DataForCsv.distribution = selectedIssueData.distribution)
          : (DataForCsv.distribution = 'N/A')
        selectedIssueData.original_issue_id
          ? (DataForCsv.original_issue = 'No')
          : (DataForCsv.original_issue = 'Yes')
        DataForCsv.initiator = selectedIssueData.user
        selectedIssueData.original_issue_id
          ? (DataForCsv.issue_id = selectedIssueData.original_issue_id)
          : (DataForCsv.issue_id = selectedIssueData.id)
        selectedIssueData.actionsRequired &&
        selectedIssueData.actionsRequired.length > 0
          ? (DataForCsv.actions_required =
              selectedIssueData.actionsRequired.join())
          : (DataForCsv.actions_required = 'N/A')
        selectedIssueData.species && selectedIssueData.species.length > 0
          ? (DataForCsv.species_of_weeds = selectedIssueData.species.join())
          : (DataForCsv.species_of_weeds = 'N/A')
        selectedIssueData.comments
          ? (DataForCsv.comments = selectedIssueData.comments)
          : (DataForCsv.comments = 'N/A')
        vegeIssueCsvData.push(DataForCsv)
      }
      let parser = new json2csv.Parser({ fields, excelStrings: true })
      let csv = parser.parse(vegeIssueCsvData)
      let blob = new Blob([csv], { type: 'application/json' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `Vegetation Issue Export.csv`
      link.click()
    },
    async deleteIssue(): Promise<boolean> {
      let items = this.selectedRows
      let deletePromises = []
      for (let item of items) {
        if (item && 'id' in item) {
          // Updates UI right away for the user without having to wait for
          // the api response to complete.
          this.$typedStore.state.assetData.issues[item.id].hidden = true
          deletePromises.push(this.$rpc('callDeleteIssue', { id: item.id }))
        }
      }
      await Promise.all(deletePromises)
      this.$emit('requestUpdate')
      this.selectedRows = []
      this.deleteIssueDialog = false
      return true
    },
    formatInspectionDate(date: string) {
      return isoToHumanDateTime(date)
    },
    riskScoreColor(item: VegetationIssueResult): string {
      return riskToColour(item.risk_score, item.isInteractive)
    },
    duplicateIssue(item: VegetationIssueResult): void {
      if ('id' in item) {
        this.$router.push({
          path: '/vegetation-issues/add',
          query: {
            duplicate: item.id.toString()
          }
        })
      }
    },
    editIssue(item: VegetationIssueResult): void {
      if ('id' in item) {
        this.$router.push(`/vegetation-issues/edit/${item.id}`)
      }
    },
    addFollowUp(item: VegetationIssueResult): void {
      if ('id' in item) {
        this.$router.push({
          path: '/vegetation-issues/add',
          query: {
            followUpIssueId: item.original_issue_id
              ? item.original_issue_id.toString()
              : item.id.toString()
          }
        })
      }
    }
  },
  created() {
    this.getThirdPartyCompanies()
  }
})

interface VegeIssueTable {
  selectedRows: VegetationIssueResult[]
  tableExpansionPanel: boolean
  dialog: boolean
  issueToDuplicate: null | VegetationIssueResult
  selectedDeleteIssue: null | VegetationIssueResult
  deleteIssueDialog: boolean
  assignIssueDialog: boolean
  selectedThirdPartyCompanies: GetThirdPartyCompanyResponse[]
  ThirdPartyCompanySelectOptions: GetThirdPartyCompanyResponse[]
  selectedAssignIssue: null | VegetationIssueResult
}
