



























































import Vue from 'vue'
import { preprocessImage } from '../util/imageProcessing'

export default Vue.extend({
  data(): MultiFileUploadI {
    return {
      dragAndDropCapable: false,
      dragging: false,
      uploading: false,
      uploadProgress: 0,
      fileError: false,
      files: []
    }
  },
  props: {
    numPhotosAllowed: {
      default: 8,
      type: Number as () => number
    },
    numPhotosSelected: {
      default: 0,
      type: Number as () => number
    }
  },
  computed: {
    maxPhotosHit(): boolean {
      //since numPhotosSelected isn't dynamic to what was added in this upload,
      //we want to add the currently selected photos and what was already uploaded before
      return this.numPhotosSelected + this.files.length >= this.numPhotosAllowed
    }
  },
  watch: {
    uploadProgress(): void {
      if (this.uploadProgress > 0) {
        this.uploading = true
      } else {
        this.uploading = false
      }
    }
  },
  methods: {
    addFiles(uploadedFiles: FileList): void {
      for (let i: number = 0; i < uploadedFiles.length; i++) {
        if (i < this.numPhotosAllowed && !this.maxPhotosHit) {
          this.files.push(uploadedFiles[i])
        }
      }
    },
    removeFile(index: number): void {
      this.files.splice(index, 1)
    },
    determineDragAndDropCapable(): boolean {
      // Create a test element to see if the browser supports drag/drop
      var div = document.createElement('div')

      /*
                Check to see if the `draggable` event is in the element
                or the `ondragstart` and `ondrop` events are in the element. If
                they are, then we have what we need for dragging and dropping files.

                We also check to see if the window has `FormData` and `FileReader` objects
                present so we can do our AJAX uploading
            */
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      )
    },
    async savePhotos(): Promise<void> {
      this.$emit('imagesProcessing', true)

      for (let i: number = 0; i < this.files.length; i++) {
        try {
          const processedImage = await preprocessImage(this.files[i])
          await this.$emit('addToInput', processedImage)
        } catch (error) {
          console.log(error)
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Error loading photo.'
          })
          this.$emit('imagesProcessing', false)
          break
        }
      }
      this.$emit('imagesProcessing', false)
    },
    clearFileList(): void {
      this.files = []
    }
  },
  mounted(): void {
    let app = this
    // Determine if drag and drop functionality is capable in the browser
    app.dragAndDropCapable = app.determineDragAndDropCapable()

    // If drag and drop capable, then we continue to bind events to our elements.
    if (app.dragAndDropCapable) {
      // Listen to all of the drag events and bind an event listener to each for the fileform.
      ;[
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop'
      ].forEach(
        function (evt: string) {
          // For each event add an event listener that prevents the default action
          // (opening the file in the browser) and stop the propagation of the event (so
          // no other elements open the file in the browser)
          //@ts-ignore
          app.$refs.fileForm.addEventListener(
            evt,
            function (e: Event) {
              e.preventDefault()
              e.stopPropagation()
              if (evt === 'drag') {
                app.dragging = true
              }
            }.bind(app),
            false
          )
        }.bind(app)
      )

      // Add an event listener for drop to the form
      //@ts-ignore
      app.$refs.fileForm.addEventListener(
        'drop',
        function (e: DragEvent) {
          if (e.dataTransfer) {
            // Capture the files from the drop event and add them to our local files array.
            app.addFiles(e.dataTransfer.files)
          }
        }.bind(app)
      )
    }
  }
})

interface MultiFileUploadI {
  dragAndDropCapable: boolean
  dragging: boolean
  uploading: boolean
  uploadProgress: number
  fileError: boolean
  files: Blob[]
}
