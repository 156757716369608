// default options = {
//  enableHighAccuracy: false,
//  timeout: infinity,
//  maximumAge: 0
// }

export default {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
}
