import { MigrationInterface, QueryRunner } from 'typeorm'

export class AddIsInteractive1678728498756 implements MigrationInterface {
  public async up(queryRunner: QueryRunner): Promise<void> {
    let entity = await queryRunner.hasTable('vegetation_issues_result_entity')
    if (entity === false) {
      console.log(
        'vegetation_issues_result_entity table not created yet - bail out of migration'
      )
      return
    }
    await queryRunner.query(
      `ALTER TABLE vegetation_issues_result_entity
        ADD COLUMN "isInteractive" BOOLEAN DEFAULT TRUE`
    )
  }

  public async down(queryRunner: QueryRunner): Promise<void> {
    await queryRunner.query(
      'ALTER TABLE vegetation_issues_result_entity DROP COLUMN "isInteractive"'
    )
  }
}
