







































































import Vue from 'vue'
import MultiFileUpload from './MultiFileUpload.vue'
import { IsMobileDevice } from '../util/ParseUserAgent'
import { blobToBase64 } from '../util/binaryData'

export default Vue.extend({
  components: {
    MultiFileUpload
  },
  data(): CameraViewI {
    return {
      loading: true,
      photosForDisplay: [],
      photoUploadDialog: false,
      isMobileDevice: IsMobileDevice()
    }
  },
  props: {
    numPhotosAllowed: {
      default: 8,
      type: Number as () => number
    },
    value: {
      default: [],
      type: Array as () => Blob[]
    },
    existingPhotos: {
      default: [],
      type: Array as () => string[]
    }
  },
  computed: {
    photosRemainingText(): string {
      return `${this.photosForDisplay.length} / ${this.numPhotosAllowed}`
    },
    photoButtonsDisabled(): boolean {
      return this.photosForDisplay.length >= this.numPhotosAllowed
    },
    takePhotoButtonText(): string {
      return this.photoButtonsDisabled
        ? `${this.numPhotosAllowed} Photo Maximum`
        : 'Take Photo'
    },
    uploadPhotoButtonText(): string {
      return this.photoButtonsDisabled
        ? `${this.numPhotosAllowed} Photo Maximum`
        : 'Upload Photo'
    }
  },
  watch: {
    photoUploadDialog(): void {
      if (!this.photoUploadDialog) {
        //@ts-ignore
        this.$refs.fileUploadComp.clearFileList()
      }
    }
  },
  methods: {
    removePhoto(index: number): void {
      if (this.photosForDisplay[index].existingPhoto) {
        this.$emit('removeExistingPhoto', this.photosForDisplay[index].key)
      } else {
        this.value.splice(index, 1)
        this.$emit('input', this.value)
      }

      this.photosForDisplay.splice(index, 1)
    },
    imagesProcessing(processing: boolean): void {
      this.$emit('photoLoading', processing)
      if (processing === false) {
        this.photoUploadDialog = false
      }
    },
    async addToInput(processedImage: Blob): Promise<void> {
      this.photosForDisplay.push({
        display: await blobToBase64(processedImage),
        key: '',
        existingPhoto: false
      })
      this.value.push(processedImage)
      this.$emit('input', this.value)
    },
    displayExistingPhotos(photos: string[]): void {
      this.photosForDisplay = []

      for (let i = 0; i < photos.length; i++) {
        this.photosForDisplay.push({
          display: `${this.$typedStore.getters.basePhotoUrl}${photos[i]}`,
          key: photos[i],
          existingPhoto: true
        })
      }
    }
  },
  created(): void {
    this.displayExistingPhotos(this.existingPhotos)
  }
})

interface CameraViewI {
  photosForDisplay: PhotoDisplay[]
  loading: boolean
  photoUploadDialog: boolean
  isMobileDevice: boolean
}

interface PhotoDisplay {
  display: string
  key: string
  existingPhoto: boolean
}
