


































































































import Vue from 'vue'
import { DataTableHeader } from 'vuetify/types'
import { AlertResponse, IdRequest } from '../../veg-common/apiTypes'
import { JwtContent } from '../../veg-common'

export default Vue.extend({
  props: {},
  data(): AlertsI {
    return {
      headers: [
        {
          text: 'Alert Name',
          value: 'alert_type',
          class: 'font-weight-black white--text'
        }
      ],
      selectedRows: [],
      saveAlertDialog: false,
      deleteAlertDialog: false,
      selectedAlertType: null
    }
  },
  computed: {
    dataForTable(): AlertResponse[] | [] {
      return this.$typedStore.getters.alerts
    },
    isThirdPartyCompany(): boolean {
      let company = this.$typedStore.getters.company
      if (company && company.company_type) {
        return company.company_type === 'thirdParty'
      } else {
        return true //if user somehow logs in without company_type return true to at least limit what the invalid user can see
      }
    },
    alertTypes(): string[] {
      let alertTypeList = this.isThirdPartyCompany
        ? ['Assigned Issue', 'Issue Deleted Notification', 'Issue Complete']
        : ['Service Provider Summary', 'New Issue']

      for (let i = 0; i < this.dataForTable.length; i++) {
        let alertTypeIndex = alertTypeList.findIndex((x) => {
          return x === this.dataForTable[i].alert_type
        })

        if (alertTypeIndex >= 0) {
          alertTypeList.splice(alertTypeIndex, 1)
        }
      }
      return alertTypeList
    }
  },
  methods: {
    async saveNewAlert(): Promise<void> {
      if (!this.selectedAlertType) return

      let result = await this.$rpc('callCreateAlert', {
        userId: (this.$typedStore.getters.jwtContent as JwtContent).data
          .userId as number,
        alert_type: this.selectedAlertType
      })

      if (result.success) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'info',
          msg: 'Alert created.'
        })
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Create alert failed, check your internet connection.'
        })
      }
      await this.updateFromApi()
      this.saveAlertDialog = false
      this.selectedAlertType = null
    },
    async removeAlert(): Promise<void> {
      try {
        if (this.selectedRows.length > 0) {
          let deleteAlertsPromises = []
          for (let alert of this.selectedRows) {
            deleteAlertsPromises.push(
              this.$rpc('callRemoveAlert', new IdRequest(alert.id))
            )
          }
          let result = await Promise.all(deleteAlertsPromises)
          if (result[result.length - 1].success) {
            this.$typedStore.commit('setSnackbarParams', {
              type: 'info',
              msg: 'Alert removed.'
            })
          } else {
            this.$typedStore.commit('setSnackbarParams', {
              type: 'error',
              msg: 'Remove alert failed, check your internet connection.'
            })
          }
        }
      } catch (e) {
        console.log(e)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error deleting selected alerts.'
        })
      }
      await this.updateFromApi()
      this.selectedRows = []
      this.deleteAlertDialog = false
    },
    openDeleteAlertDialog() {
      if (this.selectedRows.length > 0) {
        this.deleteAlertDialog = true
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No alerts selected'
        })
      }
    },
    async updateFromApi(): Promise<void> {
      // just redoing the get call here on it's own, as alerts will mostly be used when in office with internet anyways
      let alerts = await this.$rpc('callGetAlerts', {
        id: (this.$typedStore.getters.jwtContent as JwtContent).data
          .userId as number
      })
      await this.$typedStore.dispatch('setAlerts', alerts)
    }
  }
})

interface AlertsI {
  headers: DataTableHeader[]
  selectedRows: AlertResponse[]
  saveAlertDialog: boolean
  deleteAlertDialog: boolean
  selectedAlertType: string | null
}
