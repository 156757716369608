import { BaseEntity } from './BaseEntity'
import { Entity, Column } from 'typeorm'
import {
  GetThirdPartyCompanyRelationsResponse,
  GetThirdPartyCompanyResponse
} from '../../veg-common/apiTypes'
import { IsNumber, IsString } from 'class-validator'

@Entity()
export class ThirdPartyEntity extends BaseEntity {
  constructor(item?: GetThirdPartyCompanyRelationsResponse) {
    super()
    if (item) {
      this.companyId = item.company.id
      this.company = JSON.stringify(item.company)
      this.userId = JSON.stringify(item.userId)
      this.admin_email = item.admin_email
    }
  }

  toApiType(): GetThirdPartyCompanyRelationsResponse {
    let company = JSON.parse(this.company) as GetThirdPartyCompanyResponse
    return {
      companyId: company.id,
      company: company,
      userId: JSON.parse(this.userId),
      admin_email: this.admin_email
    }
  }

  @Column({ type: 'int', unique: true })
  @IsNumber()
  companyId!: number

  @Column('text')
  @IsString()
  company!: string

  @Column('text')
  @IsString()
  userId!: string

  @Column('text')
  @IsString()
  admin_email!: string
}
