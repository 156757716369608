


































































import Vue from 'vue'
import { DataTableHeader } from 'vuetify/types'
import {
  ManagementAreaResult,
  RemoveAreaFromSubAsset
} from '../../../veg-common/apiTypes'
import AreaInformationDialog from '../../../components/AreaInformationDialog.vue'
export default Vue.extend({
  components: {
    AreaInformationDialog
  },
  data(): ManagementAreaListI {
    return {
      areaDialog: false,
      deleteDialog: false,
      selectedAreaForDisplay: null,
      selectedRows: []
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      let headers: DataTableHeader[] = [
        {
          text: 'Management Areas',
          value: 'name',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Details',
          value: 'AreaDialog',
          sortable: false,
          class: 'font-weight-black white--text'
        }
      ]
      return headers
    },
    deleteText() {
      if (this.selectedRows.length > 1) {
        return 'Are you sure you want to remove the selected Sub Assets?'
      } else return 'Are you sure you want to remove the selected Sub Asset?'
    },
    areas() {
      let areas: ManagementAreaResult[] = []
      this.$typedStore.getters.managementAreas.forEach((area) => {
        if (area.subAssetId === Number(this.$route.params.id)) {
          areas.push(area)
        }
      })
      return areas
    }
  },
  methods: {
    closeDeleteSubAssetsDialog(): void {
      this.deleteDialog = false
    },
    async deleteSubAsset(): Promise<void> {
      if (this.selectedRows.length > 0 && this.$route.params.id) {
        try {
          let subAssetIds = this.selectedRows.map((data) => data.id)
          let areaList = new RemoveAreaFromSubAsset({
            areaId: subAssetIds
          })
          let result = await this.$rpc('callDeleteManagementArea', areaList)
          if (result.success) {
            this.$emit('requestUpdate')
            this.$typedStore.commit('setSnackbarParams', {
              type: 'success',
              msg: 'Selected Management Areas have been removed.'
            })
          } else {
            this.$typedStore.commit('setSnackbarParams', {
              type: 'error',
              msg: 'Remove Management Areas failed, check your internet connection.'
            })
          }
        } catch (e) {
          console.log(e)
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Error deleting selected Management Areas.'
          })
        }
      }
      this.selectedRows = []
      this.deleteDialog = false
    },
    openAreaDialog(area: ManagementAreaResult) {
      this.areaDialog = true
      this.selectedAreaForDisplay = area
    },
    openDeleteDialog(): void {
      if (this.selectedRows.length > 0) {
        this.deleteDialog = true
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No Sub Asset Selected'
        })
      }
    }
  }
})

interface ManagementAreaListI {
  areaDialog: boolean
  deleteDialog: boolean
  selectedRows: ManagementAreaResult[]
  selectedAreaForDisplay: ManagementAreaResult | null
}
