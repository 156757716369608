
































































































import Vue from 'vue'
import {
  CulvertResult,
  CulvertInspectionResult
} from '../../veg-common/apiTypes'

import CulvertAssetView from './CulvertAssetView.vue'
import CulvertTable from './CulvertTable.vue'
import CulvertFilterComponent from '../../components/CulvertFilterComponent.vue'
import { IsMobileDevice } from '../../util/ParseUserAgent'

export default Vue.extend({
  components: {
    CulvertAssetView,
    CulvertTable,
    CulvertFilterComponent
  },
  props: {
    culverts: {
      type: Array as () => CulvertResult[],
      default: (): CulvertResult[] => []
    },
    culvertInspections: {
      type: Array as () => CulvertInspectionResult[],
      default: (): CulvertInspectionResult[] => []
    }
  },
  data(): CulvertViewI {
    return {
      culvertData: [],
      rowsAreSelected: false,
      downloadDialog: false,
      filterExpansionPanel: false,
      onMobileDevice: IsMobileDevice(),
      pageSelect: 0
    }
  },
  computed: {
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    // filterPanelClass(): string {
    //   return this.filterExpansionPanel
    //     ? 'ml-0 pl-3 mt-5'
    //     : 'ml-0 pl-3 pt-0 mt-0'
    // },
    isMobileDevice(): boolean {
      return IsMobileDevice()
    },
    downloadButtonText(): string {
      return this.rowsAreSelected
        ? 'Download Selected Culverts'
        : 'Download All Filtered Culverts'
    }
  },
  watch: {
    culverts(): void {
      if (this.culverts.length > 0) {
        this.culvertData = this.culverts
      }
    },
    pageSelect(): void {
      if (this.$route.path === '/culvert-asset-view') {
        let showMap: boolean = false
        if (this.pageSelect === 0) {
          showMap = true
        }

        this.$typedStore.commit('setMapView', showMap)
      }
    }
  },
  methods: {
    downloadKml(): void {
      //@ts-ignore
      this.$refs.culvertTable
        ? //@ts-ignore
          this.$refs.culvertTable.downloadKml()
        : this.showError()
      this.downloadDialog = false
    },
    downloadCsv(): void {
      //@ts-ignore
      this.$refs.culvertTable
        ? //@ts-ignore
          this.$refs.culvertTable.downloadCsv()
        : this.showError()
      this.downloadDialog = false
    },
    openDownloadDialog() {
      this.downloadDialog = true
    },
    routeToAddCulvert() {
      this.$router.push('/culvert/add')
    },
    setRowsAreSelected(rowsAreSelected: boolean): void {
      this.rowsAreSelected = rowsAreSelected
    },
    setTableData(filteredCulvertData: CulvertResult[]): void {
      this.culvertData = filteredCulvertData
    },
    showError(): void {
      this.$typedStore.commit('setSnackbarParams', {
        type: 'error',
        msg: 'No culvert selected!'
      })
    },
    toggleExpansionPanelWidth(filterExpansionPanel: boolean): void {
      this.filterExpansionPanel = filterExpansionPanel
    }
  },
  beforeMount(): void {
    if (this.$typedStore.state.showMap) {
      this.pageSelect = 0
    } else {
      this.pageSelect = 1
    }
  },
  created(): void {
    this.culvertData = this.culverts
  }
})

interface CulvertViewI {
  downloadDialog: boolean
  rowsAreSelected: boolean
  onMobileDevice: boolean
  pageSelect: number
  culvertData: CulvertResult[]
  filterExpansionPanel: boolean
}
