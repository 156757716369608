import {
  IsBoolean,
  IsInt,
  IsNumber,
  IsString,
  IsDateString,
  IsOptional,
  ArrayUnique,
  Length
} from 'class-validator'

export class CulvertInspectionResult {
  constructor(item: CulvertInspectionResult) {
    if (item) {
      this.id = item.id
      this.photos = item.photos
      this.inspection_date = item.inspection_date
      this.next_inspection_date = item.next_inspection_date
      this.comments = item.comments
      this.risk_score = item.risk_score
      this.user = item.user
      this.culvert = item.culvert
      this.culvertStatus = item.culvertStatus
      this.actionsTaken = item.actionsTaken
      this.actionsRequired = item.actionsRequired
      this.hidden = item.hidden
    }
  }

  @IsInt()
  id!: number

  @ArrayUnique()
  photos!: string[]

  @IsDateString()
  inspection_date!: string

  @IsDateString()
  next_inspection_date!: string

  @IsString()
  comments!: string

  @IsNumber()
  risk_score!: number

  @IsString()
  user!: string

  @IsString()
  culvert!: string

  @IsString()
  culvertStatus!: string

  @IsOptional()
  @ArrayUnique()
  actionsTaken!: string[] | null

  @IsOptional()
  @ArrayUnique()
  actionsRequired!: string[] | null

  @IsBoolean()
  hidden!: boolean
}

export class CulvertInspectionParams {
  constructor(item: CulvertInspectionParams) {
    if (item) {
      this.uuid = item.uuid
      this.inspection_date = item.inspection_date
      this.next_inspection_date = item.next_inspection_date
      this.comments = item.comments
      this.risk_score = item.risk_score
      this.userId = item.userId
      this.culvertId = item.culvertId
      this.culvertUuid = item.culvertUuid
      this.culvertStatusId = item.culvertStatusId
      this.actionsTakenIds = item.actionsTakenIds
      this.actionsRequiredIds = item.actionsRequiredIds
      this.hidden = item.hidden
    }
  }

  @IsString()
  @Length(36, 36)
  uuid!: string

  @IsDateString()
  inspection_date!: string

  @IsDateString()
  next_inspection_date!: string

  @IsString()
  comments!: string

  @IsNumber()
  risk_score!: number

  @IsNumber()
  userId!: number

  @IsNumber()
  culvertId!: number

  @IsString()
  @Length(36, 36)
  @IsOptional()
  culvertUuid?: string

  @IsNumber()
  culvertStatusId!: number

  @IsBoolean()
  hidden!: boolean

  @IsOptional()
  @ArrayUnique()
  actionsTakenIds!: number[] | null

  @IsOptional()
  @ArrayUnique()
  actionsRequiredIds!: number[] | null
}

export class EditCulvertInspectionParams {
  constructor(item: EditCulvertInspectionParams) {
    if (item) {
      this.id = item.id
      this.uuid = item.uuid
      this.inspection_date = item.inspection_date
      this.next_inspection_date = item.next_inspection_date
      this.comments = item.comments
      this.risk_score = item.risk_score
      this.culvertId = item.culvertId
      this.previousCulvertId = item.previousCulvertId
      this.culvertStatusId = item.culvertStatusId
      this.actionsTakenIds = item.actionsTakenIds
      this.actionsRequiredIds = item.actionsRequiredIds
    }
  }

  @IsInt()
  id!: number

  @IsString()
  @Length(36, 36)
  uuid!: string

  @IsDateString()
  inspection_date!: string

  @IsDateString()
  next_inspection_date!: string

  @IsString()
  comments!: string

  @IsNumber()
  risk_score!: number

  @IsNumber()
  culvertId!: number

  @IsNumber()
  previousCulvertId!: number

  @IsNumber()
  culvertStatusId!: number

  @IsOptional()
  @ArrayUnique()
  actionsTakenIds!: number[] | null

  @IsOptional()
  @ArrayUnique()
  actionsRequiredIds!: number[] | null
}
