import {
  VegetationIssueParams,
  IssuePdfParams,
  IssueImageParams,
  FileResponse,
  IdRequest,
  IdResponse,
  GenericResult,
  UpdateIssueRequest
} from '../veg-common/apiTypes'

export const vegetationIssuesApiDef = {
  callGetIssuePDF: {
    route: '/vegetationIssues/pdf',
    method: 'get',
    auth: true,
    inputType: IssuePdfParams,
    outputType: FileResponse,
    returnsMultiple: false
  },
  callGetImageFile: {
    route: '/vegetationIssues/image',
    method: 'get',
    auth: true,
    inputType: IssueImageParams,
    outputType: FileResponse,
    returnsMultiple: false
  },
  callPostIssue: {
    route: '/vegetationIssues',
    method: 'post',
    auth: true,
    inputType: VegetationIssueParams,
    outputType: IdResponse,
    returnsMultiple: false
  },
  callUpdateVegetationIssue: {
    route: '/vegetationIssues/update',
    method: 'post',
    auth: true,
    inputType: UpdateIssueRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callDeleteIssue: {
    route: '/vegetationIssues/delete',
    method: 'post',
    auth: true,
    inputType: IdRequest,
    outputType: GenericResult,
    returnsMultiple: false
  }
} as const
