var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',[_c('v-row',{attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-col',{attrs:{"sm":"10"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.addNewSpeciesDialog),callback:function ($$v) {_vm.addNewSpeciesDialog=$$v},expression:"addNewSpeciesDialog"}},[_c('v-card',{staticClass:"ajmBlue"},[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" Select New Species ")]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"append-icon":"mdi-select-all","items":_vm.speciesSelectOptions,"label":"Species Selection","item-text":function (item) { return item.species + ' - ' + item.vegeType; },"item-value":"id","return-object":"","hide-details":"","chips":"","deletable-chips":"","multiple":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.species)+" -")]),_c('span',{class:data.item.vegeTypeId === 1
                        ? 'primary--text font-italic'
                        : 'secondary--text font-italic'},[_vm._v(" "+_vm._s(data.item.vegeType))])]}}]),model:{value:(_vm.selectedNewSpecies),callback:function ($$v) {_vm.selectedNewSpecies=$$v},expression:"selectedNewSpecies"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":_vm.closeAddNewSpeciesDialog}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"pr-6 pl-6 primary darken-1 accentAjmBlue--text",on:{"click":_vm.AddNewSpecies}},[_vm._v(" Submit ")]),_c('v-spacer')],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"ajmBlue",attrs:{"headers":_vm.headers,"items":_vm.assets,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.reDirect(item.id)}}},on),[_vm._v(" mdi-format-list-bulleted ")])]}}],null,true)},[_c('span',[_vm._v("Show Species List")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2 primary--text",on:{"click":function($event){return _vm.openAddNewSpeciesDialog(item)}}},on),[_vm._v(" mdi-plus-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("Add New Species")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }