

































import Vue from 'vue'
import { ManagementAreaResult, SubAssetResult } from '../veg-common/apiTypes'

import AssetViewMap from './AssetView/AssetViewMap.vue'
import AssetViewTable from './AssetView/AssetViewTable.vue'
import { IsMobileDevice } from '../util/ParseUserAgent'

export default Vue.extend({
  components: {
    AssetViewMap,
    AssetViewTable
  },
  props: {
    managementAreas: {
      type: Array as () => ManagementAreaResult[]
    },
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    }
  },
  data(): AssetViewI {
    return {
      onMobileDevice: IsMobileDevice(),
      pageSelect: 0
    }
  },
  computed: {
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  watch: {
    pageSelect(): void {
      if (
        this.$route.path === '/overview' ||
        this.$route.path === '/vegetation-issues'
      ) {
        let showMap: boolean = false
        if (this.pageSelect === 0) {
          showMap = true
        }

        this.$typedStore.commit('setMapView', showMap)
      }
    }
  },
  beforeMount(): void {
    if (this.$typedStore.state.showMap) {
      this.pageSelect = 0
    } else {
      this.pageSelect = 1
    }
  }
})

interface AssetViewI {
  onMobileDevice: boolean
  pageSelect: number
}
