










































































































import Vue from 'vue'
import MapComponent from './MapComponent.vue'
import { VuetifyThemeItem } from 'vuetify/types/services/theme'
import { Geolocation, Position } from '@capacitor/geolocation'

import positionOptions from '../util/gpsPositionOptions'
import { IsMobileDevice } from '../util/ParseUserAgent'
import { DrawablePolygon } from '../util/drawing'

import { LineString } from 'geojson'

export default Vue.extend({
  components: {
    MapComponent
  },
  props: {
    selectedManagementArea: {
      type: Number as () => number
    },
    gpsTrack: {
      type: Object as () => LineString
    }
  },
  data(): GpsPointCompI {
    return {
      mapLoadedComplete: false,
      gps_track: null,
      settingTrack: false,
      gpsLineMapDialog: false,
      watchPositionId: null,
      trackInProgress: false,
      chooseInputTypeDialog: false
    }
  },
  watch: {
    mapLoadedComplete(): void {
      if (this.mapLoadedComplete && this.gps_track) {
        //@ts-ignore
        this.$refs.mapComp.drawEditablePath(this.gps_track as LineString)
      }
    },
    selectedManagementArea() {
      this.drawSelectedArea()
    }
  },
  computed: {
    managementAreaPolygons(): DrawablePolygon[] {
      return this.$typedStore.getters.managementAreaPolygons
    },
    gpsPathButtonText(): string {
      //watch position id shows the watcher is currently active, if it's not active and the track is not null then the track is complete
      if (this.gps_track !== null && this.watchPositionId === null) {
        return 'Path Set'
      } else if (this.trackInProgress) {
        return 'Tracking A Path - Press to Stop'
      } else {
        return 'Delineate'
      }
    },
    gpsPathButtonColor(): VuetifyThemeItem | undefined {
      if (this.gps_track !== null && this.watchPositionId === null) {
        return this.$vuetify.theme.currentTheme.secondary
      } else if (this.trackInProgress) {
        return this.$vuetify.theme.currentTheme.accent
      } else {
        return this.$vuetify.theme.currentTheme.primary //default button color
      }
    },
    mapHeight(): string {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'height:60vh; width:75vw;'
      } else {
        return 'height:70vh; width:75vw;'
      }
    }
  },
  methods: {
    drawSelectedArea(): void {
      if (this.mapLoadedComplete && this.selectedManagementArea !== null) {
        let foundPolygon = this.managementAreaPolygons.find((polygon) => {
          return polygon.area.id === this.selectedManagementArea
        })
        //@ts-ignore
        this.$refs.mapComp.removeAllPolygons()
        //@ts-ignore
        this.$refs.mapComp.drawPolygon(foundPolygon, false)
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnArea(foundPolygon.area.shape)
      }
    },
    mapLoaded(loadedSuccessfully: boolean): void {
      if (loadedSuccessfully) {
        //@ts-ignore
        this.$refs.mapComp.createDrawingManager()
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnAsset()
        this.mapLoadedComplete = true
        this.drawSelectedArea()
      }
    },
    startManualDraw(): void {
      this.gpsLineMapDialog = true
    },
    async startTrackPath(): Promise<void> {
      if (IsMobileDevice()) {
        this.trackInProgress = true
        this.$emit('setTrackInProgress', this.trackInProgress)
        this.watchPositionId = await Geolocation.watchPosition(
          positionOptions,
          this.addToTrack
        )
      }
    },
    async gpsPathButtonStartStop(): Promise<void> {
      if (this.trackInProgress) {
        this.stopGpsPath()
        return
      }

      if (IsMobileDevice()) {
        this.chooseInputTypeDialog = true
      } else {
        this.startManualDraw()
      }
    },
    stopGpsPath(): void {
      this.settingTrack = true
      if (this.watchPositionId !== null) {
        Geolocation.clearWatch({ id: this.watchPositionId as string })
      }
      this.watchPositionId = null
      this.settingTrack = this.trackInProgress = false
      this.$emit('saveTrack', this.gps_track)
      this.$emit('setGpsError', false)
      this.$emit('setTrackInProgress', this.trackInProgress)
    },
    cancelManualGpsLine(): void {
      this.gps_track = null
      //@ts-ignore
      this.$refs.mapComp.removeDrawnPath()
    },
    addToTrack(position: Position | null): void {
      if (position === null) {
        return
      }
      //initialize the track if it's null
      if (this.gps_track === null) {
        this.gps_track = {
          type: 'LineString',
          coordinates: []
        }
      }
      this.gps_track.coordinates.push([
        position.coords.longitude,
        position.coords.latitude
      ])
    },
    setGpsTrack(track: LineString): void {
      this.gps_track = track
    },
    saveAndCloseDialog(): void {
      this.$emit('saveTrack', this.gps_track)
      this.$emit('setGpsError', false)
      if (this.gpsLineMapDialog) this.gpsLineMapDialog = false
    },
    handleLocationError(error: PositionError): void {
      console.log(error.message)
      //ideally there's an option for a user to tap on a point on the map to get the point as well
      //so if there was an error getting the users location, we could just offer the map and tell the user to tap
      switch (error.code) {
        case 3:
          console.log('entered timeout')
          // ...deal with timeout
          break
        case 2:
          // ...device can't get data
          break
        case 1:
          //they made it in without the gps permissions, send them back to where they were
          break
        // ...user said no
      }
    }
  },
  destroyed() {
    //make sure the watcher is killed on destroy, which will include going back in the nav stack
    if (this.watchPositionId !== null) {
      Geolocation.clearWatch({ id: this.watchPositionId as string })
    }
    this.watchPositionId = null
  },
  mounted() {
    if (this.gpsTrack) {
      this.gps_track = this.gpsTrack
    }
  }
})

interface GpsPointCompI {
  mapLoadedComplete: boolean
  gps_track: LineString | null
  settingTrack: boolean
  gpsLineMapDialog: boolean
  watchPositionId: string | null
  trackInProgress: boolean
  chooseInputTypeDialog: boolean
}
