import { IsDefined, IsInt, IsOptional, ValidateNested } from 'class-validator'

export class JwtData {
  @IsOptional()
  @IsInt()
  userId: number | null

  @IsDefined()
  isSuper: boolean

  constructor() {
    this.userId = null
    this.isSuper = false
  }
}

export class JwtContent {
  // Expiry. This is a standard jwt field.
  @IsDefined()
  @IsInt()
  exp!: number

  // Issued at. This is a standard jwt field.
  @IsDefined()
  @IsInt()
  iat!: number

  // Our custom data payload
  @IsDefined()
  @ValidateNested()
  data!: JwtData
}
