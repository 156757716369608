import {
  AlertResponse,
  CreateAlertRequest,
  IdRequest,
  GenericResult
} from '../veg-common/apiTypes'

export const alertApiDef = {
  callCreateAlert: {
    route: '/alerts/create',
    method: 'post',
    auth: true,
    inputType: CreateAlertRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callGetAlerts: {
    route: '/alerts/get',
    method: 'post',
    auth: true,
    inputType: IdRequest,
    outputType: AlertResponse,
    returnsMultiple: true
  },
  callRemoveAlert: {
    route: '/alerts/remove',
    method: 'post',
    auth: true,
    inputType: IdRequest,
    outputType: GenericResult,
    returnsMultiple: false
  }
} as const
