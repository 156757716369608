import { MigrationInterface, QueryRunner } from 'typeorm'

export class FilterSearchToSelectedValue1673903190227
  implements MigrationInterface
{
  public async up(queryRunner: QueryRunner): Promise<void> {
    let vegeFilters = await queryRunner.hasTable('vege_issue_applied_filters')
    let culvertFilters = await queryRunner.hasTable(
      'applied_culvert_filters_data_entity'
    )
    let culvertInspectionFilters = await queryRunner.hasTable(
      'applied_culvert_inspection_filters_data_entity'
    )
    if (
      vegeFilters === false &&
      culvertFilters === false &&
      culvertInspectionFilters === false
    ) {
      console.log('No filter tables created yet - bail out of migration')
      return
    }

    let hasManagementArea = await queryRunner.hasColumn(
      'vege_issue_applied_filters',
      'managementAreaSearch'
    )
    if (vegeFilters && hasManagementArea) {
      await queryRunner.renameColumn(
        'vege_issue_applied_filters',
        'managementAreaSearch',
        'managementAreaSelectedValue'
      )
    }

    let hasSiteId = await queryRunner.hasColumn(
      'applied_culvert_filters_data_entity',
      'siteIdSearch'
    )
    if (culvertFilters && hasSiteId) {
      await queryRunner.renameColumn(
        'applied_culvert_filters_data_entity',
        'siteIdSearch',
        'siteIdSelectedValue'
      )
    }

    let hasCulvertSearch = await queryRunner.hasColumn(
      'applied_culvert_inspection_filters_data_entity',
      'culvertSearch'
    )
    if (culvertInspectionFilters && hasCulvertSearch) {
      await queryRunner.renameColumn(
        'applied_culvert_inspection_filters_data_entity',
        'culvertSearch',
        'culvertSelectedValue'
      )
    }
  }

  public async down(queryRunner: QueryRunner): Promise<void> {}
}
