




































































































































































































































































































































































































































































































import Vue from 'vue'
import { format as dfnFormat, parseISO as dfnParseISO } from 'date-fns'
import { isoToHumanDateTime } from '../util/date-time'
import { Point } from 'geojson'

import {
  VegetationIssueResult,
  ManagementAreaResult,
  GetThirdPartyCompanyRelationsResponse
} from '../veg-common/apiTypes'
import { IsMobileDevice } from '../util/ParseUserAgent'
var utm = require('utm-latlng')

//setting the variable and exporting that is important here to prevent type checking errors,
//since this is a part of an intentional circular component usage tyepscript was complaining about export default Vue.extend being an implicit any
//setting it as any matches what we do in our test files, and lets the template also compile
const cmp: any = Vue.extend({
  components: {
    MapComponent: () => import('./MapComponent.vue')
  },
  props: {
    vegeIssue: {
      type: Object as () => VegetationIssueResult
    }
  },
  data(): IssueInformationCardI {
    return {
      mapIsLoaded: false,
      loadingPdf: false,
      downloadingImage: false,
      relatedServiceProviders: [],
      UtmErrorMessage: 'UTM data not available'
    }
  },
  computed: {
    convertedUtmComputedValue(): convertedUtmValue | undefined {
      let newUtm = new utm('GRS 1980')
      let point = this.vegeIssue.gps_point as Point
      let lat = point.coordinates[1]
      let lng = point.coordinates[0]
      // precision is the number of decimal points required in the UTM
      let precision = 0
      let convertedLatLng = newUtm.convertLatLngToUtm(lat, lng, precision)
      if (
        (convertedLatLng.ZoneNumber,
        convertedLatLng.ZoneLetter,
        convertedLatLng.Easting,
        convertedLatLng.Northing)
      ) {
        return newUtm.convertLatLngToUtm(lat, lng, precision)
      } else {
        return undefined
      }
    },
    serviceProvidersAndClientFieldUsers(): string {
      let originalIssueId = this.vegeIssue.original_issue_id
        ? this.vegeIssue.original_issue_id
        : this.vegeIssue.id
      let companyName = []
      //@ts-ignore
      for (let serviceProvider of this.relatedServiceProviders) {
        for (let issue of serviceProvider.company.vegetationIssues) {
          if (originalIssueId === issue.id) {
            companyName.push(serviceProvider.company.company_name)
          }
        }
      }
      let clientUsers = this.$typedStore.getters.usersArray.filter(
        (user) => user.userRoleId === 3
      )
      if (clientUsers.length > 0) {
        for (let user of clientUsers) {
          //@ts-ignore
          for (let issue of user.assignedIssues) {
            if (issue.id === originalIssueId) {
              if (this.$typedStore.getters.company) {
                companyName.push(
                  user.first_name +
                    ' ' +
                    user.last_name +
                    `(${this.$typedStore.getters.company.company_name})`
                )
              } else {
                companyName.push(user.first_name + ' ' + user.last_name)
              }
            }
          }
        }
      }
      return companyName.join(', ')
    },
    fieldUsersOfServiceProviders(): string {
      let userName = []
      let fieldUsers = this.$typedStore.getters.usersArray.filter(
        (user) => user.userRoleId === 3
      )
      let originalIssueId = this.vegeIssue.original_issue_id
        ? this.vegeIssue.original_issue_id
        : this.vegeIssue.id
      if (fieldUsers.length > 0) {
        for (let user of fieldUsers) {
          //@ts-ignore
          for (let issue of user.assignedIssues) {
            if (originalIssueId === issue.id) {
              userName.push(user.first_name + ' ' + user.last_name)
            }
          }
        }
      }
      return userName.join(', ')
    },
    isClientUser(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 1
      }
      return false
    },
    dateFormat(): string {
      return isoToHumanDateTime(this.vegeIssue.inspection_date)
    },
    nullcheckedSpecies(): string {
      //can't just do .join in the html, since species could be null and doing join would crash
      //however we won't ever print species unless it is of weeds type, so just doing a v-if in the html doesn't make the typescript checker happy
      return this.vegeIssue.species && this.vegeIssue.species.length > 0
        ? this.vegeIssue.species.join(' | ')
        : 'Other'
    },
    nullCheckedActionsRequired(): string {
      if (
        this.vegeIssue.actionsRequired &&
        this.vegeIssue.actionsRequired.length > 0
      ) {
        return this.vegeIssue.actionsRequired.join(' | ')
      } else {
        return 'No Required Actions'
      }
    },
    originalIssueText(): string {
      return this.vegeIssue.original_issue_id ? '' : 'Initial Record of Issue'
    },
    riskScoreText(): string {
      if (this.vegeIssue.risk_score) {
        switch (this.vegeIssue.risk_score) {
          case 1:
            return 'Low Risk'
            break
          case 5:
            return 'Medium Risk'
            break
          case 10:
            return 'High Risk'
            break
          default:
            return 'No Risk'
        }
      } else {
        return 'No Risk'
      }
    },
    //this check controls showing the 3rd row of issue data, and is all of the individual checks into one boolean
    hasAdditionalData(): boolean {
      return (
        (this.vegeIssue.physiological_stage !== null &&
          this.vegeIssue.physiological_stage !== '') ||
        (this.vegeIssue.issue_type &&
          (this.vegeIssue.issue_type === 'Weeds' ||
            this.vegeIssue.issue_type === 'Animal Attractant' ||
            this.vegeIssue.issue_type.includes('Pipe'))) ||
        (this.isClientUser &&
          this.serviceProvidersAndClientFieldUsers !== '') ||
        this.fieldUsersOfServiceProviders !== ''
      )
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    changeShowMap(showMap: boolean): void {
      this.$typedStore.commit('setMapView', showMap)
    },
    mapLoaded(loadedSuccessfully: boolean): void {
      // This gets run after initializeMap emits "mapLoaded"
      // So we don't try to add markers before the map is ready
      this.mapIsLoaded = loadedSuccessfully
      if (loadedSuccessfully) this.drawMarkerOnMap()
    },
    drawMarkerOnMap(): void {
      if (this.vegeIssue.gps_point) {
        //@ts-ignore
        this.$refs.mapComp.drawIssueMarker(
          this.vegeIssue.gps_point,
          {
            clickable: false,
            showLabel: false
          },
          this.vegeIssue
        )
      }
      if (this.vegeIssue.gps_track) {
        //@ts-ignore
        this.$refs.mapComp.drawPath(this.vegeIssue.gps_track)
      }
      let areaToZoom: ManagementAreaResult | undefined =
        this.$typedStore.getters.managementAreas.find(
          (area) => area.name === this.vegeIssue.area
        )
      if (this.vegeIssue.gps_point) {
        let point = this.vegeIssue.gps_point as Point
        //@ts-ignore
        this.$refs.mapComp.zoomMapAtPoint(
          point.coordinates[1],
          point.coordinates[0],
          15
        )
      }
      //@ts-ignore
      else if (areaToZoom) this.$refs.mapComp.zoomMapOnArea(areaToZoom.shape)
      //@ts-ignore
      else this.$refs.mapComp.zoomMapOnAsset()
    },
    async exportToPDF(): Promise<void> {
      try {
        if (this.$typedStore.getters.selectedAsset) {
          this.loadingPdf = true
          let fileRes = await this.$rpc('callGetIssuePDF', {
            assetId: this.$typedStore.getters.selectedAsset.id,
            issueId: this.vegeIssue.id
          })

          let binaryString = window.atob(fileRes.file)
          let binaryLen = binaryString.length
          let arrayBuffer = new Uint8Array(binaryLen)
          for (let i = 0; i < binaryLen; i++) {
            let char = binaryString.charCodeAt(i)
            arrayBuffer[i] = char
          }

          let blob = new Blob([arrayBuffer], {
            type: 'application/pdf'
          })

          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = dfnFormat(
            dfnParseISO(this.vegeIssue.inspection_date),
            'MMMM-do-yyyy_HH-mm'
          )
          link.click()
          link.remove()
          this.loadingPdf = false
        }
      } catch (error) {
        console.log(error)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error downloading pdf.'
        })
        this.loadingPdf = false
      }
    },
    async downloadImage(key: string, index: number): Promise<void> {
      if (IsMobileDevice()) {
        window.location.href = `${this.$typedStore.getters.basePhotoUrl}${key}`
      } else {
        this.downloadingImage = true
        try {
          let fileRes = await this.$rpc('callGetImageFile', {
            key
          })

          let binaryString = window.atob(fileRes.file)
          let binaryLen = binaryString.length
          let arrayBuffer = new Uint8Array(binaryLen)
          for (let i = 0; i < binaryLen; i++) {
            let char = binaryString.charCodeAt(i)
            arrayBuffer[i] = char
          }

          let blob = new Blob([arrayBuffer], {
            type: fileRes.contentType
          })

          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download =
            dfnFormat(
              dfnParseISO(this.vegeIssue.inspection_date),
              'MMMM-do-yyyy_HH-mm'
            ) + `-${index}`
          link.click()
          link.remove()
          this.downloadingImage = false
        } catch (error) {
          console.log(error)
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Error downloading image.'
          })
          this.downloadingImage = false
        }
      }
    },
    editIssue(vegeIssue: VegetationIssueResult): void {
      if ('id' in vegeIssue) {
        this.$router.push(`/vegetation-issues/edit/${vegeIssue.id}`)
      }
    },
    addFollowUpIssue(vegeIssue: VegetationIssueResult): void {
      if ('id' in vegeIssue) {
        this.$router.push({
          path: '/vegetation-issues/add',
          query: {
            followUpIssueId: vegeIssue.original_issue_id
              ? vegeIssue.original_issue_id.toString()
              : vegeIssue.id.toString()
          }
        })
      }
    },
    async getRelatedThirdPartyCompanies(): Promise<void> {
      try {
        this.relatedServiceProviders = await this.$rpc(
          'callGetCompanyRelations'
        )
      } catch (e) {
        console.log(e)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error retrieving data.'
        })
      }
    },
    goToFollowUpIssue(issue: VegetationIssueResult) {
      if (!issue.original_issue_id) {
        this.$router.replace({
          name: 'Vegetation Issues',
          query: {
            issueId: issue.id.toString()
          }
        })
      } else {
        this.$router.replace({
          name: 'Vegetation Issues',
          query: {
            issueId: issue.original_issue_id.toString()
          }
        })
      }
      if (this.$router.currentRoute.name === 'Vegetation Issues') {
        this.$router.go(0)
      }
    },
    goToOriginalIssue(issue: VegetationIssueResult) {
      if (issue.original_issue_id) {
        this.$router.replace({
          name: 'Vegetation Issues',
          query: {
            issueId: issue.original_issue_id.toString(),
            original: 'true'
          }
        })
        if (this.$router.currentRoute.name === 'Vegetation Issues') {
          this.$router.go(0)
        }
      }
    }
  },
  created() {
    this.getRelatedThirdPartyCompanies()
  }
})
export default cmp

interface IssueInformationCardI {
  mapIsLoaded: boolean
  loadingPdf: boolean
  downloadingImage: boolean
  relatedServiceProviders: GetThirdPartyCompanyRelationsResponse[]
  UtmErrorMessage: string
}

interface convertedUtmValue {
  ZoneNumber: number
  ZoneLetter: string
  Easting: number
  Northing: number
}
