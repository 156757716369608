import { format, formatISO, parse, parseISO } from 'date-fns'

// Conversion and parsing functions so we don't have to use formatting
// strings everywhere in the code.

// Date sorting with date-fns example:
//     let dfn = require('date-fns')
//     > [new Date(1), new Date(), new Date(100000000000)]
//     [
//       1970-01-01T00:00:00.001Z,
//       2020-06-11T21:15:14.582Z,
//       1973-03-03T09:46:40.000Z
//     ]
//     > [new Date(1), new Date(), new Date(100000000000)]
//            .sort(function(a,b){return dfn.compareDesc(a,b);})
//     [
//       2020-06-11T21:15:15.834Z,
//       1973-03-03T09:46:40.000Z,
//       1970-01-01T00:00:00.001Z
//     ]

export const dateObjToDateString = (d: Date): string => {
  return format(d, 'yyyy-MM-dd')
}
export const dateObjToTimeString = (d: Date): string => {
  return format(d, 'HH:mm')
}

export const dateTimeStringToISO = (d: string, t: string): string => {
  return formatISO(parse(d + ' ' + t, 'yyyy-MM-dd HH:mm', new Date()))
}

export const dateStringToISO = (d: string): string => {
  return formatISO(parse(d, 'yyyy-MM-dd', new Date()))
}

export const isoToHumanDate = (d: string): string => {
  return format(parseISO(d), 'MMMM d yyyy')
}

export const isoToHumanDateTime = (d: string): string => {
  return format(parseISO(d), 'MMMM d yyyy - H:mm')
}
