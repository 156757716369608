
























import Vue from 'vue'
import {
  add as dfnAdd,
  getTime as dfnGetTime,
  isAfter as dfnIsAfter,
  toDate as dfnToDate
} from 'date-fns'
import { isUnsupportedBrowser, IsMobileDevice } from '../util/ParseUserAgent'
export default Vue.extend({
  data(): BrowserTypes {
    return {
      dialog: false
    }
  },
  created() {
    if (isUnsupportedBrowser() && IsMobileDevice() === false) {
      const now = new Date()

      let addToBrowserTypesLastDate = localStorage.getItem(
        'addToBrowserTypesLastDate'
      )
      let lastCheckedDate
      if (addToBrowserTypesLastDate) {
        lastCheckedDate = dfnToDate(parseInt(addToBrowserTypesLastDate))
      }

      if (
        !addToBrowserTypesLastDate ||
        (lastCheckedDate && dfnIsAfter(now, lastCheckedDate))
      ) {
        this.dialog = true

        //after a week we want to prompt the user again
        localStorage.setItem(
          'addToBrowserTypesLastDate',
          dfnGetTime(dfnAdd(now, { days: 7 })).toString()
        )
      }
    }
  }
})

interface BrowserTypes {
  dialog: boolean
}
