import {} from 'google.maps'
import { ManagementAreaResult } from '../veg-common/apiTypes/managementAreas'
import colors from 'vuetify/lib/util/colors'

export const defaultColour = colors.grey.base // grey if no risk assigned
export const noRiskColour = colors.green.base
export const lowRiskColour = colors.yellow.base
export const medRiskColour = colors.orange.base
export const highRiskColour = colors.red.base
export const notInteractiveColour = colors.blueGrey.darken4
export const riskLowMedThreshold: number = 3 // number between low/medium
export const riskMedHighThreshold: number = 7 // number between medium/high risk
export const notInteractive: number = -20 // number used for unassigned

export interface DrawablePolygon {
  coordinates: google.maps.LatLngLiteral[]
  opacity: number
  colour: string // #XXXXXX format
  area: ManagementAreaResult
}

// isInteractive parameter is optional because there are situations where we want the text color to match the risk color, such as the veg issue cards.
export const riskToColour = (
  risk: number | null,
  isInteractive?: boolean
): string => {
  return isInteractive === false
    ? notInteractiveColour
    : risk === notInteractive
    ? notInteractiveColour
    : risk === null
    ? defaultColour
    : risk === 0
    ? noRiskColour
    : risk < riskLowMedThreshold
    ? lowRiskColour
    : risk < riskMedHighThreshold
    ? medRiskColour
    : highRiskColour
}

export const hexToRgba = (hexCode: string, opacity?: number): string => {
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  let workingOpacity = opacity ? opacity : 1
  /* Backward compatibility for whole number based opacity values. */
  if (opacity && opacity > 1 && opacity <= 100) {
    workingOpacity = opacity / 100
  }

  return `rgba(${r}, ${g}, ${b}, ${workingOpacity})`
}
