





































































































































































































































import Vue from 'vue'
import DrawGpsPointComponent from '../../components/DrawGpsPointComponent.vue'
import { Point } from 'geojson'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { NewCulvertParams } from '../../veg-common/apiTypes'
import { v4 as uuidv4 } from 'uuid'

export default Vue.extend({
  components: {
    DrawGpsPointComponent,
    ValidationObserver,
    ValidationProvider
  },
  data(): AddCulvertI {
    return {
      confirmedLeaving: false,
      culvertSaved: false,
      diameter: 0,
      directionOfFlow: '',
      exitDialog: false,
      fishHabitat: false,
      dropDownOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      gpsError: false,
      inletGpsPoint: null,
      outletGpsPoint: null,
      hasMarkers: false,
      inletLat: null,
      inletLong: null,
      length: 0,
      outletLat: null,
      outletLong: null,
      pageLoading: false,
      roadName: '',
      routerNextPath: '',
      siteCulvertId: '',
      selectedManagementArea: null
    }
  },
  computed: {
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    inletIsSet(): boolean {
      return this.inletGpsPoint ? true : false
    },
    outletIsSet(): boolean {
      return this.outletGpsPoint ? true : false
    }
  },
  methods: {
    clearEdits(): void {
      this.siteCulvertId = ''
      this.roadName = ''
      this.fishHabitat = false
      this.diameter = 0
      this.length = 0
      this.directionOfFlow = ''
      this.inletLat = null
      this.inletLong = null
      this.outletLat = null
      this.outletLong = null
      this.hasMarkers = false
      if (this.inletGpsPoint) {
        //@ts-ignore
        this.$refs.inletCulvert.cancelManualGpsPoint()
        this.inletGpsPoint = null
      }
      if (this.outletGpsPoint) {
        //@ts-ignore
        this.$refs.outletCulvert.cancelManualGpsPoint()
        this.outletGpsPoint = null
      }
    },
    exit() {
      this.exitDialog = false
      this.confirmedLeaving = true
      if (this.routerNextPath === '')
        this.routerNextPath = '/culvert-asset-view'
      this.$router.push({ path: this.routerNextPath })
    },
    async saveCulvert(): Promise<void> {
      this.pageLoading = true
      let app = this

      if (!app.$typedStore.getters.jwtContent) return
      if (!app.$typedStore.getters.jwtContent.data.userId) return

      let inlet: Point = {
        type: 'Point',
        coordinates: [
          app.inletLong ? app.inletLong : 0,
          app.inletLat ? app.inletLat : 0
        ]
      }

      let outlet: Point = {
        type: 'Point',
        coordinates: [
          app.outletLong ? app.outletLong : 0,
          app.outletLat ? app.outletLat : 0
        ]
      }

      let culvertAssetId = 0
      if (app.$typedStore.getters.selectedAsset) {
        culvertAssetId = app.$typedStore.getters.selectedAsset.id
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Culvert failed to save.'
        })
        this.pageLoading = false
        return
      }

      await app.$typedStore.dispatch('saveCulvert', {
        params: {
          is_fish_habitat: app.fishHabitat,
          site_culvert_id: app.siteCulvertId,
          road_name: app.roadName,
          has_markers: app.hasMarkers,
          length: app.length,
          diameter: app.diameter / 100, //save in db as m not cm,
          direction_of_flow: app.directionOfFlow,
          inlet_location: inlet,
          outlet_location: outlet,
          assetId: culvertAssetId,
          uuid: uuidv4()
        } as NewCulvertParams
      })

      app.culvertSaved = true
      app.$emit('requestUpdate', { edit: true })
      this.pageLoading = false
      app.$router.push('/culvert-asset-view')
    },
    inletPoint(point: Point | null): void {
      this.inletGpsPoint = point
    },
    outletPoint(point: Point | null): void {
      this.outletGpsPoint = point
    },
    setGpsError(gpsError: boolean): void {
      this.gpsError = gpsError
    },
    async validateInputs(): Promise<void> {
      //@ts-ignore
      let valid = await this.$refs.validator.validate()

      if (valid) {
        await this.saveCulvert()
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Form invalid.'
        })
      }
    }
  },
  watch: {
    inletGpsPoint() {
      if (this.inletGpsPoint) {
        this.inletLat = this.inletGpsPoint.coordinates[1]
        this.inletLong = this.inletGpsPoint.coordinates[0]
      } else {
        this.inletLat = null
        this.inletLong = null
      }
    },
    outletGpsPoint() {
      if (this.outletGpsPoint) {
        this.outletLat = this.outletGpsPoint.coordinates[1]
        this.outletLong = this.outletGpsPoint.coordinates[0]
      } else {
        this.outletLat = null
        this.outletLong = null
      }
    }
  },
  //@ts-ignore
  beforeRouteLeave(to, from, next) {
    if (to.path) {
      this.routerNextPath = to.path
    }
    if (this.culvertSaved) {
      this.exitDialog = false
      next()
    } else if (!this.culvertSaved && !this.confirmedLeaving) {
      this.exitDialog = true
      /*
        warning  [Vue warn]: Error in v-on handler (Promise/async): "Error: Navigation aborted from "/culvert-inspections/add" to "/culvert-inspections" via a navigation guard."
        is caused by the call to next(false), it is nothing to be concerned with.
      */
      next(false)
    } else if (!this.culvertSaved && this.confirmedLeaving) {
      next()
    }
  }
})

interface AddCulvertI {
  confirmedLeaving: boolean
  culvertSaved: boolean
  diameter: number
  directionOfFlow: string
  exitDialog: boolean
  fishHabitat: boolean
  dropDownOptions: [
    { text: string; value: boolean },
    { text: string; value: boolean }
  ]
  gpsError: boolean
  inletGpsPoint: Point | null
  outletGpsPoint: Point | null
  hasMarkers: boolean
  inletLat: number | null
  inletLong: number | null
  length: number
  outletLat: number | null
  outletLong: number | null
  pageLoading: boolean
  roadName: string
  routerNextPath: string
  selectedManagementArea: number | null
  siteCulvertId: string
}
