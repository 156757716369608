import { BaseEntity } from './BaseEntity'
import { Entity, Column } from 'typeorm'
import {
  AppliedFiltersData,
  AppliedCulvertInspectionFiltersData,
  AppliedCulvertFiltersData
} from '../../store/types'
import { IsString, IsOptional, IsInt } from 'class-validator'

@Entity()
export class VegeIssueAppliedFilters extends BaseEntity {
  constructor(x?: AppliedFiltersData) {
    super()
    if (x) {
      this.managementAreaSelectedValues = JSON.stringify(
        x.managementAreaSelectedValues
      )
      this.riskScoreSelectedValues = JSON.stringify(x.riskScoreSelectedValues)
      this.photoStatusSelectedValues = JSON.stringify(
        x.photoStatusSelectedValues
      )
      this.issueTypeSelectedValues = JSON.stringify(x.issueTypeSelectedValues)
      this.actionTakenSelectedValues = JSON.stringify(
        x.actionTakenSelectedValues
      )
      this.physiologicalStageSelectedValues = JSON.stringify(
        x.physiologicalStageSelectedValues
      )
      this.infraVegetationTypesSelectedValues = JSON.stringify(
        x.infraVegetationTypesSelectedValues
      )
      this.distributionSelectedValues = JSON.stringify(
        x.distributionSelectedValues
      )
      this.proximitySelectedValues = JSON.stringify(x.proximitySelectedValues)
      this.originalIssueSelectedValues = JSON.stringify(
        x.originalIssueSelectedValues
      )
      this.interactiveSelectedValue = x.interactiveSelectedValue

      this.commentSearch = x.commentSearch

      if (x.issueIdSearch) {
        this.issueIdSearch = x.issueIdSearch.toString()
      } else if (x.issueIdSearch === null) {
        this.issueIdSearch = null
      } else {
        this.issueIdSearch = null
      }

      this.dates = JSON.stringify(x.dates)
      this.selectedInitiators = JSON.stringify(x.selectedInitiators)
      this.selectedWeedSpecies = JSON.stringify(x.selectedWeedSpecies)
      this.selectedActionsRequired = JSON.stringify(x.selectedActionsRequired)
      this.subAssetSelectedValues = JSON.stringify(x.subAssetSelectedValues)
      this.selectedAssignedUsers = JSON.stringify(x.selectedAssignedUsers)
      this.selectedServiceCompanies = JSON.stringify(x.selectedServiceCompanies)
    }
  }

  toApiType(): AppliedFiltersData {
    let idSearch: number | null = null
    if (this.issueIdSearch) {
      idSearch = Number(this.issueIdSearch)
    } else if (this.issueIdSearch === null) {
      idSearch = null
    }

    return {
      managementAreaSelectedValues: JSON.parse(
        this.managementAreaSelectedValues
      ),
      riskScoreSelectedValues: JSON.parse(this.riskScoreSelectedValues),
      photoStatusSelectedValues: JSON.parse(this.photoStatusSelectedValues),
      issueTypeSelectedValues: JSON.parse(this.issueTypeSelectedValues),
      actionTakenSelectedValues: JSON.parse(this.actionTakenSelectedValues),
      physiologicalStageSelectedValues: JSON.parse(
        this.physiologicalStageSelectedValues
      ),
      infraVegetationTypesSelectedValues: JSON.parse(
        this.infraVegetationTypesSelectedValues
      ),
      distributionSelectedValues: JSON.parse(this.distributionSelectedValues),
      proximitySelectedValues: JSON.parse(this.proximitySelectedValues),
      originalIssueSelectedValues: JSON.parse(this.originalIssueSelectedValues),
      issueIdSearch: idSearch,
      interactiveSelectedValue: this.interactiveSelectedValue,
      commentSearch: this.commentSearch,
      dates: JSON.parse(this.dates) as string[],
      selectedInitiators: JSON.parse(this.selectedInitiators),
      selectedWeedSpecies: JSON.parse(this.selectedWeedSpecies),
      selectedActionsRequired: JSON.parse(this.selectedActionsRequired),
      subAssetSelectedValues: JSON.parse(this.subAssetSelectedValues),
      selectedAssignedUsers: JSON.parse(this.selectedAssignedUsers),
      selectedServiceCompanies: JSON.parse(this.selectedServiceCompanies)
    }
  }

  @Column({ type: 'text', default: '[]' })
  @IsString()
  managementAreaSelectedValues!: string

  @Column('text')
  @IsString()
  dates!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  riskScoreSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  photoStatusSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  issueTypeSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  actionTakenSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  physiologicalStageSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  infraVegetationTypesSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  distributionSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  proximitySelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  originalIssueSelectedValues!: string

  @Column('text')
  @IsString()
  selectedInitiators!: string

  @Column('text')
  @IsString()
  selectedWeedSpecies!: string

  @Column('text')
  @IsString()
  selectedActionsRequired!: string

  @Column({ type: 'text', nullable: true, default: null })
  @IsString()
  @IsOptional()
  issueIdSearch!: string | null

  @Column({ type: 'text', default: '[]' })
  @IsString()
  subAssetSelectedValues!: string

  @Column({ type: 'text', default: '' })
  @IsString()
  commentSearch!: string

  @Column({ type: 'integer', nullable: true, default: 'null' })
  @IsInt()
  @IsOptional()
  interactiveSelectedValue!: number | null

  @Column({ type: 'text', default: '[]' })
  @IsString()
  selectedAssignedUsers!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  selectedServiceCompanies!: string
}

@Entity()
export class AppliedCulvertInspectionFiltersDataEntity extends BaseEntity {
  constructor(x?: AppliedCulvertInspectionFiltersData) {
    super()
    if (x) {
      this.culvertSelectedValues = JSON.stringify(x.culvertSelectedValues)
      this.inspectionDateRange = JSON.stringify(x.inspectionDateRange)
      this.nextInspectionDateRange = JSON.stringify(x.nextInspectionDateRange)
      this.riskScoreSelectedValues = JSON.stringify(x.riskScoreSelectedValues)
      this.culvertStatusSelectedValues = JSON.stringify(
        x.culvertStatusSelectedValues
      )
      this.photoStatusSelectedValues = JSON.stringify(
        x.photoStatusSelectedValues
      )
      this.commentSearch = x.commentSearch
      this.selectedInitiators = JSON.stringify(x.selectedInitiators)
      this.selectedActionsTaken = JSON.stringify(x.selectedActionsTaken)
      this.selectedActionsRequired = JSON.stringify(x.selectedActionsRequired)
    }
  }

  toApiType(): AppliedCulvertInspectionFiltersData {
    return {
      culvertSelectedValues: JSON.parse(this.culvertSelectedValues),
      inspectionDateRange: JSON.parse(this.inspectionDateRange),
      nextInspectionDateRange: JSON.parse(this.nextInspectionDateRange),
      riskScoreSelectedValues: JSON.parse(this.riskScoreSelectedValues),
      culvertStatusSelectedValues: JSON.parse(this.culvertStatusSelectedValues),
      photoStatusSelectedValues: JSON.parse(this.photoStatusSelectedValues),
      selectedInitiators: JSON.parse(this.selectedInitiators),
      selectedActionsTaken: JSON.parse(this.selectedActionsTaken),
      selectedActionsRequired: JSON.parse(this.selectedActionsRequired),
      commentSearch: this.commentSearch
    }
  }

  @Column({ type: 'text', default: '[]' })
  @IsString()
  @IsOptional()
  culvertSelectedValues!: string

  @Column('text')
  @IsString()
  inspectionDateRange!: string

  @Column('text')
  @IsString()
  nextInspectionDateRange!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  @IsOptional()
  riskScoreSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  @IsOptional()
  culvertStatusSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  photoStatusSelectedValues!: string

  @Column('text')
  @IsString()
  selectedInitiators!: string

  @Column('text')
  @IsString()
  selectedActionsTaken!: string

  @Column('text')
  @IsString()
  selectedActionsRequired!: string

  @Column({ type: 'text', default: '' })
  @IsString()
  commentSearch!: string
}

@Entity()
export class AppliedCulvertFiltersDataEntity extends BaseEntity {
  constructor(x?: AppliedCulvertFiltersData) {
    super()
    if (x) {
      this.siteIdSelectedValues = JSON.stringify(x.siteIdSelectedValues)
      this.diameterRange = JSON.stringify(x.diameterRange)
      this.lengthRange = JSON.stringify(x.lengthRange)
      this.roadNameSearch = x.roadNameSearch
      this.culvertStatusSelectedValues = JSON.stringify(
        x.culvertStatusSelectedValues
      )
      this.fishHabitatSelectedValues = JSON.stringify(
        x.fishHabitatSelectedValues
      )
      this.markersSelectedValues = JSON.stringify(x.markersSelectedValues)
      this.riskScoreSelectedValues = JSON.stringify(x.riskScoreSelectedValues)
    }
  }

  toApiType(): AppliedCulvertFiltersData {
    return {
      siteIdSelectedValues: JSON.parse(this.siteIdSelectedValues),
      diameterRange: JSON.parse(this.diameterRange),
      lengthRange: JSON.parse(this.lengthRange),
      roadNameSearch: this.roadNameSearch,
      culvertStatusSelectedValues: JSON.parse(this.culvertStatusSelectedValues),
      fishHabitatSelectedValues: JSON.parse(this.fishHabitatSelectedValues),
      markersSelectedValues: JSON.parse(this.markersSelectedValues),
      riskScoreSelectedValues: JSON.parse(this.riskScoreSelectedValues)
    }
  }

  @Column({ type: 'text', default: '[]' })
  @IsString()
  @IsOptional()
  siteIdSelectedValues!: string

  @Column('text')
  @IsString()
  diameterRange!: string

  @Column('text')
  @IsString()
  lengthRange!: string

  @Column('text')
  @IsString()
  roadNameSearch!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  @IsOptional()
  culvertStatusSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  fishHabitatSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  markersSelectedValues!: string

  @Column({ type: 'text', default: '[]' })
  @IsString()
  @IsOptional()
  riskScoreSelectedValues!: string
}
