import { CulvertResult, CulvertInspectionResult } from '../veg-common/apiTypes'
import { parseISO } from 'date-fns'

export function getLatestCulvertInspection(
  culvert: CulvertResult
): CulvertInspectionResult | null {
  let dfn = require('date-fns')

  culvert.culvertInspections = culvert.culvertInspections.sort(function (
    inspection1,
    inspection2
  ) {
    return dfn.compareDesc(
      parseISO(inspection1.inspection_date),
      parseISO(inspection2.inspection_date)
    )
  })

  return culvert.culvertInspections[0] ? culvert.culvertInspections[0] : null
}
