































































































































































import Vue from 'vue'
import { DataTableHeader } from 'vuetify/types'
import {
  CulvertResult,
  CulvertInspectionResult
} from '../../veg-common/apiTypes'
import { isoToHumanDateTime, isoToHumanDate } from '../../util/date-time'
import CulvertInspectionFilterComponent from '../../components/CulvertInspectionFilterComponent.vue'
import CulvertInfoDialog from './CulvertInfoDialog.vue'
import { IsMobileDevice } from '../../util/ParseUserAgent'

export default Vue.extend({
  components: {
    CulvertInfoDialog,
    CulvertInspectionFilterComponent
  },
  props: {
    culvertInspections: {
      type: Array as () => CulvertInspectionResult[],
      default: (): CulvertInspectionResult[] => []
    },
    culverts: {
      type: Array as () => CulvertResult[],
      default: (): CulvertResult[] => []
    }
  },
  data(): CulvertInspectionTableI {
    return {
      dataForTable: [],
      deleteInspectionDialog: false,
      disableDeleteButton: true,
      selectedRows: [],
      tableExpansionPanel: false
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      let mobileHeadersList: DataTableHeader[] = []
      if (this.isMobileDevice) {
        mobileHeadersList = [
          {
            text: 'Synced',
            value: 'synced_status',
            width: '10%',
            class: 'font-weight-black white--text'
          }
        ]
      }

      let fullHeadersList: DataTableHeader[] = [
        {
          text: 'Culvert ID',
          align: 'start',
          value: 'culvert',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Culvert Status',
          value: 'culvertStatus',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Date of Inspection',
          value: 'inspection_date',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Next Date of Inspection',
          value: 'next_inspection_date',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Details',
          value: 'details',
          sortable: false,
          width: '5%',
          align: 'center',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '5%', //will need to increase with service providers update
          class: 'font-weight-black white--text'
        },
        {
          text: '',
          value: 'edit',
          filterable: false,
          sortable: false,
          width: '5%'
        }
      ]

      return fullHeadersList.concat(mobileHeadersList)
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    tableExpansionPanelHeader(): string {
      return this.tableExpansionPanel ? 'Hide Inspections' : 'Show Inspections'
    },
    isClientUser(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 1
      }
      return false
    },
    isMobileDevice(): boolean {
      return IsMobileDevice()
    }
  },
  methods: {
    async deleteInspection(): Promise<boolean> {
      let rowItems = this.selectedRows
      let deletePromises = []
      for (let item of rowItems) {
        if (item && 'id' in item) {
          this.$typedStore.state.assetData.culvertInspections[item.id].hidden =
            true
          deletePromises.push(
            this.$rpc('callDeleteCulvertInspection', { id: item.id })
          )
        }
      }
      await Promise.all(deletePromises)
      this.$emit('requestUpdate')
      this.selectedRows = []
      this.deleteInspectionDialog = false
      return true
    },
    deleteSelectedInspection() {
      if (this.selectedRows.length === 0) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No issues selected!'
        })
        return
      }
      this.deleteInspectionDialog = true
    },
    formatInspectionDate(date: string): string {
      return isoToHumanDateTime(date)
    },
    formatNextInspectionDate(date: string): string {
      return isoToHumanDate(date)
    },
    routeToAddNewInspection() {
      this.$router.push('/culvert-inspections/add')
    },
    addFollowUp(item: CulvertInspectionResult) {
      if ('culvert' in item) {
        this.$router.push({
          path: '/culvert-inspections/add',
          query: {
            siteCulvertId: item.culvert
          }
        })
      }
    },
    setTableData(filteredCulvertData: CulvertInspectionResult[]): void {
      this.dataForTable = filteredCulvertData
    },
    editInspection(inspection: CulvertInspectionResult): void {
      if ('id' in inspection) {
        this.$router.push(`/culvert-inspections/edit/${inspection.id}`)
      }
    }
  },
  watch: {
    selectedRows() {
      this.selectedRows.length
        ? (this.disableDeleteButton = false)
        : (this.disableDeleteButton = true)
    }
  },
  created() {
    this.dataForTable = this.culvertInspections
  }
})

interface CulvertInspectionTableI {
  dataForTable: CulvertInspectionResult[]
  deleteInspectionDialog: boolean
  disableDeleteButton: boolean
  selectedRows: CulvertInspectionResult[]
  tableExpansionPanel: boolean
}
