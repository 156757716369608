var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',[_c('v-row',{staticClass:"mb-0"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"8"}},[_c('v-btn',{attrs:{"x-small":"","text":"","to":"/service-providers"}},[_vm._v(" < Back To All Service Providers")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"4"}},[_vm._v(" Assign Issues To "+_vm._s(_vm.companyName)+" ")])],1),_c('v-row',{staticClass:"ma-0"},[_c('VegeIssueFilterComponent',{attrs:{"vegeIssueData":_vm.assignableIssueData,"subAssetData":_vm.subAssetData,"relations":_vm.relations,"managementAreas":_vm.managementAreas},on:{"setIssueData":_vm.setIssueData}})],1),_c('v-data-table',{staticClass:"ajmBlue",attrs:{"headers":_vm.headers,"items":_vm.filteredDataForTable,"show-select":""},scopedSlots:_vm._u([{key:"item.riskColor",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"x-large":"","dark":"","color":_vm.setRiskColour(item)}},[_vm._v("mdi-circle-small")])]}},{key:"item.originalIssueId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.originalIssueId === null ? item.id : item.originalIssueId)+" ")]}},{key:"item.data-table-select",fn:function(props){return [_c('v-simple-checkbox',{attrs:{"value":props.isSelected,"ripple":false},on:{"input":function($event){return props.select($event)}}})]}},{key:"item.inspection_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatInspectionDate(item.inspection_date)))])]}},{key:"item.issue_type",fn:function(ref){
var item = ref.item;
return [(item.issue_type)?_c('span',[_vm._v(_vm._s(item.issue_type))]):_c('span',[_vm._v("No Problem Found")])]}},{key:"item.detailsDialog",fn:function(ref){
var item = ref.item;
return [_c('VegeIssueInfoDialog',{attrs:{"vegeIssue":item}})]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-right",attrs:{"md":"4"}},[_c('v-btn',{attrs:{"text":"","to":"/service-providers"}},[_vm._v(" Cancel ")])],1),_c('v-col',{staticClass:"text-left",attrs:{"md":"4"}},[_c('v-btn',{staticClass:"pl-8 pr-8 primary accentAjmBlue--text",on:{"click":_vm.openAssignIssuesDialog}},[_vm._v(" Assign ")])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.assignIssuesDialog),callback:function ($$v) {_vm.assignIssuesDialog=$$v},expression:"assignIssuesDialog"}},[_c('v-card',{staticClass:"ajmBlue"},[_c('v-card-title',{staticClass:"headline grey lighten-2 justify-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert-plus-outline")]),_vm._v(" Assign Issues ")],1),_c('v-divider'),_c('v-card-text',{staticClass:"mt-2 pt-2"},[_vm._v(" Are you sure you want to assign selected issues to "+_vm._s(_vm.companyName)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":function($event){_vm.assignIssuesDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"primary accentAjmBlue--text",on:{"click":_vm.assignIssues}},[_vm._v(" Assign ")]),_c('v-spacer')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }