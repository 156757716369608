




































































































import Vue from 'vue'
import { DataTableHeader } from 'vuetify/types'
import { SpeciesRes } from '../../../veg-common/apiTypes'
import { AssetResult } from '../../../veg-common/apiTypes/asset'
export default Vue.extend({
  data(): SpeciesCustomizationI {
    return {
      search: '',
      addNewSpeciesDialog: false,
      selectedNewSpecies: [],
      selectedAssetId: null,
      assetSpeciesListsData: []
    }
  },
  computed: {
    isAdmin(): boolean {
      return this.$typedStore.getters.isAdminRole
    },
    headers(): DataTableHeader[] {
      let headers: DataTableHeader[] = [
        {
          text: 'Asset Name',
          value: 'asset_name',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Actions',
          value: 'actions',
          filterable: false,
          sortable: false,
          class: 'font-weight-black white--text'
        }
      ]
      return headers
    },
    assets() {
      let assets = []
      for (let key in this.$typedStore.state.appData.assets) {
        assets.push(this.$typedStore.state.appData.assets[key])
      }
      return assets
    },
    speciesData(): SpeciesRes[] {
      if (this.assetSpeciesListsData && this.selectedAssetId) {
        let filteredData = this.assetSpeciesListsData.find(
          (list: SpeciesListsI) => list.id === this.selectedAssetId
        )
        if (filteredData) {
          return filteredData.vegetationSpecies
        } else {
          return []
        }
      } else {
        return []
      }
    },
    speciesSelectOptions(): SpeciesRes[] {
      let species: SpeciesRes[] =
        this.$typedStore.getters.relations.species.filter(
          (entry1: SpeciesRes) =>
            !this.speciesData.some(
              (entry2: SpeciesRes) => entry1.id === entry2.id
            )
        )
      let speciesWithType = species.map((data) => {
        let vegeType = this.$typedStore.getters.relations.issueTypes.find(
          (type) => type.id === data.vegeTypeId
        )

        return {
          ...data,
          vegeType: vegeType?.issue_type
        }
      })
      return speciesWithType
    }
  },
  methods: {
    openAddNewSpeciesDialog(item: AssetResult): void {
      this.selectedAssetId = item.id
      this.addNewSpeciesDialog = true
    },
    closeAddNewSpeciesDialog(): void {
      this.addNewSpeciesDialog = false
      this.selectedNewSpecies = []
    },
    async AddNewSpecies(): Promise<void> {
      let speciesIds: number[] = []
      if (this.selectedNewSpecies.length > 0) {
        this.selectedNewSpecies.map((species: SpeciesRes) => {
          if (species) {
            speciesIds.push(species.id)
          }
        })
        if (this.selectedAssetId) {
          this.$rpc('callAddNewSpecies', {
            asset_id: this.selectedAssetId,
            species_ids: speciesIds
          }).then(() => {
            this.getAssetSpeciesLists()
            this.$emit('requestUpdate')
          })
        }
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No Species Selected'
        })
      }
      this.addNewSpeciesDialog = false
      this.selectedNewSpecies = []
      this.selectedAssetId = null
    },
    async getAssetSpeciesLists(): Promise<void> {
      let result = await this.$rpc('callGetAssetSpeciesLists')
      if (result.length > 0) {
        this.assetSpeciesListsData = result
      }
    },
    reDirect(id: number) {
      this.$router.push(`/species-list/${id}`)
    }
  },
  created() {
    this.getAssetSpeciesLists()
  }
})

interface SpeciesCustomizationI {
  search: string
  addNewSpeciesDialog: boolean
  selectedNewSpecies: SpeciesRes[]
  selectedAssetId: null | number
  assetSpeciesListsData: SpeciesListsI[]
}
interface SpeciesListsI {
  id: number
  vegetationSpecies: SpeciesRes[]
}
