







































































































































































































































































































































































import Vue from 'vue'
import {
  CulvertResult,
  CulvertInspectionResult,
  CulvertInspectionActionRes,
  RelationsRes
} from '../veg-common/apiTypes/'

import { dateObjToDateString } from '../util/date-time'
import { isSameDay, isAfter, isBefore, addYears } from 'date-fns'

export default Vue.extend({
  props: {
    culvertInspections: {
      type: Array as () => CulvertInspectionResult[],
      default: (): CulvertInspectionResult[] => []
    },
    culverts: {
      type: Array as () => CulvertResult[],
      default: (): CulvertResult[] => []
    }
  },
  data(): CulvertInspectionFilterComponentI {
    return {
      filterExpansionPanel: false,
      wrapped: true,

      siteIdSelectedValues: [],
      riskScoreOptions: [
        { text: 'No Risk', value: 0 },
        { text: 'Low Risk', value: 1 },
        { text: 'Medium Risk', value: 5 },
        { text: 'High Risk', value: 10 }
      ],
      riskScoreSelectedValues: [],
      inspectionDateRange: [],
      dateMenu: false,
      nextInspectionDateRange: [],
      nextDateMenu: false,

      photoOptions: [
        { text: 'No Photos', value: 0 },
        { text: 'Contain Photos', value: 1 }
      ],
      photoStatusSelectedValues: [],
      culvertStatusSelectedValues: [],
      selectedActionsTaken: [],
      selectedActionsRequired: [],
      selectedInitiators: [],
      commentSearch: '',

      badgeContent: 0,
      badgeValue: false
    }
  },
  computed: {
    dataForTable(): CulvertInspectionResult[] | [] {
      let fullCulvertList = this.culvertInspections

      return fullCulvertList.filter((item: CulvertInspectionResult) => {
        const culvertFilterMatches = this.filterSelectionHelper(
          this.siteIdSelectedValues,
          item,
          'culvert'
        )
        const riskScoreFilterMatches = this.filterSelectionHelper(
          this.riskScoreSelectedValues,
          item,
          'risk_score'
        )
        const dateRangeFilterMatches = this.dateRangeFilter(
          this.inspectionDateRange,
          item.inspection_date
        )
        const nextDateRangeFilterMatches = this.dateRangeFilter(
          this.nextInspectionDateRange,
          item.next_inspection_date
        )
        const culvertStatusFilterMatches = this.filterSelectionHelper(
          this.culvertStatusSelectedValues,
          item,
          'culvertStatus'
        )

        const photoFilterMatches = this.photoFilter(item)

        const initiatorMatches =
          this.selectedInitiators.length > 0
            ? this.selectedInitiators.includes(item.user)
            : true

        const actionsTakenMatches =
          this.selectedActionsTaken.length > 0
            ? this.actionsFilter(this.selectedActionsTaken, item.actionsTaken)
            : true

        const actionsRequiredMatches =
          this.selectedActionsRequired.length > 0
            ? this.actionsFilter(
                this.selectedActionsRequired,
                item.actionsRequired
              )
            : true

        const commentMatches = this.commentFilter(item.comments)

        return (
          culvertFilterMatches &&
          riskScoreFilterMatches &&
          dateRangeFilterMatches &&
          nextDateRangeFilterMatches &&
          culvertStatusFilterMatches &&
          photoFilterMatches &&
          actionsTakenMatches &&
          initiatorMatches &&
          actionsRequiredMatches &&
          commentMatches
        )
      })
    },
    relations(): RelationsRes {
      return this.$typedStore.getters.relations
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    clearAllFiltersClass(): string {
      if (this.isSmallScreen) {
        return 'pa-0 ma-0'
      } else {
        return 'd-flex justify-end pa-0 ma-0'
      }
    },
    applyFilterButtonClass(): string {
      if (this.isSmallScreen) {
        return 'flex-grow-0 primary accentAjmBlue--text'
      } else {
        return 'flex-grow-0 primary accentAjmBlue--text pr-6 pl-6 mr-10'
      }
    },
    inspectionDateRangeText(): string {
      return this.inspectionDateRange.join(' ~ ')
    },
    nextInspectionDateRangeText(): string {
      return this.nextInspectionDateRange.join(' ~ ')
    },
    multipleInitiatorSelectOptions(): string[] {
      let users = []
      for (let user of this.$typedStore.getters.usersArray) {
        users.push(user.first_name + ' ' + user.last_name)
      }
      return users
    },
    culvertSiteIdOptions(): selectStringOptions[] {
      return this.generateSelectOptions(this.culverts, 'site_culvert_id')
    },
    culvertStatusOptions(): selectStringOptions[] {
      return this.generateSelectOptions(
        this.relations.culvertStatuses,
        'culvert_status'
      )
    },
    actionTakenOptions(): CulvertInspectionActionRes[] {
      return [
        {
          inspection_action: 'No Actions Taken',
          action_type: 'taken',
          id: 0
        },
        ...this.relations.culvertTakenActions
      ]
    },
    actionRequiredOptions(): CulvertInspectionActionRes[] {
      return [
        {
          inspection_action: 'No Actions Required',
          action_type: 'required',
          id: 0
        },
        ...this.relations.culvertRequiredActions
      ]
    },
    nextYearDate(): string {
      return dateObjToDateString(addYears(new Date(), 1))
    }
  },
  methods: {
    applyFilters(): void {
      this.$emit('setTableData', this.dataForTable)

      this.$typedStore.dispatch('setAppliedCulvertInspectionFilters', {
        siteIdSelectedValues: this.siteIdSelectedValues,
        inspectionDateRange: this.inspectionDateRange,
        nextInspectionDateRange: this.nextInspectionDateRange,
        riskScoreSelectedValues: this.riskScoreSelectedValues,
        culvertStatusSelectedValues: this.culvertStatusSelectedValues,
        photoStatusSelectedValues: this.photoStatusSelectedValues,
        selectedActionsTaken: this.selectedActionsTaken,
        selectedInitiators: this.selectedInitiators,
        selectedActionsRequired: this.selectedActionsRequired,
        commentSearch: this.commentSearch
      })

      this.setBadgeData()
    },
    setBadgeData(): void {
      let filtersApplied = 0
      this.siteIdSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.inspectionDateRange.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.nextInspectionDateRange.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.riskScoreSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.culvertStatusSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.photoStatusSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.selectedActionsTaken.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.selectedInitiators.length > 0 ? filtersApplied++ : filtersApplied + 0
      this.selectedActionsRequired.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.commentSearch.length > 0 ? filtersApplied++ : filtersApplied + 0

      this.badgeContent = filtersApplied
      this.badgeValue = filtersApplied > 0 ? true : false
    },
    filterSelectionHelper(
      selectedValue: string[] | number[],
      item: CulvertInspectionResult,
      key: keyof CulvertInspectionResult
    ): boolean {
      if (selectedValue.length <= 0) return true

      let filtered = false
      for (let value of selectedValue) {
        if (item[key] === value) {
          return true
        }
      }

      return filtered
    },
    generateSelectOptions(
      optionType: any[],
      item: string
    ): selectStringOptions[] {
      let option = []
      optionType = optionType.sort((a, b) =>
        a[item].localeCompare(b[item], undefined, { numeric: true })
      )
      for (let data of optionType) {
        if (typeof data[item] === 'string') {
          option.push({ text: data[item], value: data[item] })
        }
      }
      return option
    },
    actionsFilter(
      selectedActions: CulvertInspectionActionRes[],
      inspectionActions: string[] | null
    ): boolean {
      let containsAction = false
      if (
        selectedActions.find((action) =>
          action.inspection_action.toLowerCase().includes('no actions')
        )
      ) {
        if (inspectionActions && inspectionActions.length === 0)
          containsAction = true
      } else {
        if (inspectionActions && inspectionActions.length > 0) {
          for (let i = 0; i < selectedActions.length; i++) {
            for (let j = 0; j < inspectionActions.length; j++) {
              if (
                selectedActions[i].inspection_action === inspectionActions[j]
              ) {
                containsAction = true
              }
            }
          }
        }
      }

      return containsAction
    },
    convertDateRangeData(date: string): Date {
      let dateArray = date.split('-')
      let year = parseInt(dateArray[0], 10)
      let month = parseInt(dateArray[1], 10) - 1
      let day = parseInt(dateArray[2], 10)
      return new Date(year, month, day)
    },
    dateRangeFilter(dateRange: string[], value: string): boolean {
      if (dateRange.length === 0) {
        return true
      }
      let _entryDate1 = this.convertDateRangeData(dateRange[0])
      let filteredDate = new Date(value)
      if (!dateRange[1]) {
        return (
          isSameDay(filteredDate, _entryDate1) ||
          isAfter(filteredDate, _entryDate1)
        )
      } else {
        let _entryDate2 = this.convertDateRangeData(dateRange[1])
        if (
          isSameDay(filteredDate, _entryDate1) ||
          isSameDay(filteredDate, _entryDate2)
        ) {
          return true
        } else if (isBefore(_entryDate1, _entryDate2)) {
          return (
            isAfter(filteredDate, _entryDate1) &&
            isBefore(filteredDate, _entryDate2)
          )
        } else {
          return (
            isBefore(filteredDate, _entryDate1) &&
            isAfter(filteredDate, _entryDate2)
          )
        }
      }
    },
    photoFilter(item: CulvertInspectionResult): boolean {
      if (this.photoStatusSelectedValues.length <= 0) return true

      for (let selected of this.photoStatusSelectedValues) {
        if (
          (selected === 0 && item.photos.length === 0) ||
          (selected === 1 && item.photos.length > 0)
        ) {
          return true
        }
      }
      return false
    },
    commentFilter(value: string): boolean {
      if (
        !this.commentSearch ||
        this.commentSearch.replace(/\s/g, '').length === 0
      ) {
        return true
      }
      return value
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(this.commentSearch.toLowerCase().replace(/\s/g, ''))
    },
    removeSelectedInitiators(toRemove: string): void {
      if (!this.selectedInitiators) return
      let index = this.selectedInitiators.findIndex((initiator: string) => {
        return initiator === toRemove
      })
      if (index >= 0) this.selectedInitiators.splice(index, 1)
    },
    removeTakenAction(toRemove: CulvertInspectionActionRes): void {
      let index = this.selectedActionsTaken.findIndex(
        (action: CulvertInspectionActionRes) => {
          return action.id === toRemove.id
        }
      )
      if (index >= 0) this.selectedActionsTaken.splice(index, 1)
    },
    removeRequiredAction(toRemove: CulvertInspectionActionRes): void {
      let index = this.selectedActionsRequired.findIndex(
        (action: CulvertInspectionActionRes) => {
          return action.id === toRemove.id
        }
      )
      if (index >= 0) this.selectedActionsRequired.splice(index, 1)
    },
    removeSelectedFromList(
      toRemove: string | number,
      list: string[] | number[]
    ): void {
      if (list.length <= 0) return
      let index = list.findIndex((item: string | number) => {
        return item === toRemove
      })

      if (index >= 0) list.splice(index, 1)
    },
    clearFilters(): void {
      this.siteIdSelectedValues = []
      this.inspectionDateRange = []
      this.nextInspectionDateRange = []
      this.riskScoreSelectedValues = []
      this.culvertStatusSelectedValues = []
      this.photoStatusSelectedValues = []
      this.selectedActionsTaken = []
      this.selectedInitiators = []
      this.selectedActionsRequired = []
      this.commentSearch = ''
      this.applyFilters()
    }
  },
  created() {
    this.siteIdSelectedValues =
      this.$typedStore.state.appliedCulvertInspectionFilters.culvertSelectedValues
    this.inspectionDateRange =
      this.$typedStore.state.appliedCulvertInspectionFilters.inspectionDateRange
    this.nextInspectionDateRange =
      this.$typedStore.state.appliedCulvertInspectionFilters.nextInspectionDateRange
    this.riskScoreSelectedValues =
      this.$typedStore.state.appliedCulvertInspectionFilters.riskScoreSelectedValues
    this.culvertStatusSelectedValues =
      this.$typedStore.state.appliedCulvertInspectionFilters.culvertStatusSelectedValues
    this.photoStatusSelectedValues =
      this.$typedStore.state.appliedCulvertInspectionFilters.photoStatusSelectedValues
    this.commentSearch =
      this.$typedStore.state.appliedCulvertInspectionFilters.commentSearch
    this.selectedInitiators =
      this.$typedStore.state.appliedCulvertInspectionFilters.selectedInitiators
    this.selectedActionsTaken =
      this.$typedStore.state.appliedCulvertInspectionFilters.selectedActionsTaken
    this.selectedActionsRequired =
      this.$typedStore.state.appliedCulvertInspectionFilters.selectedActionsRequired

    this.applyFilters()
  }
})

interface CulvertInspectionFilterComponentI {
  filterExpansionPanel: boolean
  wrapped: boolean
  siteIdSelectedValues: string[]
  riskScoreOptions: selectNumberOptions[]
  riskScoreSelectedValues: number[]
  inspectionDateRange: string[]
  dateMenu: boolean
  nextInspectionDateRange: string[]
  nextDateMenu: boolean
  photoOptions: selectNumberOptions[]
  culvertStatusSelectedValues: string[]
  photoStatusSelectedValues: number[]
  selectedActionsTaken: CulvertInspectionActionRes[]
  selectedActionsRequired: CulvertInspectionActionRes[]
  selectedInitiators: string[]
  commentSearch: string

  badgeContent: number
  badgeValue: boolean
}
interface selectNumberOptions {
  text: string
  value: number | null
}
interface selectStringOptions {
  text: string
  value: string | null
}
