





































































import Vue from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { IsMobileDevice } from '../util/ParseUserAgent'
import { SplashScreen } from '@capacitor/splash-screen'

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data(): HomeI {
    return {
      loginFormUsername: '',
      loginFormPassword: '',
      showPassword: false
    }
  },
  computed: {
    coverImageClass(): string {
      if (
        this.$vuetify.breakpoint.name === 'xs' ||
        this.$vuetify.breakpoint.name === 'sm'
      ) {
        return 'fill-height cover-image-mobile'
      } else {
        return 'fill-height cover-image'
      }
    }
  },
  methods: {
    async login(): Promise<boolean> {
      let result = await this.$rpc('callLogin', {
        username: this.loginFormUsername,
        password: this.loginFormPassword
      })
      if (result.token) {
        this.loginFormUsername = ''
        this.loginFormPassword = ''
        await this.$typedStore.dispatch('doLogin', result)

        this.$router.push('/dashboard')
        return true
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Incorrect username or password.'
        })
      }
      return false
    }
  },
  created() {
    // on browser the before created router guard will fire, meaning the user will get sent to the proper page if they are already logged in
    // we need this check here for mobile, since home is the landing point and the router doesn't fire on boot up
    if (this.$typedStore.getters.isLoggedIn) {
      this.$router.push('/dashboard')
    }
  },
  async mounted() {
    if (IsMobileDevice()) {
      await SplashScreen.hide({
        fadeOutDuration: 500
      })
    }
  }
})

interface HomeI {
  loginFormUsername: string
  loginFormPassword: string
  showPassword: boolean
}
