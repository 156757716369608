
















import Vue from 'vue'
import { SubAssetResult } from '../../veg-common/apiTypes'
import { DrawablePolygon } from '../../util/drawing'
import MapComponent from '../../components/MapComponent.vue'

export default Vue.extend({
  components: {
    MapComponent
  },
  props: {
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    }
  },
  data(): AssetViewMapI {
    return {
      mapIsLoaded: false,
      selectedYear: null
    }
  },
  computed: {
    managementAreaPolygons(): DrawablePolygon[] {
      return this.$typedStore.getters.managementAreaPolygons
    }
  },
  methods: {
    setSelectedYear(value: number | null) {
      this.$typedStore.commit('setSelectedAssetOverviewYear', value)
      this.selectedYear = value
    },
    mapLoaded(loadedSuccessfully: boolean) {
      // This gets run after initializeMap emits "mapLoaded"
      // So we don't try to add markers before the map is ready
      this.mapIsLoaded = loadedSuccessfully
      if (loadedSuccessfully) this.drawAreasOnMap()
    },
    drawAreasOnMap(): void {
      if (!this.mapIsLoaded) {
        return
      }
      this.$typedStore.commit('setSnackbarParams', {
        type: 'info',
        msg: 'Drawing data onto map.',
        timeout: -1 //flag for v-snackbar to make the snackbar indefinite
      })

      try {
        // @ts-ignore
        this.$refs.mapComp.removeAllPolygons()
        // @ts-ignore
        this.$refs.mapComp.checkPolygonYear()
        this.managementAreaPolygons.forEach((polygon) => {
          // @ts-ignore
          this.$refs.mapComp.drawPolygon(polygon, true)
        })
        // @ts-ignore
        this.$refs.mapComp.zoomMapOnAsset()
      } catch (e) {
        console.log(e)
      }
      this.$typedStore.commit('resetSnackbar')
    }
  },
  watch: {
    selectedYear() {
      this.drawAreasOnMap()
    },
    managementAreaPolygons() {
      this.drawAreasOnMap()
    }
  },
  beforeDestroy() {
    this.$typedStore.commit('setSelectedAssetOverviewYear', null)
  }
})

interface AssetViewMapI {
  mapIsLoaded: boolean
  selectedYear: number | null
}
