import Compressor from 'compressorjs'

const MAX_IMG_DIM = 4096

//https://www.npmjs.com/package/compressorjs#options
//quality 1 keeps full size
//quality 0.6 reduces by approximately 67.99%
//compressor should only run on a desktop file upload, all mobile photo uploads are controlled by Capacitor in the CameraViewComponent

export const preprocessImage = async (imageBlob: Blob): Promise<Blob> => {
  return new Promise((res, rej) => {
    new Compressor(imageBlob, {
      quality: 0.8,
      maxWidth: MAX_IMG_DIM,
      maxHeight: MAX_IMG_DIM,
      success(x: Blob) {
        res(x)
      },
      error(x) {
        rej(x)
      }
    })
  })
}

export interface Photo {
  blob: Blob
  stringified: string //base64 or URI
}
