import {
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsString,
  IsArray,
  IsOptional,
} from 'class-validator'

export class SubAssetResult {
  constructor(item: SubAssetResult) {
    if (item) {
      this.id = item.id
      this.sub_asset_name = item.sub_asset_name
      this.latitude = item.latitude
      this.longitude = item.longitude
    }
  }

  @IsInt()
  id!: number

  @IsString()
  @IsNotEmpty()
  sub_asset_name!: string

  @IsNumber()
  latitude!: number

  @IsNumber()
  longitude!: number
}

export class CreateSubAsset {
  constructor(item: CreateSubAsset) {
    if (item) {
      this.sub_asset_name = item.sub_asset_name
      this.latitude = item.latitude
      this.longitude = item.longitude
      this.assetId = item.assetId
      this.selectedManagementArea = item.selectedManagementArea
    }
  }

  @IsString()
  @IsNotEmpty()
  sub_asset_name!: string

  @IsNumber()
  latitude!: number

  @IsNumber()
  longitude!: number

  @IsNumber()
  assetId!: number

  @IsOptional()
  @IsArray()
  selectedManagementArea!: number[]
}

export class RemoveAreaFromSubAsset {
  constructor(item: RemoveAreaFromSubAsset) {
    if (item) {
      this.areaId = item.areaId
    }
  }

  @IsArray()
  areaId!: number[]
}

export class AssignAreaToSubAsset {
  constructor(item: AssignAreaToSubAsset) {
    if (item) {
      this.selectedManagementArea = item.selectedManagementArea
      this.selectedSubAssetId = item.selectedSubAssetId
    }
  }

  @IsArray()
  selectedManagementArea!: number[]

  @IsNumber()
  selectedSubAssetId!: number
}

export class EditSubAsset {
  constructor(item: EditSubAsset) {
    if (item) {
      this.id = item.id
      this.sub_asset_name = item.sub_asset_name
      this.latitude = item.latitude
      this.longitude = item.longitude
      this.selectedManagementArea = item.selectedManagementArea
      this.managementAreasToRemove = item.managementAreasToRemove
    }
  }

  @IsInt()
  id!: number

  @IsString()
  @IsNotEmpty()
  sub_asset_name!: string

  @IsNumber()
  latitude!: number

  @IsNumber()
  longitude!: number

  @IsOptional()
  @IsArray()
  selectedManagementArea!: number[]

  @IsOptional()
  @IsArray()
  managementAreasToRemove!: number[]
}
