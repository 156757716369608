import { BaseEntity } from './BaseEntity'
import { Entity, Column } from 'typeorm'
import { AlertObject } from '../../veg-common/apiTypes/newResultTypes'
import { IsString, IsInt } from 'class-validator'

@Entity()
export class AlertResponseEntity extends BaseEntity {
  constructor(params?: AlertObject) {
    super()
    if (params) {
      this.id = params.id
      this.alert_type = params.alert_type
    }
  }

  toApiType(): AlertObject {
    return {
      id: this.id,
      alert_type: this.alert_type
    }
  }

  @Column({ type: 'int', unique: true })
  @IsInt()
  id!: number

  @Column('text')
  @IsString()
  alert_type!: string
}
