import { MigrationInterface, QueryRunner } from 'typeorm'

export class NoActionRequiredIfHasNoIssue1682955832309
  implements MigrationInterface
{
  public async up(queryRunner: QueryRunner): Promise<any> {
    let resultTable = await queryRunner.hasTable(
      'vegetation_issues_result_entity'
    )
    if (resultTable === false) {
      console.log(
        'vegetation_issues_result_entity table not created yet - bail out of migration'
      )
      return
    }
    await queryRunner.query(
      `UPDATE vegetation_issues_result_entity set action_required = false where has_issue = false`
    )
    let paramsTable = await queryRunner.hasTable(
      'vegetation_issues_params_entity'
    )
    if (paramsTable === false) {
      console.log(
        'vegetation_issues_params_entity table not created yet - bail out of migration'
      )
      return
    }
    await queryRunner.query(
      `UPDATE vegetation_issues_params_entity set action_required = false where has_issue = false`
    )
  }

  public async down(queryRunner: QueryRunner): Promise<any> {}
}
