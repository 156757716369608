import { BaseEntity } from './BaseEntity'
import { Entity, Column } from 'typeorm'
import { SubAssetResult } from '../../veg-common/apiTypes'
import { AssetObject } from '../../veg-common/apiTypes/newResultTypes'
import { IsString, IsInt, IsNumber, IsNotEmpty } from 'class-validator'

@Entity()
export class AssetResultEntity extends BaseEntity {
  constructor(item?: AssetObject) {
    super()
    if (item) {
      this.id = item.id
      this.asset_name = item.asset_name
      this.latitude = item.latitude
      this.longitude = item.longitude
    }
  }

  toApiType(): AssetObject {
    return {
      id: this.id,
      asset_name: this.asset_name,
      latitude: this.latitude,
      longitude: this.longitude
    }
  }

  @Column({ type: 'int', unique: true })
  @IsInt()
  id!: number

  @Column('text')
  @IsString()
  @IsNotEmpty()
  asset_name!: string

  @Column('float')
  @IsNumber()
  latitude!: number

  @Column('float')
  @IsNumber()
  longitude!: number
}

@Entity()
export class SubAssetResultEntity extends BaseEntity {
  constructor(item: SubAssetResult) {
    super()
    if (item) {
      this.id = item.id
      this.sub_asset_name = item.sub_asset_name
      this.latitude = item.latitude
      this.longitude = item.longitude
    }
  }

  toApiType(): SubAssetResult {
    return {
      id: this.id,
      sub_asset_name: this.sub_asset_name,
      latitude: this.latitude,
      longitude: this.longitude
    }
  }

  @Column({ type: 'int', unique: true })
  @IsInt()
  id!: number

  @Column('text')
  @IsString()
  @IsNotEmpty()
  sub_asset_name!: string

  @Column('float')
  @IsNumber()
  latitude!: number

  @Column('float')
  @IsNumber()
  longitude!: number
}
