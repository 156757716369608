





























import Vue from 'vue'
import { AdminCreateCompanyParams } from '../../veg-common/apiTypes'

export default Vue.extend({
  data(): AdminCreateCompanyDataI {
    return {
      company: new AdminCreateCompanyParams(),
      companyTypes: ['client', 'thirdParty']
    }
  },
  methods: {
    async onSubmit(): Promise<boolean> {
      let result = await this.$rpc('callAdminCreateCompany', this.company)
      if (result.success) {
        console.log('AdminCreateCompany success')
      } else {
        console.log('AdminCreateCompany failed')
      }
      this.company = new AdminCreateCompanyParams()
      return result.success
    }
  }
})

interface AdminCreateCompanyDataI {
  company: AdminCreateCompanyParams
  companyTypes: string[]
}
