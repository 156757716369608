
















































































































































import Vue from 'vue'
import {
  VegetationIssueResult,
  GetThirdPartyCompanyRelationsResponse,
  GetThirdPartyCompanyResponse
} from '../veg-common/apiTypes'
import { isoToHumanDateTime } from './../util/date-time'
import VegeIssueInfoDialog from './VegeIssueView/VegeIssueInfoDialog.vue'
import { riskToColour } from '../util/drawing'
import { DataTableHeader } from 'vuetify/types'
import { getListOfMostRecentFollowups } from '../util/IssueHelpers'

export default Vue.extend({
  components: {
    VegeIssueInfoDialog
  },
  props: {
    vegeIssueData: {
      type: Array as () => VegetationIssueResult[],
      default: (): VegetationIssueResult[] => []
    }
  },
  data(): assignedIssuesData {
    return {
      headers: [
        {
          text: 'Risk Level',
          value: 'riskColor',
          sortable: false,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Area Name',
          value: 'area',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Date Inspected',
          value: 'inspection_date',
          class: 'font-weight-black white--text'
        },
        {
          text: 'VegLogic Id',
          value: 'originalIssueId',
          class: 'font-weight-black white--text'
        },
        {
          text: 'Issue Type',
          value: 'issue_type',
          class: 'font-weight-black white--text'
        },

        {
          text: 'Details',
          value: 'detailsDialog',
          sortable: false,
          class: 'font-weight-black white--text'
        }
      ],
      thirdPartyCompaniesData: [],
      companyName: '',
      selectedRows: [],
      deleteAssignedIssueDialog: false,
      transferCompanySelectDialog: false,
      selectedTransferCompanies: [],
      thirdPartyCompanyId: null,
      assignedIssuesTableData: []
    }
  },
  computed: {
    transferCompanySelectOptions(): GetThirdPartyCompanyResponse[] {
      let companies = this.thirdPartyCompaniesData.filter(
        (c) => c.company.id !== this.thirdPartyCompanyId
      )
      let companyArray: GetThirdPartyCompanyResponse[] = []
      for (let c of companies) {
        companyArray.push(c.company)
      }
      return companyArray
    }
  },
  methods: {
    formatInspectionDate(date: string) {
      return isoToHumanDateTime(date)
    },
    setRiskColour(item: VegetationIssueResult): string {
      return riskToColour(item.risk_score, item.isInteractive)
    },
    openDeleteAssignedIssueDialog(): void {
      if (this.selectedRows.length <= 0) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No issue selected.'
        })
      } else {
        this.deleteAssignedIssueDialog = true
      }
    },
    transferIssuesToCompanies(): void {
      let transfer_issue_ids: number[] = []
      this.selectedRows.map((row) => transfer_issue_ids.push(row.id))
      let transfer_to_company_ids: number[] = []
      this.selectedTransferCompanies.map((c) =>
        transfer_to_company_ids.push(c.id)
      )
      if (
        transfer_issue_ids.length > 0 &&
        transfer_to_company_ids.length > 0 &&
        this.thirdPartyCompanyId
      ) {
        this.$rpc('callTransferIssues', {
          transfer_issue_ids,
          transfer_to_company_ids,
          transfer_from_company_id: this.thirdPartyCompanyId
        }).then(() => this.getRelatedThirdPartyCompanies())
      }

      this.selectedRows = []
      this.selectedTransferCompanies = []
      this.transferCompanySelectDialog = false
    },
    deleteAssignedIssue(): void {
      if (this.selectedRows.length > 0 && this.thirdPartyCompanyId) {
        let issueIds = this.selectedRows.map(
          (data: VegetationIssueResult) => data.id
        )
        if (issueIds.length > 0) {
          //@ts-ignore
          this.$rpc('callDeleteAssignedIssue', {
            delete_issue_ids: issueIds,
            third_party_company_id: this.thirdPartyCompanyId
          }).then(() => {
            this.deleteAssignedIssueDialog = false
            this.getRelatedThirdPartyCompanies()
          })
        }
      }
    },
    openCompanySelectionDialog(): void {
      if (this.selectedRows.length > 0) {
        this.transferCompanySelectDialog = true
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'No issues selected for transfer.'
        })
      }
    },
    closeTransferCompanySelectDialog(): void {
      this.transferCompanySelectDialog = false
      this.selectedTransferCompanies = []
    },
    async getRelatedThirdPartyCompanies(): Promise<void> {
      this.thirdPartyCompanyId = Number(this.$route.params.id)
      try {
        let result = await this.$rpc('callGetCompanyRelations')
        this.thirdPartyCompaniesData = result
      } catch (e) {
        console.log(e)
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Error retrieving data.'
        })
      }
    }
  },
  created() {
    this.getRelatedThirdPartyCompanies().then(() => {
      if (this.thirdPartyCompanyId) {
        let company = this.thirdPartyCompaniesData.find(
          (c) => c.company.id === this.thirdPartyCompanyId
        )
        let mostRecentFollowups = getListOfMostRecentFollowups(
          this.vegeIssueData
        )
        if (company) {
          this.companyName = company.company.company_name
          let assignedIssues = company.company.vegetationIssues
          let tableData: VegetationIssueResult[] = []
          if (assignedIssues && assignedIssues.length > 0) {
            tableData = mostRecentFollowups.filter(
              (entry1: VegetationIssueResult) =>
                entry1.has_issue &&
                assignedIssues.some(
                  (entry2: VegetationIssueResult) =>
                    entry1.id === entry2.id ||
                    entry1.original_issue_id === entry2.id
                )
            )
          }
          this.assignedIssuesTableData = tableData
        }
      }
    })
  }
})
interface assignedIssuesData {
  headers: DataTableHeader[]
  deleteAssignedIssueDialog: boolean
  transferCompanySelectDialog: boolean
  selectedRows: VegetationIssueResult[]
  thirdPartyCompaniesData: GetThirdPartyCompanyRelationsResponse[]
  selectedTransferCompanies: GetThirdPartyCompanyResponse[]
  companyName: string
  thirdPartyCompanyId: null | number
  assignedIssuesTableData: VegetationIssueResult[]
}
