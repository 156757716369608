import {
  CreateUserRequest,
  CreateUserResponse,
  GenericResult,
  ResetPasswordRequestParams,
  ResetPasswordSubmissionParams,
  SendFeedbackRequest,
  AssignAssetsRequest,
  AssignIssuesRequest,
  IdRequest
} from '../veg-common/apiTypes'

export const usersApiDef = {
  callUsersCreate: {
    route: '/users/create',
    method: 'post',
    auth: true,
    inputType: CreateUserRequest,
    outputType: CreateUserResponse,
    returnsMultiple: false
  },
  callResetPasswordRequest: {
    route: '/reset-password-request',
    method: 'post',
    auth: false,
    inputType: ResetPasswordRequestParams,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callResetPasswordSubmission: {
    route: '/reset-password-submission',
    method: 'post',
    auth: false,
    inputType: ResetPasswordSubmissionParams,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callSendFeedback: {
    route: '/users/feedback',
    method: 'post',
    auth: true,
    inputType: SendFeedbackRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callAssignAssets: {
    route: '/users/assignAssets',
    method: 'post',
    auth: true,
    inputType: AssignAssetsRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callAssignIssues: {
    route: '/users/assignIssues',
    method: 'post',
    auth: true,
    inputType: AssignIssuesRequest,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callToggleUserActivation: {
    route: '/users/toggleUserActivation',
    method: 'post',
    auth: true,
    inputType: IdRequest,
    outputType: GenericResult,
    returnsMultiple: false
  }
} as const
