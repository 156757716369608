













import Vue from 'vue'
import {
  CulvertResult,
  CulvertInspectionResult
} from '../../veg-common/apiTypes'
import { getLatestCulvertInspection } from '../../util/CulvertHelpers'
import MapComponent from '../../components/MapComponent.vue'
import midpoint from '@turf/midpoint'
import { Point } from 'geojson'

export default Vue.extend({
  components: {
    MapComponent
  },
  props: {
    culverts: {
      type: Array as () => CulvertResult[],
      default: (): CulvertResult[] => []
    },
    culvertInspections: {
      type: Array as () => CulvertInspectionResult[],
      default: (): CulvertInspectionResult[] => []
    }
  },
  data(): CulvertAssetViewI {
    return {
      drawingInProgress: false,
      mapIsLoaded: false
    }
  },
  methods: {
    findMidpoints() {
      if (!this.mapIsLoaded) {
        return
      }
      //@ts-ignore
      this.$refs.mapComp.removeAllMarkers()
      this.drawingInProgress = true
      this.culverts.forEach((item) => {
        let mid = midpoint(
          item.inlet_location as Point,
          item.outlet_location as Point
        )
        let latestInspection: CulvertInspectionResult | null =
          getLatestCulvertInspection(item)
        // @ts-ignore
        this.$refs.mapComp.drawCulvertMarker(
          mid.geometry as Point,
          {
            clickable: true,
            showLabel: true
          },
          item,
          latestInspection
        )
      })
      //@ts-ignore
      this.$refs.mapComp.markerCluster()
      this.drawingInProgress = false
    },
    // initializeMap is already emitting
    mapLoaded(loadedSuccessfully: boolean): void {
      // This gets run after initializeMap emits "mapLoaded"
      // So we don't try to add markers before the map is ready
      this.mapIsLoaded = loadedSuccessfully
      if (loadedSuccessfully) this.findMidpoints()
    }
  },
  watch: {
    culverts() {
      if (this.mapIsLoaded) this.findMidpoints()
    }
  }
})

interface CulvertAssetViewI {
  drawingInProgress: boolean
  mapIsLoaded: boolean
}
