import { BaseEntity } from './BaseEntity'
import { Entity, Column } from 'typeorm'
import { IsInt, IsString, IsOptional, IsNotEmpty } from 'class-validator'

import { AreaObject } from '../../veg-common/apiTypes/newResultTypes'

@Entity()
export class ManagementAreasEntity extends BaseEntity {
  constructor(x?: AreaObject) {
    super()
    if (x) {
      this.id = x.id
      this.assetId = x.assetId
      this.subAssetId = x.subAssetId
      this.name = x.name
      this.imageLabel = x.image_label

      this.shape = JSON.stringify(x.shape)
    }
  }

  toApiType(): AreaObject {
    return {
      id: this.id,
      assetId: this.assetId,
      subAssetId: this.subAssetId,
      name: this.name,
      shape: JSON.parse(this.shape),
      image_label: this.imageLabel
    }
  }

  @Column({ type: 'int', unique: true })
  @IsInt()
  id!: number

  @Column('int')
  @IsInt()
  assetId!: number

  @Column({ type: 'int', nullable: true })
  @IsOptional()
  @IsInt()
  subAssetId!: number | null

  @Column('text')
  @IsString()
  @IsNotEmpty()
  name!: string

  @Column('text')
  @IsString()
  //@NeedGeometryValidator
  shape!: string

  @Column('int')
  @IsString()
  imageLabel!: string
}
