import {
  CulvertInspectionParams,
  EditCulvertInspectionParams,
  IdResponse,
  IdRequest,
  GenericResult
} from '../veg-common/apiTypes'

export const culvertInspectionsApiDef = {
  callPostCulvertInspection: {
    route: '/culvertInspections',
    method: 'post',
    auth: true,
    inputType: CulvertInspectionParams,
    outputType: IdResponse,
    returnsMultiple: false
  },
  callEditCulvertInspection: {
    route: '/culvertInspections/update',
    method: 'post',
    auth: true,
    inputType: EditCulvertInspectionParams,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callDeleteCulvertInspection: {
    route: '/culvertInspections/delete',
    method: 'post',
    auth: true,
    inputType: IdRequest,
    outputType: GenericResult,
    returnsMultiple: false
  }
} as const
