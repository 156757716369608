//https://stackoverflow.com/questions/48734975/extend-or-override-type-definition-for-vuex-store

import Vue from 'vue'
import { Store } from 'vuex-typescript-interface'
import { IStore } from './store'
import { RpcType, apiDef, callApi } from './rpc'
import './vee-validate-rules'
import VegDialog from './components/global/VegDialog.vue'
import VegDialogForm from './components/global/VegDialogForm.vue'
import VegSingleExpansionPanelWrapper from './components/global/VegSingleExpansionPanelWrapper.vue'
import WrappedComponent from 'vue-wrapped-component'

declare module 'vue/types/vue' {
  interface Vue {
    $typedStore: Store<IStore>
    $rpc: RpcType
  }
}

Object.defineProperty(Vue.prototype, '$typedStore', {
  get: function (this: Vue) {
    return this.$store
  }
})

Object.defineProperty(Vue.prototype, '$rpc', {
  get: function (this: Vue) {
    return async (endpoint: any, ...args: any[]): Promise<any> => {
      const { data, token, version } = await callApi(
        apiDef,
        this.$typedStore.getters.apiUrl,
        this.$typedStore.state.token,
        endpoint,
        ...args
      )
      if (token) {
        await this.$typedStore.dispatch('setToken', token)
      }
      if (version) {
        await this.$typedStore.dispatch('checkVersion', version)
      }
      return data
    }
  }
})

// Globally registered components
Vue.component('wrapped-component', WrappedComponent)
Vue.component('veg-dialog', VegDialog)
Vue.component('veg-dialog-form', VegDialogForm)
Vue.component(
  'veg-single-expansion-panel-wrapper',
  VegSingleExpansionPanelWrapper
)
