

















import Vue from 'vue'
import { VegetationIssueResult } from '../../veg-common/apiTypes'

import IssueInformationCard from '../../components/IssueInformationCard.vue'

export default Vue.extend({
  components: {
    IssueInformationCard
  },
  props: {
    vegeIssue: {
      type: Object as () => VegetationIssueResult
    }
  },
  computed: {
    issueId(): number {
      return this.vegeIssue.original_issue_id
        ? this.vegeIssue.original_issue_id
        : this.vegeIssue.id
    },
    title(): string {
      if (this.vegeIssue.isInteractive) {
        if (this.issueId > 0) {
          return `VegLogic Id ${this.issueId}`
        } else {
          return 'Issue Done Offline, No Id Set Yet'
        }
      } else {
        return 'Issue Not Assigned For Work'
      }
    }
  }
})
