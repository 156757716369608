import { IsInt, ArrayNotEmpty, IsArray } from 'class-validator'
import { VegSpeciesObject } from './newResultTypes'
export class DeleteSpeciesRequest {
  constructor(x: DeleteSpeciesRequest) {
    if (x) {
      this.asset_id = x.asset_id
      this.species_ids = x.species_ids
    }
  }
  @IsInt()
  asset_id!: number

  @ArrayNotEmpty()
  species_ids!: number[]
}

export class AddNewSpeciesRequest {
  constructor(x: AddNewSpeciesRequest) {
    if (x) {
      this.species_ids = x.species_ids
      this.asset_id = x.asset_id
    }
  }
  @IsInt()
  asset_id!: number

  @ArrayNotEmpty()
  species_ids!: number[]
}

export class GetAssetSpeciesListsResponse {
  constructor(x: GetAssetSpeciesListsResponse) {
    if (x) {
      this.id = x.id
      this.vegetationSpecies = x.vegetationSpecies
    }
  }
  @IsInt()
  id!: number

  @IsArray()
  vegetationSpecies!: VegSpeciesObject[]
}
