import {
  AdminCreateCompanyParams,
  AdminCreateUserParams,
  AdminGetCompanyResult,
  AdminLoginParams,
  GenericResult
} from '../veg-common/apiTypes'

export const adminApiDef = {
  // The admin login endpoint is unique in that it works both with and
  // without authorization, hence two endpoint definitions
  callAdminLogin: {
    route: '/admin/login',
    method: 'post',
    auth: false,
    inputType: AdminLoginParams,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callAdminLoginAuth: {
    route: '/admin/login',
    method: 'post',
    auth: true,
    inputType: AdminLoginParams,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callAdminLogout: {
    route: '/admin/logout',
    method: 'get',
    auth: true,
    inputType: 'void',
    outputType: GenericResult,
    returnsMultiple: false
  },
  callAdminCreateCompany: {
    route: '/admin/createCompany',
    method: 'post',
    auth: true,
    inputType: AdminCreateCompanyParams,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callAdminCreateUser: {
    route: '/admin/createUser',
    method: 'post',
    auth: true,
    inputType: AdminCreateUserParams,
    outputType: GenericResult,
    returnsMultiple: false
  },
  callAdminGetAllCompanies: {
    route: '/admin/allCompanies',
    method: 'get',
    auth: true,
    inputType: 'void',
    outputType: AdminGetCompanyResult,
    returnsMultiple: true
  }
} as const
