















































import Vue from 'vue'
import MapComponent from '../../components/MapComponent.vue'

export default Vue.extend({
  components: {
    MapComponent
  },
  data(): AssetViewMapI {
    return {
      mapIsLoaded: false,
      map2IsLoaded: false,
      showLsdGrid: false
    }
  },
  watch: {
    showLsdGrid() {
      this.$typedStore.dispatch('setShowLsdGrid', this.showLsdGrid)
    }
  },
  methods: {
    mapLoaded(loadedSuccessfully: boolean) {
      this.mapIsLoaded = loadedSuccessfully
    },
    map2Loaded(loadedSuccessfully: boolean) {
      this.map2IsLoaded = loadedSuccessfully
    }
  },
  created() {
    this.showLsdGrid = this.$typedStore.state.showLsdGrid
  }
})

interface AssetViewMapI {
  mapIsLoaded: boolean
  map2IsLoaded: boolean
  showLsdGrid: boolean
}
