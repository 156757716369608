


















































































































import Vue from 'vue'
import { DataTableHeader } from 'vuetify/types'
import VegDialog from '../../../components/global/VegDialog.vue'
import SeeAreasDialog from '../../../components/SeeAreasDialog.vue'
import {
  IdRequest,
  ManagementAreaResult,
  SubAssetResult
} from '../../../veg-common/apiTypes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider,
    VegDialog,
    SeeAreasDialog
  },
  data(): SubAssetsTableI {
    return {
      assignAreaDialog: false,
      managementAreaOptions: [],
      pageLoading: false,
      search: '',
      selectedAssetId: null,
      selectedSubAssetId: null,
      selectedManagementArea: []
    }
  },
  props: {
    managementAreas: {
      type: Array as () => ManagementAreaResult[],
      default: (): ManagementAreaResult[] => []
    },
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      let headers: DataTableHeader[] = [
        {
          text: 'Sub Asset Name',
          value: 'sub_asset_name',
          sortable: true,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Latitude',
          value: 'latitude',
          sortable: false,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Longitude',
          value: 'longitude',
          sortable: false,
          class: 'font-weight-black white--text'
        },
        {
          text: 'Actions',
          value: 'actions',
          filterable: false,
          sortable: false,
          class: 'font-weight-black white--text'
        }
      ]
      return headers
    },
    isClientUser(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 1
      }
      return false
    }
  },
  methods: {
    areasFiltered() {
      this.managementAreas.forEach((item) => {
        if (!item.subAssetId) {
          this.managementAreaOptions.push(item)
        }
      })
    },
    closeAssignAreaDialog(): void {
      this.selectedManagementArea = []
      this.assignAreaDialog = false
    },
    createSubAsset() {
      this.$router.push('/add-sub-asset')
    },
    editSubAsset(item: SubAssetResult) {
      this.$router.push(`/edit-sub-asset/${item.id}`)
    },
    openAssignAreaDialog(item: IdRequest): void {
      this.assignAreaDialog = true
      this.selectedSubAssetId = item.id
    },
    async saveAreaToSubAsset(): Promise<void> {
      this.pageLoading = true
      let app = this

      if (!app.$typedStore.getters.jwtContent) return
      if (!app.$typedStore.getters.jwtContent.data.userId) return

      let result
      if (app.selectedManagementArea && app.selectedSubAssetId) {
        result = await app.$rpc('callAssignAreaToSubAsset', {
          selectedManagementArea: app.selectedManagementArea,
          selectedSubAssetId: app.selectedSubAssetId
        })
      }

      if (result && !result.success) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Assignment to Management Area failed.'
        })
        return
      }

      this.$typedStore.commit('setSnackbarParams', {
        type: 'success',
        msg: 'Sub Asset assigned to Management Area successfully.'
      })

      app.$emit('requestUpdate', { edit: true })
      this.closeAssignAreaDialog()
    },
    reDirect(id: number) {
      this.$router.push(`/management-areas-list/${id}`)
    },
    async validateInputs(): Promise<void> {
      //@ts-ignore
      let valid = await this.$refs.validator.validate()

      if (valid) {
        await this.saveAreaToSubAsset()
      } else {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Sub Asset invalid.'
        })
      }
    }
  },
  created() {
    this.areasFiltered()
  }
})

interface SubAssetsTableI {
  assignAreaDialog: boolean
  managementAreaOptions: ManagementAreaResult[]
  pageLoading: boolean
  search: string
  selectedAssetId: null | number
  selectedManagementArea: number[]
  selectedSubAssetId: null | number
}
