import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import App from './App.vue'
import router from './router'
import store from './store'

import './vee-validate-rules'

import { IsMobileDevice } from './util/ParseUserAgent'

import {
  defineCustomElements as jeepSqlite,
  applyPolyfills
} from 'jeep-sqlite/loader'
import { SplashScreen } from '@capacitor/splash-screen'
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite'
import { StatusBar } from '@capacitor/status-bar'

// Side effecty scripts
require('./vue-customize.ts')

Vue.use(Vuetify)
Vue.config.productionTip = false

import '@mdi/font/css/materialdesignicons.css'
import './styles/base.sass'

const vuetify = new Vuetify({
  theme: {
    options: { customProperties: true },
    dark: true,
    themes: {
      light: {
        primary: '#BBBF1D', //the green on buttons
        secondary: '#BF611D', //the orange on delete type buttons
        accent: '#BCBCBC', //grey accent for buttons
        complementaryRed: '#D63440',
        ajmBlue: '#1D4557',
        accentAjmBlue: '#15303c',
        altTableBlue: '#173C4C',
        // theming has opacity set just for background, adjusting the opacity in styling adjusts the entire row content not just the background
        altTableBlue60Opac: '#1f3f51',
        infoIcon: '#7C7E1D',
        userMarkerColor: '#2A637D',
        ajmBluePaletteRed: '#863B3B',
        ajmBluePalettePurple: '#4D3444',
        ajmBluePaletteGreen: '#005F42'
      },
      dark: {
        primary: '#BBBF1D',
        secondary: '#BF611D',
        accent: '#BCBCBC',
        complementaryRed: '#D63440',
        ajmBlue: '#1D4557',
        accentAjmBlue: '#15303c',
        altTableBlue: '#173C4C',
        altTableBlue60Opac: '#1f3f51',
        infoIcon: '#7C7E1D',
        userMarkerColor: '#2A637D',
        ajmBluePaletteRed: '#863B3B',
        ajmBluePalettePurple: '#4D3444',
        ajmBluePaletteGreen: '#005F42'
      }
    }
  }
})

async function main() {
  const isMobile = IsMobileDevice()

  if (isMobile) {
    await SplashScreen.show({
      autoHide: false
    })
    StatusBar.setOverlaysWebView({ overlay: true })
  }

  await applyPolyfills()
  jeepSqlite(window)

  try {
    if (!isMobile) {
      const sqliteConnection = new SQLiteConnection(CapacitorSQLite)

      const jeepEl = document.querySelector('jeep-sqlite')
      if (jeepEl != null) {
        document.body.removeChild(jeepEl)
      }
      // Create the 'jeep-sqlite' Stencil component
      const jeepSqlite = document.createElement('jeep-sqlite')
      document.body.appendChild(jeepSqlite)
      await customElements.whenDefined('jeep-sqlite')
      // Initialize the Web store
      await sqliteConnection.initWebStore()

      // await sqliteConnection.saveToStore('local-veglogic')
    }

    // when using Capacitor, you might want to close existing connections,
    // otherwise new connections will fail when using dev-live-reload
    // see https://github.com/capacitor-community/sqlite/issues/106
    CapacitorSQLite.checkConnectionsConsistency({
      dbNames: [], // i.e. "i expect no connections to be open"
      openModes: ['RW', 'RO']
    }).catch((e) => {
      // the plugin throws an error when closing connections. we can ignore
      // that since it is expected behaviour
      console.log(e)
      return {}
    })

    //set token on initial startup of app in case it exists
    await store.dispatch('loadToken')
  } catch (err) {
    console.log(`Error: ${err}`)
    throw new Error(`Error: ${err}`)
  }

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
  }).$mount('#app')
}

main()
