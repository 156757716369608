

















import Vue from 'vue'

export default Vue.extend({
  data(): VegSingleExpansionPanelWrapperI {
    return {
      panelModel: this.value ? 0 : undefined,
    }
  },
  watch: {
    panelModel(): void {
      this.$emit('input', this.panelModel === undefined ? false : true)
    },
  },
  props: {
    panelHeader: {
      type: String,
    },
    value: {
      type: Boolean,
    },
  },
})

interface VegSingleExpansionPanelWrapperI {
  panelModel: number | undefined
}
