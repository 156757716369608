import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite'
import { DataSourceOptions, DataSource, FindOptionsWhere } from 'typeorm'
import { BaseEntity } from './entities'
import { IsMobileDevice } from '../util/ParseUserAgent'

import { ClassType } from '../veg-common'
import { transformAndValidate } from '../veg-common'

type EntitiesAndMigrationsOpts = Pick<
  DataSourceOptions,
  'entities' | 'migrations'
>

const importAllFunctions = (
  requireContext: __WebpackModuleApi.RequireContext
) =>
  requireContext
    .keys()
    .sort()
    .map((filename) => {
      const required = requireContext(filename)
      return Object.keys(required).reduce((result, exportedKey) => {
        const exported = required[exportedKey]
        if (typeof exported === 'function') {
          return result.concat(exported)
        }
        return result
      }, [] as any)
    })
    .flat()

const entitiesViaWebpack: NonNullable<EntitiesAndMigrationsOpts['entities']> =
  process.env.NODE_ENV === 'test'
    ? []
    : importAllFunctions(require.context('./entity/', true, /\.ts$/))

const migrationsViaWebpack: NonNullable<
  EntitiesAndMigrationsOpts['migrations']
> =
  process.env.NODE_ENV === 'test'
    ? []
    : importAllFunctions(require.context('./migration/', true, /\.ts$/))

export default class SQLite {
  private databaseConnection: DataSource
  private connection: SQLiteConnection
  private dbName: string

  constructor() {
    this.connection = new SQLiteConnection(CapacitorSQLite)

    let dataOptions: DataSourceOptions
    let deployEnv = process.env.NODE_ENV

    //localApp is used for app compilation, development is used just for web dev so use the same db
    switch (deployEnv) {
      case 'localApp':
      case 'development':
        dataOptions = {
          type: 'capacitor',
          driver: this.connection,
          mode: 'no-encryption',
          database: 'local-veglogic',

          logging: false,
          migrationsRun: true,
          synchronize: true,

          entities: entitiesViaWebpack,
          migrations: migrationsViaWebpack,
          subscribers: []
        }
        this.dbName = 'local-veglogic'
        break
      case 'stagingApp':
        dataOptions = {
          type: 'capacitor',
          driver: this.connection,
          mode: 'no-encryption',
          database: 'staging-veglogic',

          logging: false,
          migrationsRun: true,
          synchronize: true,

          entities: entitiesViaWebpack,
          migrations: migrationsViaWebpack,
          subscribers: []
        }
        this.dbName = 'staging-veglogic'
        break
      default:
        dataOptions = {
          type: 'capacitor',
          driver: this.connection,
          mode: 'no-encryption',
          database: 'veglogic',

          logging: false,
          migrationsRun: true,
          synchronize: true,

          entities: entitiesViaWebpack,
          migrations: migrationsViaWebpack,
          subscribers: []
        }
        this.dbName = 'veglogic'
        break
    }
    this.databaseConnection = new DataSource(dataOptions)
  }

  async findOne(
    entity: ClassType<BaseEntity>,
    options?: FindOptionsWhere<ClassType<BaseEntity>>
  ): Promise<any | null> {
    try {
      if (!this.databaseConnection.isInitialized)
        await this.databaseConnection.initialize()

      let repository = await this.databaseConnection.getRepository(entity)

      if (options) {
        let item = await repository.findOne({
          where: options
        })
        return item ? item : null
      } else {
        let listOfItems = await repository.find()

        if (listOfItems.length > 0) {
          return await transformAndValidate(entity, listOfItems[0])
        } else {
          return null
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  async find(
    entity: ClassType<BaseEntity>,
    options?: FindOptionsWhere<ClassType<BaseEntity>>
  ): Promise<any[]> {
    let listOfItems: any[] = []
    try {
      if (!this.databaseConnection.isInitialized)
        await this.databaseConnection.initialize()

      if (options) {
        listOfItems = await this.databaseConnection
          .getRepository(entity)
          .find({ where: options })
      } else {
        listOfItems = await this.databaseConnection.getRepository(entity).find()
      }
    } catch (e) {
      console.log(e)
    }
    return listOfItems
  }

  async clear(entity: ClassType<BaseEntity>): Promise<void> {
    try {
      if (!this.databaseConnection.isInitialized)
        await this.databaseConnection.initialize()

      await this.databaseConnection.manager.clear(entity)
    } catch (e) {
      console.log(e)
    }
  }

  //should only be called on logout when we want EVERYTHING gone from the localdb
  async clearAll(): Promise<void> {
    try {
      // Fetch all the entities
      const entities = this.databaseConnection.entityMetadatas

      for (const entity of entities) {
        await this.databaseConnection.manager.clear(entity.name)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async deleteOne(
    entity: ClassType<BaseEntity>,
    sqliteId: number
  ): Promise<void> {
    try {
      if (!this.databaseConnection.isInitialized)
        await this.databaseConnection.initialize()

      await this.databaseConnection
        .getRepository(entity)
        .delete({ sqliteId: sqliteId })
    } catch (e) {
      console.log(e)
    }
  }

  async save(entity: BaseEntity): Promise<any> {
    try {
      if (!this.databaseConnection.isInitialized)
        await this.databaseConnection.initialize()

      let entityTarget = { type: entity, name: entity.constructor.name }
      return await this.databaseConnection
        .getRepository(entityTarget)
        .save(entity)
    } catch (e) {
      console.log(e)
    }
  }

  async upsert(entity: BaseEntity, upsertKey: string): Promise<void> {
    try {
      if (!this.databaseConnection.isInitialized)
        await this.databaseConnection.initialize()

      let entityTarget = { type: entity, name: entity.constructor.name }
      await this.databaseConnection
        .getRepository(entityTarget)
        .upsert([entity], [upsertKey])
    } catch (e) {
      console.log(e)
    }
  }

  //must be called after any save to the db to ensure the data is saved to the web browser local storage
  async SaveToStore(): Promise<void> {
    try {
      if (!IsMobileDevice()) {
        await this.connection.saveToStore(this.dbName)
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export const localDb = new SQLite()
