var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',[_c('v-row',{attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-col',{attrs:{"sm":"10"}},[_c('v-text-field',{staticClass:"mb-6",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_c('v-data-table',{staticClass:"ajmBlue",attrs:{"headers":_vm.headers,"items":_vm.subAssetData,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.reDirect(item.id)}}},on),[_vm._v(" mdi-format-list-bulleted ")])]}}],null,true)},[_c('span',[_vm._v("Show Assigned Management Areas")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2 primary--text",on:{"click":function($event){return _vm.openAssignAreaDialog(item)}}},on),[_vm._v(" mdi-plus-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("Add Management Area to Sub Asset")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editSubAsset(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Sub Asset")])])]}}])}),_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[(_vm.isClientUser)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"primary accentAjmBlue--text",on:{"click":_vm.createSubAsset}},[_vm._v("Create New Sub Asset   "),_c('v-icon',[_vm._v("mdi-plus")])],1)],1):_vm._e()],1),_c('ValidationObserver',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.assignAreaDialog),callback:function ($$v) {_vm.assignAreaDialog=$$v},expression:"assignAreaDialog"}},[_c('v-card',{staticClass:"ajmBlue"},[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" Assign Management Area ")]),_c('v-row',{staticClass:"mx-4 my-2"},[_c('SeeAreasDialog')],1),_c('v-card-text',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":"management area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-0 pt-0 pr-2",attrs:{"items":_vm.managementAreaOptions,"error-messages":errors,"label":"Select Management Area","multiple":"","chips":"","hide-details":"auto","item-text":"name","item-value":"id"},model:{value:(_vm.selectedManagementArea),callback:function ($$v) {_vm.selectedManagementArea=$$v},expression:"selectedManagementArea"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){return _vm.validateInputs()}}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"primary darken-1 accentAjmBlue--text",on:{"click":_vm.closeAssignAreaDialog}},[_vm._v(" Close ")]),_c('v-spacer')],1)],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }