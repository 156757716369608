












































































































































































































import Vue from 'vue'

import {
  compareDesc as dfnCompareDesc,
  parseISO as dfnParseISO
} from 'date-fns'
import { Geolocation, Position } from '@capacitor/geolocation'

import { NoResponseError } from './util/errors'
import positionOptions from './util/gpsPositionOptions'
import { IsMobileDevice } from './util/ParseUserAgent'

import {
  ManagementAreaResult,
  VegetationIssueResult,
  SubAssetResult,
  CulvertResult,
  CulvertInspectionResult,
  RelationsRes
} from './veg-common/apiTypes'
import { JwtContent } from './veg-common'
import { AssetObject, UserObject } from './veg-common/apiTypes/newResultTypes'

import { SafeArea } from 'capacitor-plugin-safe-area'

interface NavItem {
  title: string
  link: string
}

interface UserMenuItem {
  title: string
  icon: string
  tooltip: string
  action: () => void
}

export default Vue.extend({
  data(): LoggedInWrapperI {
    return {
      loading: true,
      createdPromise: null,
      sideNav: true,
      watchId: null,
      vegelogicRoutes: [
        '/dashboard',
        '/overview',
        '/vegetation-issues',
        '/sub-assets',
        '/species-customization',
        '/service-providers',
        '/assign-issues'
      ],
      culvertlogicRoutes: [
        '/culvert-inspections',
        '/culvert-asset-view',
        '/culvert-inspections/add',
        '/culverts/edit/:id'
      ],
      topBarPaddingStyle: '',
      topMarginStyle: '',
      leftPaddingStyle: '',
      leftMarginStyle: '',
      rightPaddingStyle: '',
      rightMarginStyle: '',
      navDrawerWidthStyle: ''
    }
  },
  computed: {
    exitTitle() {
      switch (this.$route.name) {
        case 'Edit Vegetation Issue':
          return 'Editing VegeIssue'
          break
        case 'Add Issue':
          return 'Adding VegeIssue'
          break
        case 'Add Culvert Inspection':
          return 'Adding Culvert Inspection'
          break
        case 'Edit Culvert':
          return 'Editng Culvert'
          break
        case 'Edit Culvert Inspection':
          return 'Editing Culvert Inspection'
          break
        default:
          return ''
      }
    },
    showExitButtonOnPage() {
      return (
        this.$route.name === 'Edit Vegetation Issue' ||
        this.$route.name === 'Add Issue' ||
        this.$route.name === 'Add Culvert Inspection' ||
        this.$route.name === 'Edit Culvert' ||
        this.$route.name === 'Edit Culvert Inspection'
      )
    },
    asset(): AssetObject | null {
      return this.$typedStore.getters.selectedAsset
    },
    assetsArray(): AssetObject[] {
      return this.$typedStore.getters.assetsArray
    },
    avatarText(): string {
      const user: UserObject | null = this.$typedStore.getters.user
      if (user) {
        const fn = user.first_name
        const ln = user.last_name
        if (fn.length > 0 && ln.length > 0) {
          return `${fn[0]}${ln[0]}`
        }
      }
      return ''
    },
    relations(): RelationsRes {
      return this.$typedStore.getters.relations
    },
    loggedIntoCulvertlogic() {
      return this.$typedStore.getters.culvertlogicStatus
    },
    loggedIntoVegelogic() {
      return this.$typedStore.getters.vegelogicStatus
    },
    managementAreas(): ManagementAreaResult[] {
      return this.$typedStore.getters.managementAreas
    },
    vegeIssueData(): VegetationIssueResult[] {
      return this.$typedStore.getters.vegetationIssues.sort((a, b) => {
        //sort issues by date DESC
        return dfnCompareDesc(
          dfnParseISO(a.inspection_date),
          dfnParseISO(b.inspection_date)
        )
      })
    },
    subAssetData(): SubAssetResult[] {
      return this.$typedStore.getters.subAssets
    },
    culverts(): CulvertResult[] {
      return this.$typedStore.getters.culverts
    },
    culvertInspections(): CulvertInspectionResult[] {
      return this.$typedStore.getters.culvertInspections
    },
    hasAsset(): boolean {
      return this.asset !== null
    },
    assetSelectValue(): number | null {
      return this.asset ? this.asset.id : null
    },
    hasUnsyncedData(): boolean {
      return this.$typedStore.getters.hasUnsyncedData
    },
    dataSyncing(): boolean {
      return this.$typedStore.state.dataSyncing
    },
    navBarTouchless(): boolean {
      return this.$typedStore.state.navBarTouchless
    },
    avatarColor(): string {
      if (this.hasUnsyncedData) {
        return '#E65100' //orange
      } else {
        return '#7C7E1D'
      }
    },
    checkMobileDevice(): boolean {
      return IsMobileDevice()
    },
    navItems(): VTNavItem[] {
      let items: VTNavItem[] = []
      //VegLogic Pages + Both Setup Titles
      if (this.loggedIntoVegelogic) {
        items.push({ title: 'Dashboard', link: '/dashboard', isTitle: false })
        if (!this.isThirdPartyCompany && this.managementAreas.length > 1) {
          items.push({
            title: 'Asset Overview',
            link: '/overview',
            isTitle: false
          })
        }
        items.push({
          title: 'Vegetation Issues',
          link: '/vegetation-issues',
          isTitle: false
        })
        if (!this.isThirdPartyCompany && this.$typedStore.getters.isAdminRole) {
          items.push({
            title: 'Assign To Service Providers',
            link: '/service-providers',
            isTitle: false
          })
        }
        if (this.$typedStore.getters.isAdminRole) {
          items.push({
            title: 'Assign to Users',
            link: '/assign-issues',
            isTitle: false
          })
          if (!this.isThirdPartyCompany) {
            items.push({
              title: 'Asset Setup',
              link: '',
              isTitle: true
            })
          }
        }
      }
      //CulvertLogic pages + System Setup
      if (this.loggedIntoCulvertlogic) {
        items = [
          {
            title: 'Culvert Asset View',
            link: '/culvert-asset-view',
            isTitle: false
          },
          {
            title: 'Culvert Inspections',
            link: '/culvert-inspections',
            isTitle: false
          }
        ]
      }
      items.push({
        title: 'System Setup',
        link: '',
        isTitle: true
      })
      return items
    },

    setupAssetNavItems(): VTNavItem[] {
      //all of the logic on what to show is based on if the title exists, that logic exists above - no need to recheck all the logic here
      return [
        {
          title: 'Species Customization',
          link: '/species-customization',
          isTitle: false
        },
        {
          title: 'Sub Assets',
          link: '/sub-assets',
          isTitle: false
        }
      ]
    },

    setupSystemNavItems(): VTNavItem[] {
      //all of the logic on what to show is based on if the title exists, that logic exists above - no need to recheck all the logic here
      return [
        {
          title: 'Users',
          link: '/users',
          isTitle: false
        },
        {
          title: 'Alerts',
          link: '/alerts',
          isTitle: false
        },
        {
          title: 'Set LSD Grid On Maps',
          link: '/set-lsd-grid',
          isTitle: false
        }
      ]
    },

    userMenuItems(): UserMenuItem[] {
      const updateDataItem: UserMenuItem = {
        title: 'Update Data',
        icon: 'mdi-reload',
        tooltip: 'Check for updated data.',
        action: this.callUpdateData
      }

      const saveUnsyncedItem: UserMenuItem = {
        title: 'Save Unsynced Data',
        icon: 'mdi-cloud-upload',
        tooltip: 'Saves all pending data.',
        action: this.syncData
      }
      const sendFeedbackItem: UserMenuItem = {
        title: 'Send Feedback',
        icon: 'mdi-message-text',
        tooltip: 'Tell us what you like of what we can do better.',
        action: this.sendFeedback
      }
      const logoutItem: UserMenuItem = {
        title: 'Log Out',
        icon: 'mdi-logout',
        tooltip:
          'Performs a logout. A connection to the internet is required to log back in.',
        action: this.logout
      }
      const vegelogicButton: UserMenuItem = {
        title: 'VegLogic',
        icon: 'mdi-leaf',
        tooltip: 'Log into VegLogic.',
        action: this.setLogIntoVegelogic
      }
      const culvertlogicButton: UserMenuItem = {
        title: 'CulvertLogic',
        icon: 'mdi-leaf',
        tooltip: 'Log into CulvertLogic.',
        action: this.setLogIntoCulvertlogic
      }

      if (this.hasUnsyncedData) {
        return [
          updateDataItem,
          saveUnsyncedItem,
          sendFeedbackItem,
          vegelogicButton,
          culvertlogicButton,
          logoutItem
        ]
      } else {
        return [
          updateDataItem,
          sendFeedbackItem,
          vegelogicButton,
          culvertlogicButton,
          logoutItem
        ]
      }
    },
    isThirdPartyCompany(): boolean {
      let company = this.$typedStore.getters.company
      if (company && company.company_type) {
        return company.company_type === 'thirdParty'
      } else {
        return true //if user somehow logs in without company_type return true to at least limit what the invalid user can see
      }
    },
    mapViewButtonClass(): string {
      if (
        this.$route.path === '/overview' ||
        this.$route.path === '/vegetation-issues' ||
        this.$route.path === '/culvert-asset-view'
      ) {
        if (this.$typedStore.state.showMap) {
          return 'white--text'
        } else {
          return 'accent--text text--darken-2'
        }
      } else {
        return 'accent--text text--darken-4'
      }
    },
    listViewButtonClass(): string {
      if (
        this.$route.path === '/overview' ||
        this.$route.path === '/vegetation-issues' ||
        this.$route.path === '/culvert-asset-view'
      ) {
        if (!this.$typedStore.state.showMap) {
          return 'white--text'
        } else {
          return 'accent--text text--darken-2'
        }
      } else {
        return 'accent--text text--darken-4'
      }
    },
    cursorStyle(): string {
      if (
        this.$route.path === '/overview' ||
        this.$route.path === '/vegetation-issues' ||
        this.$route.path === '/culvert-asset-view'
      ) {
        return 'cursor: pointer;'
      } else {
        return ''
      }
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  watch: {
    dataSyncing(): void {
      if (this.dataSyncing === false) {
        if (this.hasUnsyncedData) {
          this.$typedStore.commit('setSnackbarParams', {
            type: 'info',
            msg: 'Data could not be uploaded. Once you have an internet connection you will need to manually sync your data.'
          })
        } else {
          this.callUpdateData(false)
        }
      }
    },
    loggedIntoCulvertlogic(): void {
      this.culvertlogicRouting()
    },
    loggedIntoVegelogic(): void {
      this.vegelogicRouting()
    }
  },
  methods: {
    getSubNavItems(title: string): VTNavItem[] {
      if (title === 'System Setup') {
        return this.setupSystemNavItems
      } else if (title === 'Asset Setup') {
        return this.setupAssetNavItems
      } else {
        return []
      }
    },
    setLogIntoVegelogic(): void {
      this.$typedStore.dispatch('setLoggedIntoTool', {
        loggedIntoVegeLogic: true,
        loggedIntoCulvertLogic: false
      })
    },
    setLogIntoCulvertlogic(): void {
      this.$typedStore.dispatch('setLoggedIntoTool', {
        loggedIntoVegeLogic: false,
        loggedIntoCulvertLogic: true
      })
    },
    sendFeedback() {
      this.$router.push('/feedback')
    },
    culvertlogicRouting(): void {
      const route = this.$router.currentRoute.path
      if (this.loggedIntoCulvertlogic && this.vegelogicRoutes.includes(route)) {
        this.$router.push('/culvert-asset-view')
      }
    },
    vegelogicRouting(): void {
      const route = this.$router.currentRoute.path
      if (this.loggedIntoVegelogic && this.culvertlogicRoutes.includes(route)) {
        this.$router.push('/dashboard')
      }
    },
    changeShowMap(showMap: boolean): void {
      if (
        this.$route.path === '/overview' ||
        this.$route.path === '/vegetation-issues' ||
        this.$route.path === '/culvert-asset-view'
      ) {
        this.$typedStore.commit('setMapView', showMap)
      }
    },
    async assetChanged(assetId: number): Promise<void> {
      if (typeof assetId !== 'number') {
        console.error('Expected assetId to be a number')
        return
      }
      this.turnOffLocationWatch()
      this.$typedStore.commit('setZoom', null)
      this.$typedStore.commit('setPosition', null)
      this.loading = true
      await this.$typedStore.dispatch('doClearFilters')
      await this.$typedStore.dispatch('setSelectedAsset', assetId)
      await this.callUpdateData(true)

      await this.turnOnLocationWatch()
    },
    async logout(): Promise<void> {
      //logout takes a bit now due to having to clear the typeorm databases
      //TODO: text with the loading spinner would be fantastic here - VT-432
      this.loading = true

      await this.$typedStore.dispatch('doLogout')
      this.$router.push('/')
    },
    toggleNav(): void {
      this.sideNav = !this.sideNav
    },
    //onRequestUpdate is only called through an emit, by that logic we should never hit this function without firstTimeSetup being done, so we can always set it to false
    onRequestUpdate(): Promise<void> {
      return this.updateData(false)
    },
    //calling updateData directly with no params caused the editData param to be a MouseEvent, causing issues
    callUpdateData(firstTimeSetup?: boolean): void {
      firstTimeSetup = firstTimeSetup !== undefined ? firstTimeSetup : false
      this.updateData(firstTimeSetup)
    },
    // This function incrementally updates the application state by querying
    // the api with a "last updated" time (if available). If there is not
    // last updated time, all data is retrieved.
    async updateData(firstTimeSetup: boolean): Promise<void> {
      try {
        const appDataUpdatedAt = await this.$typedStore.dispatch(
          'getUpdateTime',
          'app'
        )
        // not using time for now as it doesn't seem necessary, these likely won't change often
        let alerts = await this.$rpc('callGetAlerts', {
          id: (this.$typedStore.getters.jwtContent as JwtContent).data
            .userId as number
        })
        await this.$typedStore.dispatch('setAlerts', alerts)

        let thirdParties = await this.$rpc('callGetCompanyRelations')
        await this.$typedStore.dispatch('setThirdPartyCompanies', thirdParties)

        const appData = await this.$rpc('callGetAppUpdate', {
          since: appDataUpdatedAt,
          userId: (this.$typedStore.getters.jwtContent as JwtContent).data
            .userId as number
        })
        await this.$typedStore.dispatch('updateAppData', appData)

        if (this.asset) {
          const assetUpdatedAt = await this.$typedStore.dispatch(
            'getUpdateTime',
            this.asset.id
          )
          const apiData = await this.$rpc('callGetAssetUpdate', {
            assetId: this.asset.id,
            userId: (this.$typedStore.getters.jwtContent as JwtContent).data
              .userId as number,
            since: assetUpdatedAt
          })

          await this.$typedStore.dispatch('updateAssetData', {
            assetId: this.asset.id,
            data: apiData,
            firstTimeSetup
          })
        }
      } catch (error) {
        console.log(error)
        // As soon as one request fails this will load cached data. If some
        // succeeded first, they will have been stored, so they will be used.
        if (error instanceof NoResponseError) {
          this.$typedStore.commit('setSnackbarParams', {
            type: 'info',
            msg: 'You may be offline or have an intermittent internet connection. Cached data is being used.'
          })
        } else {
          this.$typedStore.commit('setSnackbarParams', {
            type: 'error',
            msg: 'Error loading data. Please ensure your app is up to date and you have a connection to the internet.'
          })
        }
      }

      this.loading = false
    },
    async syncData(): Promise<void> {
      if (this.dataSyncing) {
        return
      }
      await this.$typedStore.dispatch('runSync')
    },
    async asyncCreated(): Promise<void> {
      try {
        const relations = await this.$rpc('callGetRelations')
        await this.$typedStore.dispatch('setRelations', relations)

        // Try to update state with anything new from the api
        await this.callUpdateData(true)
      } catch (error) {
        if (error instanceof NoResponseError) {
          this.$typedStore.commit('setSnackbarParams', {
            type: 'info',
            msg: 'You may be offline or have an intermittent internet connection. Cached data is being used.'
          })
        }
      }
      await this.turnOnLocationWatch()

      this.loading = false
    },
    async turnOnLocationWatch(): Promise<void> {
      if (this.checkMobileDevice) {
        let locationPermission = await Geolocation.checkPermissions()
        if (
          locationPermission.location != 'granted' &&
          locationPermission.location != 'denied'
        ) {
          locationPermission = await Geolocation.requestPermissions()
        }
        if (locationPermission.location == 'granted') {
          this.watchId = await Geolocation.watchPosition(
            positionOptions,
            this.savePoint
          )
        }
      }
    },
    turnOffLocationWatch(): void {
      if (this.watchId !== null) {
        Geolocation.clearWatch({ id: this.watchId as string })
      }
    },
    savePoint(position: Position | null): void {
      if (position) {
        if (position.coords.accuracy > 25) {
          return
        }
        this.$typedStore.state.latestPosition = position
      }
    },
    openExitDialog() {
      switch (this.$route.name) {
        case 'Edit Vegetation Issue':
          this.$children[2].$data.exitEditingDialog = true
          break
        case 'Add Issue':
          this.$children[2].$data.exitDialog = true
          break
        case 'Add Culvert Inspection':
          this.$children[2].$data.exitDialog = true
          break
        case 'Edit Culvert':
          this.$children[2].$data.exitDialog = true
          break
        case 'Edit Culvert Inspection':
          this.$children[2].$data.exitDialog = true
          break
      }
    },
    async getsafeAreaPadding(): Promise<void> {
      let safeArea = await SafeArea.getSafeAreaInsets()

      this.topBarPaddingStyle = ''
      this.topMarginStyle = ''
      this.leftPaddingStyle = ''
      this.rightPaddingStyle = ''
      this.navDrawerWidthStyle = ''

      // safeArea.insets.left = 44

      //vuetify default height for the toolbar is 56px on mobile https://vuetifyjs.com/en/components/toolbars/#usage
      this.topBarPaddingStyle = `height: ${
        safeArea.insets.top + 56
      }px; padding-top: ${safeArea.insets.top}px; `
      //vuetify default width for navigation drawer is 256
      this.navDrawerWidthStyle = `width: ${safeArea.insets.left + 256}px;`

      //main content needs margin as padding keeps getting overridden by vuetifyjs, otherwise we'll use padding
      this.topMarginStyle = `margin-top: ${safeArea.insets.top}px !important;`
      this.leftPaddingStyle = `padding-left: ${safeArea.insets.left}px;`
      this.leftMarginStyle = `margin-left: ${safeArea.insets.left}px;`
      this.rightPaddingStyle = `padding-right: ${safeArea.insets.right}px;`
      this.rightMarginStyle = `margin-right: ${safeArea.insets.right}px;`
    }
  },
  created(): void {
    //actually need this set here, using 'this' in the listener refers to window and not the Vue component
    let app = this

    // When created, first check for a valid token, otherwise navigate to login
    if (!app.$typedStore.getters.isLoggedIn) {
      app.$router.replace('/login')
      return
    }
    if (app.loggedIntoVegelogic) app.vegelogicRouting()
    else app.culvertlogicRouting()

    //only add the listener if the user is on a mobile device
    if (this.checkMobileDevice) {
      app.getsafeAreaPadding()
      app.sideNav = false

      window.addEventListener('orientationchange', async function () {
        await app.getsafeAreaPadding()
      })
    }

    // Just call a helper method to simplify assigning promise
    app.createdPromise = app.asyncCreated()
  }
})

interface LoggedInWrapperI {
  loading: boolean
  createdPromise: Promise<void> | null
  sideNav: boolean
  watchId: string | null
  vegelogicRoutes: string[]
  culvertlogicRoutes: string[]
  topBarPaddingStyle: string
  topMarginStyle: string
  leftPaddingStyle: string
  leftMarginStyle: string
  rightPaddingStyle: string
  rightMarginStyle: string
  navDrawerWidthStyle: string
}

interface VTNavItem extends NavItem {
  title: string
  link: string
  isTitle: boolean
}
