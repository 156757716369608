import { IsDateString, IsInt, IsOptional } from 'class-validator'

import {
  SubAssetObject,
  AssetObject,
  CompanyObject,
  UserObject,
  AreaObject,
  IssueObject,
  VegSpeciesObject,
  CulvertObject,
  CulvertInspectionsObject
} from './newResultTypes'

import { GetThirdPartyCompanyRelationsResponse } from './thirdPartyCompanies'

export class GetAppRequest {
  constructor(x: GetAppRequest) {
    if (x) {
      this.since = x.since
      this.userId = x.userId
    }
  }

  @IsOptional()
  @IsDateString()
  since!: string | null

  @IsInt()
  userId!: number
}

export class GetAppResponse {
  constructor(x: GetAppResponse) {
    if (x) {
      this.since = x.since
      this.company = x.company
      this.users = x.users
      this.assets = x.assets
      this.thirdPartyCompanies = x.thirdPartyCompanies
    }
  }

  since!: string
  company!: CompanyObject
  users!: UserObject[]
  assets!: AssetObject[]
  thirdPartyCompanies!: GetThirdPartyCompanyRelationsResponse[]
}
export class GetAssetRequest {
  constructor(x: GetAssetRequest) {
    if (x) {
      this.assetId = x.assetId
      this.userId = x.userId
      this.since = x.since
    }
  }

  @IsInt()
  assetId!: number

  @IsInt()
  userId!: number

  @IsOptional()
  @IsDateString()
  since!: string | null
}

export class GetAssetResponse {
  constructor(x: GetAssetResponse) {
    if (x) {
      this.since = x.since
      this.areas = x.areas
      this.issues = x.issues
      this.subAssets = x.subAssets
      this.assetAssociatedSpecies = x.assetAssociatedSpecies
      this.culverts = x.culverts
      this.culvertInspections = x.culvertInspections
    }
  }

  since!: string
  areas!: AreaObject[]
  issues!: IssueObject[]
  subAssets!: SubAssetObject[]
  assetAssociatedSpecies!: VegSpeciesObject[]
  culverts!: CulvertObject[]
  culvertInspections!: CulvertInspectionsObject[]
}
