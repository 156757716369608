import { BaseEntity } from './BaseEntity'

import { Entity, Column } from 'typeorm'
import { IsInt, IsString, IsBoolean } from 'class-validator'

import { UserObject } from '../../veg-common/apiTypes/newResultTypes'

@Entity()
export class UserToken extends BaseEntity {
  constructor(token?: string) {
    super()
    if (token) {
      this.token = token
    }
  }

  @Column('text')
  @IsString()
  token!: string
}

@Entity()
export class UsersEntity extends BaseEntity {
  constructor(x?: UserObject) {
    super()
    if (x) {
      this.id = x.id
      this.user_name = x.user_name
      this.email = x.email
      this.first_name = x.first_name
      this.last_name = x.last_name
      this.userRoleId = x.userRoleId
      this.is_active = x.is_active
      this.assignedIssues = JSON.stringify(x.assignedIssues)
    }
  }

  toApiType(): UserObject {
    return {
      id: this.id,
      user_name: this.user_name,
      email: this.email,
      first_name: this.first_name,
      last_name: this.last_name,
      userRoleId: this.userRoleId,
      is_active: this.is_active,
      assignedIssues: JSON.parse(this.assignedIssues)
    }
  }

  @Column({ type: 'int', unique: true })
  @IsInt()
  id!: number

  @Column('text')
  @IsString()
  user_name!: string

  @Column('text')
  @IsString()
  email!: string

  @Column('text')
  @IsString()
  first_name!: string

  @Column('text')
  @IsString()
  last_name!: string

  @Column('int')
  @IsInt()
  userRoleId!: number

  @Column('boolean')
  @IsBoolean()
  is_active!: boolean

  @Column('text')
  @IsString()
  assignedIssues!: string
}
