














































import Vue from 'vue'
import { JwtContent } from '../veg-common/jwt'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data(): ResetPasswordI {
    return {
      feedbackContent: '',
      sending: false
    }
  },
  methods: {
    async sendFeedback(): Promise<void> {
      if (!navigator.onLine) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Cannot Connect to Internet, Please Try Again Later'
        })
        return
      }
      //@ts-ignore
      let valid = await this.$refs.validator.validate()
      if (!valid) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Feedback Content Cannot Be Empty.'
        })
        return
      }
      let result = await this.$rpc('callSendFeedback', {
        userId: (this.$typedStore.getters.jwtContent as JwtContent).data
          .userId as number,
        feedbackContent: this.feedbackContent
      })
      if (!result.success) {
        this.$typedStore.commit('setSnackbarParams', {
          type: 'error',
          msg: 'Feedback Sent Failed'
        })
        return
      }
      this.feedbackContent = ''
      this.$typedStore.commit('setSnackbarParams', {
        type: 'success',
        msg: 'Feedback Sent Successfully'
      })
      this.$router.push({ name: 'Home' })
    },
    close(): void {
      this.$router.push({ name: 'Home' })
    }
  }
})

interface ResetPasswordI {
  feedbackContent: string
  sending: boolean
}
