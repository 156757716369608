

























































































import Vue from 'vue'
import { ManagementAreaResult } from '../../veg-common/apiTypes'
import { DataTableHeader } from 'vuetify/types'
import SeeIssueDialog from '../../components/SeeIssueDialog.vue'

import { riskToColour } from '../../util/drawing'
import { determineMaxRisk } from '../../util/CalculateIssueRisk'

export default Vue.extend({
  components: {
    SeeIssueDialog
  },
  props: {
    managementAreas: {
      type: Array as () => ManagementAreaResult[]
    }
  },
  data(): AssetViewTableI {
    return {
      areas: [],
      headers: [
        {
          text: 'Risk Level',
          value: 'riskColor',
          class: 'altTableBlue font-weight-black white--text'
        },
        {
          text: 'Area Name',
          value: 'name',
          class: 'altTableBlue font-weight-black white--text'
        },
        {
          text: 'Issue Count',
          value: 'originalIssueCount',
          class: 'altTableBlue font-weight-black white--text'
        },
        {
          text: 'Map Of Issues',
          value: 'mapDialog',
          sortable: false,
          class: 'altTableBlue font-weight-black white--text'
        }
      ],
      managementAreaSearch: ''
    }
  },
  computed: {
    dataForTable(): AreaWithMaxRisk[] {
      return this.areas.filter((item) => {
        if (
          !this.managementAreaSearch ||
          this.managementAreaSearch.replace(/\s/g, '').length === 0
        ) {
          return true
        }
        return (
          item.area.name.toLowerCase().replace(/\s/g, '') ===
          this.managementAreaSearch.toLowerCase().replace(/\s/g, '')
        )
      })
    },
    isXSmallScreen(): boolean {
      return this.$vuetify.breakpoint.xsOnly
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    sortTextFieldClass(): string {
      return this.isSmallScreen ? 'mb-3' : 'mb-5'
    },
    absolutePageControlBreakpoint(): boolean {
      //420px width found by manually moving the browser window until the point where the absolute button group clips with the search bar
      if (this.$vuetify.breakpoint.width <= 420) {
        return true
      } else {
        return false
      }
    },
    mobileSearchWidth(): number {
      if (this.absolutePageControlBreakpoint) {
        return 12
      } else {
        return 6
      }
    }
  },
  methods: {
    getRiskColour(maxRisk: number | null): string {
      return riskToColour(maxRisk)
    },
    getRiskText(maxRisk: number | null): string {
      switch (maxRisk) {
        case 0:
          return 'N/A'
          break
        case 1:
          return 'Low'
          break
        case 5:
          return 'Medium'
          break
        case 10:
          return 'High'
          break
      }
      return 'N/A'
    }
  },
  created() {
    let areas: AreaWithMaxRisk[] = []

    for (let area of this.managementAreas) {
      let maxRisk = determineMaxRisk(area.issues)
      areas.push({ area, maxRisk })
    }

    this.areas = areas
  }
})

interface AssetViewTableI {
  areas: AreaWithMaxRisk[]
  headers: DataTableHeader[]
  managementAreaSearch: string
}

interface AreaWithMaxRisk {
  area: ManagementAreaResult
  maxRisk: number | null
}
